import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Typography,
  Tooltip,
  Box,
  TextField,
  IconButton,
  Chip,
  Modal,
} from "@mui/material";
import useStyles from "./styles";
import DashboardLayout from "../../../layouts/DashboardLayout";
import {
  addReviewComments,
  approveMonthlyApproval,
  assignMonthlyApproval,
  resetApprovalData,
  roleBasedApproval,
  submitMonthlyApproval,
} from "../../../redux/actions";
import CeroCheckboxTable from "../../../components/CeroCheckboxTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  StationaryColumns,
  MobileColumns,
  PurchasedElectricityColumns,
  WaterDischargeColumns,
  WaterConsumptionColumns,
  RefrigerantsColumns,
  TransportationColumns,
  WasteCombustionColumns,
} from "../../ApprovalStatus/MonthlyFacilityDetails/ApprovalStatusTable/ApprovalTableColumns";
import { STATUS } from "../../../redux/constants";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { getCookie } from "../../../services/cookie";
import CeroPopUp from "../../../components/CeroPopUp";
import CeroButton from "../../../components/CeroButton";
import { useSnackbar } from "notistack";
import CreateIcon from "@mui/icons-material/CreateOutlined";
import Download from "../../../assets/images/Download";
import CloseIcon from "@mui/icons-material/Close";

const ApprovalTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, type, subType } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  let userRole = getCookie("role");
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [reviewPopup, setReviewPopup] = useState(false);
  const [reviewConfirm, setReviewConfirm] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const [canShowReviewButton, setCanShowReviewButton] = useState(true);
  const [comment, setComment] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePdfOpen = () => {
    setIsModalOpen(true);
  };

  const handlePdfClose = () => {
    setIsModalOpen(false);
  };

  const newColumn = {
    columnKey: "attachment", // Unique key for the column
    columnId: "attachment", // ID for internal reference
    columnHeader: "Attachment", // Column header to display
  };

  const getTableColumn = {
    stationary_combustion: [...StationaryColumns, newColumn],
    mobile_combustion: [...MobileColumns, newColumn],
    purchased_electricity: [...PurchasedElectricityColumns, newColumn],
    water_discharge: [...WaterDischargeColumns, newColumn],
    water_consumption: [...WaterConsumptionColumns, newColumn],
    refrigerants: [...RefrigerantsColumns, newColumn],
    transportation: [...TransportationColumns, newColumn],
    waste: [...WasteCombustionColumns, newColumn],
  };

  useEffect(() => {
    dispatch(resetApprovalData());
    dispatch(roleBasedApproval(id, type, subType));
  }, [dispatch, id, type, subType]);

  const approvalData = useSelector(
    (state) => state.approval.roleBasedApproval.data?.topic_list
  );
  const approvalDataStatus = useSelector(
    (state) => state.approval.roleBasedApproval.status
  );

  const submitStatus = useSelector(
    (state) => state.approval.submitMonthlyApproval
  );

  const assignStatus = useSelector(
    (state) => state.approval.assignMonthlyApproval
  );

  const approvalStatus = useSelector(
    (state) => state.approval.approveMonthlyApproval
  );

  const reviewStatus = useSelector((state) => state.approval.reviewComments);

  const dataCount = useSelector(
    (state) => state.approval.roleBasedApproval.data?.emission_count
  );

  const handlePdfPopup = () => {
    return (
      <embed
        src={`${approvalData?.blob_url}#toolbar=0`}
        type="application/pdf"
        width="100%"
        height="600px"
      />
    );
  };

  const itemCount = approvalData?.length;
  const canReviewActionDoIds = approvalData
    ?.filter((item) => item.can_review_action_do)
    .map((item) => item.id);
  const approvalDataLength = getCookie("approvalDataLength")
    ? parseInt(getCookie("approvalDataLength"))
    : 0;
  const getStatusColor = (item) => {
    if (item === "approved") {
      return (
        <Tooltip title="Approved" arrow>
          <Chip className={classes.signalApproved} />
        </Tooltip>
      );
    } else if (item === "submitted") {
      return (
        <Tooltip title="Submitted" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "audited") {
      return (
        <Tooltip title="Audited" arrow>
          <Chip className={classes.signalAudited} />
        </Tooltip>
      );
    } else if (item === "review") {
      return (
        <Tooltip title="Review" arrow>
          <Chip className={classes.signalReview} />
        </Tooltip>
      );
    } else if (item === "added") {
      return (
        <Tooltip title="Added" arrow>
          <Chip className={classes.signalAdded} />
        </Tooltip>
      );
    } else if (item === "assigned") {
      return (
        <Tooltip title="assigned" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "verified") {
      return (
        <Tooltip title="Verified" arrow>
          <Chip className={classes.signalVerified} />
        </Tooltip>
      );
    } else if (item === "Awaiting approval") {
      return (
        <Tooltip title="Awaiting approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "In Progress") {
      return (
        <Tooltip title="In Progress" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else if (item === "pending_approval") {
      return (
        <Tooltip title="Pending Approval" arrow>
          <Chip className={classes.signalPending} />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={item} arrow>
          <Chip className={classes.signalAdded} />
          {item}
        </Tooltip>
      );
    }
  };

  const onEditEmissionData = (e, emission, status, ticketId) => {
    e.stopPropagation();
    e.preventDefault();
    if (status === "review") {
      navigate(`/tickets/id?ticketId=${ticketId}`);
    } else {
      navigate(`/emissions/edit/${type}/${emission}`);
    }
  };

  const getEmissionData = () => {
    return Array.isArray(approvalData)
      ? approvalData.map((item) => ({
          ...item,
          status: item.status ? getStatusColor(item.status) : "",
          treatment_required: item.treatment_required === true ? "Yes" : "No",
          pending_action_role:
            item.pending_action_role === null ? (
              "NA"
            ) : (
              <Box className={classes.actionContainer}>
                {item.pending_action_role}
                {userRole === "business_user" &&
                  !readOnlyMode &&
                  (item.status === "added" || item.status === "review") && (
                    <IconButton
                      className={classes.editIcon}
                      onMouseDown={(e) => {
                        onEditEmissionData(
                          e,
                          item.id,
                          item.status,
                          item.ticket_id
                        );
                      }}
                    >
                      <CreateIcon />
                    </IconButton>
                  )}
              </Box>
            ),
          amount: item.amount
            ? item.unit
              ? `${item.amount} ${item.unit}`
              : item.amount
            : "",
          attachment: item.blob_url ? (
            <div
              style={{ transform: "scale(0.6)", transformOrigin: "center" }}
              onClick={handlePdfOpen}
            >
              <Download />
            </div>
          ) : (
            "-"
          ),
        }))
      : [];
  };

  console.log("approvalData", approvalData);

  const handleCheckedRowsChange = (newCheckedRows) => {
    setCheckedRows(newCheckedRows);
    setCanShowReviewButton(
      newCheckedRows.every((element) => canReviewActionDoIds.includes(element))
    );
  };
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleReviewPopup = () => {
    setReviewConfirm(true);
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setReviewPopup(false);
  };

  const handleAction = () => {
    if (userRole === "business_user") {
      dispatch(submitMonthlyApproval(id, subType, checkedRows));
    } else if (userRole === "facility_manager") {
      dispatch(assignMonthlyApproval(id, checkedRows));
    } else {
      dispatch(approveMonthlyApproval(id, subType, checkedRows));
    }
    handleClosePopup();
  };

  useEffect(() => {
    if (submitStatus.status === STATUS.SUCCESS) {
      if (itemCount === checkedRows.length) {
        if (approvalDataLength > 1) {
          dispatch(resetApprovalData());
          navigate(-1);
        } else {
          dispatch(resetApprovalData());
          if (userRole === "business_user") {
            navigate("/request-approvals");
          } else {
            navigate("/pending-approvals");
          }
        }
      }
      dispatch(resetApprovalData());
      dispatch(roleBasedApproval(id, type, subType));
      enqueueSnackbar(submitStatus?.data?.message, {
        variant: "success",
        key: "submit",
      });
    } else if (submitStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        "error" || "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetApprovalData());
    }
  }, [enqueueSnackbar, submitStatus, dispatch, id, type, subType]);

  useEffect(() => {
    if (assignStatus.status === STATUS.SUCCESS) {
      if (itemCount === checkedRows.length) {
        if (approvalDataLength > 1) {
          dispatch(resetApprovalData());
          navigate(-1);
        } else {
          if (userRole === "business_user") {
            navigate("/request-approvals");
          } else {
            navigate("/pending-approvals");
          }
        }
      }
      dispatch(resetApprovalData());
      dispatch(roleBasedApproval(id, type, subType));
      enqueueSnackbar(assignStatus?.data?.message, {
        variant: "success",
        key: "assign",
      });
    } else if (assignStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        assignStatus.data.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetApprovalData());
    }
  }, [enqueueSnackbar, assignStatus, dispatch, id, type]);

  useEffect(() => {
    if (approvalStatus.status === STATUS.SUCCESS) {
      if (itemCount === checkedRows.length) {
        if (approvalDataLength > 1) {
          dispatch(resetApprovalData());
          navigate(-1);
        } else {
          if (userRole === "business_user") {
            navigate("/request-approvals");
          } else {
            navigate("/pending-approvals");
          }
        }
      }
      dispatch(resetApprovalData());
      dispatch(roleBasedApproval(id, type, subType));
      enqueueSnackbar(approvalStatus?.data?.message, {
        variant: "success",
        key: "approve",
      });
    } else if (approvalStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        approvalStatus.data.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetApprovalData());
    }
  }, [enqueueSnackbar, approvalStatus, dispatch, id, type]);

  useEffect(() => {
    if (reviewStatus.status === STATUS.SUCCESS) {
      dispatch(resetApprovalData());
      dispatch(roleBasedApproval(id, type, subType));
      enqueueSnackbar(reviewStatus?.data?.message, {
        variant: "success",
        key: "review",
      });
    } else if (reviewStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        reviewStatus.data.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetApprovalData());
    }
  }, [enqueueSnackbar, reviewStatus, dispatch, id, type]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const onSubmitReview = () => {
    setReviewPopup(false);
    dispatch(addReviewComments(id, checkedRows, comment));
  };

  const getReviewComments = () => {
    return (
      <>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={5}
          placeholder="Comment"
          style={{ width: "100%" }}
          value={comment}
          onChange={handleCommentChange}
          className={classes.textField}
        />
        <div className={classes.textLimit}>
          <div className={classes.noComments}>
            {comment.trim().length === 0 ? "No comments" : ""}
          </div>
          <div className={classes.maxCharacters}>
            {"Maximum 200 characters"}
          </div>
        </div>
        <div className={classes.reviewButtons}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={onSubmitReview}
            buttonText="Save"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setReviewPopup(false)}
            buttonText="Cancel"
          />
        </div>
      </>
    );
  };

  const getIcon = () => {
    if (userRole === "business_user") {
      return (
        <Tooltip title="Submit" arrow>
          <UploadOutlinedIcon
            onClick={handleOpenPopup}
            className={classes.icon}
          />
        </Tooltip>
      );
    } else {
      return (
        <>
          <Tooltip title="Approve" arrow>
            <CheckCircleOutlineOutlinedIcon
              onClick={handleOpenPopup}
              className={classes.icon}
            />
          </Tooltip>
          {canShowReviewButton && (
            <Tooltip title="Review" arrow>
              <ArrowCircleLeftOutlinedIcon
                onClick={handleReviewPopup}
                className={classes.icon}
              />
            </Tooltip>
          )}
        </>
      );
    }
  };

  const getConfirmation = () => {
    return (
      <Box className={classes.popUp}>
        {userRole === "business_user" ? (
          <Typography variant="body1" style={{ fontWeight: 500 }}>
            Are you sure you want to submit {checkedRows.length} records?
          </Typography>
        ) : (
          <Typography variant="body1" style={{ fontWeight: 500 }}>
            Are you sure you want to approve {checkedRows.length} records?
          </Typography>
        )}
        <div className={classes.buttonContainer}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={handleAction}
            buttonText="Yes"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={handleClosePopup}
            buttonText="No"
          />
        </div>
      </Box>
    );
  };

  const getReviewConfirm = () => {
    return (
      <Box className={classes.popUp}>
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          Are you sure you want to sent {checkedRows.length} records to review?
        </Typography>
        <div className={classes.buttonContainer}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={() => {
              setReviewPopup(true);
              setReviewConfirm(false);
            }}
            buttonText="Yes"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setReviewConfirm(false)}
            buttonText="No"
          />
        </div>
      </Box>
    );
  };

  const navigateBack = () => {
    if (userRole === "business_user") {
      if (dataCount > 0) {
        navigate(-1);
      } else {
        navigate(`/request-approvals`);
      }
    } else {
      if (dataCount > 0) {
        navigate(-1);
      } else {
        navigate(`/pending-approvals`);
      }
    }
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <div className={classes.topContainer}>
          <Typography variant="h6">{type.replace(/_/g, " ")}</Typography>
          <CeroButton
            buttonText="Back"
            variant="outlined"
            className={classes.buttonSecondary}
            startIcon={<ArrowBackIcon />}
            onClick={navigateBack}
          />
        </div>
        {approvalDataStatus === STATUS.SUCCESS ? (
          <CeroCheckboxTable
            columns={getTableColumn[type] || []}
            data={getEmissionData()}
            hasMore={false}
            loading={false}
            // loadMore={onLoadMore}
            classes={{ tableContainer: classes.tableContainer }}
            showSelectAllCheckbox={true}
            isSelectable
            showDropdown={true}
            checkedRows={checkedRows}
            onCheckedRowsChange={handleCheckedRowsChange}
            showIcon={true && getIcon()}
          />
        ) : (
          ""
        )}
        <CeroPopUp
          primaryPopUp={{
            open: isPopupOpen,
            onClose: () => setIsPopupOpen(false),
            content: getConfirmation(),
            header: { title: "" },
          }}
          showCloseButton={true}
          classes={{ dialog: classes.dialog }}
        />
        <CeroPopUp
          primaryPopUp={{
            open: reviewPopup,
            onClose: () => setReviewPopup(false),
            content: getReviewComments(),
            header: { title: "Review Comments" },
          }}
          showCloseButton={true}
        />
        <CeroPopUp
          primaryPopUp={{
            open: reviewConfirm,
            onClose: () => setReviewConfirm(false),
            content: getReviewConfirm(),
            header: { title: "" },
          }}
          showCloseButton={true}
          classes={{ dialog: classes.dialog }}
        />
        <CeroPopUp
          primaryPopUp={{
            open: reviewConfirm,
            onClose: () => setReviewConfirm(false),
            content: getReviewConfirm(),
            header: { title: "" },
          }}
          showCloseButton={true}
          classes={{ dialog: classes.dialog }}
        />
        <Modal open={isModalOpen} onClose={handlePdfClose}>
          <Box className={classes.modalContent}>
            <CloseIcon
              onClick={handlePdfClose}
              className={classes.closeButton}
            />
            {handlePdfPopup()}
          </Box>
        </Modal>
      </Container>
    </DashboardLayout>
  );
};

export default ApprovalTable;
