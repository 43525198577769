import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import useStyles from "./styles";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroTable from "../../../../components/CeroTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getStandardsQuestionnaireHistory,
} from "../../../../redux/actions";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { getCookie } from "../../../../services/cookie";
import { rolesEnum } from "../../../../layouts/DashboardLayout/pages";

export const columns = [
  {
    columnKey: "country",
    columnId: "country",
    columnHeader: "Country",
  },
  {
    columnKey: "assessment_year",
    columnId: "assessment_year",
    columnHeader: "Year",
  },
  {
    columnKey: "framework",
    columnId: "framework",
    columnHeader: "Framework",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "Action",
  },
];

function StandardsQuestionnaireTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [year, setYear] = useState(null);
  const role = getCookie("role");
  const isSusManager = role === rolesEnum.SUSTAINABILITY_MANAGER;

  const data = useSelector(
    (state) => state.assessment.getStandardsQuestionnaireHistory.data
  );

  const countryData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_data
  );
  useEffect(() => {
    dispatch(getStandardsQuestionnaireHistory());
  }, [dispatch]);

  const navigateBack = () => {
    navigate(-1);
  };

  const getTicketsList = () =>
    data?.map((item) => ({
      ...item,
      country: item.country,
      assessment_year: item.assessment_year,
      framework: item.framework,
      status:
        item?.status === "approved" ? (
          <div className={classes.approved}>Approved</div>
        ) : item?.status === "in progress" ? (
          <div className={classes.inProgress}>In Progress</div>
        ) : (
          <div className={classes.submitted}>Submitted</div>
        ),
      action:
        item?.status === "approved" ? (
          <div
            className={classes.linkButton}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/emissions/standards-questionnaire/answer/${item.id}`, {
                state: {
                  framework_id: item.framework_id,
                  questionnaire_id: item.id,
                },
              });
            }}
          >
            View
            <VisibilityOutlinedIcon className={classes.editIcon} />
          </div>
        ) : item?.status === "in progress" ? (
          <div
            className={classes.linkButton}
            onClick={(e) => {
              const countryCode = countryData[item.country];
              e.stopPropagation();
              navigate(`/emissions/standards-questionnaire/${item.id}`, {
                state: {
                  id: item.id,
                  approve: false,
                  framework_id: item.framework_id,
                  country: countryCode,
                  year: item.assessment_year,
                },
              });
            }}
          >
            Edit
            <EditOutlinedIcon className={classes.editIcon} />
          </div>
        ) : isSusManager ? (
          <div
            className={classes.linkButton}
            onClick={(e) => {
              const countryCode = countryData[item.country];
              e.stopPropagation();
              navigate(`/emissions/standards-questionnaire/${item.id}`, {
                state: {
                  id: item.id,
                  framework_id: item.framework_id,
                  country: countryCode,
                  year: item.assessment_year,
                  approve: true,
                },
              });
            }}
          >
            Manage Data
          </div>
        ) : (
          <div
            className={classes.linkButton}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/emissions/standards-questionnaire/answer/${item.id}`, {
                state: {
                  framework_id: item.framework_id,
                  questionnaire_id: item.id,
                },
              });
            }}
          >
            View
            <VisibilityOutlinedIcon className={classes.editIcon} />
          </div>
        ),
    }));

  return (
    <DashboardLayout>
      <Grid container>
        <Box className={classes.buttonContainer}>
          <div className={classes.backContainer} onClick={navigateBack}>
            <ArrowBackIosIcon />
            Back
          </div>
        </Box>
        <Grid width={"99%"}>
          <CeroTable
            columns={columns}
            data={getTicketsList()}
            hasMore={false}
            loading={false}
            classes={{ tableContainer: classes.tableContainer }}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default StandardsQuestionnaireTable;
