import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, Grid } from "@mui/material";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroTable from "../../../components/CeroTable";
import { sampleYear } from "../../../constants";
import { getAuditTypes } from "../../../redux/actions";
import dayjs from "dayjs";
import useStyles from "./styles";
import CeroButton from "../../../components/CeroButton";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../../layouts/DashboardLayout";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const AuditHistory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedYear, setSelectedYear] = useState(null);
  const [loading, setLoading] = useState(false);

  const auditData = useSelector((state) => state.listings.getAuditTypes.data);

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const auditHistoryColumns = [
    { columnKey: "country", columnId: "country", columnHeader: "Country" },
    {
      columnKey: "assessment_year",
      columnId: "assessment_year",
      columnHeader: "Year",
    },
    { columnKey: "period", columnId: "period", columnHeader: "Audit Period" },
    {
      columnKey: "auditType",
      columnId: "auditType",
      columnHeader: "Audit Type",
    },
    {
      columnKey: "assigned_to_name",
      columnId: "assigned_to_name",
      columnHeader: "Auditor",
    },
    { columnKey: "topics", columnId: "topics", columnHeader: "Audit Items" },
    {
      columnKey: "dateAdded",
      columnId: "dateAdded",
      columnHeader: "Date Added",
    },
  ];

//   const getAuditHistory = () =>
//     auditData?.map((item) => ({
//       ...item,
//       auditType: item.is_self_audited ? "Internal" : "External",
//       topics: (
//         <Typography className={classes.topic}>
//           {item.topics
//             ? item.topics.map((topic, index) => <div key={index}>{topic}</div>)
//             : ""}
//         </Typography>
//       ),
//       dateAdded: dayjs(item.date_added).format("DD/MM/YYYY"),
//     }));

const getAuditHistory = () =>
    auditData?.map((item) => {
      const formattedDate = dayjs(item.date_added);
      return {
        ...item,
        auditType: item.is_self_audited ? "Internal" : "External",
        topics: (
          <Typography className={classes.topic}>
            {item.topics
              ? item.topics.map((topic, index) => <div key={index}>{topic}</div>)
              : ""}
          </Typography>
        ),
        dateAdded: formattedDate.isValid() ? formattedDate.format("DD/MM/YYYY") : "",
      };
    });
  

useEffect(() => {
    setLoading(true);
    const year = selectedYear === "Year" || selectedYear === null ? 0 : selectedYear;
    dispatch(getAuditTypes(year, ""));

    setLoading(false);
  }, [dispatch, selectedYear]);
  

  return (
    <DashboardLayout>
      <Box className={classes.containerBox}>
        <div className={classes.backContainer} 
          onClick={() => navigate(-1)}>
          <ArrowBackIosIcon />
          Back
        </div>

        <div className={classes.yearSelection}>
          <CeroAutoComplete
            required
            id="year"
            label="Year"
            value={selectedYear}
            onChange={(e, value) => setSelectedYear(value ? value.id : null)}
            options={yearList}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </div>

        <CeroTable
          columns={auditHistoryColumns}
          data={getAuditHistory()}
          hasMore={false}
          loading={loading}
          classes={{ tableContainer: classes.tableContainer }}
        />
      </Box>
    </DashboardLayout>
  );
};

export default AuditHistory;
