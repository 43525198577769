import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PermissionDenied from "../pages/PermissionDenied";
import SessionTimeOut from "../Hooks/sessionTimeOut";
import { getCookie } from "../services/cookie";

const TradePrivateRoute = ({ children, redirectTo, rolesAllowed = [] }) => {
  // let isAuthenticated = useSelector(
  //   (state) => state.auth.userInfoTrade
  // );
  let isAuthenticated = getCookie("auth_token_trade")
  // const userRole = useSelector((state) => state.auth.userInfoTrade.role);
  const userRole = getCookie("role_trade")
  const isAllowed = userRole
    ? rolesAllowed.some((role) => userRole.includes(role))
    : "";

  return (
    <SessionTimeOut>
      {isAuthenticated ? (
        isAllowed ? (
          children
        ) : (
          <PermissionDenied />
        )
      ) : (
        <Navigate to={ redirectTo} />
      )}
    </SessionTimeOut>
  );
};

export default TradePrivateRoute;
