import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/system";
import {
  Avatar,
  Tooltip,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
} from "@mui/material";
import { Logout, LockResetOutlined } from "@mui/icons-material";
import { clearDashboardData, resetApprovalDetails, showSwitchUserModal, userLogout } from "../../../redux/actions";
import useStyles from "./styles";
import { getCookie } from "../../../services/cookie";
import switchCompanyIcon from "../../../assets/icons/switchCompanyIcon.png";
import subscriptionIcon from "../../../assets/icons/subscriptionIcon.png"
import switchRole from "../../../assets/icons/switchRole.png";
import { userRoles } from "../../../constants";

const popoverStyle = {
  overflow: "visible",
  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
  mt: 1.5,
  "& .MuiAvatar-root": {
    width: 30,
    height: 30,
    ml: -0.5,
    mr: 1,
  },
  "&:before": {
    content: '""',
    display: "block",
    position: "absolute",
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    bgcolor: "background.paper",
    transform: "translateY(-50%) rotate(45deg)",
    zIndex: 0,
  },
};

const SettingsMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const role = getCookie("role");
  const isTradePlatform = window.location.pathname.includes("trade");
  
  const handleSwitchRole = () => {
    dispatch(showSwitchUserModal(true))
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToPage = (path) => {
    navigate(path);
  };

  const getValueForKey = (key) => {
    const role = userRoles.find(role => role.key === key);
    return role ? role.value : null;
  };

  const handleLogout = ()=>{
     dispatch(userLogout(userInfo?.email));
     dispatch(clearDashboardData())
     dispatch(resetApprovalDetails())
  }

const profileNavigation = () => {
  if(role === "trade_admin" || isTradePlatform){
    navigate("/trade-profile")
  }else{
    navigateToPage("/profile")
  }
}
  return (
    <Box className={classes.settingsMenuContainer}>
      <Tooltip
        title={
          <>
            <div>Current Role</div>
            <div>{getValueForKey(role)}</div>
          </>
        }
      >
        <Box onClick={handleClick}>
          <Avatar
            alt={userInfo.name}
            src={userInfo.logo}
            children={
              userInfo.name ? userInfo.name.charAt(0).toUpperCase() : 
              (userInfo.user_name ? userInfo.user_name.charAt(0).toUpperCase() : <Avatar />)
            }
            className={classes.avatar}
          />
        </Box>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        disableAutoFocusItem
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: { ...popoverStyle },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          className={classes.text}
          // onClick={() => navigateToPage("/profile")}
          onClick={profileNavigation}
        >
          <Avatar className={classes.text} /> My Profile
        </MenuItem>
        <Divider />
        {role !== "auditor" && role !== "super_admin" && role !== "read_only_user" && role !== "trade_admin" && !isTradePlatform ? (
          <MenuItem className={classes.text} onClick={() => handleSwitchRole()}>
            <img src={switchRole} style={{ paddingRight: 10 }} alt="sr" />
            Switch Role
          </MenuItem>
        ) : (
          ""
        )}
          <MenuItem
            className={classes.text}
            onClick={() => navigateToPage("/change-password")}
          >
            <ListItemIcon>
              <LockResetOutlined className={classes.text} fontSize="small" />
            </ListItemIcon>
            Change Password
          </MenuItem>
        {role !== "super_admin" && role !== "auditor" && role !== "trade_admin" && !isTradePlatform && (
        <MenuItem
          className={classes.text}
          onClick={() => navigateToPage("/subscription-details")}
        >
           <img
              src={subscriptionIcon}
              style={{ paddingRight: 10 }}
              alt="subs"
            />
          Subscription Info
        </MenuItem>)}
        {role === "read_only_user" ? (
          <MenuItem
            className={classes.text}
            onClick={() => navigateToPage("/company")}
          >
            <img
              src={switchCompanyIcon}
              style={{ paddingRight: 10 }}
              alt="sc"
            />
            Switch Company
          </MenuItem>
        ) : (
          ""
        )}
        <MenuItem className={classes.text} onClick={handleLogout}>
          <ListItemIcon>
            <Logout className={classes.text} fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default SettingsMenu;