import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, Box } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import CeroInput from "../../../components/CeroInput";
import CeroSelect from "../../../components/CeroSelect";
import { months, sampleYear } from "../../../constants";
import { addOffsetValidation } from "./schema";
import {
  addCarbonOffset,
  getCountryList,
  getPrograms,
  listCarbonOffsetPrograms,
  resetCarbonOffset,
  updateCarbonOffsetFormDetails,
} from "../../../redux/actions";
import { STATUS } from "../../../redux/constants";
import useStyles from "./styles";
import CeroPopUp from "../../../components/CeroPopUp";
import CeroButton from "../../../components/CeroButton";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import CeroUploadedFile from "../../../components/CeroUploadedFile";
import { useNavigate, useParams } from "react-router-dom";
import { resetEditOffsetStatus } from "../../../redux/actions/carbonOffset";
import { getProgramTypes } from "../../../redux/actions/carbonOffset";

const CreateCarbonOffsetPopup = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { isOpen, formDetails, isEdit } = props;
  const addCarbonOffsetStatus = useSelector(
    (state) => state.carbonOffset.addCarbonOffset.status
  );
  const editStatusMessage = useSelector(
    (state) => state.carbonOffset.updateCarbonOffsetFormDetails.message
  );
  const editCarbonOffsetStatus = useSelector(
    (state) => state.carbonOffset.updateCarbonOffsetFormDetails.status
  );
  const statusMessage = useSelector(
    (state) => state.carbonOffset.addCarbonOffset.message
  );
  const programTypes = useSelector(
    (state) => state.carbonOffset.getProgramTypes.data
  );
  const programs = useSelector((state) => state.carbonOffset.getPrograms.data);
  const countryListData = useSelector(
    (state) => state.listings.countryList.data
  );
  const countryList = countryListData.map((item) => ({
    id: item.code,
    label: item.name,
  }));
  const countryFacilityData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_facility_data
  );

  const facilityList = [];

  for (const country in countryFacilityData) {
    const facilities = countryFacilityData[country];
    for (const facility_name in facilities) {
      const facility_id = facilities[facility_name];
      facilityList.push({ id: facility_id, label: facility_name });
    }
  }
  const [selectedFacilitites, setFacility] = useState(facilityList);
  const programTypeOptions = programTypes.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  const programOptions = [];
  const statusList = [];
  const nameMap = {};

  programs.forEach((item) => {
    programOptions.push({
      key: item.id,
      value: item.name,
    });

    if (item.status === false) {
      statusList.push(item.name);
    }
    nameMap[item.id] = item.name;
  });

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const yearList = sampleYear()
    .map((year) => ({
      ...year,
      disabled: year.key > currentYear,
    }))
    .filter((year) => !year.disabled);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [monthOptions, setMonthOptions] = useState(months);
  const isButtonLoading = addCarbonOffsetStatus === STATUS.RUNNING;
  const findLabelByValue = (options, value) => {
    const option = options.find((opt) => opt.id === value || opt.key === value);
    return option;
    // ? option.label || option.value : "";
  };
  const findValuelByLabel = (options, value) => {
    const option = options.find(
      (opt) => opt.value === value || opt.key === value
    );
    return option;
  };

  const initialProgramType = findValuelByLabel(
    programTypeOptions,
    formDetails?.program_type
  );
  const initialProgram = findValuelByLabel(
    programOptions,
    formDetails?.program_name
  );

  const getProgramSpecificValue = (key) =>
    formDetails?.program_specific_data?.find((item) => item.data_name === key)
      ?.value || "";

  const addCarbonOffsetForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      program_name: formDetails?.name || "",
      country: formDetails?.country_code || "",
      program_type: formDetails?.program_type_id || "",
      program: initialProgram?.key || "",
      start_year: formDetails?.start_year || 0,
      start_month: formDetails?.start_month || 0,
      number_of_panels: getProgramSpecificValue("number of panels"),
      installed_capacity: getProgramSpecificValue("Installed capacity (KW)"),
      height_of_turbine: getProgramSpecificValue("Height of Turbines"),
      rotor_diameter: getProgramSpecificValue("Rotor diameter"),
      number_of_turbines: getProgramSpecificValue("No. of Turbines"),
      number_of_converters: getProgramSpecificValue("No. of Converters"),
      device_dimensions: getProgramSpecificValue("Device Dimensions"),
      trees_planted: getProgramSpecificValue("Number of Trees Planted"),
      climate_zone: getProgramSpecificValue("Climate Zone"),
      hydro_type: getProgramSpecificValue("Type of Hydro power"),
      wave_energy_converter_type: getProgramSpecificValue(
        "Type of Wave Energy Converter"
      ),
      total_area_planted: getProgramSpecificValue("Total Area Planted"),
      planting_density: getProgramSpecificValue("Planting Density"),
      description: formDetails?.description || "",
      facility: formDetails?.facility_id || "",
    },
    validationSchema: addOffsetValidation,
    onSubmit: () => {},
  });

  const updateSelectedFacilities = (country) => {
    const facilityList = [];
    const facilities = countryFacilityData[country];
    for (const facility_name in facilities) {
      const facility_id = facilities[facility_name];
      facilityList.push({ id: facility_id, label: facility_name });
    }
    setFacility(facilityList);
  };

  async function blobUrlToFile(blobUrl, filename) {
    try {
      // Fetch the blob
      // const response = await fetch(blobUrl, { mode: "no-cors" });
      const response = await fetch(blobUrl);

      if (!response.ok) {
        // throw new Error(Failed to fetch blob: ${response.statusText});
      }

      // Get the Blob object
      const blob = await response.blob();

      // Create a File object from the Blob
      const file = new File([blob], filename || "file", {
        type: blob.type,
      });
      return file;
    } catch (error) {
      console.error("Error converting blob URL to file:", error);
      return null;
    }
  }

  useEffect(() => {
    if (isEdit && formDetails?.attachment_url?.length) {
      const fetchFiles = async () => {
        const files = await Promise.all(
          formDetails.attachment_url?.map((url, index) =>
            blobUrlToFile(url, `attachment-${index + 1}`)
          )
        );
        const validFiles = files?.filter((file) => file !== null); // Filter out failed conversions
        setSelectedFiles((prev) => [
          ...prev,
          ...validFiles?.map((file) => ({
            name: file.name,
            size: `${(file.size / 1024).toFixed(2)} KB`,
            file,
          })),
        ]);
      };
      fetchFiles();
    }
  }, [isEdit]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const newFiles = files.map((file) => ({
      name: file.name,
      size: `${(file.size / 1024).toFixed(2)} KB`,
      file,
    }));
    setSelectedFiles((prev) => [...prev, ...newFiles]);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };

  const onAddData = () => {
    const programSpecificData = [
      {
        data_name: "number of panels",
        value: addCarbonOffsetForm.values.number_of_panels,
      },
      {
        data_name: "Installed capacity (KW)",
        value: addCarbonOffsetForm.values.installed_capacity,
      },
      {
        data_name: "Height of Turbines",
        value: addCarbonOffsetForm.values.height_of_turbine,
      },
      {
        data_name: "Rotor diameter",
        value: addCarbonOffsetForm.values.rotor_diameter,
      },
      {
        data_name: "No. of Turbines",
        value: addCarbonOffsetForm.values.number_of_turbines,
      },
      {
        data_name: "No. of Converters",
        value: addCarbonOffsetForm.values.number_of_converters,
      },
      {
        data_name: "Device Dimensions",
        value: addCarbonOffsetForm.values.device_dimensions,
      },
      {
        data_name: "Number of Trees Planted",
        value: addCarbonOffsetForm.values.trees_planted,
      },
      {
        data_name: "Climate Zone",
        value: addCarbonOffsetForm.values.climate_zone,
      },
      {
        data_name: "Type of Hydro power",
        value: addCarbonOffsetForm.values.hydro_type,
      },
      {
        data_name: "Type of Wave Energy Converter",
        value: addCarbonOffsetForm.values.wave_energy_converter_type,
      },
      {
        data_name: "Total Area Planted",
        value: addCarbonOffsetForm.values.total_area_planted,
      },
      {
        data_name: "Planting Density",
        value: addCarbonOffsetForm.values.planting_density,
      },
    ];

    const filteredProgramSpecificData = programSpecificData.filter(
      (item) =>
        item.value !== null && item.value !== undefined && item.value !== ""
    );

    const formData = new FormData();

    formData.append("program_type_id", addCarbonOffsetForm.values.program_type);
    formData.append("name", addCarbonOffsetForm.values.program_name);
    formData.append("choose_program_id", addCarbonOffsetForm.values.program);
    formData.append("start_year", addCarbonOffsetForm.values.start_year);
    formData.append("start_month", addCarbonOffsetForm.values.start_month);
    formData.append("description", addCarbonOffsetForm.values.description);
    formData.append("country", addCarbonOffsetForm.values.country);
    formData.append("facility", addCarbonOffsetForm.values.facility);
    formData.append(
      "program_specific_data",
      JSON.stringify(filteredProgramSpecificData)
    );
    selectedFiles.forEach((fileData, index) => {
      formData.append(`file`, fileData.file);
    });
    if (isEdit) {
      dispatch(updateCarbonOffsetFormDetails(id, formData));
      navigate("/tickets");
    } else {
      dispatch(addCarbonOffset(formData));
      navigate("/carbon-offset");
    }
  };

  const onClose = useCallback(() => {
    addCarbonOffsetForm.resetForm({});
    props.onClose();
  }, [addCarbonOffsetForm, props]);

  useEffect(() => {
    addCarbonOffsetForm.values.program_type &&
      dispatch(getPrograms(addCarbonOffsetForm.values.program_type));
    formDetails?.program_type &&
      dispatch(getPrograms(formDetails?.program_type_id));
  }, [
    dispatch,
    addCarbonOffsetForm.values.program_type,
    formDetails?.program_type_id,
  ]);

  useEffect(() => {
    dispatch(getProgramTypes());
    dispatch(getCountryList());
    if (formDetails?.country_code) {
      addCarbonOffsetForm.setFieldValue("country", formDetails.country_code);
      updateSelectedFacilities(formDetails.country_code);
    }
  }, [dispatch, formDetails]);

  useEffect(() => {
    if (editCarbonOffsetStatus === STATUS.SUCCESS) {
      enqueueSnackbar("Data updated successfully", {
        variant: "success",
      });
      dispatch(listCarbonOffsetPrograms());
      dispatch(resetEditOffsetStatus());
      setSelectedFiles([]);
      onClose();
    } else if (editCarbonOffsetStatus === STATUS.ERROR) {
      enqueueSnackbar(
        editStatusMessage
          ? editStatusMessage
          : "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetEditOffsetStatus());
    }
  }, [addCarbonOffsetStatus, enqueueSnackbar, onClose, dispatch]);

  useEffect(() => {
    if (addCarbonOffsetStatus === STATUS.SUCCESS) {
      enqueueSnackbar("Data added successfully", {
        variant: "success",
      });
      dispatch(listCarbonOffsetPrograms());
      dispatch(resetCarbonOffset());
      setSelectedFiles([]);
      onClose();
    } else if (addCarbonOffsetStatus === STATUS.ERROR) {
      enqueueSnackbar(
        statusMessage
          ? statusMessage
          : "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetCarbonOffset());
    }
  }, [addCarbonOffsetStatus, enqueueSnackbar, onClose, dispatch]);

  const handleYearChange = (event) => {
    const selectedYear = event.target.value;
    addCarbonOffsetForm.setFieldValue("start_year", selectedYear);

    if (parseInt(selectedYear) === currentYear) {
      const availableMonths = months.filter(
        (month) => month.key <= currentMonth
      );
      setMonthOptions(availableMonths);
    } else {
      setMonthOptions(months);
    }
  };
  const selectedFacility = facilityList?.find(
    (facility) => facility?.id === addCarbonOffsetForm.values.facility
  );
  const selectedCountry = countryList?.find(
    (facility) => facility?.id === addCarbonOffsetForm.values.country
  );
  const getContent = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CeroInput
              classes={{ container: classes.selectContainer }}
              required
              id="program_name"
              name="program_name"
              label="Program Name"
              fullWidth
              value={addCarbonOffsetForm.values.program_name}
              onChange={addCarbonOffsetForm.handleChange}
              onBlur={addCarbonOffsetForm.handleBlur}
              error={
                addCarbonOffsetForm.touched.program_name &&
                addCarbonOffsetForm.errors.program_name
              }
            />
            <CeroAutoComplete
              id="facility"
              label="Facility"
              required
              value={selectedFacility}
              onChange={(e, value) =>
                addCarbonOffsetForm.setFieldValue(
                  "facility",
                  value ? value.id : undefined
                )
              }
              onBlur={addCarbonOffsetForm.handleBlur}
              error={
                addCarbonOffsetForm.touched.facility &&
                addCarbonOffsetForm.errors.facility
              }
              options={selectedFacilitites}
              sortOption={true}
            />
            {addCarbonOffsetForm.values.program_type && (
              <CeroSelect
                classes={{ container: classes.selectContainer }}
                required
                id="program"
                name="program"
                label="Choose Program"
                fullWidth
                options={programOptions}
                selectedValue={addCarbonOffsetForm.values.program || ""}
                onChange={addCarbonOffsetForm.handleChange}
                onBlur={addCarbonOffsetForm.handleBlur}
                error={
                  addCarbonOffsetForm.touched.program &&
                  addCarbonOffsetForm.errors.program
                }
                disableItem={statusList}
              />
            )}
            {addCarbonOffsetForm.values.program &&
              ["wind energy program", "solar energy program"].includes(
                nameMap[addCarbonOffsetForm.values.program]?.toLowerCase()
              ) && (
                <CeroInput
                  classes={{ container: classes.selectContainer }}
                  required
                  id="number_of_panels"
                  name="number_of_panels"
                  label="No. of panels installed"
                  fullWidth
                  type="number"
                  value={addCarbonOffsetForm.values.number_of_panels}
                  onChange={addCarbonOffsetForm.handleChange}
                  onBlur={addCarbonOffsetForm.handleBlur}
                  error={
                    addCarbonOffsetForm.touched.number_of_panels &&
                    addCarbonOffsetForm.errors.number_of_panels
                  }
                />
              )}
            {addCarbonOffsetForm.values.program &&
              nameMap[addCarbonOffsetForm.values.program]?.toLowerCase() ==
                "wind energy program" && (
                <CeroInput
                  classes={{ container: classes.selectContainer }}
                  required
                  id="height_of_turbine"
                  name="height_of_turbine"
                  label="Height of Turbines"
                  fullWidth
                  type="number"
                  value={addCarbonOffsetForm.values.height_of_turbine}
                  onChange={addCarbonOffsetForm.handleChange}
                  onBlur={addCarbonOffsetForm.handleBlur}
                  error={
                    addCarbonOffsetForm.touched.height_of_turbine &&
                    addCarbonOffsetForm.errors.height_of_turbine
                  }
                />
              )}
            {addCarbonOffsetForm.values.program &&
              ["wind energy program", "hydro energy program"].includes(
                nameMap[addCarbonOffsetForm.values.program]?.toLowerCase()
              ) && (
                <CeroInput
                  classes={{ container: classes.selectContainer }}
                  required
                  id="rotor_diameter"
                  name="rotor_diameter"
                  label="Rotor Diameter"
                  fullWidth
                  type="number"
                  value={addCarbonOffsetForm.values.rotor_diameter}
                  onChange={addCarbonOffsetForm.handleChange}
                  onBlur={addCarbonOffsetForm.handleBlur}
                  error={
                    addCarbonOffsetForm.touched.rotor_diameter &&
                    addCarbonOffsetForm.errors.rotor_diameter
                  }
                />
              )}
            {addCarbonOffsetForm.values.program &&
              nameMap[addCarbonOffsetForm.values.program]?.toLowerCase() ==
                "hydro energy program" && (
                <CeroInput
                  classes={{ container: classes.selectContainer }}
                  required
                  id="number_of_turbines"
                  name="number_of_turbines"
                  label="No. of Turbines"
                  fullWidth
                  type="number"
                  value={addCarbonOffsetForm.values.number_of_turbines}
                  onChange={addCarbonOffsetForm.handleChange}
                  onBlur={addCarbonOffsetForm.handleBlur}
                  error={
                    addCarbonOffsetForm.touched.number_of_turbines &&
                    addCarbonOffsetForm.errors.number_of_turbines
                  }
                />
              )}
            {addCarbonOffsetForm.values.program &&
              nameMap[addCarbonOffsetForm.values.program]?.toLowerCase() ==
                "wave energy converter" && (
                <CeroInput
                  classes={{ container: classes.selectContainer }}
                  required
                  id="number_of_converters"
                  name="number_of_converters"
                  label="No. of Converters"
                  fullWidth
                  type="number"
                  value={addCarbonOffsetForm.values.number_of_converters}
                  onChange={addCarbonOffsetForm.handleChange}
                  onBlur={addCarbonOffsetForm.handleBlur}
                  error={
                    addCarbonOffsetForm.touched.number_of_converters &&
                    addCarbonOffsetForm.errors.number_of_converters
                  }
                />
              )}
            {addCarbonOffsetForm.values.program &&
              nameMap[addCarbonOffsetForm.values.program]?.toLowerCase() ==
                "wave energy converter" && (
                <CeroInput
                  classes={{ container: classes.selectContainer }}
                  required
                  id="device_dimensions"
                  name="device_dimensions"
                  label="Device Dimensions"
                  fullWidth
                  type="number"
                  value={addCarbonOffsetForm.values.device_dimensions}
                  onChange={addCarbonOffsetForm.handleChange}
                  onBlur={addCarbonOffsetForm.handleBlur}
                  error={
                    addCarbonOffsetForm.touched.device_dimensions &&
                    addCarbonOffsetForm.errors.device_dimensions
                  }
                />
              )}
            {addCarbonOffsetForm.values.program &&
              ["reforestation", "afforestation"].includes(
                nameMap[addCarbonOffsetForm.values.program]?.toLowerCase()
              ) && (
                <CeroInput
                  classes={{ container: classes.selectContainer }}
                  required
                  id="trees_planted"
                  name="trees_planted"
                  label="Number of Trees Planted"
                  fullWidth
                  type="number"
                  value={addCarbonOffsetForm.values.trees_planted}
                  onChange={addCarbonOffsetForm.handleChange}
                  onBlur={addCarbonOffsetForm.handleBlur}
                  error={
                    addCarbonOffsetForm.touched.trees_planted &&
                    addCarbonOffsetForm.errors.trees_planted
                  }
                />
              )}
            {addCarbonOffsetForm.values.program &&
              ["reforestation", "afforestation"].includes(
                nameMap[addCarbonOffsetForm.values.program]?.toLowerCase()
              ) && (
                <CeroSelect
                  classes={{ container: classes.selectContainer }}
                  required
                  id="climate_zone"
                  name="climate_zone"
                  label="Climate Zone"
                  fullWidth
                  options={[
                    { key: "Tropical", value: "Tropical" },
                    { key: "Subtropical", value: "Subtropical" },
                    { key: "Temperate", value: "Temperate" },
                    { key: "Boreal", value: "Boreal" },
                    { key: "Arid", value: "Arid" },
                  ]}
                  selectedValue={addCarbonOffsetForm.values.climate_zone || ""}
                  onChange={addCarbonOffsetForm.handleChange}
                  onBlur={addCarbonOffsetForm.handleBlur}
                  error={
                    addCarbonOffsetForm.touched.climate_zone &&
                    addCarbonOffsetForm.errors.climate_zone
                  }
                  disableItem={statusList}
                />
              )}
            {addCarbonOffsetForm.values.program && (
              <CeroSelect
                classes={{ container: classes.selectContainer }}
                id="start_month"
                name="start_month"
                label="Start Month"
                fullWidth
                options={monthOptions}
                selectedValue={addCarbonOffsetForm.values.start_month || ""}
                onChange={addCarbonOffsetForm.handleChange}
                onBlur={addCarbonOffsetForm.handleBlur}
                error={
                  addCarbonOffsetForm.touched.start_month &&
                  addCarbonOffsetForm.errors.start_month
                }
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <CeroAutoComplete
              id="country"
              label="Country"
              required
              value={selectedCountry}
              onChange={(e, value) => {
                addCarbonOffsetForm.setFieldValue(
                  "country",
                  value ? value.id : undefined
                );
                updateSelectedFacilities(value ? value.id : undefined);
              }}
              onBlur={addCarbonOffsetForm.handleBlur}
              error={
                addCarbonOffsetForm.touched.country &&
                addCarbonOffsetForm.errors.country
              }
              options={countryList}
            />
            <CeroSelect
              classes={{ container: classes.selectContainer }}
              required
              id="program_type"
              name="program_type"
              label="Program Type"
              fullWidth
              options={programTypeOptions}
              selectedValue={addCarbonOffsetForm.values.program_type || ""}
              onChange={addCarbonOffsetForm.handleChange}
              onBlur={addCarbonOffsetForm.handleBlur}
              error={
                addCarbonOffsetForm.touched.program_type &&
                addCarbonOffsetForm.errors.program_type
              }
            />
            {addCarbonOffsetForm.values.program &&
              [
                "solar energy program",
                "wind energy program",
                "hydro energy program",
                "wave energy converter",
              ].includes(
                nameMap[addCarbonOffsetForm.values.program]?.toLowerCase()
              ) && (
                <CeroInput
                  classes={{ container: classes.selectContainer }}
                  required
                  id="installed_capacity"
                  name="installed_capacity"
                  label="Installed Capacity (kW)"
                  fullWidth
                  type="number"
                  value={addCarbonOffsetForm.values.installed_capacity}
                  onChange={addCarbonOffsetForm.handleChange}
                  onBlur={addCarbonOffsetForm.handleBlur}
                  error={
                    addCarbonOffsetForm.touched.installed_capacity &&
                    addCarbonOffsetForm.errors.installed_capacity
                  }
                />
              )}
            {addCarbonOffsetForm.values.program &&
              nameMap[addCarbonOffsetForm.values.program]?.toLowerCase() ==
                "wind energy program" && (
                <CeroInput
                  classes={{ container: classes.selectContainer }}
                  required
                  id="number_of_turbines"
                  name="number_of_turbines"
                  label="No. of Turbines"
                  fullWidth
                  type="number"
                  value={addCarbonOffsetForm.values.number_of_turbines}
                  onChange={addCarbonOffsetForm.handleChange}
                  onBlur={addCarbonOffsetForm.handleBlur}
                  error={
                    addCarbonOffsetForm.touched.number_of_turbines &&
                    addCarbonOffsetForm.errors.number_of_turbines
                  }
                />
              )}
            {addCarbonOffsetForm.values.program &&
              nameMap[addCarbonOffsetForm.values.program]?.toLowerCase() ==
                "hydro energy program" && (
                <CeroSelect
                  classes={{ container: classes.selectContainer }}
                  required
                  id="hydro_type"
                  name="hydro_type"
                  label="Type of Hydro power"
                  fullWidth
                  options={[
                    { key: "Run-of-River", value: "Run-of-River" },
                    { key: "Reservoir", value: "Reservoir" },
                    { key: "Pumped Storage", value: "Pumped Storage" },
                  ]}
                  selectedValue={addCarbonOffsetForm.values.hydro_type || ""}
                  onChange={addCarbonOffsetForm.handleChange}
                  onBlur={addCarbonOffsetForm.handleBlur}
                  error={
                    addCarbonOffsetForm.touched.hydro_type &&
                    addCarbonOffsetForm.errors.hydro_type
                  }
                  disableItem={statusList}
                />
              )}
            {addCarbonOffsetForm.values.program &&
              nameMap[addCarbonOffsetForm.values.program]?.toLowerCase() ==
                "wave energy program" && (
                <CeroSelect
                  classes={{ container: classes.selectContainer }}
                  required
                  id="wave_energy_converter_type"
                  name="wave_energy_converter_type"
                  label="Type of Wave Energy Converter"
                  fullWidth
                  options={[
                    { key: "Point Absorber", value: "Point Absorber" },
                    {
                      key: "Oscillating Water Column",
                      value: "Oscillating Water Column",
                    },
                    { key: "Oscillating Surge", value: "Oscillating Surge" },
                  ]}
                  selectedValue={
                    addCarbonOffsetForm.values.wave_energy_converter_type || ""
                  }
                  onChange={addCarbonOffsetForm.handleChange}
                  onBlur={addCarbonOffsetForm.handleBlur}
                  error={
                    addCarbonOffsetForm.touched.wave_energy_converter_type &&
                    addCarbonOffsetForm.errors.wave_energy_converter_type
                  }
                  disableItem={statusList}
                />
              )}
            {addCarbonOffsetForm.values.program &&
              ["reforestation", "afforestation"].includes(
                nameMap[addCarbonOffsetForm.values.program]?.toLowerCase()
              ) && (
                <CeroInput
                  classes={{ container: classes.selectContainer }}
                  required
                  id="total_area_planted"
                  name="total_area_planted"
                  label="Total Area Planted(Acre)"
                  fullWidth
                  type="number"
                  value={addCarbonOffsetForm.values.total_area_planted}
                  onChange={addCarbonOffsetForm.handleChange}
                  onBlur={addCarbonOffsetForm.handleBlur}
                  error={
                    addCarbonOffsetForm.touched.total_area_planted &&
                    addCarbonOffsetForm.errors.total_area_planted
                  }
                />
              )}
            {addCarbonOffsetForm.values.program &&
              ["reforestation", "afforestation"].includes(
                nameMap[addCarbonOffsetForm.values.program]?.toLowerCase()
              ) && (
                <CeroInput
                  classes={{ container: classes.selectContainer }}
                  required
                  id="planting_density"
                  name="planting_density"
                  label="Planting Density"
                  fullWidth
                  type="number"
                  value={addCarbonOffsetForm.values.planting_density}
                  onChange={addCarbonOffsetForm.handleChange}
                  onBlur={addCarbonOffsetForm.handleBlur}
                  error={
                    addCarbonOffsetForm.touched.planting_density &&
                    addCarbonOffsetForm.errors.planting_density
                  }
                />
              )}
            {addCarbonOffsetForm.values.program && (
              <>
                <CeroSelect
                  classes={{ container: classes.selectContainer }}
                  id="start_year"
                  name="start_year"
                  label="Start Year"
                  fullWidth
                  options={yearList}
                  selectedValue={addCarbonOffsetForm.values.start_year || ""}
                  onChange={handleYearChange}
                  onBlur={addCarbonOffsetForm.handleBlur}
                  error={
                    addCarbonOffsetForm.touched.start_year &&
                    addCarbonOffsetForm.errors.start_year
                  }
                />
              </>
            )}
          </Grid>
        </Grid>
        {addCarbonOffsetForm.values.program && (
          <CeroInput
            classes={{ container: classes.selectContainer }}
            required
            id="description"
            name="description"
            label="Brief description about the project"
            fullWidth
            multiline
            rows="2"
            type="number"
            value={addCarbonOffsetForm.values.description}
            onChange={addCarbonOffsetForm.handleChange}
            onBlur={addCarbonOffsetForm.handleBlur}
            error={
              addCarbonOffsetForm.touched.description &&
              addCarbonOffsetForm.errors.description
            }
          />
        )}
        {addCarbonOffsetForm.values.program && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              className={classes.uploadButton}
              component="label"
              startIcon={<AttachmentOutlinedIcon />}
            >
              Attach Documents
              <input type="file" hidden multiple onChange={handleFileChange} />
            </Button>
            <Box sx={{ display: "flex", gap: "8px" }}>
              {selectedFiles?.map((file, index) => (
                <CeroUploadedFile
                  key={index}
                  fileName={file.name}
                  fileSize={file.size}
                  onRemove={() => handleRemoveFile(index)}
                />
              ))}
            </Box>
          </Grid>
        )}

        <Grid item xs={12}>
          <CeroButton
            buttonText={isButtonLoading ? "Saving..." : "Save"}
            variant="contained"
            // disabled={
            //   Object.keys(addCarbonOffsetForm.errors).length > 0 ||
            //   // addCarbonOffsetForm.values.country === "" ||
            //   isButtonLoading
            // }
            onClick={onAddData}
            className={classes.buttonPrimary}
          />
          <CeroButton
            buttonText="Cancel"
            variant="outlined"
            disabled={false}
            onClick={onClose}
            className={classes.buttonSecondary}
          />
        </Grid>
      </>
    );
  };

  return (
    <CeroPopUp
      primaryPopUp={{
        open: isOpen,
        onClose: onClose,
        content: getContent(),
        header: { title: `${isEdit ? "Edit" : "Add"} Carbon Offset Programs` },
      }}
      dialog={classes.popUpClass}
      showCloseButton={true}
    />
  );
};

export default CreateCarbonOffsetPopup;
