import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    padding: 0,
    position: "relative",
    display: "flex",
  },
  filterContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  yearContainer: {
    display: "flex",
    alignItems: "center",
  },
  dropdown: {
    width: 82,
    marginRight: theme.spacing(6),
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    color: theme.palette.Primary.light,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
      borderColor: theme.palette.Primary.background,
    },
    fontSize: 13,
    marginLeft: theme.spacing(5),
    borderColor: theme.palette.Primary.background,
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    borderColor: theme.palette.Primary.background,
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      background: theme.palette.Secondary.hoverButton,
    },
    marginLeft: theme.spacing(5),
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: 10,
    alignItems: "center",
    cursor: "pointer",
    background: theme.palette.Secondary.background,
    zIndex: 999,
  },
  popover: {
    "& .MuiPopover-paper": {
      padding: "20px 35px",
    },
  },
  popoverTitle:{
    fontWeight: 800,
    textAlign: "center",
    marginBottom: theme.spacing(5)
  },
  formControlLabel:{
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    '& .MuiSvgIcon-root': {
      color: theme.palette.Primary.background,
    },
  },
  profileIcon: {
    width: 32, 
    height: 32, 
    borderRadius: "50%",
    backgroundColor: "#ccc", 
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10, 
    fontSize: 16,
  },
  popoverButtons:{
    justifyContent: "space-between",
    marginTop: theme.spacing(8)
  },
  popoverButton:{
    marginLeft: 0,
    marginRight: 15
  },
  separator:{
    border: `0.1px solid #AEABAB`, 
    margin: theme.spacing(3, 0),
  },
  dialog:{
    width: "30%",
    "& .MuiPaper-root.MuiDialog-paper": {
     width: "50vw",
     margin: 80,
     },
  },
  confirmDialog:{
    width: "50%",
    "& .MuiPaper-root.MuiDialog-paper": {
     width: "40vw",
     margin: 80,
     background: "#fff"
     },
  },
  textField: {
    backgroundColor: theme.palette.background.white,
  },
  noComments: {
    float: "left",
    fontFamily: "Arial, sans-serif",
    fontSize: 12
  },
  maxCharacters: {
    float: "right",
    fontSize: 12
  },
  textLimit:{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 10
  },
  buttonContainer:{
    display: "flex", 
    justifyContent: "flex-start",
    paddingTop: 20,
    paddingBottom: 15,
  },
  buttons:{
    display: "flex", 
    justifyContent: "center",
    paddingTop: 20,
    paddingBottom: 15,
  },
  closeIcon: {
    position: "absolute",
    zIndex: 999,
    right: 40,
    top: 20,
    cursor: "pointer"
  },
  heading:{
    textAlign: "center",
    margin: theme.spacing(4,0),
  }
}));

export default useStyles;
