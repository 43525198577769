import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    width: "100%",
    background: theme.palette.background.white,
    fontSize: 13,
    fontWeight: 500,
    borderRadius: 8,
    margin: theme.spacing(5, 0),
    position: "relative",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
  },
  ticket: {
    color: theme.palette.text.accent,
  },
  infoContainer: {
    display: "flex !important",
    alignItems: "start",
    // gap: "46px",
    padding: theme.spacing(1, 0),
  },
  missionContainer: {
    display: "flex !important",
    alignItems: "start",
    // gap: "46px",
    padding: theme.spacing(1, 0),
  },
  title: {
    width: "50%",
  },
  approved: {
    color: theme.palette.status.approved,
    fontWeight: 500,
    fontSize: 14,
  },
  verified: {
    color: theme.palette.status.verified,
    fontWeight: 600,
    fontSize: 16,
  },
  review: {
    color: theme.palette.status.review,
    fontWeight: 600,
    fontSize: 16,
  },
  assigned: {
    color: theme.palette.status.pendingApproval,
    fontWeight: 500,
    fontSize: 14,
  },
  audited: {
    color: theme.palette.status.audited,
    fontWeight: 500,
    fontSize: 14,
  },
  submitted: {
    color: theme.palette.status.pendingApproval,
    fontWeight: 500,
    fontSize: 14,
  },
  added: {
    color: theme.palette.status.added,
    fontWeight: 500,
    fontSize: 14,
  },
  Inprogress: {
    color: theme.palette.status.pendingApproval,
    fontWeight: 500,
    fontSize: 14,
  },
  infoTitle: {
    width: "120px",
    marginRight: theme.spacing(5),
    fontWeight: 500,
  },
  value: {
    fontWeight: 500,
    whiteSpace: "break-spaces",
    marginLeft: "-3px",
  },
  span: {
    fontSize: "15px",
    fontWeight: "600",
  },
  containerBottom: {
    padding: theme.spacing(4),
    width: "100%",
    background: theme.palette.background.white,
    fontSize: 13,
    fontWeight: 500,
    borderRadius: 8,
    margin: theme.spacing(5, 0),
    border: "1px solid #d6cfd3",
    // boxShadow:
    //   "0px 4px 4px rgba(0, 0, 0, 0.05), 0px -4px 4px rgba(0, 0, 0, 0.05), 4px 0px 4px rgba(0, 0, 0, 0.05), -4px 0px 4px rgba(0, 0, 0, 0.05)",
  },
  taskDetailList: {
    display: "flex",
    alignItems: "center",
    fontSize: "15px",
    height: "40px",
    justifyContent: "space-between"
  },
  buttonContainer: {
    display: "flex",
    paddingTop: 20,
    paddingBottom: 15,
    width: "100%",
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
    width: "fit-content",
    height: 37,
    fontSize: 14,
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
    marginLeft: theme.spacing(5),
  },
  closeIcon: {
    position: "absolute",
    zIndex: 999,
    right: 40,
    top: 20,
    cursor: 'pointer'
  },
  icon: {
    color: theme.palette.Primary.background,
    cursor: "pointer",
  },
  dialog:{
    "& .MuiPaper-root.MuiDialog-paper": {
      backgroundColor: theme.palette.background.white
    },
  },
  backContainer:{
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingTop: 10,
    alignItems: 'center',
    cursor: "pointer"
  },
  addTaskButton: {
    borderRadius: "40px",
    border: "1px solid #af647f",
    color: "#8f264c",
    backgroundColor: "#f9f4f6",
    textTransform: "none",
    transition: "none",
    boxShadow: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "initial",
    },
  },
  ceroSelectContainer: {
    width: "25%",
    marginLeft:'2px'
  },
  ceroInputContainer: {
    width: "64%",
  },
  saveButton: {
    width: "100px",
    backgroundColor: `${theme.palette.Primary.background}!important`,
    fontStyle: "normal",
    fontWeight: "600!important",
    fontSize: "13px!important",
    textTransform: "capitalize",
    marginRight: "15px",
    borderRadius: "6px",
  },
  cancelButton: {
    width: "100px",
    backgroundColor: "white",
    fontStyle: "normal",
    fontWeight: "600!important",
    fontSize: "13px!important",
    textTransform: "capitalize",
    border: "1px solid #af647f",
    borderRadius: "6px",
    color: "#af647f",
    transition: "none",
    boxShadow: "none",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "initial",
    },
  },
  // uploadIcon: {
  //   width: "100px",
  //   height: "100px",
  //   position: "absolute",
  //   top: 10,
  //   right: 10,
  //   flexWrap: "wrap",
  //   display: "flex",
  //   justifyContent: "center", /* Center the icon horizontally */
  // alignItems: "center", /* Center the icon vertically */
  // overflow: "hidden", /* Ensure no overflow */
  // boxSizing: "border-box",
  // },
  uploadIcon: {
    width: "100px", // Define the container width
    height: "100px", // Define the container height
    position: "absolute",
    // top: 10,
    bottom:'5px',
    right: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden", // Hide anything that overflows the container
    background: "#fff", // Optional: Add a background color
  },
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    outline: 'none',
  },
  modalHeader: {
    marginBottom: theme.spacing(2),
  },
  closeButton: {
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    display: 'block', 
  },
  
}));

export default useStyles;
