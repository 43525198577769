import React, { useEffect } from "react";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import { Container, Typography } from "@mui/material";
import useStyles from "./styles";
import CeroTable from "../../../../components/CeroTable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getSupplierRespondentList, getSustainabilityRiskRespondentList } from "../../../../redux/actions";

const SustainabilityRiskAssessmentHistoryDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const surveyList = useSelector((state) => state.sustainabilityRiskAssessment.getSustainabilityRiskAssessementRespondentList?.data?.survey_respondent_lst);

  const historyColumns = [
    {
      columnKey: "name",
      columnId: "name",
      columnHeader: "Name",
      width: "30vw"
    },
    {
      columnKey: "email",
      columnId: "email",
      columnHeader: "Email",
      width: "30vw"
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
      width: "20vw"
    }
  ];

  useEffect(() => {
    id && dispatch(getSustainabilityRiskRespondentList(id));
  }, [dispatch]);

  const getStatus = (status) => {
    if (status === "pending") {
      return <Typography className={classes.pending}>Pending</Typography>;
    } else if (status === "completed") {
      return <Typography className={classes.completed}>Completed</Typography>;
    }
  };


  const getData = () => {
    if (!Array.isArray(surveyList)) {
      return [];
    }

    return surveyList?.map((item) => ({
      ...item,
      status: getStatus(item.status),
    }));
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <div
          className={classes.backContainer}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        <CeroTable
          columns={historyColumns}
          data={getData}
          hasMore={false}
          loading={false}
          useExtraStyles={true}
          classes={{ tableContainer: classes.tableContainer }}
        />
      </Container>
    </DashboardLayout>
  );
};

export default SustainabilityRiskAssessmentHistoryDetails;
