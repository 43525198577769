import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { Container, Typography, Box, CircularProgress, Stack } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import useStyles from "./styles";
import { STATUS } from "../../../../redux/constants";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import CeroButton from "../../../../components/CeroButton";
import Status from "../../../Tickets/Status";
import CeroCommentCell from "../../../../components/CeroCommentCell";
import CeroInput from "../../../../components/CeroInput";
import {
  addSuperadminResponse,
  closeSuperadminTicket,
  generateBlockchainCertificate,
  getNftUri,
  getSuperadminTicketDetails,
  loadingEnd,
  loadingStart,
  resetSuperAdminStatus,
  viewCertificate,
} from "../../../../redux/actions";
import Web3 from "web3";
import NFTABI from "../../../../Abi/NFTABI.json";
import CustomModal from "../../../Reports/ReportDetails/NftCertificate";
import { getCookie } from "../../../../services/cookie";

const TicketDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [comment, setComment] = useState("");
  const [web3, setWeb3] = useState(null);
  const [contractInstance, setContractInstance] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const role = getCookie("role");

  const userInfo = useSelector((state) => state.auth.userInfo);
  const imageUrl = useSelector((state) => state.reports.viewCertificate.data);
  const ticketDetails = useSelector(
    (state) => state.superAdmin.getSuperadminTicketDetails.data.response
  );
  const ticketDetailsStatus = useSelector(
    (state) => state.superAdmin.getSuperadminTicketDetails.status
  );
  const closeTicketDetailsStatus = useSelector(
    (state) => state.superAdmin.closeSuperadminTicket.status
  );
  const closeTicketDetailsError = useSelector(
    (state) => state.superAdmin.closeSuperadminTicket.message
  );
  const deleteTicketDetailsStatus = useSelector(
    (state) => state.ticket.deleteTicketDetails.status
  );
  const deleteTicketDetailsError = useSelector(
    (state) => state.ticket.deleteTicketDetails.message
  );
  const addCommentStatus = useSelector(
    (state) => state.superAdmin.addSuperadminResponse.status
  );
  const nftMetadata = useSelector((state) => state.reports.getNftUri.data);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  // console.log('ticketDetails', ticketDetails)

  const onAddComment = () => {
    dispatch(addSuperadminResponse(id, comment));
  };

  const onCloseTicket = () => {
    dispatch(closeSuperadminTicket(id));
  };

  const isCommentLoading = addCommentStatus === STATUS.RUNNING;
  const isCloseLoading = closeTicketDetailsStatus === STATUS.RUNNING;

  useEffect(() => {
    if (addCommentStatus === STATUS.SUCCESS) {
      enqueueSnackbar("Comment added successfully", { variant: "success" });
      setComment("");
      dispatch(resetSuperAdminStatus());
      dispatch(getSuperadminTicketDetails(id));
    } else if (addCommentStatus === STATUS.ERROR) {
      enqueueSnackbar(
        "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetSuperAdminStatus());
    }
  }, [addCommentStatus, enqueueSnackbar, dispatch, id]);

  useEffect(() => {
    if (closeTicketDetailsStatus === STATUS.SUCCESS) {
      enqueueSnackbar("Ticket closed successfully", { variant: "success" });
      dispatch(resetSuperAdminStatus());
      navigate("/super-admin-tickets");
    } else if (closeTicketDetailsStatus === STATUS.ERROR) {
      enqueueSnackbar(
        closeTicketDetailsError.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetSuperAdminStatus());
    }
  }, [
    closeTicketDetailsStatus,
    enqueueSnackbar,
    dispatch,
    navigate,
    closeTicketDetailsError.message,
  ]);

  useEffect(() => {
    if (deleteTicketDetailsStatus === STATUS.SUCCESS) {
      enqueueSnackbar("Ticket deleted successfully", { variant: "success" });
      dispatch(resetSuperAdminStatus());
      navigate("/super-admin-tickets");
    } else if (deleteTicketDetailsStatus === STATUS.ERROR) {
      enqueueSnackbar(
        deleteTicketDetailsError.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetSuperAdminStatus());
    }
  }, [
    deleteTicketDetailsStatus,
    enqueueSnackbar,
    dispatch,
    navigate,
    deleteTicketDetailsError.message,
  ]);

  const walletAddress  = useSelector((state) => state.users.walletAddress);

  const NFT_ADDRESS = process.env.CONTRACT_ADDRESS || "0xA0AcDd5f46E43DecD7CAa53A314Ba7850f8139F2";

  useEffect(() => {
    const initWeb3 = async () => {
      if (window.ethereum) {
        try {
          const web3Instance = new Web3(window.ethereum);
          setWeb3(web3Instance);

          const contract = new web3Instance.eth.Contract(NFTABI, NFT_ADDRESS);
          setContractInstance(contract);
        } catch (error) {
          console.error("Error initializing Web3:", error);
        }
      } else {
        console.error("MetaMask is not installed");
      }
    };

    initWeb3();
  }, []);

  const mintNFT = async (_ticketDetails) => {
    if (!walletAddress) {
      enqueueSnackbar("Please connect your wallet", { variant: "error" });
      return;
    }
    if (contractInstance && web3) {
      dispatch(loadingStart());
      try {
        const accounts = await web3.eth.getAccounts();
        const senderAddress = accounts[0];
        const toAddress = walletAddress; // Replace with recipient's address
        const tokenURI = nftMetadata?.file_url;

        console.log('senderAddress', senderAddress,toAddress)

        // Estimate gas
        const gasEstimate = await contractInstance.methods
          .safeMint(toAddress, tokenURI, _ticketDetails.ticket_owner_id)
          .estimateGas({
            from: walletAddress,
          });

        console.log("gasEstimate", gasEstimate);

        // Get gas price
        const gasPrice = await web3.eth.getGasPrice();
        console.log("gasPrice", gasPrice);

        // Calculate the gas fee in ETH
        const gasFee = web3.utils.fromWei(
          (gasEstimate * gasPrice).toString(),
          "ether"
        );
        console.log("gasFee", gasFee);

        // Send the transaction
        const receipt = await contractInstance.methods
          .safeMint(toAddress, tokenURI, _ticketDetails.ticket_owner_id)
          .send({
            from: senderAddress,
            gas: gasEstimate,
            gasPrice: gasPrice,
          });
          const nftId = receipt?.events?.NFTMinted?.returnValues?.tokenId
          const openSeaUrl = `${process.env.OPENSEA_BASE_URL || "https://testnets.opensea.io/assets/amoy"}/${NFT_ADDRESS}/${nftId}`
          console.log('openSeaUrl', openSeaUrl)
        dispatch(loadingEnd());
        dispatch(
          generateBlockchainCertificate({
            audit_id: _ticketDetails.ticket_scope_id,
            transaction_hash: receipt.transactionHash,
            polygon_meta_url:openSeaUrl
          })
        );
        enqueueSnackbar("NFT minted successfully", { variant: "success" });
        setTimeout(() => {
          dispatch(getSuperadminTicketDetails(id));
          dispatch(
            viewCertificate({
              audit_id: ticketDetails?.ticket_scope_id,
              role: role,
            })
          );
        }, 3000);
      } catch (error) {
        dispatch(loadingEnd());
        console.error("Error minting NFT:", error);
        enqueueSnackbar("Error minting NFT", { variant: "error" });
      }
    }
  };

  useEffect(() => {
    id && dispatch(getSuperadminTicketDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    ticketDetails?.ticket_scope_id &&
      dispatch(getNftUri(ticketDetails?.ticket_scope_id));
  }, [dispatch, ticketDetails?.ticket_scope_id]);

  useEffect(() => {
    dispatch(
      viewCertificate({ audit_id: ticketDetails?.ticket_scope_id, role: role })
    );
  }, [dispatch, ticketDetails?.ticket_scope_id,role]);
  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Stack direction="row" justifyContent="space-between">
          <div className={classes.backContainer} onClick={navigateBack}>
            <ArrowBackIosIcon />
            Back
          </div>
          <Box className={classes.buttonContainer}>
            {/* {ticketDetails?.status === "open" && ticketDetails?.ticket_type==="request nft" &&<CeroButton
          buttonText="Mint NFT"
          className={clsx(classes.buttonPrimary)}
          onClick={mintNFT}
          />} */}
            {imageUrl.file_url && (
              <CeroButton
                buttonText="View Certificate"
                className={classes.buttonPrimary}
                onClick={openModal}
                // disabled={readOnlyMode}
              />
            )}
            {ticketDetails?.status !== "closed" && (
              <CeroButton
                buttonText={isCloseLoading ? "Closing..." : "Close ticket"}
                className={clsx(classes.buttonPrimary)}
                onClick={onCloseTicket}
              />
            )}
          </Box>
        </Stack>
        {ticketDetailsStatus === STATUS.SUCCESS ? (
          <Fragment>
            <Box className={classes.header}>
              <Typography variant="h6" component="span">
                {`Title: ${ticketDetails.title}`}
              </Typography>
            </Box>
            <Status ticketDetails={ticketDetails} userInfo={mintNFT} />
            {ticketDetails.responses.length > 0 && (
              <>
                <Typography
                  variant="body1"
                  component="span"
                  className={classes.comment}
                >
                  Comments
                </Typography>
                <Box className={classes.detailsContainer}>
                  <Box className={classes.descriptionContainer}>
                    <Typography variant="h6" component="span">
                      {ticketDetails.ticket_owner_name}
                    </Typography>
                    {ticketDetails.responses && ticketDetails.responses.length
                      ? ticketDetails.responses.map((comment) => (
                          <CeroCommentCell
                            key={comment.response.id}
                            name={comment.response_user_name}
                            msg={comment.response}
                            time={comment.created_on}
                          />
                        ))
                      : ""}
                  </Box>
                </Box>
              </>
            )}
            {ticketDetails?.status !== "closed" && (
              <Box className={classes.commentBox}>
                <CeroInput
                  classes={{ container: classes.textArea }}
                  rows={3}
                  multiline
                  id="comment"
                  name="comment"
                  label="Comment"
                  placeholder="Please type your comment"
                  value={comment}
                  fullWidth
                  onChange={({ target }) => setComment(target.value)}
                />
                <CeroButton
                  buttonText={
                    isCommentLoading ? "Commenting..." : "Add comment"
                  }
                  className={clsx(classes.buttonPrimary)}
                  onClick={onAddComment}
                  disabled={!comment || isCommentLoading}
                />
              </Box>
            )}
          </Fragment>
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {ticketDetailsStatus === STATUS.RUNNING ? (
                <CircularProgress />
              ) : ticketDetailsStatus === STATUS.ERROR ? (
                "We couldn't process your request. Please try again later."
              ) : (
                ""
              )}
            </Typography>
          </Box>
        )}
        <CustomModal
          isOpen={isModalOpen}
          onClose={closeModal}
          fileUrl={imageUrl.file_url}
          imgUrl={imageUrl.img_url}
          title={imageUrl.header}
        />
      </Container>
    </DashboardLayout>
  );
};

export default TicketDetails;