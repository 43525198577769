import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants/actions';

export const {
    getCompanyList,
    getCompanyDetails,
    getCompanyAuditHistory,
    getCompanyAuditDetails,
    approveCompanyAudit,
    approveCompanyAuditReset,
    getDashboardSummary,
    getTotalCompanies,
    companyDetails,
    getApprovalFlow,
    updateApprovalFlow,
    resetApprovalUpdate,
    resetGetApproval,
    companyFunctionalitySetup,
    functionalityView,
    getFunctionalityData,
    resetCompanyFunctionalitySetup,
    getAdminAnnouncementList,
    addAdminAnnouncement,
    editAdminAnnouncement,
    getAdminAnnouncement,
    getAdminAnnouncementChangelog,
    resetAddAdminAnnouncement,
    resetEditAdminAnnouncement,
} = createActions({
    [ActionTypes.GET_COMPANY_LIST]: (filter) => ({filter}),
    [ActionTypes.GET_COMPANY_DETAILS]: (companyId) => ({ companyId }),
    [ActionTypes.GET_COMPANY_AUDIT_HISTORY]: (companyId) => ({ companyId }),
    [ActionTypes.GET_COMPANY_AUDIT_DETAILS]: (companyId, auditId, year) => ({ companyId, auditId, year }),
    [ActionTypes.APPROVE_COMPANY_AUDIT]: (company, auditId, comment) => ({ company, auditId, comment }),
    [ActionTypes.APPROVE_COMPANY_AUDIT_RESET]: () => ({}),
    [ActionTypes.GET_DASHBOARD_SUMMARY]: (filter) => ({ filter }),
    [ActionTypes.GET_TOTAL_COMPANIES]: (filter) => ({filter}),
    [ActionTypes.COMPANY_DETAILS]: (companyId) => ({companyId}),
    [ActionTypes.GET_APPROVAL_FLOW]: (country, year) => ({country, year}),
    [ActionTypes.UPDATE_APPROVAL_FLOW]: (country, year, approvalFlow) => ({country, year, approvalFlow}),
    [ActionTypes.RESET_APPROVAL_UPDATE]: () => ({}),
    [ActionTypes.RESET_GET_APPROVAL]: () => ({}),
    [ActionTypes.COMPANY_FUNCTIONALITY_SETUP]: (companyId, payload) => ({companyId, payload}),
    [ActionTypes.FUNCTIONALITY_VIEW]: () => ({}),
    [ActionTypes.GET_FUNCTIONALITY_DATA]: (companyId) => ({companyId}),
    [ActionTypes.RESET_COMPANY_FUNCTIONALITY_SETUP]: () => ({}),
    [ActionTypes.GET_ADMIN_ANNOUNCEMENT_LIST]: (year, notification_type, notification_status) => ({year, notification_type, notification_status}),
    [ActionTypes.ADD_ADMIN_ANNOUNCEMENT]: (payload) => ({payload}),
    [ActionTypes.EDIT_ADMIN_ANNOUNCEMENT]: (requestData) => ({requestData}),
    [ActionTypes.GET_ADMIN_ANNOUNCEMENT]: (id) => ({id}),
    [ActionTypes.GET_ADMIN_ANNOUNCEMENT_CHANGELOG]: (id) => ({id}),
    [ActionTypes.RESET_ADD_ADMIN_ANNOUNCEMENT]: () => ({}),
    [ActionTypes.RESET_EDIT_ADMIN_ANNOUNCEMENT]: () => ({}),
})
