import React, { useEffect, useMemo } from "react";
import DashboardLayout from "../../../../layouts/DashboardLayout";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  getStandardsQuestionnaire,
  listAssessmentCycle,
  viewStandardsQuestionnaireAnswer,
} from "../../../../redux/actions";

function ViewQuestionnaire() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const data = useSelector(
    (state) => state.assessment.viewStandardsQuestionnaireAnswer.data
  );
  console.log('data', data)

  const filteredData = data?.answer?.filter(item => item.answer !== "" && item.answer[0] !== "")

  const questionsData = useSelector((state) => {
    const data = state.emission.getStandardsQuestionnaire.data.questions_list;
    return Array.isArray(data) ? data : [];
  });

  const questionsMap = questionsData.reduce((map, question) => {
    map[question.id] = question.question;
    return map;
  }, {});

  const requestData = useMemo(
    () => ({
      framework_id: location.state.framework_id,
      questionnaire_id: location.state.questionnaire_id,
    }),
    []
  );

  useEffect(() => {
    dispatch(viewStandardsQuestionnaireAnswer(requestData));
    dispatch(
      getStandardsQuestionnaire(
        location.state.framework_id || "67580e50d845c48ffba61868"
      )
    );
  }, [requestData]);

  const navigateBack = () => {
    navigate(-1);
  };

  const renderTable = (answer) => (
    <TableContainer
      sx={{ marginBottom: 3, borderRadius: 1, overflow: "hidden" }}
    >
      <Table sx={{ borderRadius: 1 }}>
        <TableHead>
          {/* Check if category exists and conditionally render headers */}
          {answer.some((item) => item.category) ? (
            // Table Header when category exists
            <TableRow sx={{ backgroundColor: "#d0c5ca" }}>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Category
              </TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>Risks</TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Potential Impacts (Pre-defined)
              </TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Time period (Short/Medium/Long)
              </TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Applicable to Your Organisation? (Yes/ NO)
              </TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Comments/Additional Details
              </TableCell>
            </TableRow>
          ) : (
            // Table Header when category is missing
            <TableRow sx={{ backgroundColor: "#d0c5ca" }}>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Scenario
              </TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Description of Scenario
              </TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Key Risks Identified
              </TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Key Opportunities Identified
              </TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Resilience Strategies
              </TableCell>
              <TableCell sx={{ border: 1, fontWeight: 520 }}>
                Gaps or Challenges Identified
              </TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {answer.map((categoryData, idx) =>
            // If `category` is present
            categoryData.category
              ? categoryData.risks.map((riskData, riskIdx) => (
                  <TableRow key={`${idx}-${riskIdx}`}>
                    {riskIdx === 0 && (
                      <TableCell
                        rowSpan={categoryData.risks.length}
                        sx={{ border: 1 }}
                      >
                        {categoryData.category}
                      </TableCell>
                    )}
                    <TableCell sx={{ border: 1 }}>{riskData.risk}</TableCell>
                    <TableCell sx={{ border: 1 }}>{riskData.impacts}</TableCell>
                    <TableCell sx={{ border: 1 }}>
                      {riskData.timePeriod}
                    </TableCell>
                    <TableCell sx={{ border: 1 }}>
                      {riskData.applicable}
                    </TableCell>
                    <TableCell sx={{ border: 1 }}>
                      {riskData.comments}
                    </TableCell>
                  </TableRow>
                ))
              : // If `category` is missing
                categoryData.risks.map((riskData, riskIdx) => (
                  <TableRow key={`${idx}-${riskIdx}`}>
                    <TableCell sx={{ border: 1 }}>{riskData.risk}</TableCell>
                    <TableCell sx={{ border: 1 }}>{riskData.impacts}</TableCell>
                    <TableCell sx={{ border: 1 }}>{riskData.keyRisk}</TableCell>
                    <TableCell sx={{ border: 1 }}>
                      {riskData.keyOpportunity}
                    </TableCell>
                    <TableCell sx={{ border: 1 }}>
                      {riskData.resilience}
                    </TableCell>
                    <TableCell sx={{ border: 1 }}>
                      {riskData.gapsChallenges}
                    </TableCell>
                  </TableRow>
                ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <DashboardLayout>
      <Grid container>
        <Box className={classes.buttonContainer}>
          <div className={classes.backContainer} onClick={navigateBack}>
            <ArrowBackIosIcon />
            Back
          </div>
        </Box>

        <Paper className={classes.container}>
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 550, mb: 10 }}>
                {data?.framework} Standards Questionnaire
              </Typography>
            </Grid>
            {filteredData?.length > 0 ? (
              filteredData.map((item, index) => (
                <Grid item xs={12} key={index} sx={{ marginBottom: 4 }}>
                  <Typography mb={5} fontWeight={520}>
                    {index + 1}.&nbsp; {questionsMap[item.question_id]}
                  </Typography>
                  {item.question_type === "table" ? (
                    renderTable(item.answer)
                  ) : (
                    <>
                      <span style={{ fontWeight: 520 }}>Answer: </span>
                      <span style={{ fontWeight: 430 }}>
                        {Array.isArray(item.answer)
                          ? item.answer.join(", ")
                          : item.answer}
                      </span>
                      {item.other && (
                        <div style={{ fontWeight: 430, marginLeft: 68, marginTop: 10 }}>{item.other}</div>
                      )}
                    </>
                  )}
                </Grid>
              ))
            ) : (
              <Typography>No answers available</Typography>
            )}
          </Grid>
        </Paper>
      </Grid>
    </DashboardLayout>
  );
}

export default ViewQuestionnaire;
