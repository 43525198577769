import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    height: "fit-content",
    // background: "#f9f4f6",
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  tableContainer: {
    maxHeight: "calc(100vh - 140px)",
  },
  actionContainer: {
    display: "flex",
  },
  button: {
    backgroundColor: theme.palette.Secondary.main,
  },
  editIcon: {
    marginLeft: theme.spacing(3),
    fontSize: "large",
    color: "#9d4162"
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
    width: 140,
    height: 37,
    fontWeight: 400,
    marginBottom: theme.spacing(5),
  },
  actions: {
    display: "flex",
    justifyContent: "space-evenly",
    color: theme.palette.icon.blue,
  },
  linkButton: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "inherit",
    cursor: "pointer",
    fontWeight: 400,
  },
  dropdown: {
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
      backgroundColor: theme.palette.background.white,
    },
    "& .MuiSelect-select": {
      backgroundColor: theme.palette.background.white,
    },
  },
  enabled: {
    fontWeight: 600,
    color: "#48742c",
  },
  disabled: {
    fontWeight: 600,
    color: "#d3291d",
  },
  filterContainer: {
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
      backgroundColor: theme.palette.background.white,
    },
  },
}));

export default useStyles;
