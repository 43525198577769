import { createActions } from 'redux-actions';
import { ActionTypes } from '../constants/actions';

export const {
    setIndustryType,
    getSurvey,
    getMaterialityIndustries,
    getMaterialityQuestions,
    createMaterialityQuestions,
    sendQuestionnaires,
    resetCreateQuestionsStatus,
    resetSendQuestionnairesStatus,
    getSurveyQuestions,
    answerSurveyQuestions,
    getSurveyList,
    trackSurvey,
    sendReminder,
    surveyResponse,
    surveyQuestions,
    getSurveyInsightsList,
    getSurveyInsightsDetails,
} = createActions({
    [ActionTypes.SET_INDUSTRY_TYPE]: (industry) => ({industry}),
    [ActionTypes.GET_SURVEY]: () => ({ }),
    [ActionTypes.GET_MATERIALITY_INDUSTRIES]: () => ({}),
    [ActionTypes.GET_MATERIALITY_QUESTIONS]: () => ({ }),
    [ActionTypes.CREATE_MATERIALITY_QUESTIONS]: (payload) => ({payload }),
    [ActionTypes.SEND_QUESTIONNAIRES]: (payload) => ({ payload }),
    [ActionTypes.RESET_CREATE_QUESTIONS_STATUS]: () => {},
    [ActionTypes.RESET_SEND_QUESTIONNAIRES_STATUS]: () => {},
    [ActionTypes.GET_SURVEY_QUESTIONS]: (answerId) => ({ answerId }),
    [ActionTypes.ANSWER_SURVEY_QUESTIONS]: (answerId, requestData) => ({ answerId, requestData }),
    [ActionTypes.GET_SURVEY_LIST]: (year) => ({ year }),
    [ActionTypes.TRACK_SURVEY]: (surveyId) => ({ surveyId }),
    [ActionTypes.SEND_REMINDER]: (id) => ({ id }),
    [ActionTypes.SURVEY_RESPONSE]: (answerId) => ({ answerId }),
    [ActionTypes.SURVEY_QUESTIONS]: (surveyId) => ({ surveyId }),
    [ActionTypes.GET_SURVEY_INSIGHTS_LIST]: (year) => ({ year }),
    [ActionTypes.GET_SURVEY_INSIGHTS_DETAILS]: (id) => ({ id }),
})
