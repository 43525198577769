import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Typography,
  IconButton,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import _ from "lodash";

import CeroInput from "../../../components/CeroInput";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroSideSheetDrawer from "../../../components/CeroSideSheetDrawer";
import { months } from "../../../constants";
import { createReportValidation } from "./schema";
import { resetReportStatus, createReport } from "../../../redux/actions";
import { STATUS } from "../../../redux/constants";

import useStyles from "./styles";
import CeroChipSelect from "../../../components/CeroChipSelect";
import CeroSelect from "../../../components/CeroSelect";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

const CreateReportDrawer = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [view, setView] = useState("category");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const { reportData, isOpen } = props;
  const topicList = useSelector((state) => state.listings.topicList.data);
  const frameworkList = useSelector(
    (state) => state.listings.frameworkList.data
  );
  const topicFetchStatus = useSelector(
    (state) => state.listings.topicList.status
  );
  const createReportStatus = useSelector(
    (state) => state.reports.createReport.status
  );
  const createReportError = useSelector(
    (state) => state.reports.createReport.message
  );
  const countryData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_data
  );
  console.log('countryData', countryData)
  const countryFacilityData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_facility_data
  );
  const assessmentYearData = useSelector(
    (state) => state.listings.getAssessmentYear.data
  );
  const countryFacilityDataObject = {};

  console.log('reportData', reportData)

  const esgoptions = [
    {
      id: "esg",
      label: "ESG",
    },
    {
      id: "environmental",
      label: "Environmental",
      category: "ESG",
    },
    {
      id: "social",
      label: "Social",
      category: "ESG",
    },
    {
      id: "governance",
      label: "Governance",
      category: "ESG",
    },
    {
      id: "carbon_offset",
      label: "Carbon Offset",
    },
    {
      id: "assessments",
      label: "Assessments",
    },
    {
      id: "materiality",
      label: "Materiality",
      category: "Assessments",
    },
    {
      id: "supplier_assessment",
      label: "Supplier Assessment",
      category: "Assessments",
    },
    {
      id: "sustainability_risk_assessment",
      label: "Sustainability Risk Assessment",
      category: "Assessments",
    },
  ];

  for (const key in countryFacilityData) {
    Object.assign(countryFacilityDataObject, countryFacilityData[key]);
  }
  const isButtonLoading = createReportStatus === STATUS.RUNNING;

  const topicOptionList = topicList.map((item) => ({
    id: item.id,
    label: item.name,
  }));

  const frameworkOptionList = frameworkList.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const yearList = assessmentYearData?.map((item) => ({
    id: item,
    label: item,
  }));

  // const getReportTopic = (data = []) =>
  //   data.map((item) => {
  //     const innerItem = topicOptionList.find((inner) => inner.id === item);
  //     return _.isEmpty(innerItem) ? { id: item, label: item } : innerItem;
  //   });

  const createReportForm = useFormik({
    initialValues: {
      name: reportData ? reportData.name : "",
      // year: reportData
      //   ? dayjs(reportData.created_ts).get("year")
      //   : yearList
      //   ? yearList[0]
      //   : "",
      year: "",
      framework: reportData ? reportData.framework_id : "",
      // topic: reportData ? getReportTopic(reportData.topics) : topicOptionList,
      topic: esgoptions || "",
      start_month: reportData ? reportData.start_month : "",
      end_month: reportData ? reportData.end_month : "",
      country_code: reportData
      ? reportData.countries?.map((country) => countryData[country])
      : [],
    },
    validationSchema: createReportValidation,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  // const mapping = {};
  // topicList.forEach((obj) => {
  //   mapping[obj.name] = obj.id;
  // });
  // const topicIdList = selectedValues.map((name) => mapping[name]);

  const filteredValues = selectedValues.filter(
    (item) => item !== "esg" && item !== "assessments"
  );

  const onSubmitReportData = () => {
    const payload = {
      framework_id: createReportForm.values.framework,
      name: createReportForm.values.name,
      year: createReportForm.values.year,
      topic_id: filteredValues,
      start_month: createReportForm.values.start_month,
      end_month: createReportForm.values.end_month,
      country_code: createReportForm.values.country_code
    };
    if (filterCountryFacility.length > 0) {
      var apiFacilitites = [];
      for (const data of filterCountryFacility) {
        apiFacilitites.push(countryFacilityDataObject[data]);
      }
      payload.facilities = apiFacilitites;
    }
    dispatch(createReport(payload));
  };
  const onClose = useCallback(() => {
    createReportForm.resetForm();
    props.onClose();
  }, [createReportForm, props]);

  useEffect(() => {
    if (createReportStatus === STATUS.SUCCESS) {
      enqueueSnackbar("Report created successfully", { variant: "success" });
      dispatch(resetReportStatus());
      setSelectedValues([]);
      setCountry([]);
      setCountryFacility([]);
      onClose();
    } else if (createReportStatus === STATUS.ERROR) {
      enqueueSnackbar(
        createReportError.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetReportStatus());
      setSelectedValues([]);
      setCountry([]);
      setCountryFacility([]);
    }
  }, [
    createReportStatus,
    enqueueSnackbar,
    onClose,
    dispatch,
    createReportError.message,
  ]);

  useEffect(() => {
    if (topicFetchStatus === STATUS.SUCCESS) {
      createReportForm.setFieldValue("topic_id", topicOptionList);
    }
  }, [topicFetchStatus]);

  // const handleCheckboxChange = (event) => {
  //   const value = event.target.value;
  //   if (value === "all") {
  //     if (selectedValues.length === esgoptions.length) {
  //       setSelectedValues([]);
  //       setSelectAll(false);
  //     } else {
  //       setSelectedValues(esgoptions.map((item) => item.id));
  //       setSelectAll(true);
  //     }
  //   } else {
  //     const updatedArray = selectedValues.includes(value)
  //       ? selectedValues.filter((item) => item !== value)
  //       : [...selectedValues, value];
  //     setSelectedValues(updatedArray);
  //     if (updatedArray.length === esgoptions.length) setSelectAll(true);
  //     else setSelectAll(false);
  //   }
  // };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    if (value === "all") {
      if (selectedValues.length === esgoptions.length) {
        setSelectedValues([]);
        setSelectAll(false);
      } else {
        setSelectedValues(esgoptions.map((item) => item.id));
        setSelectAll(true);
      }
    } else {
      let updatedValues = [...selectedValues];
      const parentItem = Object.values(groupedOptions).find(
        ({ parent }) => parent?.id === value
      );
      const childItems = parentItem
        ? parentItem.children.map((child) => child.id)
        : [];
      if (parentItem) {
        if (isChecked) {
          updatedValues = [...updatedValues, value, ...childItems];
        } else {
          updatedValues = updatedValues.filter(
            (id) => id !== value && !childItems.includes(id)
          );
        }
      } else {
        const parent = Object.values(groupedOptions).find(({ children }) =>
          children.some((child) => child.id === value)
        );
        if (isChecked) {
          updatedValues.push(value);
          if (
            parent &&
            parent.children.every((child) => updatedValues.includes(child.id))
          ) {
            updatedValues.push(parent.parent.id);
          }
        } else {
          updatedValues = updatedValues.filter((id) => id !== value);
          if (parent) {
            updatedValues = updatedValues.filter(
              (id) => id !== parent.parent.id
            );
          }
        }
      }
      setSelectedValues([...new Set(updatedValues)]);
      setSelectAll(updatedValues.length === esgoptions.length);
    }
  };

  const [filterCountry, setCountry] = useState([]);
  const [facilitites, setFacility] = useState(countryFacilityDataObject);
  const [filterCountryFacility, setCountryFacility] = useState([]);
  const getFacility = (countries) => {
    const countryFacilityDataObject = {};
    var availableFacility = [];
    for (const item of countries) {
      Object.assign(
        countryFacilityDataObject,
        countryFacilityData[countryData[item]]
      );
      availableFacility = availableFacility.concat(
        Object.keys(countryFacilityData[countryData[item]])
      );
    }
    const persistedfacilitySelection = availableFacility.filter((element) =>
      filterCountryFacility.includes(element)
    );
    setFacility(countryFacilityDataObject);
    setCountryFacility(persistedfacilitySelection);
  };
  // const countryHandleChange = (event) => {
  //   const value = event.target.value;
  //   if (value[value.length - 1] === "all") {
  //     setCountry(
  //       filterCountry.length === Object.keys(countryData).length
  //         ? []
  //         : Object.keys(countryData)
  //     );
  //     getFacility(
  //       filterCountry.length === Object.keys(countryData).length
  //         ? []
  //         : Object.keys(countryData)
  //     );
  //     return;
  //   }
  //   setCountry(value);
  //   getFacility(value);
  // };

  const countryHandleChange = (event) => {
    const value = event.target.value;
  
    if (value[value.length - 1] === "all") {
      const allCountries = filterCountry.length === Object.keys(countryData).length
        ? []
        : Object.keys(countryData);
  
      setCountry(allCountries);
      getFacility(allCountries);
  
      // Update formik values with all or no country codes
      const allCountryCodes = allCountries.map((country) => countryData[country]);
      createReportForm.setFieldValue("country_code", allCountryCodes);
  
      return;
    }
  
    setCountry(value);
    getFacility(value);
  
    // Map selected country names to their codes
    const selectedCountryCodes = value.map((country) => countryData[country]);
    createReportForm.setFieldValue("country_code", selectedCountryCodes);
  };
  

  const FacilityHandleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setCountryFacility(
        filterCountryFacility.length === Object.keys(facilitites).length
          ? []
          : Object.keys(facilitites)
      );
      return;
    }
    setCountryFacility(value);
  };

  const [expanded, setExpanded] = useState({});

  const groupedOptions = esgoptions.reduce((acc, topic) => {
    if (!topic.category) {
      acc[topic.id] = { parent: topic, children: [] }; 
    } else {
      const parentKey = esgoptions.find(
        (item) => item.label === topic.category
      )?.id;
      if (parentKey) {
        acc[parentKey] = acc[parentKey] || { parent: null, children: [] };
        acc[parentKey].children.push(topic); 
      }
    }
    return acc;
  }, {});

  const handleExpandToggle = (parentId) => {
    setExpanded((prev) => ({ ...prev, [parentId]: !prev[parentId] }));
  };

  const getPrimaryPaymentDrawer = () => {
    return (
      <Box className={classes.mainContainer}>
        <CeroInput
          required
          id="name"
          name="name"
          label="Report Name"
          fullWidth
          value={createReportForm.values.name}
          onChange={createReportForm.handleChange}
          onBlur={createReportForm.handleBlur}
          error={createReportForm.touched.year && createReportForm.errors.name}
        />
        <CeroChipSelect
          label={"Country"}
          formControlClass={classes.MultiSelectformControl}
          optionsde={countryData}
          selected={filterCountry}
          handleChange={countryHandleChange}
          // handleChange={(selectedCountries) => {
          //   setFilterCountry(selectedCountries); // Update the selected countries in state
          //   const countryCodes = selectedCountries.map(
          //     (country) => countryData.find((data) => data.name === country)?.code
          //   );
          //   createReportForm.setFieldValue("country_code", countryCodes); // Update formik value
          // }}
        />
        <CeroChipSelect
          label={"Facility"}
          formControlClass={classes.MultiSelectformControl}
          optionsde={
            Object.keys(facilitites).length > 0
              ? facilitites
              : countryFacilityDataObject
          }
          selected={filterCountryFacility}
          handleChange={FacilityHandleChange}
        />
        <Grid>
          <Grid className={classes.gridContainer}>
            <Grid style={{ width: "48%", margin: "8px 0" }}>
              <CeroAutoComplete
                className={classes.yearFilter}
                required
                id="year"
                name="year"
                label="Assessment Year"
                fullWidth
                options={yearList}
                value={createReportForm.values?.year}
                onChange={(e, value) => {
                  createReportForm.setFieldValue("year", value?.id);
                }}
                onBlur={createReportForm.handleBlur}
                error={
                  createReportForm.touched.year && createReportForm.errors.year
                }
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
              />
            </Grid>
            <Grid className={classes.gridItem}>
              <CeroSelect
                classes={{ container: classes.selectContainer }}
                id="framework"
                name="framework"
                label="Framework"
                fullWidth
                options={frameworkOptionList}
                selectedValue={createReportForm.values.framework || ""}
                onChange={createReportForm.handleChange}
                onBlur={createReportForm.handleBlur}
                error={
                  createReportForm.touched.framework &&
                  createReportForm.errors.framework
                }
              />
            </Grid>
          </Grid>
          <Grid className={classes.gridContainer}>
            <Grid className={classes.gridItem}>
              <CeroSelect
                classes={{ container: classes.selectContainer }}
                id="start_month"
                name="start_month"
                label="Start Month"
                fullWidth
                options={months}
                selectedValue={createReportForm.values.start_month || ""}
                onChange={createReportForm.handleChange}
                onBlur={createReportForm.handleBlur}
                error={
                  createReportForm.touched.start_month &&
                  createReportForm.errors.start_month
                }
              />
            </Grid>

            <Grid className={classes.gridItem}>
              <CeroSelect
                classes={{ container: classes.selectContainer }}
                id="end_month"
                name="end_month"
                label="End Month"
                fullWidth
                options={months}
                selectedValue={createReportForm.values.end_month || ""}
                onChange={createReportForm.handleChange}
                onBlur={createReportForm.handleBlur}
                error={
                  createReportForm.touched.end_month &&
                  createReportForm.errors.end_month
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <InputLabel
          className={
            createReportForm.errors.end_month
              ? classes["input-padding"]
              : classes["no-padding"]
          }
          id="demo-multiple-name-label"
        >
          Select Topics
        </InputLabel>
        <Box className={classes.checkbox}>
          <FormGroup>
            <FormControlLabel
              key={"all"}
              control={
                <Checkbox
                  className={classes.selectCheckbox}
                  checked={selectAll}
                  onChange={handleCheckboxChange}
                  value={"all"}
                  style={{
                    color: "#8C2148",
                  }}
                />
              }
              label={"Select All"}
            />
            <Box>
              {Object.values(groupedOptions).map(({ parent, children }) => (
                <Box key={parent?.id} mb={2} sx={{ position: "relative" }}>
                  {parent && (
                    <Box display="flex" alignItems="center">
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.selectCheckbox}
                            // checked={selectedValues.includes(parent.id)}
                            checked={
                              children.length === 0
                                ? selectedValues.includes(parent.id)
                                : children.every((child) =>
                                    selectedValues.includes(child.id)
                                  )
                            }
                            // onClick={() => handleExpandToggle(parent.id)}
                            // onChange={handleCheckboxChange}
                            onClick={() => {
                              if (!expanded[parent.id]) {
                                setExpanded((prev) => ({ ...prev, [parent.id]: true }));
                              }
                            }}
                            onChange={(e) => {
                              handleCheckboxChange(e);
                              if (e.target.checked && !expanded[parent.id]) {
                                setExpanded((prev) => ({ ...prev, [parent.id]: true }));
                              }
                            }}
                            value={parent.id}
                            style={{
                              color: "#8C2148",
                            }}
                          />
                        }
                        label={parent.label}
                      />
                      {children.length > 0 && (
                        <IconButton
                        size="small"
                        onClick={() =>
                          setExpanded((prev) => ({
                            ...prev,
                            [parent.id]: !prev[parent.id],
                          }))
                        }
                      >
                        {expanded[parent.id] ? <ExpandLess /> : <ExpandMore />}
                      </IconButton>
                      )}
                    </Box>
                  )}

                  {children.length > 0 && expanded[parent?.id] && (
                    <Box
                      sx={{
                        position: "absolute",
                        left: "42px",
                        top: "45px",
                        bottom: "23px",
                        width: "1px",
                        backgroundColor: "#8C2148",
                      }}
                    />
                  )}

                  {expanded[parent?.id] &&
                    children.map((child, index) => (
                      <Box
                        key={child.id}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          ml: 15.5,
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            left: "-20px",
                            top: "50%",
                            width: "20px",
                            height: "1px",
                            backgroundColor: "#8C2148",
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              className={classes.selectCheckbox}
                              checked={selectedValues.includes(child.id)}
                              onChange={handleCheckboxChange}
                              value={child.id}
                              style={{
                                color: "#8C2148",
                              }}
                            />
                          }
                          label={child.label}
                        />
                      </Box>
                    ))}
                </Box>
              ))}
            </Box>
          </FormGroup>
        </Box>
      </Box>
    );
  };
  return (
    <CeroSideSheetDrawer
      primaryDrawer={{
        drawerOpen: isOpen,
        onClose: onClose,
        content: getPrimaryPaymentDrawer(),
        header: { title: "Create report" },
        footer: {
          primaryBtnTitle: isButtonLoading ? "Saving..." : "Save",
          secondaryBtnTitle: "Cancel",
          primaryBtnAction: onSubmitReportData,
          secondaryBtnAction: onClose,
          // disablePrimaryBtn:
          //   !createReportForm.dirty ||
          //   !createReportForm.isValid ||
          //   isButtonLoading,
        },
        classes: {
          drawerContainer: classes.drawerContainer,
          contentArea: classes.drawerContentArea,
        },
      }}
    />
  );
};

export default CreateReportDrawer;
