import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    height: "100%",
    borderRadius: 8,
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  tableContainer: {
    padding: 0,
    width: "100%",
    maxHeight: "calc(100% - 100px)",
    boxShadow: "none",
    background: theme.palette.background.white,
    overflow: "auto",
    marginBottom: theme.spacing(5),
    borderRadius: 8,
  },
  pending:{
    color: theme.palette.status.pendingApproval,
    fontWeight: 500,
    fontSize: 14
  },
  completed:{
    color: theme.palette.status.approved,
    fontWeight: 500,
    fontSize: 14
  },
  yearFilter:{
    marginBottom: theme.spacing(5),
    width: "25%",
    "& .MuiBox-root": {
      height: '42px !important',
      margin: theme.spacing(0.6, 0, 0, 0),
    },
    "& .MuiBox-root > .MuiAutocomplete-root ":{
      background:'white',
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root":{
      height:46
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root > input[type=text]":{
      paddingBlock:0,
      paddingInline:0
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .Mui-focused":{
      color:theme.palette.Primary.main,
      marginTop: '0 !important'
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiFormLabel-filled":{
      color:theme.palette.Primary.main,
      marginTop: '0 !important'
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputLabel-outlined":{
      marginTop:-3
    }
  },
  High:{
    color:'#48742C'
  },
  Low:{
    color:'#7B7B7B'
  },
  Medium:{
    color:'#F5C142'
  },
}));

export default useStyles;
