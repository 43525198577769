import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { STATUS } from "../../../redux/constants";
import { schemeValidation } from "./schema";
import {
  addWorkerSafetyTraining,
  getAssessmentPeriod,
  resetAddCombustionStatus,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroSelect from "../../../components/CeroSelect";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import { sampleYear } from "../../../constants";
import CeroDate from "../../../components/CeroDate";
import CeroQuestionWithCheckbox from "../../../components/CeroQuestionWithCheckbox";
import FileUpload from "../FileUpload";

const AddWorkerSafetyTrainingForm = (props) => {
  const { onCancel } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleAnswerChange = (fieldName, answer) => {
    formik.handleChange({ target: { name: fieldName, value: answer } });
  };

  const addEmissionData = useSelector(
    (state) => state.emission.addWorkerSafetyTraining
  );

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));
  const currentYear = new Date().getFullYear().toString()

  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      assessmentPeriod: "",
      trainingTitle: "",
      date: "",
      trainingMethods: "",
      attended: "",
      trainingHours: "",
      trainingProfile: "",
      details: "",
      isTrainingDuringPaidHours: "",
      isTrainingFreeOfCharge: "",
      isTrainingMandatory: "",
      areEmployeesCompensated: ""
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility));
    }
  }, [formik.values, dispatch]);

  useEffect(() => {
    if (addEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar(
        "Worker Safety Training and Procedure added successfully",
        { variant: "success" }
      );
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        addEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [addEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onAddWorkerSafetyTaining = () => {
    const requestData = {
      facility_id: formik.values.facility,
      year: formik.values.year,
      assessment_period: formik.values.assessmentPeriod,
      training_title: formik.values.trainingTitle,
      training_date: dayjs(formik.values.date).format("DD/MM/YYYY"),
      training_method: formik.values.trainingMethods,
      no_of_attendees: formik.values.attended,
      training_hours: formik.values.trainingHours,
      training_profile: formik.values.trainingProfile,
      training_details: formik.values.details,
      questions: [
        {
          question: "Is the training conducted during paid working hours?",
          answer: formik.values.isTrainingDuringPaidHours,
        },
        {
          question: "Is the training provided free of charge?",
          answer: formik.values.isTrainingFreeOfCharge,
        },
        {
          question: "If no, is the training mandatory?",
          answer: formik.values.isTrainingMandatory,
        },
        {
          question: "Are the employees compensated for attending?",
          answer: formik.values.areEmployeesCompensated,
        },
      ],
      file:selectedFile,
      save: true,
    };
    sessionStorage.setItem('year', requestData.year)
    dispatch(addWorkerSafetyTraining(requestData));
  };

  const trainingMethods = [
    { key: "On the job Training", value: "On the job Training" },
    { key: "Online Courses", value: "Online Courses" },
    { key: "Workshops", value: "Workshops" },
  ];

  const handleFileUpload = (file) => {
    setSelectedFile(file);
  };
  const handleFileRemove = () => {
    setSelectedFile(null);
  };
  

  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Worker Safety Training and Procedures
          </Typography>
          <Box>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid> 
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column">
                <CeroInput
                  required
                  id="trainingTitle"
                  name="trainingTitle"
                  label="Training Title"
                  fullWidth
                  multiline
                  rows="2"
                  value={formik.values.trainingTitle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  classes={{ container: classes.textAreaContainer }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroDate
                  required
                  label="Training Date"
                  value={formik.values.date}
                  assessmentPeriod={formik.values.assessmentPeriod}
                  year={formik.values.year}
                  onChange={(date) => formik.setFieldValue("date", date)}
                  error={formik.touched.date && formik.errors.date}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="trainingMethods"
                  name="trainingMethods"
                  label="Training Methods"
                  options={trainingMethods}
                  selectedValue={formik.values.trainingMethods}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroInput
                  required
                  id="attended"
                  name="attended"
                  label="Number of Attendees"
                  value={formik.values.attended}
                  type="number"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.attended && formik.errors.attended}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={4} xs={6}>
                <CeroInput
                  required
                  id="trainingHours"
                  name="trainingHours"
                  label="Number of training hours"
                  fullWidth
                  value={formik.values.trainingHours}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item container direction="column" md={8} xs={6}>
                <CeroInput
                  required
                  id="trainingProfile"
                  name="trainingProfile"
                  label="Trainer Profile"
                  fullWidth
                  value={formik.values.trainingProfile}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column">
                <CeroInput 
                  required
                  id="details"
                  name="details"
                  label="Training details"
                  fullWidth
                  multiline
                  rows="2"
                  value={formik.values.details}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.questionBox}
            >
            <CeroQuestionWithCheckbox
              required
              question="Is the training conducted during paid working hours?"
              formik={formik}
              fieldName="isTrainingDuringPaidHours"
              onChange={handleAnswerChange}
            />
            <CeroQuestionWithCheckbox
              required
              question="Is the training provided free of charge?"
              formik={formik}
              fieldName="isTrainingFreeOfCharge"
              onChange={handleAnswerChange}
            />
            {formik.values.isTrainingFreeOfCharge === "No" &&
            <>
            <CeroQuestionWithCheckbox
              question="If no, is the training mandatory?"
              formik={formik}
              fieldName="isTrainingMandatory"
              onChange={handleAnswerChange}
            />
            <CeroQuestionWithCheckbox
              question="Are the employees compensated for attending?"
              formik={formik}
              fieldName="areEmployeesCompensated"
              onChange={handleAnswerChange}
            /></>}
            </Grid>
          </Box>
          <FileUpload onFileUpload={handleFileUpload} onFileRemove={handleFileRemove} />
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            disabled={!formik.dirty || !formik.isValid || addEmissionData.status === STATUS.RUNNING}
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={onAddWorkerSafetyTaining}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={formik.resetForm}
          />
        </Box>
      </Container>
    </>
  );
};

export default AddWorkerSafetyTrainingForm;
