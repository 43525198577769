import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";

import dayjs from "dayjs";

import useStyles from "./styles";
import CeroPopUp from "../../../components/CeroPopUp";
import CeroInfoPair from "../../../components/CeroInfoPair";
import CeroTable from "../../../components/CeroTable";
import { useEffect } from "react";
import { getCountryList, getUserCompanyDetails } from "../../../redux/actions";

export const TableColumns = [
  {
    columnKey: "date_time",
    columnId: "date_time",
    columnHeader: "Date & Time",
    width: "30%",
  },
  {
    columnKey: "modified_by",
    columnId: "modified_by",
    columnHeader: "Modified by",
    width: "20%",
  },
  {
    columnKey: "action_taken",
    columnId: "action_taken",
    columnHeader: "Action Taken",
  },
];

const ViewChangeLog = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isOpen, setIsOpen } = props;

  const countryListData = useSelector(
    (state) => state.listings.countryList.data
  );

  useEffect(() => {
    dispatch(getCountryList());
    dispatch(getUserCompanyDetails("business"));
  }, [dispatch]);

  const changeLogData = useSelector(
    (state) => state.company.getAdminAnnouncementChangelog.data.change_logs
  );
  const changeLogData2 = useSelector(
    (state) => state.company.getAdminAnnouncement.data.announcement
  );
  const companyDetails = useSelector(
    (state) => state.account.companyDetails.data
  );

  const companyList = companyDetails
    ? [
        {
          value: companyDetails.name,
          key: companyDetails.id,
          title: companyDetails.name,
        },
      ]
    : [];

  const countryList = countryListData?.map((item) => ({
    key: item.code,
    value: item.name,
    title: item.name,
  }));

  const getTicketsList = () =>
    changeLogData?.map((item) => ({
      ...item,
      date_time: item.date_time
        ? dayjs(item.date_time).format("DD MMM YYYY hh:mm A")
        : "-",
    }));

  const startTime = dayjs(changeLogData2?.start_ts).format(
    "DD MMM YYYY hh:mm A"
  );
  const endTime = dayjs(changeLogData2?.end_ts).format("DD MMM YYYY hh:mm A");

  const countryOptions = changeLogData2?.country?.map((countryCode) => {
    const country = countryList.find((item) => item.key === countryCode);
    return country ? country.value : null;
  });

  const companyOptions = changeLogData2?.company_id?.map((companyCode) => {
    const company = companyList.find((item) => item.key === companyCode);
    return company ? company.value : null;
  });

  const statusData =
    changeLogData2?.status === "enable" ? (
      <div className={classes.enabled}>Enabled</div>
    ) : (
      <div className={classes.disabled}>Disabled</div>
    );

  const onClose = () => {
    setIsOpen(false);
  };

  const getDrawer = () => {
    return (
      <Grid container spacing={2} padding={10}>
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <Typography fontWeight={650} fontSize={18} mb={7} mt={-10}>
            Change Log
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          <Grid container item xs={6} direction="column" spacing={2}>
            <Grid item>
              <CeroInfoPair
                title="Status"
                value={statusData}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                  value: classes.title,
                }}
              />
            </Grid>
            <Grid item>
              <CeroInfoPair
                title="Notification Type"
                value={changeLogData2?.type_of_notification}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                  value: classes.title,
                }}
              />
            </Grid>
            <Grid item>
              <CeroInfoPair
                title="Exhibit Type"
                value={changeLogData2?.type_of_exhibit}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                  value: classes.title,
                }}
              />
            </Grid>
            <Grid item>
              <CeroInfoPair
                title="Created by"
                value={changeLogData2?.created_by}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                  value: classes.title,
                }}
              />
            </Grid>
          </Grid>

          <Grid container item xs={6} direction="column" spacing={2}>
            <Grid item>
              <CeroInfoPair
                title="Country"
                value={
                  Array.isArray(countryOptions)
                    ? countryOptions.join(", ")
                    : countryOptions
                }
                classes={{
                  container: classes.countryContainer,
                  title: classes.title,
                  value: classes.title,
                }}
              />
            </Grid>
            <Grid item>
              <CeroInfoPair
                title="Company"
                value={
                  Array.isArray(companyOptions)
                    ? companyOptions.join(", ")
                    : companyOptions
                }
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                  value: classes.title,
                }}
              />
            </Grid>
            <Grid item>
              <CeroInfoPair
                title="Start Time (UTC)"
                value={startTime}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                  value: classes.title,
                }}
              />
            </Grid>
            <Grid item>
              <CeroInfoPair
                title="End Time (UTC)"
                value={endTime}
                classes={{
                  container: classes.infoContainer,
                  title: classes.title,
                  value: classes.title,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} mb={4}>
          <Typography fontWeight={600} fontSize={15} mb={3} mt={6}>
            Message
          </Typography>
          {changeLogData2?.message}
        </Grid>
        <Grid item xs={12}>
          <CeroTable
            columns={TableColumns}
            data={getTicketsList}
            hasMore={false}
            loading={false}
            classes={{ tableContainer: classes.tableContainer }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <CeroPopUp
      primaryPopUp={{
        open: isOpen,
        onClose: onClose,
        content: getDrawer(),
        header: {
          title: "",
        },
      }}
      dialog={classes.popUpClass}
      showCloseButton={true}
    />
  );
};

export default ViewChangeLog;
