import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Stack,
} from "@mui/material";
import useStyles from "./styles";
import DashboardLayout from "../../layouts/DashboardLayout";
import CeroButton from "../../components/CeroButton";
import CeroTable from "../../components/CeroTable";
import dayjs from "dayjs";
import { getAdminAnnouncement, getAdminAnnouncementChangelog, getAdminAnnouncementList, getUserCompanyDetails, listAnnouncement, updateCompanyDetails } from "../../redux/actions";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CeroDropdown from "../../components/CeroDropdown";
import { sampleYear } from "../../constants";
import ViewChangeLog from "./ViewChangeLog";

export const TableColumns = [
  {
    columnKey: "type_of_notification",
    columnId: "type_of_notification",
    columnHeader: "Notification Type",
  },
  {
    columnKey: "created_by",
    columnId: "created_by",
    columnHeader: "Created by",
  },
  {
    columnKey: "start_ts",
    columnId: "start_ts",
    columnHeader: "Start Time (UTC)",
  },
  {
    columnKey: "end_ts",
    columnId: "end_ts",
    columnHeader: "End Time (UTC)",
  },
  {
    columnKey: "status",
    columnId: "status",
    columnHeader: "Status",
  },
  {
    columnKey: "action",
    columnId: "action",
    columnHeader: "",
  },
  {
    columnKey: "view",
    columnId: "view",
    columnHeader: "",
  },
];

export const companyList = [
  {id: "news",
  name: "News"
  },
  {id: "release_update",
  name: "Release Update"
  },
  {id: "outage",
  name: "Outage"
  },
  {id: "emergency",
  name: "Emergency"
  },
];

export const ticketType = [
  {id: "enable",
  name: "Enabled"
  },
  {id: "disable",
  name: "Disabled"
  },
];

var yearInput = [{ key: 0, value: 'All' }]
yearInput = yearInput.concat(sampleYear())


const AdminNotifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [year, setYear] = useState(0);
  const [notification_type, setnotification_type] = useState("");
  const [notification_status, setnotification_status] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  
  const announcementList = useSelector(
    (state) => 
       state.company.getAdminAnnouncementList?.data?.announcements
  );

  // console.log('announcementList', announcementList)

  // const userDetails = useSelector(
  //   (state) => state.account.getUserCompanyDetails.data 
  // )

  // console.log('userDetails', userDetails)



  const handleEditNotification = (item) => {
    navigate(`edit/${item}`)
  };
  const handleViewChangelog = (item) => {
    dispatch(getAdminAnnouncementChangelog(item))
    dispatch(getAdminAnnouncement(item))
    setIsOpen(true)
  };

  const companyListOption = companyList.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  
  const ticketTypeOption = ticketType.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  useEffect(() => {
    dispatch(getAdminAnnouncementList(year, notification_type, notification_status));
    // dispatch(getUserCompanyDetails(role));
  }, [dispatch, year, notification_type, notification_status]);
console.log('announcementList', announcementList)
  const getTicketsList = () =>
    announcementList?.map((item) => ({
      ...item,
      start_ts: item.start_ts
        ? dayjs(item.start_ts).format("DD MMM YYYY hh:mm A")
        : "-",
        end_ts: item.end_ts
        ? dayjs(item.end_ts).format("DD MMM YYYY hh:mm A")
        : "-",
        status: item?.status === "enable" ?  <div className={classes.enabled}>Enabled</div> : 
        <div className={classes.disabled}>Disabled</div>,
        action: (item.type_of_notification === "Outage" || item.type_of_notification === "Emergency") ? null : (
          <>
            <div className={classes.actions}>
              <div
                className={classes.linkButton}
                onClick={(e) => {
                  handleEditNotification(item.id);
                  e.stopPropagation();
                }}
              >
                <EditOutlinedIcon className={classes.editIcon} />
              </div>
            </div>
          </>
        ), 
        view: (
          <>
            <div className={classes.actions}>
              <div
                className={classes.linkButton}
                onClick={(e) => {
                  handleViewChangelog(item.id);
                  e.stopPropagation();
                }}
              >
                View Log
              </div>
            </div>
          </>
        ) 
    }));

  // const getTicketsList = () =>
  //   announcementList?.map((item) => {
  //     return {
  //       ...item,
  //       start_ts: item.start_ts
  //         ? dayjs(item.start_ts).format("DD MMM YYYY hh:mm A")
  //         : "-",
  //       end_ts: item.end_ts
  //         ? dayjs(item.end_ts).format("DD MMM YYYY hh:mm A")
  //         : "-",
  //       status:
  //         item.status === "enable" ? (
  //           <div className={classes.enabled}>Enabled</div>
  //         ) : (
  //           <div className={classes.disabled}>Disabled</div>
  //         ),
  //       action:
  //       item.type_of_notification !== "Outage" &&
  //       item.type_of_notification !== "Emergency" ? (
  //         <div className={classes.actions}>
  //             <div
  //               className={classes.linkButton}
  //               onClick={(e) => {
  //                 handleEditNotification(item.id);
  //                 e.stopPropagation();
  //                 console.log('item.type_of_notification', item.type_of_notification);
  //               }}
  //             >
  //               <EditOutlinedIcon className={classes.editIcon} />
  //             </div>
  //           </div>
  //         ) : null,
  //       view: (
  //         <div className={classes.actions}>
  //           <div
  //             className={classes.linkButton}
  //             onClick={(e) => {
  //               handleViewChangelog(item.id);
  //               e.stopPropagation();
  //             }}
  //           >
  //             View Log
  //           </div>
  //         </div>
  //       ),
  //     };
  //   });
  
  
  

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <div>
        <Grid
          container
          columnSpacing={2}
          alignItems="center"
          wrap="nowrap"
          className={classes.filterContainer}
        >
            <Grid item xs={2.5} >
              <CeroDropdown
                id="year"
                label="Year"
                fullWidth
                options={yearInput}
                onChange={({ target }) => setYear(target.value)}
                selectedValue={year}
                className= {classes.dropdown}
                />
            </Grid>
          <Grid item xs={2.5}>
            <CeroDropdown
              id="notification_type"
              label="Notification Type"
              fullWidth
              options={companyListOption}
              onChange={({ target }) => setnotification_type(target.value)}
              selectedValue={notification_type}
              className= {classes.dropdown}
            />
          </Grid>
          <Grid item xs={2.5}>
            <CeroDropdown
              id="notification_status"
              label="Status"
              fullWidth
              options={ticketTypeOption}
              onChange={({ target }) => setnotification_status(target.value)}
              selectedValue={notification_status}
              className= {classes.dropdown}
            />
          </Grid>
        <Grid item xs={4.5} container justifyContent="flex-end" pt={5}>
          <CeroButton
            buttonText="Add notification"
            className={classes.buttonPrimary}
            onClick={()=>{
              navigate("add-notifications");
            }
            }
          />
        </Grid>
        </Grid>
        </div>
        <CeroTable
          columns={TableColumns}
          data={getTicketsList}
          // data={""}
          hasMore={false}
          loading={false}
          // onSelectRow={rowData => onSelectCompanyData(rowData)}
          classes={{ tableContainer: classes.tableContainer }}
        />
        <ViewChangeLog
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              onClose={() => setIsOpen(false)}
            />
      </Container>
    </DashboardLayout>
  );
};

export default AdminNotifications;
