import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 2),
    height: "100%",
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  tableContainer: {
    maxHeight: "calc(100vh - 120px)",
    marginTop: theme.spacing(5),
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  audited: {
    color: theme.palette.status.audited,
    fontWeight: 500,
    fontSize: 14,
  },
  verified: {
    color: theme.palette.status.verified,
    fontWeight: 500,
    fontSize: 14,
  },
  review: {
    color: theme.palette.status.review,
    fontWeight: 500,
    fontSize: 14,
  },
  pending: {
    color: theme.palette.status.pendingApproval,
    fontWeight: 500,
    fontSize: 14,
  },
  inProgress: {
    color: "#7b7b7b",
    fontWeight: 500,
    fontSize: 14,
  },
  approved: {
    color: theme.palette.status.approved,
    fontWeight: 500,
    fontSize: 14,
  },
  submitted: {
    color: "#f7931d",
    fontWeight: 500,
    fontSize: 14,
  },
  filterContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: 300,
    marginRight: 10,
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
      backgroundColor: theme.palette.background.white,
    },
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 15,
    width: "98%"
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    height: 37,
    margin: theme.spacing(0, 2),
    textTransform: "capitalize",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
    },
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
    margin: theme.spacing(0,4),
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  questions: {
    margin: theme.spacing(5, 0),
    minWidth: '80vw',
    minHeight: '50vh',
    position: 'relative'
  },
  bottomContainer: {
    position: 'absolute',
    bottom: 10
  },
  linkButton: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    cursor: "pointer",
    fontWeight: 400,
    color: "#0088c7",
  },
  editIcon: {
    marginLeft: theme.spacing(3),
    fontSize: "large",
    color: "#8c2148"
  },
}));

export default useStyles;
