import { all, put, call, takeLatest } from 'redux-saga/effects'

import { request } from '../../services/client'
import { ActionTypes } from '../constants/actions';
import { APIEndpoints } from '../constants';

export function* getSustainabilityRiskQuestions(action) {
    try {
        const { industryId } = action.payload
        const response = yield call(request, APIEndpoints.GET_SUSTAINABILITY_RISK_QUESTIONS(industryId), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SUSTAINABILITY_RISK_QUESTIONS_SUCCESS,
            payload: response.supplier_industry_questions
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SUSTAINABILITY_RISK_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* createSustainabilityRiskQuestions(action) {
    try {        
        const { industryId, requestData } = action.payload
        const response = yield call(request, APIEndpoints.CREATE_SUSTAINABILITY_RISK_QUESTIONS(industryId), {
            method: 'POST',
            payload: requestData
        })
        yield put({
            type: ActionTypes.CREATE_SUSTAINABILITY_RISK_QUESTIONS_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.SCREATE_SUSTAINABILITY_RISK_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* getSustainabilityRiskGenericQuestions() {
    try {
        console.log("niklh123");
        
        const response = yield call(request, APIEndpoints.GET_SUSTAINABILITY_RISK_GENERIC_QUESTIONS, {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SUSTAINABILITY_RISK_GENERIC_QUESTIONS_SUCCESS,
            payload: response.supplier_generic_questions
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SUSTAINABILITY_RISK_GENERIC_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* sendSustainabilityRiskQuestions(action) {
    try {        
        const { senderList } = action.payload
        const response = yield call(request, APIEndpoints.SEND_SUSTAINABILITY_RISK_QUESTIONS, {
            method: 'POST',
            payload: senderList
        })
        yield put({
            type: ActionTypes.SEND_SUSTAINABILITY_RISK_QUESTIONS_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.SEND_SUSTAINABILITY_RISK_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* getSustainabilityRiskSurveyQuestions(action) {
    try {
        const { answerId } = action.payload
        const response = yield call(request, APIEndpoints.GET_SUSTAINABILITY_RISK_SURVEY_QUESTIONS(answerId), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_QUESTIONS_SUCCESS,
            payload: response.supplier_generic_list
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* sustainabilityRiskSurveyResponse(action) {
    try {
        const { answerId, requestData } = action.payload
        const response = yield call(request, APIEndpoints.SUSTAINABILITY_RISK_SURVEY_RESPONSE(answerId), {
            method: 'POST',
            payload: requestData
        })
        yield put({
            type: ActionTypes.SUSTAINABILITY_RISK_SURVEY_RESPONSE_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.SUSTAINABILITY_RISK_SURVEY_RESPONSE_FAILURE,
            payload: err.message
        })
    }
}

export function* getSustainabilityRiskSurveys(action) {
    const { year } = action.payload;
    try {
        const response = yield call(request, APIEndpoints.GET_SUSTAINABILITY_RISK_SURVEYS(year), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SUSTAINABILITY_RISK_SURVEYS_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SUSTAINABILITY_RISK_SURVEYS_FAILURE,
            payload: err.message
        })
    }
}

export function* getSustainabilityRiskRespondentList(action) {
    try {
        const { id } = action.payload
        const response = yield call(request, APIEndpoints.GET_SUSTAINABILITY_RISK_RESPONDENT_LIST(id), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SUSTAINABILITY_RISK_RESPONDENT_LIST_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SUSTAINABILITY_RISK_RESPONDENT_LIST_FAILURE,
            payload: err.message
        })
    }
}

export function* sendSustainabilityRiskRespondentReminder(action) {
    try {
        console.log('action.payload', action.payload)
        const { id, requestData } = action.payload
        const response = yield call(request, APIEndpoints.SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER(id), {
            method: 'POST',
            payload: {}
        })
        yield put({
            type: ActionTypes.SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER_FAILURE,
            payload: err.message
        })
    }
}

export function* getSustainabilityRiskSurveyTrackQuestions(action) {
    try {
        const { id } = action.payload
        const response = yield call(request, APIEndpoints.GET_SUSTAINABILITY_RISK_SURVEY_TRACK_QUESTIONS(id), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_TRACK_QUESTIONS_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_TRACK_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* getSustainabilityRiskSurveyTrackAnswers(action) {
    try {
        const { id } = action.payload
        const response = yield call(request, APIEndpoints.GET_SUSTAINABILITY_RISK_SURVEY_TRACK_ANSWERS(id), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_TRACK_ANSWERS_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_TRACK_ANSWERS_FAILURE,
            payload: err.message
        })
    }
}
export function* getSustainabilityRiskSurveyResults(action) {
    const { year, industry } = action.payload;
    try {
        const response = yield call(request, APIEndpoints.GET_SUSTAINABILITY_RISK_SURVEY_RESULTS(year), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_RESULTS_SUCCESS,
            payload: response.survey_list
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_RESULTS_FAILURE,
            payload: err.message
        })
    }
}
export default function* root() {
    yield all([
        takeLatest(ActionTypes.GET_SUSTAINABILITY_RISK_QUESTIONS, getSustainabilityRiskQuestions),
        takeLatest(ActionTypes.CREATE_SUSTAINABILITY_RISK_QUESTIONS, createSustainabilityRiskQuestions),
        takeLatest(ActionTypes.GET_SUSTAINABILITY_RISK_GENERIC_QUESTIONS, getSustainabilityRiskGenericQuestions),
        takeLatest(ActionTypes.SEND_SUSTAINABILITY_RISK_QUESTIONS, sendSustainabilityRiskQuestions),
        takeLatest(ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_QUESTIONS, getSustainabilityRiskSurveyQuestions),
        takeLatest(ActionTypes.SUSTAINABILITY_RISK_SURVEY_RESPONSE, sustainabilityRiskSurveyResponse),
        takeLatest(ActionTypes.GET_SUSTAINABILITY_RISK_SURVEYS, getSustainabilityRiskSurveys),
        takeLatest(ActionTypes.GET_SUSTAINABILITY_RISK_RESPONDENT_LIST, getSustainabilityRiskRespondentList),
        takeLatest(ActionTypes.SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER, sendSustainabilityRiskRespondentReminder),
        takeLatest(ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_TRACK_QUESTIONS, getSustainabilityRiskSurveyTrackQuestions),
        takeLatest(ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_TRACK_ANSWERS, getSustainabilityRiskSurveyTrackAnswers),
        takeLatest(ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_RESULTS, getSustainabilityRiskSurveyResults),

    ])
}
