import { handleActions } from "redux-actions";
import immutable from "immutability-helper";

import { ActionTypes } from "../constants/actions";
import { STATUS } from "../constants";
import { parseError } from "../../services/client";

export const sustainabilityRiskAssessmentState = {
  getSustainabilityRiskAssessementQuestions: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  createSustainabilityRiskAssessementQuestions: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getGenericQuestions: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  sendSustainabilityRiskAssessementQuestions: {
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getSustainabilityRiskAssessementSurveyQuestions:{
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  sustainabilityRiskAssessementSurveyResponse:{
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getSustainabilityRiskAssessementSurveys:{
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getSustainabilityRiskAssessementRespondentList:{
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  sendRespondentReminder:{
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getSustainabilityRiskAssessementSurveyTrackQuestions:{
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getSustainabilityRiskAssessementSurveyTrackAnswers:{
    data: {},
    status: STATUS.IDLE,
    message: "",
  },
  getSustainabilityRiskAssessementSurveyResults:{
    data: [],
    status: STATUS.IDLE,
    message: "",
  },
};

const sustainabilityRiskAssessmentActions = {
  sustainabilityRiskAssessment: handleActions(
    {
      [ActionTypes.GET_SUSTAINABILITY_RISK_QUESTIONS]: (state, { payload }) =>
        immutable(state, {
          getSustainabilityRiskAssessementQuestions: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_SUSTAINABILITY_RISK_QUESTIONS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
            getSustainabilityRiskAssessementQuestions: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_SUSTAINABILITY_RISK_QUESTIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
            getSustainabilityRiskAssessementQuestions: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.CREATE_SUSTAINABILITY_RISK_QUESTIONS]: (state, { payload }) =>
        immutable(state, {
          createSustainabilityRiskAssessementQuestions: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.CREATE_SUSTAINABILITY_RISK_QUESTIONS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          createSustainabilityRiskAssessementQuestions: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.CREATE_SUSTAINABILITY_RISK_QUESTIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          createSustainabilityRiskAssessementQuestions: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.GET_SUSTAINABILITY_RISK_GENERIC_QUESTIONS]: (state, { payload }) =>
        immutable(state, {
          getGenericQuestions: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_SUSTAINABILITY_RISK_GENERIC_QUESTIONS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getGenericQuestions: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_SUSTAINABILITY_RISK_GENERIC_QUESTIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getGenericQuestions: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

      [ActionTypes.SEND_SUSTAINABILITY_RISK_QUESTIONS]: (state, { payload }) =>
        immutable(state, {
          sendSustainabilityRiskAssessementQuestions: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.SEND_SUSTAINABILITY_RISK_QUESTIONS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          sendSustainabilityRiskAssessementQuestions: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.SEND_SUSTAINABILITY_RISK_QUESTIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          sendSustainabilityRiskAssessementQuestions: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),

        [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_QUESTIONS]: (state, { payload }) =>
          immutable(state, {
            getSustainabilityRiskAssessementSurveyQuestions: {
              status: { $set: STATUS.RUNNING },
            },
          }),
        [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_QUESTIONS_SUCCESS]: (state, { payload }) =>
          immutable(state, {
            getSustainabilityRiskAssessementSurveyQuestions: {
              status: { $set: STATUS.SUCCESS },
              data: { $set: payload },
            },
          }),
        [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_QUESTIONS_FAILURE]: (state, { payload }) =>
          immutable(state, {
            getSustainabilityRiskAssessementSurveyQuestions: {
              status: { $set: STATUS.ERROR },
              message: { $set: parseError(payload) },
            },
          }),

          [ActionTypes.SUSTAINABILITY_RISK_SURVEY_RESPONSE]: (state, { payload }) =>
            immutable(state, {
              sustainabilityRiskAssessementSurveyResponse: {
                status: { $set: STATUS.RUNNING },
              },
            }),
          [ActionTypes.SUSTAINABILITY_RISK_SURVEY_RESPONSE_SUCCESS]: (state, { payload }) =>
            immutable(state, {
              sustainabilityRiskAssessementSurveyResponse: {
                status: { $set: STATUS.SUCCESS },
                data: { $set: payload },
              },
            }),
          [ActionTypes.SUSTAINABILITY_RISK_SURVEY_RESPONSE_FAILURE]: (state, { payload }) =>
            immutable(state, {
              sustainabilityRiskAssessementSurveyResponse: {
                status: { $set: STATUS.ERROR },
                message: { $set: parseError(payload) },
              },
            }),
          [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEYS]: (state, { payload }) =>
            immutable(state, {
              getSustainabilityRiskAssessementSurveys: {
                status: { $set: STATUS.RUNNING },
              },
            }),
          [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEYS_SUCCESS]: (state, { payload }) =>
            immutable(state, {
              getSustainabilityRiskAssessementSurveys: {
                status: { $set: STATUS.SUCCESS },
                data: { $set: payload },
              },
            }),
          [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEYS_FAILURE]: (state, { payload }) =>
            immutable(state, {
              getSustainabilityRiskAssessementSurveys: {
                status: { $set: STATUS.ERROR },
                message: { $set: parseError(payload) },
              },
            }),
            [ActionTypes.GET_SUSTAINABILITY_RISK_RESPONDENT_LIST]: (state, { payload }) =>
              immutable(state, {
                getSustainabilityRiskAssessementRespondentList: {
                  status: { $set: STATUS.RUNNING },
                },
              }),
            [ActionTypes.GET_SUSTAINABILITY_RISK_RESPONDENT_LIST_SUCCESS]: (state, { payload }) =>
              immutable(state, {
                getSustainabilityRiskAssessementRespondentList: {
                  status: { $set: STATUS.SUCCESS },
                  data: { $set: payload },
                },
              }),
            [ActionTypes.GET_SUSTAINABILITY_RISK_RESPONDENT_LIST_FAILURE]: (state, { payload }) =>
              immutable(state, {
                getSustainabilityRiskAssessementRespondentList: {
                  status: { $set: STATUS.ERROR },
                  message: { $set: parseError(payload) },
                },
              }),
              [ActionTypes.SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER]: (state, { payload }) =>
                immutable(state, {
                  sendRespondentReminder: {
                    status: { $set: STATUS.RUNNING },
                  },
                }),
              [ActionTypes.SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                  sendRespondentReminder: {
                    status: { $set: STATUS.SUCCESS },
                    data: { $set: payload },
                  },
                }),
              [ActionTypes.SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER_FAILURE]: (state, { payload }) =>
                immutable(state, {
                  sendRespondentReminder: {
                    status: { $set: STATUS.ERROR },
                    message: { $set: parseError(payload) },
                  },
                }),
                [ActionTypes.RESET_SEND_RESPONDENT_REMINDER_STATUS]: (state, { payload }) =>
                  immutable(state, {
                    sendRespondentReminder: {
                          status: { $set: STATUS.IDLE },
                          message: { $set: '' }
                    },
                  }),
                [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_TRACK_QUESTIONS]: (state, { payload }) =>
                  immutable(state, {
                    getSustainabilityRiskAssessementSurveyTrackQuestions: {
                      status: { $set: STATUS.RUNNING },
                    },
                  }),
                [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_TRACK_QUESTIONS_SUCCESS]: (state, { payload }) =>
                  immutable(state, {
                    getSustainabilityRiskAssessementSurveyTrackQuestions: {
                      status: { $set: STATUS.SUCCESS },
                      data: { $set: payload },
                    },
                  }),
                [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_TRACK_QUESTIONS_FAILURE]: (state, { payload }) =>
                  immutable(state, {
                    getSustainabilityRiskAssessementSurveyTrackQuestions: {
                      status: { $set: STATUS.ERROR },
                      message: { $set: parseError(payload) },
                    },
                  }),
                  [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_TRACK_ANSWERS]: (state, { payload }) =>
                    immutable(state, {
                      getSustainabilityRiskAssessementSurveyTrackAnswers: {
                        status: { $set: STATUS.RUNNING },
                      },
                    }),
                  [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_TRACK_ANSWERS_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                      getSustainabilityRiskAssessementSurveyTrackAnswers: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                      },
                    }),
                  [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_TRACK_ANSWERS_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                      getSustainabilityRiskAssessementSurveyTrackAnswers: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) },
                      },
                    }),

      [ActionTypes.RESET_SUSTAINABILITY_RISK_SCREENING_STATUS]: (state, { payload }) =>
        immutable(state, {
          sendSustainabilityRiskAssessementQuestions:{
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          },
          sustainabilityRiskAssessementSurveyResponse:{
            status: { $set: STATUS.IDLE },
            message: { $set: "" },
          }
        }),
      [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_RESULTS]: (state, { payload }) =>
        immutable(state, {
          getSustainabilityRiskAssessementSurveyResults: {
            status: { $set: STATUS.RUNNING },
          },
        }),
      [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_RESULTS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          getSustainabilityRiskAssessementSurveyResults: {
            status: { $set: STATUS.SUCCESS },
            data: { $set: payload },
          },
        }),
      [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_RESULTS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          getSustainabilityRiskAssessementSurveyResults: {
            status: { $set: STATUS.ERROR },
            message: { $set: parseError(payload) },
          },
        }),
    },
    sustainabilityRiskAssessmentState
  ),
};

export default sustainabilityRiskAssessmentActions;
