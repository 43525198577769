import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'

import { ActionTypes } from '../constants/actions';
import { STATUS } from '../constants'
import { parseError } from '../../services/client';

export const assessmentState = {
    assessmentList: {
        data: [],
        status: STATUS.IDLE,
        message: ''
    },
    assessmentDetails: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    createAssessmentCycle: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    updateAssessmentCycle: {
        data: {},
        status: STATUS.IDLE,
        message: ''
    },
    assessmentPeriod: {
        data: {},
        lastYear:[],
        status: STATUS.IDLE,
        message: ''
    },
    saveStandardsQuestionnaire: {
        data: [],
        status: STATUS.IDLE,
        message: ''
    },
    getStandardsQuestionnaireHistory: {
        data: [],
        status: STATUS.IDLE,
        message: ''
    },
    viewStandardsQuestionnaireAnswer: {
        data: [],
        status: STATUS.IDLE,
        message: ''
    },
    submitStandardsQuestionnaire: {
        data: [],
        status: STATUS.IDLE,
        message: ''
    },
    getStandardsQuestionnaireAnswers: {
        data: [],
        status: STATUS.IDLE,
        message: ''
    },
    approveStandardsQuestionnaire: {
        data: [],
        status: STATUS.IDLE,
        message: ''
    },
    listAssessmentCycleCountry: {
        data: [],
        status: STATUS.IDLE,
        message: ''
    },
}

const assessmentActions = {
    assessment: handleActions(
        {

            [ActionTypes.LIST_ASSESSMENT_CYCLE]: (state, { payload }) =>
                immutable(state, {
                    assessmentList: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.LIST_ASSESSMENT_CYCLE_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    assessmentList: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.LIST_ASSESSMENT_CYCLE_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    assessmentList: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),

            [ActionTypes.GET_ASSESSMENT_DETAILS]: (state, { payload }) =>
                immutable(state, {
                    assessmentDetails: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.GET_ASSESSMENT_DETAILS_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    assessmentDetails: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.GET_ASSESSMENT_DETAILS_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    assessmentDetails: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),

            [ActionTypes.CREATE_ASSESSMENT_CYCLE]: (state, { payload }) =>
                immutable(state, {
                    createAssessmentCycle: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.CREATE_ASSESSMENT_CYCLE_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    createAssessmentCycle: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.CREATE_ASSESSMENT_CYCLE_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    createAssessmentCycle: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),

            [ActionTypes.UPDATE_ASSESSMENT_CYCLE]: (state, { payload }) =>
                immutable(state, {
                    updateAssessmentCycle: {
                        status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.UPDATE_ASSESSMENT_CYCLE_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    updateAssessmentCycle: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload },
                    }
                }),
            [ActionTypes.UPDATE_ASSESSMENT_CYCLE_FAILURE]: (state, { payload }) =>
                immutable(state, {
                    updateAssessmentCycle: {
                        status: { $set: STATUS.ERROR },
                        message: { $set: parseError(payload) }
                    }
                }),
                
                [ActionTypes.GET_ASSESSMENT_PERIOD]: (state, { payload }) =>
                    immutable(state, {
                        assessmentPeriod: {
                            status: { $set: STATUS.RUNNING }
                    }
                }),
            [ActionTypes.GET_ASSESSMENT_PERIOD_SUCCESS]: (state, { payload }) =>
                immutable(state, {
                    assessmentPeriod: {
                        status: { $set: STATUS.SUCCESS },
                        data: { $set: payload.assessment_period },
                        lastYear:{$set : payload.last_year_months}
                    }
                }),
                [ActionTypes.GET_ASSESSMENT_PERIOD_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                        assessmentPeriod: {
                            status: { $set: STATUS.ERROR },
                            message: { $set: parseError(payload) }
                        }
                    }),
                    [ActionTypes.RESET_ASSESSMENT_CYCLE]: (state, { payload }) =>
                        immutable(state, {
                            createAssessmentCycle: {
                        status: { $set: STATUS.IDLE },
                        message: { $set: '' }
                    },
                    updateAssessmentCycle: {
                        status: { $set: STATUS.IDLE },
                        message: { $set: '' }
                    }
                }),
                [ActionTypes.SAVE_STANDARDS_QUESTIONNAIRE]: (state, { payload }) =>
                    immutable(state, {
                        saveStandardsQuestionnaire: {
                            status: { $set: STATUS.RUNNING }
                        }
                    }),
                [ActionTypes.SAVE_STANDARDS_QUESTIONNAIRE_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                        saveStandardsQuestionnaire: {
                            status: { $set: STATUS.SUCCESS },
                            data: { $set: payload },
                        }
                    }),
                [ActionTypes.SAVE_STANDARDS_QUESTIONNAIRE_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                        saveStandardsQuestionnaire: {
                            status: { $set: STATUS.ERROR },
                            message: { $set: parseError(payload) }
                        }
                    }),
                    [ActionTypes.GET_STANDARDS_QUESTIONNAIRE_HISTORY]: (state, { payload }) =>
                        immutable(state, {
                        getStandardsQuestionnaireHistory: {
                            status: { $set: STATUS.RUNNING }
                        }
                    }),
                [ActionTypes.GET_STANDARDS_QUESTIONNAIRE_HISTORY_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                        getStandardsQuestionnaireHistory: {
                            status: { $set: STATUS.SUCCESS },
                            data: { $set: payload },
                        }
                    }),
                [ActionTypes.GET_STANDARDS_QUESTIONNAIRE_HISTORY_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                        getStandardsQuestionnaireHistory: {
                            status: { $set: STATUS.ERROR },
                            message: { $set: parseError(payload) }
                        }
                    }),
                    [ActionTypes.VIEW_STANDARDS_QUESTIONNAIRE_ANSWER]: (state, { payload }) =>
                    immutable(state, {
                        viewStandardsQuestionnaireAnswer: {
                            status: { $set: STATUS.RUNNING }
                        }
                    }),
                    [ActionTypes.VIEW_STANDARDS_QUESTIONNAIRE_ANSWER_SUCCESS]: (state, { payload }) =>
                        immutable(state, {
                            viewStandardsQuestionnaireAnswer: {
                                status: { $set: STATUS.SUCCESS },
                            data: { $set: payload },
                        }
                    }),
                [ActionTypes.VIEW_STANDARDS_QUESTIONNAIRE_ANSWER_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                        viewStandardsQuestionnaireAnswer: {
                            status: { $set: STATUS.ERROR },
                            message: { $set: parseError(payload) }
                        }
                    }),
                [ActionTypes.SUBMIT_STANDARDS_QUESTIONNAIRE]: (state, { payload }) =>
                    immutable(state, {
                        submitStandardsQuestionnaire: {
                            status: { $set: STATUS.RUNNING }
                        }
                    }),
                [ActionTypes.SUBMIT_STANDARDS_QUESTIONNAIRE_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                        submitStandardsQuestionnaire: {
                            status: { $set: STATUS.SUCCESS },
                            data: { $set: payload },
                        }
                    }),
                [ActionTypes.SUBMIT_STANDARDS_QUESTIONNAIRE_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                        submitStandardsQuestionnaire: {
                            status: { $set: STATUS.ERROR },
                            message: { $set: parseError(payload) }
                        }
                    }),
                [ActionTypes.GET_STANDARDS_QUESTIONNAIRE_ANSWERS]: (state, { payload }) =>
                    immutable(state, {
                        getStandardsQuestionnaireAnswers: {
                            status: { $set: STATUS.RUNNING }
                        }
                    }),
                [ActionTypes.GET_STANDARDS_QUESTIONNAIRE_ANSWERS_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                        getStandardsQuestionnaireAnswers: {
                            status: { $set: STATUS.SUCCESS },
                            data: { $set: payload },
                        }
                    }),
                [ActionTypes.GET_STANDARDS_QUESTIONNAIRE_ANSWERS_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                        getStandardsQuestionnaireAnswers: {
                            status: { $set: STATUS.ERROR },
                            message: { $set: parseError(payload) }
                        }
                    }),
                [ActionTypes.APPROVE_STANDARDS_QUESTIONNAIRE]: (state, { payload }) =>
                    immutable(state, {
                        approveStandardsQuestionnaire: {
                            status: { $set: STATUS.RUNNING }
                        }
                    }),
                [ActionTypes.APPROVE_STANDARDS_QUESTIONNAIRE_SUCCESS]: (state, { payload }) =>
                    immutable(state, {
                        approveStandardsQuestionnaire: {
                            status: { $set: STATUS.SUCCESS },
                            data: { $set: payload },
                        }
                    }),
                [ActionTypes.APPROVE_STANDARDS_QUESTIONNAIRE_FAILURE]: (state, { payload }) =>
                    immutable(state, {
                        approveStandardsQuestionnaire: {
                            status: { $set: STATUS.ERROR },
                            message: { $set: parseError(payload) }
                        }
                    }),
                    [ActionTypes.RESET_SAVE_STANDARDS_QUESTIONNAIRE]: (state, { payload }) =>
                        immutable(state, {
                            saveStandardsQuestionnaire: {
                                status: { $set: STATUS.IDLE },
                                message: { $set: '' }
                            }
                }),
                [ActionTypes.RESET_SUBMIT_STANDARDS_QUESTIONNAIRE]: (state, { payload }) =>
                    immutable(state, {
                        submitStandardsQuestionnaire: {
                            status: { $set: STATUS.IDLE },
                            message: { $set: '' }
                        }
                    }),
                    [ActionTypes.RESET_APPROVE_STANDARDS_QUESTIONNAIRE]: (state, { payload }) =>
                        immutable(state, {
                            approveStandardsQuestionnaire: {
                                status: { $set: STATUS.IDLE },
                                message: { $set: '' }
                            }
                        }),
                        [ActionTypes.RESET_STANDARDS_QUESTIONNAIRE_ANSWERS]: (state, { payload }) =>
                            immutable(state, {
                                getStandardsQuestionnaireAnswers: {
                                    status: { $set: STATUS.IDLE },
                                    message: { $set: '' },
                                    data: { $set: [] }
                                }
                            }),
                        [ActionTypes.LIST_ASSESSMENT_CYCLE_COUNTRY]: (state, { payload }) =>
                            immutable(state, {
                                listAssessmentCycleCountry: {
                                    status: { $set: STATUS.RUNNING }
                                }
                            }),
                        [ActionTypes.LIST_ASSESSMENT_CYCLE_COUNTRY_SUCCESS]: (state, { payload }) =>
                            immutable(state, {
                                listAssessmentCycleCountry: {
                                    status: { $set: STATUS.SUCCESS },
                                    data: { $set: payload },
                                }
                            }),
                        [ActionTypes.LIST_ASSESSMENT_CYCLE_COUNTRY_FAILURE]: (state, { payload }) =>
                            immutable(state, {
                                listAssessmentCycleCountry: {
                                    status: { $set: STATUS.ERROR },
                                    message: { $set: parseError(payload) }
                                }
                            }),
            },
        assessmentState
    )
}

export default assessmentActions;
