import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import CeroPopUp from "../../../../components/CeroPopUp";
import CeroButton from "../../../../components/CeroButton";
import CeroInput from "../../../../components/CeroInput";
import { useDispatch, useSelector } from "react-redux";
import { getSupplierQuestions } from "../../../../redux/actions/supplierScreening";
import CeroTable from "../../../../components/CeroTable";
import AddQuestions from "./AddQuestions";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { STATUS } from "../../../../redux/constants";
import { getSustainabilityRiskQuestions } from "../../../../redux/actions";

const ViewQuestions = ({
  openQuestionsForm,
  setOpenQuestionsForm,
  createQuestionStatus,
  companyName,
  currentIndustry,
}) => {
  const classes = useStyles();
  const [selectedValues, setSelectedValues] = useState({});
  const dispatch = useDispatch();
  const industry = currentIndustry;
  const supplierQuestionsList = useSelector((state) => {
    const data = state.sustainabilityRiskAssessment.getSustainabilityRiskAssessementQuestions.data;
    return Array.isArray(data) ? data : [];
  });
  const questionListStatus = useSelector((state) => state.sustainabilityRiskAssessment.getSustainabilityRiskAssessementQuestions.status);

  const handleCheckboxChange = (event, questionId) => {
    const value = event.target.value;
    setSelectedValues((prevValues) => {
      const questionValues = prevValues[questionId] || [];
      if (questionValues.includes(value)) {
        return {
          ...prevValues,
          [questionId]: questionValues.filter((item) => item !== value),
        };
      } else {
        return {
          ...prevValues,
          [questionId]: [...questionValues, value],
        };
      }
    });
  };

  useEffect(() => {
    if (industry) {
      dispatch(getSustainabilityRiskQuestions(industry));
    }
  }, [dispatch, industry]);

  const groupedQuestions = supplierQuestionsList?.reduce((acc, question) => {
    const section = question.section || "Uncategorized"; 
    if (!acc[section]) {
      acc[section] = [];
    }
    acc[section].push(question);
    return acc;
  }, {});

  let questionNumber = 1;
  return (
    <Paper className={classes.container}>
      <Box mb={4} display="flex" justifyContent="space-between" paddingY={2}>
        <Typography fontSize={20} fontWeight={550} variant="h3">
          SUPPLIER ASSESSMENT INDUSTRY SPECIFIC QUESTIONNAIRE
        </Typography>
        <CeroButton
          buttonText="Add new Questions"
          className={classes.buttonPrimary}
          onClick={() => setOpenQuestionsForm(true)}
        />
      </Box>
      {questionListStatus === STATUS.SUCCESS ?
      Object.entries(groupedQuestions)?.map(([sectionTitle, questions]) => (
            <Box key={sectionTitle} mb={4}>
              {questions?.map((question) => {
                if (question.question_id === null) {
                  const currentQuestionNumber = questionNumber;
                  questionNumber += 1;
                  return (
                    <Grid
                      key={question.id}
                      className={
                        question.question_type === "text"
                          ? classes.inputGrid
                          : classes.gridItem
                      }
                    >
                      <Typography
                        fontSize={18}
                        fontWeight={500}
                        variant="body1"
                        className={classes.mainQuestion}
                      >
                        {`${currentQuestionNumber}. ${question.question}`}
                      </Typography>
                      <Box className={classes.layout} display="flex" flexDirection="column" paddingLeft={6}>
                        {(question.question_type === "choose_from_option" ||
                          question.question_type === "choose_from_topic" ||
                          question.question_type === "multiselect_check_box") &&
                          question.options.map((option, idx) => (
                            <FormControlLabel
                              key={idx}
                              control={
                                <Checkbox
                                  className={classes.selectCheckbox}
                                  checked={
                                    selectedValues[question.id]?.includes(option) || false
                                  }
                                  onChange={(e) =>
                                    handleCheckboxChange(e, question.id)
                                  }
                                  value={option}
                                  sx={{
                                    color: "#5A404E !important",
                                    "&.Mui-checked": {
                                      color: "#8C2148 !important",
                                    },
                                  }}
                                  disabled
                                />
                              }
                              label={option}
                              className={classes.label}
                            />
                          ))}
                           {question.question_type === "upload" && (
                        <Box className={classes.upload}>
                          <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            disabled
                            className={classes.buttonPrimary}
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload files
                          </Button>
                        </Box>
                      )}
                        {question.question_type === "text" && (
                          <Box>
                            <CeroInput
                              classes={{ container: classes.inputContainerMain }}
                              rows={3}
                              placeholder={"Please type your Answer"}
                              id={`answer-${question.id}`}
                              name={`answer-${question.id}`}
                              label="Please type your Answer"
                              multiline
                              disabled
                            />
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  );
                }
                const subQuestionLetter = String.fromCharCode(
                  96 + supplierQuestionsList
                    .filter((q) => q.question_id === question.question_id)
                    .indexOf(question) + 1
                );
                return (
                  <Grid key={question.id} paddingLeft={6}>
                    <Typography className={classes.subQuestion}>
                      {`${subQuestionLetter}. ${question.question}`}
                    </Typography>
                    {question.options.map((option, idx) => (
                      <FormControlLabel
                        key={idx}
                        control={
                          <Checkbox
                            className={classes.selectCheckbox}
                            checked={
                              selectedValues[question.id]?.includes(option) || false
                            }
                            onChange={(e) =>
                              handleCheckboxChange(e, question.id)
                            }
                            value={option}
                            sx={{
                              color: "#5A404E !important",
                              "&.Mui-checked": {
                                color: "#8C2148 !important",
                              },
                            }}
                            disabled
                          />
                        }
                        label={option}
                        className={classes.label}
                      />
                    ))}
                    {question.question_type === "text" && (
                        <Box>
                          <CeroInput
                            classes={{container : classes.inputContainerSub}}
                            rows={2}
                            placeholder={"Please type your Answer"}
                            id={`answer-${question.id}`}
                            name={`answer-${question.id}`}
                            label="Please type your Answer"
                            multiline
                            disabled
                          />
                        </Box>
                      )}
                      {question.question_type === "upload" && (
                        <Box className={classes.upload}>
                          <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            disabled
                            className={classes.buttonPrimary}
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload files
                          </Button>
                        </Box>
                      )}
                  </Grid>
                );
              })}
            </Box>
          )) : <div className={classes.loading}>
          <CircularProgress />
        </div>}
      <CeroPopUp
        primaryPopUp={{
          open: openQuestionsForm,
          onClose: () => setOpenQuestionsForm(false),
          content: AddQuestions({
            setOpenQuestionsForm,
            createQuestionStatus,
            industry,
          }),
          header: { title: "" },
        }}
        classes={{ dialog: classes.dialog }}
        showCloseButton={true}
      />
    </Paper>
  );
};

export default ViewQuestions;
