import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import Slider from "@mui/material/Slider";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import CeroPopUp from "../../../../components/CeroPopUp";
import CeroButton from "../../../../components/CeroButton";
import CeroInput from "../../../../components/CeroInput";
import AddQuestions from "../AddQuestions";
import { useDispatch, useSelector } from "react-redux";
import { getMaterialityQuestions } from "../../../../redux/actions";
import CeroTable from "../../../../components/CeroTable";

const Questions = ({
  openQuestionsForm,
  setOpenQuestionsForm,
  createQuestionStatus,
  companyName,
}) => {
  const classes = useStyles();
  const [selectedValues, setSelectedValues] = useState({});
  const dispatch = useDispatch();

  const materialityQuestionsList = useSelector((state) => {
    const data = state.materiality.getMaterialityQuestions.data;
    return Array.isArray(data) ? data : [];
  });

  function toRoman(num) {
    const romanMap = {
      1: "I",
      2: "II",
      3: "III",
      4: "IV",
      5: "V",
      6: "VI",
      7: "VII",
      8: "VIII",
      9: "IX",
      10: "X",
      11: "XI",
      12: "XII",
      13: "XIII",
      14: "XIV",
      15: "XV",
    };

    return romanMap[num] || "Invalid number";
  }

  const questionDict = {};

  materialityQuestionsList.forEach(
    ({
      section,
      sub_section,
      question,
      category,
      question_type,
      options,
      id,
    }) => {
      // Ensure the section exists
      if (!questionDict[section]) {
        questionDict[section] = {};
      }

      // Ensure the sub_section exists
      if (!questionDict[section][sub_section]) {
        questionDict[section][sub_section] = [];
      }

      // Add the question to the appropriate sub_section
      questionDict[section][sub_section].push({
        question,
        category,
        question_type,
        options,
        id,
      });
    }
  );

  console.log(questionDict, "nik");

  const tableColums = [
    {
      columnKey: "topic",
      columnId: "topic",
      columnHeader: "Topic",
      width: "30%",
    },
    {
      columnKey: "relevance",
      columnId: "relevance",
      columnHeader: `The relevance of the topic for you as a ${companyName} stakeholder`,
      width: "35%",
    },
    {
      columnKey: "impact",
      columnId: "impact",
      columnHeader: `Impact/Influence of ${companyName} on this topic`,
      width: "35%",
    },
  ];

  const handleCheckboxChange = (event, questionId) => {
    const value = event.target.value;
    setSelectedValues((prevValues) => {
      const questionValues = prevValues[questionId] || [];
      if (questionValues.includes(value)) {
        return {
          ...prevValues,
          [questionId]: questionValues.filter((item) => item !== value),
        };
      } else {
        return {
          ...prevValues,
          [questionId]: [...questionValues, value],
        };
      }
    });
  };

  useEffect(() => {
    dispatch(getMaterialityQuestions());
  }, [dispatch]);

  var qtnIndex = 0;
  return (
    <Paper className={classes.container}>
      <Box
        mb={4}
        display="flex"
        justifyContent="space-between"
        paddingX={4}
        paddingY={2}
      >
        <Typography fontSize={20} fontWeight={550} variant="h3">
          MATERIALITY SURVEY
        </Typography>
        <CeroButton
          buttonText="Add new Questions"
          className={classes.buttonPrimary}
          onClick={() => setOpenQuestionsForm(true)}
        />
      </Box>
      <Typography
        paddingX={4}
        fontSize={20}
        fontWeight={550}
        variant="h3"
        paddingTop="10px"
      >
        {`SECTION 1 : BACKGROUND INFORMATION`}
      </Typography>
      {questionDict["Background Information"] &&
        Object.entries(questionDict["Background Information"]).map(
          ([key, questions]) =>
            questions.map((question, index) => {
              qtnIndex += 1;
              return (
                <Grid
                  key={question.id}
                  className={
                    question.question_type === "text" ||
                    question.question_type === "Text Field"
                      ? classes.inputGrid
                      : classes.gridItem
                  }
                >
                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    variant="h3"
                    paddingLeft={4}
                    paddingBottom={2}
                  >
                    {qtnIndex}. {question.question}
                  </Typography>
                  <Box display="flex" paddingLeft={4} paddingX={3}>
                    <Grid xs={12}>
                      {(question.question_type === "choose_from_option" ||
                        question.question_type === "choose_from_topic" ||
                        question.question_type === "Choose from Options") &&
                        question.options.map((option, idx) => (
                          <FormControlLabel
                            key={idx}
                            control={
                              <Checkbox
                                className={classes.selectCheckbox}
                                checked={
                                  selectedValues[question.id]?.includes(
                                    option
                                  ) || false
                                }
                                onChange={(e) =>
                                  handleCheckboxChange(e, question.id)
                                }
                                value={option}
                                sx={{
                                  color: "#5A404E !important",
                                  borderWidth: "none",
                                  paddingLeft: "20px !important",
                                  "&.Mui-checked": {
                                    color: "#8C2148 !important",
                                  },
                                }}
                                disabled
                              />
                            }
                            label={option}
                            className={classes.label}
                          />
                        ))}
                    </Grid>
                    {(question.question_type === "text" ||
                      question.question_type === "Text Field") && (
                      <Box sx={{ width: "80vw" }}>
                        <CeroInput
                          className={classes.textAreaContainer}
                          rows={3}
                          placeholder={"Please type your Answer"}
                          id={`answer-${question.id}`}
                          name={`answer-${question.id}`}
                          label="Please type your Answer"
                          multiline
                          disabled
                        />
                      </Box>
                    )}
                    {question.question_type === "table" && (
                      <Grid>
                        <Typography
                          fontSize={18}
                          fontWeight={500}
                          variant="h3"
                          paddingLeft={4}
                          paddingY={3}
                        >
                          Please rate the importance of the following topics for
                          you in two main areas:
                        </Typography>
                        <Typography paddingLeft={6} paddingBottom={2} mt={3}>
                          a) The relevance of the topic for you as a{" "}
                          {companyName} stakeholder
                        </Typography>
                        <Typography paddingLeft={6} paddingBottom={2} mb={3}>
                          b) The influence of {companyName} on this topic
                        </Typography>
                        <Typography paddingLeft={4} paddingY={3}>
                          This will allow us to identify material topics
                          relevant to our activities and disclose information
                          about our activities within{"\n"} these topics.Please
                          use a scale from 0 to 10
                        </Typography>
                        <Typography paddingLeft={4} paddingBottom={2}>
                          (0 - the topic is not important at all; 10 - the topic
                          is extremely important).
                        </Typography>
                        <CeroTable
                          columns={tableColums}
                          data={question.options.map((option) => ({
                            topic: option,
                            relevance: "",
                            impact: "",
                          }))}
                          hasMore={false}
                          loading={false}
                          classes={{
                            headerCell: classes.headerCell,
                            tableBodyCell: classes.tableBodyCell,
                            tableHead: classes.tableTitle,
                            tableContainer: classes.tableContainer,
                          }}
                        />
                      </Grid>
                    )}
                  </Box>
                </Grid>
              );
            })
        )}

      {Object.keys(questionDict).map(
        (section, sectionIndex) =>
          section !== "Background Information" && (
            <>
              <div className={classes.scoreDiv}>
                <Typography
                  fontSize={20}
                  fontWeight={550}
                  variant="h3"
                  paddingTop="10px"
                >
                  {`Section ${sectionIndex + 1} : ${section}`.toUpperCase()}
                </Typography>
                <div
                  className={classes.scoreHeadDiv}
                  style={{ display: sectionIndex == 1 ? "block" : "None" }}
                >
                  <Typography
                    fontSize={20}
                    fontWeight={550}
                    variant="h3"
                    paddingTop="10px"
                  >
                    {`Score`}
                  </Typography>
                </div>
              </div>
              {Object.keys(questionDict[section]).map(
                (subSection, subIndex) => (
                  <>
                    <div className={classes.subScoreDiv}>
                      <Typography
                        fontSize={19}
                        fontWeight={450}
                        variant="h4"
                        paddingTop="10px"
                      >
                        {`${toRoman(subIndex + 1)}. ${subSection}`}
                      </Typography>
                      <div
                        className={classes.scoreHeadDiv}
                        style={{
                          display:
                            sectionIndex == 1 && subIndex == 0
                              ? "block"
                              : "None",
                        }}
                      >
                        <div className={classes.scoreDiv}>
                          {["Low", "Medium", "high"].map((item) => (
                            <Typography
                              fontSize={18}
                              fontWeight={450}
                              variant="h4"
                              paddingTop="10px"
                            >
                              {item}
                            </Typography>
                          ))}
                        </div>
                        <div className={classes.scoreDiv}>
                          {[1, 2, 3, 4, 5].map((item) => (
                            <Typography
                              fontSize={18}
                              fontWeight={450}
                              variant="h4"
                              paddingTop="10px"
                            >
                              {item}
                            </Typography>
                          ))}
                        </div>
                      </div>
                    </div>
                    {questionDict[section][subSection].map(
                      (question, index) => {
                        qtnIndex += 1;
                        return (
                          <Grid
                            key={question.id}
                            className={
                              question.question_type === "text" ||
                              question.question_type === "Text Field"
                                ? classes.inputGrid
                                : question.question_type === "slider"
                                ? classes.sliderQuestionMain
                                : classes.gridItem
                            }
                          >
                            <div className={classes.sliderQuestion}>
                              <Typography
                                fontSize={18}
                                fontWeight={500}
                                variant="h3"
                                paddingLeft={4}
                                paddingBottom={2}
                              >
                                {qtnIndex}. {question.question}
                              </Typography>
                            </div>
                            <Box
                              display="flex"
                              paddingLeft={4}
                              paddingX={3}
                              className={
                                question.question_type === "slider"
                                  ? classes.sliderDiv
                                  : classes.default
                              }
                            >
                              <Grid xs={12}>
                                {(question.question_type ===
                                  "choose_from_option" ||
                                  question.question_type ===
                                    "choose_from_topic" ||
                                  question.question_type ===
                                    "Choose from Options") &&
                                  question.options.map((option, idx) => (
                                    <FormControlLabel
                                      key={idx}
                                      control={
                                        <Checkbox
                                          className={classes.selectCheckbox}
                                          checked={
                                            selectedValues[
                                              question.id
                                            ]?.includes(option) || false
                                          }
                                          onChange={(e) =>
                                            handleCheckboxChange(e, question.id)
                                          }
                                          value={option}
                                          sx={{
                                            color: "#5A404E !important",
                                            borderWidth: "none",
                                            paddingLeft: "20px !important",
                                            "&.Mui-checked": {
                                              color: "#8C2148 !important",
                                            },
                                          }}
                                          disabled
                                        />
                                      }
                                      label={option}
                                      className={classes.label}
                                    />
                                  ))}
                              </Grid>
                              {(question.question_type === "text" ||
                                question.question_type === "Text Field") && (
                                <Box sx={{ width: "80vw" }}>
                                  <CeroInput
                                    className={classes.textAreaContainer}
                                    rows={3}
                                    placeholder={"Please type your Answer"}
                                    id={`answer-${question.id}`}
                                    name={`answer-${question.id}`}
                                    label="Please type your Answer"
                                    multiline
                                    disabled
                                  />
                                </Box>
                              )}
                              {question.question_type === "table" && (
                                <Grid>
                                  <Typography
                                    fontSize={18}
                                    fontWeight={500}
                                    variant="h3"
                                    paddingLeft={4}
                                    paddingY={3}
                                  >
                                    Please rate the importance of the following
                                    topics for you in two main areas:
                                  </Typography>
                                  <Typography
                                    paddingLeft={6}
                                    paddingBottom={2}
                                    mt={3}
                                  >
                                    a) The relevance of the topic for you as a{" "}
                                    {companyName} stakeholder
                                  </Typography>
                                  <Typography
                                    paddingLeft={6}
                                    paddingBottom={2}
                                    mb={3}
                                  >
                                    b) The influence of {companyName} on this
                                    topic
                                  </Typography>
                                  <Typography paddingLeft={4} paddingY={3}>
                                    This will allow us to identify material
                                    topics relevant to our activities and
                                    disclose information about our activities
                                    within{"\n"} these topics.Please use a scale
                                    from 0 to 10
                                  </Typography>
                                  <Typography paddingLeft={4} paddingBottom={2}>
                                    (0 - the topic is not important at all; 10 -
                                    the topic is extremely important).
                                  </Typography>
                                  <CeroTable
                                    columns={tableColums}
                                    data={question.options.map((option) => ({
                                      topic: option,
                                      relevance: "",
                                      impact: "",
                                    }))}
                                    hasMore={false}
                                    loading={false}
                                    classes={{
                                      headerCell: classes.headerCell,
                                      tableBodyCell: classes.tableBodyCell,
                                      tableHead: classes.tableTitle,
                                      tableContainer: classes.tableContainer,
                                    }}
                                  />
                                </Grid>
                              )}
                              {question.question_type === "slider" && (
                                <Slider
                                  size="medium"
                                  defaultValue={3}
                                  aria-label="Small"
                                  valueLabelDisplay="auto"
                                  shiftStep={1}
                                  step={1}
                                  min={1}
                                  max={5}
                                  className={classes.slider}
                                />
                              )}
                            </Box>
                          </Grid>
                        );
                      }
                    )}
                  </>
                )
              )}
            </>
          )
      )}

      <CeroPopUp
        primaryPopUp={{
          open: openQuestionsForm,
          onClose: () => setOpenQuestionsForm(false),
          content: AddQuestions({ setOpenQuestionsForm, createQuestionStatus }),
          header: { title: "" },
        }}
        classes={{ dialog: classes.dialog }}
        showCloseButton={true}
      />
    </Paper>
  );
};

export default Questions;
