import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Typography, Box,CircularProgress, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { getAssessmentDetails } from "../../../redux/actions";
import { STATUS } from "../../../redux/constants";
import CeroButton from "../../../components/CeroButton";
import CeroInfoPair from "../../../components/CeroInfoPair";
import { months } from "../../../constants";
import DashboardLayout from "../../../layouts/DashboardLayout";
import useStyles from "./styles";
import CreateAssessmentCyclePopup from "../CreateAssessmentPopup";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { getCookie } from "../../../services/cookie";
import { listFramework } from "../../../redux/actions/listings";

const AssessmentYearlyDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const year = queryParams.get("year");
  const country = queryParams.get("country");
  const id = queryParams.get("id");

  const assessmentDetails = useSelector(
    (state) => state.assessment.assessmentDetails.data
  );
  const assessmentDetailsStatus = useSelector(
    (state) => state.assessment.assessmentDetails.status
  );

  const countryData = useSelector(state => state.listings.getOrganizationCountry.data.country_data);
  const countryMap = Object.entries(countryData).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});

  useEffect(() => {
    year && dispatch(getAssessmentDetails({ year:year, country:country, id:id }));
  }, [dispatch, year, country]);

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Box className={classes.header}>
          <Box className={classes.buttonContainer}>
            <div className={classes.backContainer} onClick={() => navigate(-1)}>
              <ArrowBackIosIcon />
              Back
            </div>
              <CeroButton
                buttonText="Edit"
                className={classes.buttonPrimary}
                onClick={() => setIsDrawerOpen(true)}
              />
          </Box>
        </Box>
        {assessmentDetailsStatus === STATUS.SUCCESS ? (
          <Box className={classes.innerContainer}>
            <Grid container spacing={2} display="flex" flexDirection="row">
            <Grid item xs={6}>
            <CeroInfoPair
              title="GWP dataset"
              value={assessmentDetails.gwp_dataset}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.title,
              }}
            />
            <CeroInfoPair
              title="Framework"
              value={assessmentDetails.framework}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.title,
              }}
            />
            <CeroInfoPair
              title="Country"
              value={countryMap[assessmentDetails.country]}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.title,
              }}
            />
            <CeroInfoPair
              title="Assessment year"
              value={assessmentDetails.assessment_year}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.title,
              }}
            />
            <CeroInfoPair
              title="Assessment start year"
              value={assessmentDetails.assessment_start_year}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.title,
              }}
            />
            <CeroInfoPair
              title="Assessment end year"
              value={assessmentDetails.assessment_end_year}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.title,
              }}
            />
            <CeroInfoPair
              title="Assessment start month"
              value={
                months.find(
                  (month) =>
                    month.key === assessmentDetails.assessment_start_month
                )?.value
              }
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.title,
              }}
            /></Grid>
            <Grid item xs={6}>
            <CeroInfoPair
              title="Assessment end month"
              value={
                months.find(
                  (month) =>
                    month.key === assessmentDetails.assessment_end_month
                )?.value
              }
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.title,
              }}
            />
            <CeroInfoPair
              title="Approval cycle"
              value={assessmentDetails.approval_cycle.split("_").join(" ")}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.title,
              }}
            />
            <CeroInfoPair
              title="Submission due days count"
              value={assessmentDetails.submission_due_days_count}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.title,
              }}
            />
            <CeroInfoPair
              title="Approval due days count"
              value={assessmentDetails.approval_due_days_count}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.title,
              }}
            />
            <CeroInfoPair
              title="Audit cycle"
              value={assessmentDetails.audit_cycle.split("_").join(" ")}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.title,
              }}
            />
            <CeroInfoPair
              title="Auditor due days count"
              value={assessmentDetails.audit_due_days_count}
              classes={{
                container: classes.infoContainer,
                title: classes.title,
                value: classes.title,
              }}
            />
            </Grid>
            </Grid>
          </Box>
        ) : (
          <Box className={classes.loader}>
            <Typography variant="h7" component="span">
              {assessmentDetailsStatus === STATUS.RUNNING ? (
                <CircularProgress />
              ) : assessmentDetailsStatus === STATUS.ERROR ? (
                "We couldn't process your request. Please try again later."
              ) : (
                ""
              )}
            </Typography>
          </Box>
        )}
        {assessmentDetailsStatus !== STATUS.RUNNING &&
          !_.isEmpty(assessmentDetails) && (
            <CreateAssessmentCyclePopup
              isOpen={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
              assessmentData={assessmentDetails}
              isEdit
            />
          )}
      </Container>
    </DashboardLayout>
  );
};

export default AssessmentYearlyDetails;
