import React, { useEffect, useState } from "react";
import { Typography, Link, TextField, Box, Grid, TableHead, TableCell, Table, TableBody, TableRow, TableContainer, Paper } from "@mui/material";
import useStyles from "./styles";
import CeroTable from "../../../../components/CeroTable";
import {
  getIotData,
  resetIotData,
  updateIotData,
} from "../../../../redux/actions";
import { useDispatch } from "react-redux";
import { months } from "../../../../constants";
import CeroPopUp from "../../../../components/CeroPopUp";
import CeroButton from "../../../../components/CeroButton";
import WarningIcon from "../../../../assets/icons/WarningIcon.png";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../../redux/constants";
import { getCookie } from "../../../../services/cookie";
import { rolesEnum } from "../../../../layouts/DashboardLayout/pages";

const DataTable = ({ programId , iotDetails, updatedStatus,year}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [editPopup, setEditPopup] = useState(false);
  const [warningPopup, setWarningPopup] = useState(false);
  const [editableRow, setEditableRow] = useState(null);
  const [editedValue, setEditedValue] = useState(0);
  const [isEditable, setIsEditable] = useState(false);
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";
  const role = getCookie("role");

  console.log('iotDetails', iotDetails)

  const headerMapping = {
    year: { columnHeader: "Year", width: "5vw" },
    month: { columnHeader: "Month", width: "5vw" },
    action: {
      columnKey: "action",
      columnId: "action",
      columnHeader: "Action",
      width: "5vw",
    },
    "Solar Energy Produced (kWh)": {
      columnHeader: "Solar Energy Produced (kWh)",
      width: "15vw",
    },
    "number of panels": { columnHeader: "CO2 Offset (kg)", width: "10vw" },
  };

  const carbonOffsetColumns = iotDetails?.table_header?.map((header) => ({
    columnKey: header,
    columnId: header,
    columnHeader: headerMapping[header]?.columnHeader || header,
    width: headerMapping[header]?.width || "10vw",
  }));

  if (carbonOffsetColumns && iotDetails?.table_header?.includes("Co2 Offset (kg)")) {
    carbonOffsetColumns.push({
      columnKey: "action",
      columnId: "action",
      columnHeader: "Action",
      width: "5vw",
    });
  }

  const onSelectData = (id) => {
    setEditPopup(true);
    setEditableRow(id);
  };

  const getMonthValue = (value) => {
    const monthKey = Number(value);
    const month = months.find((item) => item.key === monthKey);
    return month ? month.value : "";
  };

  const getProgramList = () =>
    iotDetails?.iot_data?.map((item) => {
      const monthValue = getMonthValue(item.month);
      const solarEnergyProduced = item["Solar Energy Produced (kWh)"];

      return {
        ...item,
        month: monthValue,
        "Solar Energy Produced (kWh)":
          editableRow === item._id && isEditable && (item.status=== "review" || item.status === "added") ? (
            <div className={classes.editBox}>
              <TextField
                value={editedValue}
                onChange={(e) => setEditedValue(e.target.value)}
                variant="outlined"
                className={classes.textField}
              />
              <CeroButton
                className={classes.buttonPrimary}
                variant="contained"
                onClick={() => handleSave(item._id)}
                buttonText="Save"
              />
              <CeroButton
                className={classes.buttonSecondary}
                variant="outlined"
                onClick={handleCancel}
                buttonText="Cancel"
              />
            </div>
          ) : (
            solarEnergyProduced
          ),
        action: (
          role !== rolesEnum.AUDITOR && !readOnlyMode && (item.status=== "review" || item.status === "added") && 
            <Link
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onSelectData(item._id);
            }}
          >
            Edit
          </Link>
        ),
      };
    });

  const handleSave = (id) => {
    setEditableRow(null);
    setIsEditable(false);
    setEditPopup(false);
    const requestData = {
      program_specific_data: [
        {
          data_name: "Solar Energy Produced (kWh)",
          value: parseInt(editedValue),
        },
      ],
    };
    dispatch(updateIotData(editableRow, requestData));
  };



  useEffect(() => {
    if (updatedStatus?.status === STATUS.SUCCESS) {
      dispatch(resetIotData());
      enqueueSnackbar("Value Edited Successfully!", {
        variant: "success", autoHideDuration: 3000
      });
      dispatch(getIotData(programId,year));
    } else if (updatedStatus?.status === STATUS.ERROR) {
      dispatch(resetIotData());
      enqueueSnackbar(
        updatedStatus?.data?.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error", autoHideDuration: 3000 }
      );
      dispatch(resetIotData());
    }
  }, [updatedStatus, enqueueSnackbar, dispatch]);

  const handleCancel = () => {
    setEditableRow(null);
    setIsEditable(false);
    setEditPopup(false);
  };

  const getWarningMessage = () => {
    const selectedRow = iotDetails?.iot_data?.find(
      (item) => item._id === editableRow
    );
    const currentValue = selectedRow
      ? selectedRow["Solar Energy Produced (kWh)"]
      : "";

    return (
      <>
        <Grid
          container
          spacing={2}
          alignItems="center"
          className={classes.warningBox}
        >
          <Grid item className={classes.icon}>
            <img src={WarningIcon} alt="Logo" width="40" height="40" />
          </Grid>
          <Grid item>
            <Typography variant="h6">Warning!</Typography>
          </Grid>
        </Grid>
        <Typography className={classes.popupTitle}>
          Manual entries will trigger Approval Flow!
        </Typography>
        <Typography className={classes.popupTitle}>
          Do you want to proceed?
        </Typography>
        <div className={classes.buttonContainer}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={() => {
              if (selectedRow) {
                setEditedValue(currentValue);
                setWarningPopup(false);
                setIsEditable(true);
              }
            }}
            buttonText="Yes"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setWarningPopup(false)}
            buttonText="No"
          />
        </div>
      </>
    );
  };


  const getEditContent = () => {
    return (
      <>
        <Typography variant="h6" style={{ textAlign: "center" }}>
          Edit Energy Produced
        </Typography>
        <Typography className={classes.popupTitle}>
          Are you sure you want to override the value pulled from IoT Device?
        </Typography>
        <div className={classes.buttonContainer}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={() => {
              setEditPopup(false);
              setWarningPopup(true);
            }}
            buttonText="Yes"
          />
          <CeroButton
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={() => setEditPopup(false)}
            buttonText="No"
          />
        </div>
      </>
    );
  };

  

  const noTableRows = () => (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {carbonOffsetColumns?.map((column) => (
              <TableCell key={column.columnId} className={classes.tableHeader}>
                {column.columnHeader}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell
              colSpan={carbonOffsetColumns?.length} 
              className={classes.noDataWarning}
              align="center" 
            >
              <Typography align="center">No Data</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box className={classes.container}>
      {iotDetails?.iot_data?.length === 0 ?
      noTableRows() :
      <CeroTable
        columns={carbonOffsetColumns}
        data={getProgramList}
        hasMore={false}
        loading={false}
        classes={{ tableContainer: classes.tableContainer }}
      />}
      <CeroPopUp
        primaryPopUp={{
          open: editPopup,
          onClose: () => setEditPopup(false),
          content: getEditContent(),
          header: { title: "" },
        }}
        showCloseButton={true}
        classes={{ dialog: classes.dialog }}
      />
      <CeroPopUp
        primaryPopUp={{
          open: warningPopup,
          onClose: () => setWarningPopup(false),
          content: getWarningMessage(),
          header: { title: "" },
        }}
        showCloseButton={true}
        classes={{ dialog: classes.dialog }}
      />
    </Box>
  );
};

export default DataTable;
