import { all, put, call, takeLatest } from 'redux-saga/effects'

import { request } from '../../services/client'
import { ActionTypes } from '../constants/actions';
import { APIEndpoints } from '../constants';

export function* setIndustryType(action) {
    try {        
        const { industry } = action.payload
        const response = yield call(request, APIEndpoints.SET_INDUSTRY_TYPE(industry), {
            method: 'POST',
            payload: industry
        })
        yield put({
            type: ActionTypes.SET_INDUSTRY_TYPE_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.SET_INDUSTRY_TYPE_FAILURE,
            payload: err.message
        })
    }
}

export function* getSurvey(action) {
    try {
        const response = yield call(request, APIEndpoints.GET_SURVEY, {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SURVEY_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SURVEY_FAILURE,
            payload: err.message
        })
    }
}

export function* getMaterialityIndustries(action) {
    try {
        const response = yield call(request, APIEndpoints.GET_MATERIALITY_INDUSTRIES, {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_MATERIALITY_INDUSTRIES_SUCCESS,
            payload: response?.materiality_industry_type_list || []
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_MATERIALITY_INDUSTRIES_FAILURE,
            payload: err.message
        })
    }
}

export function* getMaterialityQuestions(action) {
    try {
        const response = yield call(request, APIEndpoints.GET_MATERIALITY_QUESTIONS, {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_MATERIALITY_QUESTIONS_SUCCESS,
            payload: response.esg_questions_list
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_MATERIALITY_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* createMaterialityQuestions(action) {
    try {
        const { questions } = action.payload.payload;        
        const response = yield call(request, APIEndpoints.CREATE_MATERIALITY_QUESTIONS, {
            method: 'POST',
            payload:  {questions} 
        })
        yield put({
            type: ActionTypes.CREATE_MATERIALITY_QUESTIONS_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.CREATE_MATERIALITY_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* sendQuestionnaires(action) {
    try {
        const { users } = action.payload.payload;
        const response = yield call(request, APIEndpoints.SEND_QUESTIONNAIRES, {
            method: 'POST',
            payload:  {users} 
        })
        yield put({
            type: ActionTypes.SEND_QUESTIONNAIRES_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.SEND_QUESTIONNAIRES_FAILURE,
            payload: err.message
        })
    }
}

export function* getSurveyQuestions(action) {
    try {
        const { answerId } = action.payload
        const response = yield call(request, APIEndpoints.GET_SURVEY_QUESTIONS(answerId), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SURVEY_QUESTIONS_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SURVEY_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* answerSurveyQuestions(action) {
    try {
        const { answerId, requestData } = action.payload
        const response = yield call(request, APIEndpoints.ANSWER_SURVEY_QUESTIONS(answerId), {
            method: 'POST',
            payload: requestData
        })
        yield put({
            type: ActionTypes.ANSWER_SURVEY_QUESTIONS_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.ANSWER_SURVEY_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* getSurveyList({payload}) {
    try {
        const response = yield call(request, APIEndpoints.GET_SURVEY_LIST(payload.year), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SURVEY_LIST_SUCCESS,
            payload: response.survey_list
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SURVEY_LIST_FAILURE,
            payload: err.message
        })
    }
}

export function* trackSurvey(action) {
    try {
        const { surveyId } = action.payload
        const response = yield call(request, APIEndpoints.TRACK_SURVEY(surveyId), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.TRACK_SURVEY_SUCCESS,
            payload: response.survey_respondent_lst
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.TRACK_SURVEY_FAILURE,
            payload: err.message
        })
    }
}

export function* sendReminder(action) {
    try {        
        const { id } = action.payload
        const response = yield call(request, APIEndpoints.SEND_REMINDER(id), {
            method: 'POST',
            payload: id
        })
        yield put({
            type: ActionTypes.SEND_REMINDER_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.SEND_REMINDER_FAILURE,
            payload: err.message
        })
    }
}

export function* surveyResponse(action) {
    try {
        const { answerId } = action.payload
        const response = yield call(request, APIEndpoints.SURVEY_RESPONSE(answerId), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.SURVEY_RESPONSE_SUCCESS,
            payload: response.answer_response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.SURVEY_RESPONSE_FAILURE,
            payload: err.message
        })
    }
}

export function* surveyQuestions(action) {
    try {
        const { surveyId } = action.payload
        const response = yield call(request, APIEndpoints.SURVEY_QUESTIONS(surveyId), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.SURVEY_QUESTIONS_SUCCESS,
            payload: response.esg_questions_list
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.SURVEY_QUESTIONS_FAILURE,
            payload: err.message
        })
    }
}

export function* getSurveyInsigtsList({payload}) {
    try {
        const response = yield call(request, APIEndpoints.GET_SURVEY_INSIGHTS_LIST(payload.year), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SURVEY_INSIGHTS_LIST_SUCCESS,
            payload: response.survey_list
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SURVEY_INSIGHTS_LIST_FAILURE,
            payload: err.message
        })
    }
}

export function* getSurveyInsigtsDetails({payload}) {
    try {
        const response = yield call(request, APIEndpoints.GET_SURVEY_INSIGHTS_DETAILS(payload.id), {
            method: 'GET',
        })
        yield put({
            type: ActionTypes.GET_SURVEY_INSIGHTS_DETAILS_SUCCESS,
            payload: response
        })
    } catch (err) {
        /* istanbul ignore next */
        yield put({
            type: ActionTypes.GET_SURVEY_INSIGHTS_DETAILS_FAILURE,
            payload: err.message
        })
    }
}

export default function* root() {
    yield all([
        takeLatest(ActionTypes.SET_INDUSTRY_TYPE, setIndustryType),
        takeLatest(ActionTypes.GET_SURVEY, getSurvey),
        takeLatest(ActionTypes.GET_MATERIALITY_INDUSTRIES, getMaterialityIndustries),
        takeLatest(ActionTypes.GET_MATERIALITY_QUESTIONS, getMaterialityQuestions),
        takeLatest(ActionTypes.CREATE_MATERIALITY_QUESTIONS, createMaterialityQuestions),
        takeLatest(ActionTypes.SEND_QUESTIONNAIRES, sendQuestionnaires),
        takeLatest(ActionTypes.GET_SURVEY_QUESTIONS, getSurveyQuestions),
        takeLatest(ActionTypes.ANSWER_SURVEY_QUESTIONS, answerSurveyQuestions),
        takeLatest(ActionTypes.GET_SURVEY_LIST, getSurveyList),
        takeLatest(ActionTypes.TRACK_SURVEY, trackSurvey),
        takeLatest(ActionTypes.SEND_REMINDER, sendReminder),
        takeLatest(ActionTypes.SURVEY_RESPONSE, surveyResponse),
        takeLatest(ActionTypes.SURVEY_QUESTIONS, surveyQuestions),
        takeLatest(ActionTypes.GET_SURVEY_INSIGHTS_LIST, getSurveyInsigtsList),
        takeLatest(ActionTypes.GET_SURVEY_INSIGHTS_DETAILS, getSurveyInsigtsDetails),
    ])
}
