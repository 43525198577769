import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Container, Typography,CircularProgress, Chip, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import DashboardLayout from '../../layouts/DashboardLayout';
import Header from './Header';
import Status from './Status';
import { reportEmissionTypeData } from '../../constants';
import {
  resetRequestAuditData,
  getYearlyAuditSummary,
  requestAudit,
  getYearlyAuditSummaryOverview
} from '../../redux/actions';
import { STATUS } from '../../redux/constants';
import useStyles from './styles';
import _ from 'lodash';
import ReportAccordion from '../Reports/ReportDetails/ReportAccordian';
import CeroTable from '../../components/CeroTable';

const CurrentYearApproval = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { year } = useParams();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = new URLSearchParams(window.location.search);
  const statusId = queryParams.get('id');

  const auditYearlySummary = useSelector(
    (state) => state.audit.auditYearlySummary.data
  );
  const auditYearlySummaryOverView = useSelector(
    (state) => state.audit.auditYearlySummaryOverview.data
  );
  const auditYearlySummaryStatus = useSelector(
    (state) => state.audit.auditYearlySummary.status
  );
  const requestAuditStatus = useSelector(
    (state) => state.audit.requestAudit.status
  );
  const requestAuditError = useSelector(
    (state) => state.audit.requestAudit.message
  );
  

  const onSelectData = (row) => {
    const emisionType = row.type.replace(/ /g, '_')
    navigate(`/emissions/${emisionType}/?year=${year}&redirect_table=${true}&period=${auditYearlySummaryOverView.period}&country=${auditYearlySummaryOverView.country}`);
  };

  const combustionSummaryColumns = [
    {
      columnKey: 'status',
      columnId: 'status',
      columnHeader: 'Status',
      classes: { column: classes.cellContainer }
    },
    {
      columnKey: 'sector',
      columnId: 'sector',
      columnHeader: 'Topics',
      classes: { column: classes.cellContainer }
    },
    {
      columnKey: 'total_co2',
      columnId: 'total_co2',
      columnHeader: 'CO2 (tonnes)',
      classes: { column: classes.cellContainer }
    },
    {
      columnKey: 'total_ch4',
      columnId: 'total_ch4',
      columnHeader: 'CH4 (tonnes)',
      classes: { column: classes.cellContainer }
    },
    {
      columnKey: 'total_n2o',
      columnId: 'total_n2o',
      columnHeader: 'N2O (tonnes)',
      classes: { column: classes.cellContainer }
    },
    {
      columnKey: 'total_co2e',
      columnId: 'total_co2e',
      columnHeader: 'CO2e (tonnes)',
      classes: { column: classes.cellContainer }
    }
  ];

  const waterSummaryColumns = [
    {
      columnKey: 'status',
      columnId: 'status',
      columnHeader: 'Status',
      classes: { column: classes.cellContainer }
    },
    {
      columnKey: 'sector',
      columnId: 'sector',
      columnHeader: 'Topics',
      classes: { column: classes.cellContainer }
    },
    {
      columnKey: 'usage',
      columnId: 'usage',
      columnHeader: 'Usage',
      classes: { column: classes.cellContainer }
    },
    {
      columnKey: 'total_co2e',
      columnId: 'total_co2e',
      columnHeader: 'CO2e (tonnes)',
      classes: { column: classes.cellContainer }
    }
  ];


  useEffect(() => {
    if (statusId && year) {
      dispatch(getYearlyAuditSummary(year, statusId));
      dispatch(getYearlyAuditSummaryOverview(year, statusId));
    }
    else if (statusId) {
        dispatch(getYearlyAuditSummary(null, statusId));
        dispatch(getYearlyAuditSummaryOverview(null, statusId));
      }
    else if (year) {
      dispatch(getYearlyAuditSummary(year, null));
      dispatch(getYearlyAuditSummaryOverview(year, null));
    }
  }, [dispatch, year, statusId]);

  useEffect(() => {
    ;
  }, [dispatch, ]);

  useEffect(() => {
    if (requestAuditStatus === STATUS.SUCCESS) {
      dispatch(resetRequestAuditData());
      enqueueSnackbar('Successfully requested for audit', {
        variant: 'success'
      });
    } else if (requestAuditStatus === STATUS.ERROR) {
      dispatch(resetRequestAuditData());
      enqueueSnackbar(requestAuditError.message || "We couldn't process your request. Please try again later.", { variant: 'error' });
    }
  }, [requestAuditStatus, dispatch, enqueueSnackbar, requestAuditError.message, auditYearlySummaryOverView]);

  const onApplyFilter = (year) => {
    navigate(`/audit-status/current-year-approval/${year}`);
  };
  const onRequestAudit = (auditId) => {
    dispatch(
      requestAudit({
        auditor_id: auditId,
        audit_status_id: auditYearlySummary.audit_status_id
      })
    );
  };

let statusDict = {};
let statusClassesDict = {};

const getStatusColor = (item) => {
  if (item === 'approved') {
  return <Tooltip title="Approved" arrow><Chip className={classes.signalApproved}/></Tooltip>
  } else if (item === 'submitted') {
      return  <Tooltip title="Submitted" arrow><Chip className={classes.signalPending}/></Tooltip>
  } else if (item === 'audited') {
      return  <Tooltip title="Audited" arrow><Chip className={classes.signalAudited}/></Tooltip>
  } else if (item === 'review') {
      return <Tooltip title="Review" arrow><Chip className={classes.signalReview}/></Tooltip>
  }else if (item === 'added') {
      return <Tooltip title="Added" arrow><Chip className={classes.signalAdded}/></Tooltip>
  }else if (item === 'assigned') {
    return <Tooltip title="assigned" arrow><Chip className={classes.signalPending}/></Tooltip>
  }else if (item === 'Awaiting approval') {
    return <Tooltip title="Awaiting approval" arrow><Chip className={classes.signalPending}/></Tooltip>
  }else if (item === 'In Progress') {
    return <Tooltip title="In Progress" arrow><Chip className={classes.signalPending}/></Tooltip>
  }else if (item === 'verified') {
    return <Tooltip title="Verified" arrow><Chip className={classes.signalVerified}/></Tooltip>
  }else {
      return <Tooltip title={item} arrow><Chip className={classes.signalAdded}/>{item}</Tooltip>
  } 
  }
  
  const parentData = {};
    const superParentData = {};
    reportEmissionTypeData.map((item) => {
      item.subItems.map((innerItem) => {
        innerItem.subItems.map((subInnerItem) => {
          parentData[subInnerItem.id] = innerItem.id;
          superParentData[subInnerItem.id] = item.id;
        });
      });
    });
    let aggreatedData = {};
    auditYearlySummary.response?.map((item) => {
      let pushedData = item;
      let pushedcolumn = combustionSummaryColumns;
      if (parentData[item.type] === "energy_and_materials") {
        pushedData = {
          ...item,
          type: item.type ? item.type.replace(/_/g, ' ') : "",
          status: getStatusColor(item.status)
        };
        pushedcolumn = combustionSummaryColumns;
      } else if (
        parentData[item.type] === "waste_water" 
      ) {
        pushedData = {
          ...item,
          type: item.type ? item.type.replace(/_/g, ' ') : "",
          usage: `${item.usage}`,
          status: getStatusColor(item.status)
        };
        pushedcolumn = waterSummaryColumns;
      }
      if (parentData[item.type] in aggreatedData) {
        aggreatedData[parentData[item.type]]["data"] =
          aggreatedData[parentData[item.type]]["data"].concat(pushedData);
      } else {
        aggreatedData[parentData[item.type]] = {};
        aggreatedData[parentData[item.type]]["data"] = [pushedData];
      }
      if (!("columns" in aggreatedData[parentData[item.type]]))
        aggreatedData[parentData[item.type]]["columns"] = pushedcolumn;
    });
    const getStatusObject =  Object.keys(statusDict).map((item,index)=>{
      const uniqueStatuses = [...new Set(statusDict[item])];
      if (uniqueStatuses.length > 1) 
        statusClassesDict[item] = 'in progress'
      else{
        statusClassesDict[item] = uniqueStatuses[0]
        // if (statusDict[item].includes('in progress'))
        //   statusClassesDict[item] = 'in progress'
        // else if (statusDict[item].includes('added'))
        //   statusClassesDict[item] = 'added'
        // else if (statusDict[item].includes('pending approval'))
        //   statusClassesDict[item] = 'pending approval'
        // else if (statusDict[item].includes('approved'))
        //   statusClassesDict[item] = 'approved'
        //   else if (statusDict[item].includes('review'))
        //   statusClassesDict[item] = 'review'
        // else if (statusDict[item].includes('audited'))
        //   statusClassesDict[item] = 'audited'
      }
    }
    )
    const filteredData = auditYearlySummary.response?.filter(function (item) {
      return !("sector" in item);
    });
    const isaudited = auditYearlySummary.status === 'audited';

    const TableColumns = [{
      columnKey: 'assessment_year',
      columnId: 'assessment_year',
      columnHeader: 'Assessment Year',
  }, {
      columnKey: 'period',
      columnId: 'period',
      columnHeader: 'Period',
  }, {
      columnKey: 'program',
      columnId: 'program',
      columnHeader: 'Program name',
  }, {
      columnKey: 'status',
      columnId: 'status',
      columnHeader: 'Status',
  }]
  const getStatus = (status) => {
    console.log('status', status)
    if (status === 'approved') {
        return <Typography className={classes.approved}>Approved</Typography>;
    } else if (status === 'assigned') {
        return <Typography className={classes.assigned}>Pending Approval</Typography>;
    } else if (status === 'audited') {
        return <Typography className={classes.audited}>Audited</Typography>;
    } else if (status === 'review') {
      return <Typography className={classes.review}>Review</Typography>;
    }else if (status === 'reported') {
        return <Typography className={classes.reported}>Reported</Typography>;
    } else if (status === 'submitted') {
      return <Typography className={classes.submitted}>Pending Approval</Typography>;
    } else if (status === 'added') {
      return <Typography className={classes.added}>Added</Typography>; 
    } else if (status === 'verified') {
      return <Typography className={classes.verified}>Verified</Typography>; 
    } else if (status === 'in_progress') {
      return <Typography className={classes.inprogress}>In Progress</Typography>;
    } else if (status.toLowerCase() === 'pending_approval') {
      return <Typography className={classes.pending}>Pending Approval</Typography>;
    }
     else {
        return <Typography className={classes.default}>{status}</Typography>;
    }
};
  const getCarbonOffsetList = () =>
    auditYearlySummary.offset_approval_status_list?.map((item) => ({
      ...item,
      status: getStatus(item.status.toLowerCase()),
    }));

    const onSelectCarbonOffsetData = (row) => {
      console.log('row.assessment_year', row.assessment_year,row.period)
      navigate(`/carbon-offset/${row.program_id}`, {state:{choosenYear:row.assessment_year,choosenPeriod:row.period}});
    };
    return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Header
          onApplyFilter={onApplyFilter}
          selectedYear={year}
          isRequestAuditVisible={
            auditYearlySummary &&
            auditYearlySummary.actions &&
            auditYearlySummary.actions.perform_request_audit && 
            (auditYearlySummary.response?.length > 0  || auditYearlySummary.offset_approval_status_list?.length) && 
            !auditYearlySummaryOverView.is_self_audited
          }
          onRequestAudit={(auditId) => {onRequestAudit(auditId)}}
          isLoading={requestAuditStatus === STATUS.RUNNING}
          statusId={auditYearlySummary && auditYearlySummary.audit_status_id}
          showApproveButton={auditYearlySummaryOverView.is_self_audited || auditYearlySummary?.actions?.perform_request_audit}
          responseData={auditYearlySummary.response}
          country={auditYearlySummaryOverView.country}
          status={auditYearlySummaryOverView.status}
          offset_approval_status_list={auditYearlySummary.offset_approval_status_list}
        />
        {auditYearlySummaryStatus !== STATUS.SUCCESS ? (
          <Box className={classes.loader}>
            <Typography variant='h7' component='span'>
              {auditYearlySummaryStatus === STATUS.RUNNING
                ? <CircularProgress/>
                : auditYearlySummaryStatus === STATUS.ERROR
                ? "We couldn't process your request. Please try again later."
                : ''}
            </Typography>
          </Box>
        ) : (
          ''
        )}
        {auditYearlySummaryStatus === STATUS.SUCCESS &&
          (auditYearlySummary.response?.length  > 0 || auditYearlySummary.offset_approval_status_list?.length > 0) && (
            <Status
              status={auditYearlySummaryOverView.status}
              assignedBy={auditYearlySummaryOverView.assigned_by_name}
              assignedOn={auditYearlySummaryOverView.assigned_on}
              auditedBy={auditYearlySummaryOverView.audited_by_name}
              auditedOn={auditYearlySummaryOverView.audited_on}
              auditCycle={auditYearlySummaryOverView.audit_cycle}
              period={auditYearlySummaryOverView.period}
              noOfTickets={auditYearlySummaryOverView.total_tickets}
              auditNumber={auditYearlySummaryOverView.audit_number}
            />
          )}
        {/* {auditYearlySummaryStatus === STATUS.SUCCESS &&
          !!(auditYearlySummary.response?.length === 0) && (
            <Typography variant='h7' component='span'>
              {"Nothing to show at this moment (Topics added are not yet approved)"}
            </Typography>
          )} */}
        {/* {auditYearlySummaryStatus === STATUS.SUCCESS &&
          topicKeys &&
          topicKeys.length > 0 &&
          (<CeroStatusTableAccordion approvalData={auditYearlySummary.response} aggreatedData={aggreatedData} emissionData={filteredData}
            emissionDataStatus={auditYearlySummaryStatus}
            isaudited={isaudited} statusClassesDict={statusClassesDict} onSelectData={onSelectData} company={null} isAuditor={false}/>)
          } */}

            { auditYearlySummaryStatus === STATUS.SUCCESS && auditYearlySummary?.emissions.length>0 && (
              <>
              <Typography className={classes.title} paddingBottom={3}>
              ESG Data
            </Typography>
              <ReportAccordion
              approvalData={auditYearlySummary?.emissions}
              aggreatedData={aggreatedData}
              emissionData={filteredData}
              emissionDataStatus={auditYearlySummaryStatus}
              onSelectData={onSelectData}
              isaudited={isaudited}
              reportSignals={auditYearlySummary.category}
              topicSignals={auditYearlySummary.sub_category_list}
              subTopicSignals={auditYearlySummary.topic_type_status}
            /> 
            </>
              )}
              { auditYearlySummaryStatus === STATUS.SUCCESS && auditYearlySummary.offset_approval_status_list?.length>0 && (
                <>
               <Typography className={classes.title} paddingBottom={3}>
                Carbon Offset
              </Typography>
              <CeroTable
                  columns={TableColumns}
                  data={getCarbonOffsetList}
                  hasMore={false}
                  loading={false}
                  // loadMore={onLoadMore}
                  onSelectRow={onSelectCarbonOffsetData}
              />
              </>)}
      </Container>
    </DashboardLayout>
  );
};

export default CurrentYearApproval;
