import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../../layouts/DashboardLayout';
import { Box, Container, Link, Typography } from '@mui/material';
import useStyles from './styles';
import dayjs from 'dayjs';
import { getSupplierSurveys, getSustainabilityRiskSurveys } from '../../../redux/actions';
import CeroTable from '../../../components/CeroTable';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sampleYear } from '../../../constants';
import CeroAutoComplete from '../../../components/CeroAutoComplete';

const SustainabilityRiskAssessmentHistory = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [year, setYear] = useState(0);
  const isHistorypage = window.location.pathname.includes("/history")

  const surveyList = useSelector((state)=>state.sustainabilityRiskAssessment.getSustainabilityRiskAssessementSurveys.data?.survey_list);
  console.log('surveryList', surveyList)

  const progressTrackColumns = [
    {
      columnKey: "name",
      columnId: "name",
      columnHeader: "Survey Name",
      width: "40vw"
    },
    {
      columnKey: "created_ts",
      columnId: "created_ts",
      columnHeader: "Date",
      width: "20vw"
    },
    {
      columnKey: "status",
      columnId: "status",
      columnHeader: "Status",
      width: "20vw"
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "Action",
      width: "20vw"
    }
  ];

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));


  const getStatus = (status) => {
    if(status === "pending"){
      return <Typography className={classes.pending}>Pending</Typography>
    }else if(status === "completed"){
      return <Typography className={classes.completed}>Completed</Typography>
    }
  }
  const onSelectData = (id) => {
    if(isHistorypage){
      navigate(`/sustainability-risk/history/${id}`)
    }else{
    navigate(`/sustainability-risk/track-progress/${id}`)
    }
  }

  const getData = () => {
    if (!Array.isArray(surveyList)) {
      return [];
    }

    return surveyList?.map((item) => ({
      ...item,
      created_ts: item.created_ts ? dayjs(item.created_ts).format("DD/MM/YYYY") : "NA",
      status: getStatus(item.status),
      action: <Link onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onSelectData(item.id);
      }} style={{ cursor: "pointer"}}>View Details</Link>,
    }))
  }

  useEffect(()=>{
    dispatch(getSustainabilityRiskSurveys(year || 0))
  },[dispatch, year])
  
    return (
      <DashboardLayout>
      <Container className={classes.container}>
      {isHistorypage && <Box className={classes.yearFilter}>
          <CeroAutoComplete
            id="year"
            label="Assessment Year"
            onChange={(e, item) => setYear(item ? item.id : null)}
            options={yearList}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          </Box>}
      <CeroTable
          columns={progressTrackColumns}
          data={getData}
          hasMore={false}
          loading={false}
          useExtraStyles={true}      
          classes={{ tableContainer: classes.tableContainer }}
        />
      </Container>
    </DashboardLayout>
      );
}

export default SustainabilityRiskAssessmentHistory