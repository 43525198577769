import {
  Box,
  Container,
  Grid,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import useStyles from "./styles";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { APIEndpoints } from "../../../redux/constants";
import { getCookie } from "../../../services/cookie";
import { sampleYear, sampleTimeDurations, sampleDuration } from "../../../constants";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import { useFormik } from "formik";
import { schemeValidation } from "./schema";
import CeroInput from "../../../components/CeroInput";
import {
  getMission,
  getTrackingCycleAssessmentPeriod,
} from "../../../redux/actions";
import Task from "./Task";
import ViewInitiative from "./ViewInitiative/index.";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import FileUpload from "../../AddEmissions/FileUpload";

const Initiatives = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";

  const { enqueueSnackbar } = useSnackbar();
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(0);
  const [addedInitiatives, setAddedInitiatives] = useState([]);
  const [previousCountry, setPreviousCountry] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      mission: "",
      country: "",
      facility: "",
      year: "",
      tracking: "",
      initiative: "",
      select_initiative: "",
      duration:undefined
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  const countryData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_data
  );
  const countryFacilityData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_facility_data
  );
  const countryFacilityDataObject = {};
  for (const key in countryFacilityData) {
    Object.assign(countryFacilityDataObject, countryFacilityData[key]);
  }

  const [facilitites, setFacility] = useState(countryFacilityDataObject);
  const [filterCountryFacility, setCountryFacility] = useState([]);
  const [filterCountry, setCountry] = useState([]);
  const [showInitiativeInput, setShowInitiativeInput] = useState(false);

  const getFacility = (countries) => {
    const countryFacilityDataObject = {};
    var availableFacility = [];
    for (const item of countries) {
      Object.assign(
        countryFacilityDataObject,
        countryFacilityData[countries[0]]
      );
      availableFacility = availableFacility.concat(
        Object.keys(countryFacilityData[countries[0]])
      );
    }

    const persistedfacilitySelection = availableFacility.filter((element) =>
      filterCountryFacility.includes(element)
    );
    setFacility(countryFacilityDataObject);
    setCountryFacility(persistedfacilitySelection);
  };

  const countryHandleChange = (event) => {
    const value = event.target.value;
    setCountry(value);
    getFacility([value]);
  };

  const countryOptions = Object.keys(countryData).map((item) => ({
    key: countryData[item],
    value: item,
  }));

  const listDropdownOptions = [
    "Absorption chillers",
    "Advanced analytics for cooling system efficiency",
    "Advanced analytics for energy efficiency",
    "Automated compressor staging for cooling",
    "Automated mine ventilation control",
    "Cement with pozzolana",
    "Coarse flotation for efficiency",
    "Combustion-system improvements",
    "Dehumidifying coils in HVAC systems",
    "Direct-contact water heaters",
    "Efficient kiln drives",
    "Electric and hydro-powered drilling",
    "Energy management systems",
    "Energy-efficient blanching processes",
    "Equipment control programming for optimization",
    "Evaporator-fan controls in refrigerated storage",
    "E-methanol as renewable fuel/alternative fuel",
    "E-ammonia as renewable fuel/alternative fuel",
    "Floating/variable head-pressure controls",
    "Fluidized-bed cement kilns",
    "Fluidized-bed reactors for manufacturing",
    "Free cooling in appropriate climates",
    "Fuel-cell-powered mine vehicles",
    "Gravity-type blending silos",
    "Hardware demand control systems",
    "High-efficiency compressors",
    "High-efficiency grate coolers",
    "High-efficiency motors",
    "High-efficiency pumps",
    "High-pressure grinding rolls",
    "High-pressure roller presses for grinding",
    "Immersion cooling technology for data centers",
    "In-pit crushing and high-angle conveyance",
    "Kiln-shell heat-loss reduction",
    "LED lighting retrofits",
    "Liquid-desiccant systems for humidity control",
    "Low-face-velocity systems for energy savings",
    "Low-loss conveyor belts",
    "Low-pressure drop cyclones for preheaters",
    "Oil cooling conversion for compressors",
    "Pressurized-plenum recirculation systems",
    "Refrigeration heat recovery systems",
    "Robotized production systems",
    "Scroll compressors for improved energy use",
    "Smart grids",
    "Spray dryer heat recovery systems",
    "Stirred-media mills",
    "Truck optimization for reduced emissions",
    "Two-stage compression for refrigerants",
    "Underground ore preconcentration",
    "Variable speed drives for motors",
    "Vertical roller mills for finish grinding",
    "Other"
  ];

  const missionData = useSelector(
    (state) => state.goalSettings.getMission.data
  );

  const missionOptions = () => {
    const missionList = missionData?.missions?.map((item) => ({
      key: item.id,
      value: item.mission,
    }));
    return missionList;
  };

  const authToken = getCookie("auth_token_admin");

  // const onSaveInitiatives = async (event) => {
  //   const requestData = {
  //     mission_id: formik.values.mission,
  //     country: filterCountry,
  //     facility_id: formik.values.facility,
  //     assessment_year: formik.values.year,
  //     tracking_cycle: formik.values.tracking,
  //     initiative: formik.values.select_initiative !== 'Other' ? formik.values.select_initiative : formik.values.initiative ,
  //     duration: formik.values.duration,
  //     file:selectedFile,
  //   };
  //   try {
  //     const response = await axios.post(
  //       APIEndpoints.ADD_INITIATIVE,
  //       requestData,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${authToken}`,
  //         },
  //       }
  //     );
  //     enqueueSnackbar(response?.data.message, { variant: "success" });
  //     setAddedInitiatives((addedInitiatives) => [
  //       ...addedInitiatives,
  //       {
  //         initiative: response.data.initiative,
  //         initiative_id: response.data.initiative_id,
  //         id: response.data.id,
  //       },
  //     ]);
  //     formik.setValues({
  //       ...formik.values,
  //       initiative: "",
  //     });
  //   } catch (error) {
  //     enqueueSnackbar(error?.message, { variant: "error" });
  //   }
  // };

  const onSaveInitiatives = async () => {
    try {
      const { mission, facility, year, tracking, select_initiative, initiative, duration } = formik.values;
  
      const requestData = new FormData();
      const mapToFormData = (data) => {
        Object.entries(data).forEach(([key, value]) => {
          if (value !== undefined && value !== null) {
            requestData.append(key, value);
          }
        });
      };
  
      const formDataValues = {
        mission_id: mission,
        country: filterCountry,
        facility_id: facility,
        assessment_year: year,
        tracking_cycle: tracking,
        initiative: select_initiative !== "Other" ? select_initiative : initiative,
        duration,
      };
  
      mapToFormData(formDataValues);
  
      if (selectedFile) {
        requestData.append("file", selectedFile);
      }
  
      const response = await axios.post(APIEndpoints.ADD_INITIATIVE, requestData, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
  
      enqueueSnackbar(response?.data.message, { variant: "success" });
  
      setAddedInitiatives((prev) => [
        ...prev,
        {
          initiative: response.data.initiative,
          initiative_id: response.data.initiative_id,
          id: response.data.id,
        },
      ]);
  
      formik.setFieldValue("initiative", "");
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };
  

  const resetInitiativeList = () => {
    setAddedInitiatives([]);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleFileUpload = (file) => {
    setSelectedFile(file);
  };
  const handleFileRemove = () => {
    setSelectedFile(null);
  };
  
  const facilitiesArray = Object.entries(facilitites).map(([key, value]) => ({
    key: value,
    value: key,
  }));

  // const initiativeList = useSelector(
  //   (state) => state.goalSettings.listInitiative.data
  // );

  // const listOptions = (initiativeList.map(item => item.initiative))

  useEffect(() => {
    const { tracking } = formik.values;
    if (tracking) {
      dispatch(getTrackingCycleAssessmentPeriod(tracking));
    }
  }, [formik.values.tracking, dispatch]);

  useEffect(() => {
    dispatch(getMission(filterCountry));
  }, [dispatch, filterCountry]);

  useEffect(() => {
    if (previousCountry !== filterCountry) {
      formik.setValues({
        ...formik.values,
        mission: "",
        facility: "",
        year: "",
        tracking: "",
        initiative: "",
      });
      setPreviousCountry(filterCountry);
    }
  }, [filterCountry, previousCountry]);

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Paper
          className={classes.containerBox}
          style={{
            backgroundColor: (() => {
              switch (value) {
                case 0:
                  return "#F8F7F9";
                case 1:
                  return "#F8F7F9";
                default:
                  return "#E0E0E1";
              }
            })(),
          }}
        >
          <div className={classes.tabsContainer}>
            <Tabs
              value={value}
              className={classes.tabs}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={false}
            >
              {["Add Initiative", "View Initiatives"].map((topic, index) => (
                <Tab
                  className={classes.tabOption}
                  key={index}
                  style={{
                    backgroundColor: value === index ? "#F8F7F9" : "#E0E0E0",
                    color: value === index ? "white" : "inherit",
                    flexGrow: 1,
                    border: value === index ? "3px solid #5A404E" : "none",
                    borderBottom:
                      value === index ? "none" : "3px solid #5A404E",
                  }}
                  label={
                    <div
                      className={classes.tabLabel}
                      onClick={() =>
                        navigate(
                          `/initiatives/${topic
                            .split(" ")
                            .join("-")
                            .toLowerCase()}`
                        )
                      }
                    >
                      <span className={classes.tabTitle}>{topic}</span>
                    </div>
                  }
                />
              ))}
            </Tabs>
            <div className={classes.tabBorder}></div>
          </div>
          <div className={classes.bottomBox}>
            {value === 1 && (
              <ViewInitiative resetInitiativeList={resetInitiativeList} />
            )}
            {value === 0 && (
              <Box>
                <Grid
                  container
                  columnSpacing={0.1}
                  alignItems="center"
                  gap={1}
                  justifyContent="center"
                  wrap="nowrap"
                ></Grid>
                <Grid className={classes.inputContainer} columnSpacing={0.1}>
                  <Grid
                    item
                    container
                    direction="column"
                    lg={11.7}
                    md={6}
                    xs={12}
                    height={10}
                  >
                    <CeroSelect
                      // required
                      id="country"
                      name="country"
                      label="Country"
                      fullWidth
                      size="small"
                      options={countryOptions}
                      selectedValue={filterCountry}
                      onChange={countryHandleChange}
                      onBlur={formik.handleBlur}
                      // error={formik.touched.country && formik.errors.country}
                      classes={{ container: classes.inputFieldContainer }}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    lg={11.7}
                    md={6}
                    xs={12}
                  >
                    <CeroSelect
                      required
                      id="facility"
                      name="facility"
                      label="Facility"
                      fullWidth
                      size="small"
                      options={facilitiesArray}
                      selectedValue={formik.values.facility}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.facility && formik.errors.facility}
                      classes={{ container: classes.inputFieldContainer }}
                      sortOption={true}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    lg={11.85}
                    md={6}
                    xs={12}
                    style={{ padding: "0px", gridColumn: "1 / span 2 " }}
                  >
                    <CeroSelect
                      id="mission"
                      name="mission"
                      label="Choose Mission"
                      fullWidth
                      size="small"
                      options={missionOptions()}
                      selectedValue={formik.values.mission}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.mission && formik.errors.mission}
                      classes={{ container: classes.missionInput, paperRoot: classes.paperRoot }}
                      sortOption={true}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    lg={11.7}
                    md={6}
                    xs={12}
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'

                  >
                    <CeroSelect
                      required
                      id="year"
                      name="year"
                      label="Start Year"
                      fullWidth
                      size="small"
                      options={sampleYear()}
                      selectedValue={formik.values.year}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.year && formik.errors.year}
                      classes={{ container: classes.inputFieldContainer }}
                    />
                    <CeroSelect
                      required
                      id="duration"
                      name="duration"
                      label="Duration"
                      fullWidth
                      size="small"
                      options={sampleDuration()}
                      selectedValue={formik.values.duration}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.duration && formik.errors.duration}
                      classes={{ container: classes.inputFieldContainer }}
                      
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    lg={11.7}
                    md={6}
                    xs={12}
                  >
                    <CeroSelect
                      required
                      id="tracking"
                      name="tracking"
                      label="Tracking Cycle"
                      fullWidth
                      size="small"
                      options={sampleTimeDurations}
                      selectedValue={formik.values.tracking}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.tracking && formik.errors.tracking}
                      classes={{ container: classes.inputFieldContainer }}
                    />
                  </Grid>
                </Grid>
                  <FileUpload onFileUpload={handleFileUpload} onFileRemove={handleFileRemove} />
                {addedInitiatives?.length > 0 && (
                  <Grid>
                    {addedInitiatives?.map((item) => (
                      <Task
                        id={item.id}
                        initiative_id={item.initiative_id}
                        initiative={item.initiative}
                        startYear={formik.values.year}
                        duration={formik.values.duration}
                      />
                    ))}
                  </Grid>
                )}
                {show && (
                  <Grid
                    item
                    container
                    direction="column"
                    lg={11.85}
                    md={6}
                    xs={12}
                  >
                    <Grid
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: 'column',
                      }}
                    >
                      <Grid style={{ width: "100%" }}>
                        <CeroAutoComplete
                          id="select_initiative"
                          name="select_initiative"
                          options={listDropdownOptions}
                          onChange={(e, value) =>{
                            formik.setFieldValue(
                              "select_initiative",
                              value
                            )
                            value==='Other' ? setShowInitiativeInput(true) : setShowInitiativeInput(false)
                          }
                          }
                          label="Select Initiative"
                          classes={{ container: classes.textAreaContainer2 }}
                        />
                      </Grid>
                      {showInitiativeInput && (
                      <Grid style={{ width: "100%" }}>
                        <CeroInput
                          required
                          id="initiative"
                          name="initiative"
                          placeholder="Enter Initiative"
                          value={formik.values.initiative}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled= {formik.values.select_initiative && formik.values.select_initiative !== 'Other'}
                          error={
                            formik.touched.initiative &&
                            formik.errors.initiative
                          }
                          classes={{ container: classes.textAreaContainer }}
                        />
                      </Grid>
                        )}

                    </Grid>
                    <Box style={{ marginBottom: "30px" }}>
                      <CeroButton
                        buttonText="Save"
                        className={classes.saveButton}
                        onClick={() => {
                          setShow(false);
                          onSaveInitiatives();
                        }}
                        disabled={(!formik.dirty || !formik.isValid || (formik.values.select_initiative === 'Other' && !formik.values.initiative)) }
                      />
                      <CeroButton
                        buttonText="Cancel"
                        className={classes.cancelButton}
                        onClick={() => {
                          setShow(false);
                        }}
                      />
                    </Box>
                  </Grid>
                )}
                {!show && (
                  <Grid>
                    <CeroButton
                      buttonText="Add Initiatives"
                      className={classes.button}
                      onClick={() => {
                        setShow(true);
                        formik.setFieldValue(
                          "select_initiative",
                          null
                        );
                      }}
                      disabled={readOnlyMode}
                    />
                  </Grid>
                )}
              </Box>
            )}
          </div>
        </Paper>
      </Container>
    </DashboardLayout>
  );
};

export default Initiatives;
