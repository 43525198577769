import * as yup from "yup";
import { periodEnum } from ".";

export const createAssessmentValidation = yup.object({
  gwp_dataset: yup
    .string("GWP dataset is required")
    .required("GWP dataset is required"),
  assessment_year: yup
    .number("Assessment year is required")
    .required("Assessment year is required"),
  assessment_start_year: yup
    .number("Assessment start year is required")
    .required("Assessment start year is required"),
  assessment_end_year: yup
    .number("Assessment end year is required")
    .required("Assessment end year is required")
    .test('assessmentYearValidation',"Assessment end year should be same as assessment year", function(assessment_end_year_test) {
      const assessment_year_test = this.parent.assessment_year;
      if(assessment_year_test!==assessment_end_year_test)
        return false;
      else
        return true;
    }),
  assessment_start_month: yup
    .number("Assessment start month is required")
    .required("Assessment start month is required"),
  assessment_end_month: yup
    .number("Assessment end month is required")
    .required("Assessment end month is required"),
  approval_cycle: yup
    .string("Approval cycle is required")
    .required("Approval cycle is required"),
  submission_due_days_count: yup
    .number("Submission due days count is required")
    .required("Submission due days count is required"),
  // approval_assignment_due_days_count: yup
  //   .number("Approval assignment due days count is required")
  //   .required("Approval assignment due days count is required")
  //   .test("Max", "Should not exceed submission due days count", function (f) {
  //     const ref = yup.ref("submission_due_days_count");
  //     return f <= this.resolve(ref);
  //   }),
  approval_due_days_count: yup
    .number("Approval due days count is required")
    .required("Approval due days count is required"),
    // .test(
    //   "Max",
    //   "Should not exceed approval assignment due days count",
    //   function (f) {
    //     const ref = yup.ref("approval_assignment_due_days_count");
    //     return f <= this.resolve(ref);
    //   }
    // ),
    audit_cycle: yup
    .string("Audit cycle is required")
    .required("Audit cycle is required")
    .test('auditCycleValidation',"Approval cycle must not have large period than audit cycle", function(auditCycleValue) {
        const approvalCycleValue = this.parent.approval_cycle;
        if(auditCycleValue===periodEnum.QUARTERLY && [periodEnum.HALF_YEARLY,periodEnum.YEARLY].includes(approvalCycleValue))
          return false;
        else if(auditCycleValue===periodEnum.HALF_YEARLY && [periodEnum.YEARLY].includes(approvalCycleValue))
          return false
        else
          return true
      }),
      //   const ref = yup.ref("auditor_assignment_due_days_count");
      //   return f <= this.resolve(ref);
      // }),
  // auditor_assignment_due_days_count: yup
  //   .number("Auditor assignment due days count is required")
  //   .required("Auditor assignment due days count is required"),
  audit_due_days_count: yup
    .number("Audit due days count is required")
    .required("Audit due days count is required"),
    // .test("Max", "Should not exceed auditor assignment due days count", function (f) {
    //   const ref = yup.ref("auditor_assignment_due_days_count");
    //   return f <= this.resolve(ref);
    // }),
  assessment_country: yup
  .string("country required")
  .required("country required"),
  framework: yup
    .string("framework is required")
    .required("framework is required"),
});
