import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { STATUS } from "../../../redux/constants";
import { schemeValidation } from "./schema";
import {
  addPoliticalContribution,
  getAssessmentPeriod,
  resetAddCombustionStatus,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroSelect from "../../../components/CeroSelect";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import { sampleYear } from "../../../constants";
import CeroQuestionWithCheckbox from "../../../components/CeroQuestionWithCheckbox";
import FileUpload from "../FileUpload";

const AddPoliticalContributionForm = (props) => {
  const { onCancel } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFile, setSelectedFile] = useState(null);

  const addEmissionData = useSelector(
    (state) => state.emission.addPoliticalContributions
  );
  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const currentYear = new Date().getFullYear().toString()

  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      assessmentPeriod: "",
      amount: 0.0,
      beneficiaryContribution: "",
      contributionDescription: "",
      disclosureExplanation: "",
      politicalContributions: "",
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility, 'political_contributions'));
    }
  }, [formik.values, dispatch]);

  useEffect(() => {
    if (addEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Political Contribution added successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        addEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [addEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onAddPoliticalContribution = () => {
    const requestData = {
      facility_id: formik.values.facility,
      assessment_period: formik.values.assessmentPeriod,
      year: formik.values.year,
      any_political_contribution: formik.values.politicalContributions,
      amount: formik.values.amount,
      beneficiary_of_contribution: formik.values.beneficiaryContribution,
      description_of_contribution: formik.values.contributionDescription,
      disclosure_explanation: formik.values.disclosureExplanation,
      file:selectedFile,
      save: true,
    };
    sessionStorage.setItem('year', requestData.year)
    dispatch(addPoliticalContribution(requestData));
  };

  const handleAnswerChange = (fieldName, answer) => {
    formik.handleChange({ target: { name: fieldName, value: answer } });
  };

  const handleFileUpload = (file) => {
    setSelectedFile(file);
  };
  const handleFileRemove = () => {
    setSelectedFile(null);
  };
  

  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Political Contribution
          </Typography>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}

                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
            </Grid>
            <CeroQuestionWithCheckbox
              question="Choose whether your company made political contributions during the reporting period"
              formik={formik}
              fieldName="politicalContributions"
              onChange={handleAnswerChange}
              classes={{ questionBox: classes.questionBox }}
            />
            {formik.values.politicalContributions === "Yes" ? 
            <>
            <Typography variant="body1" style={{padding: "10px 0px"}}>
              If Yes, Provide Details of Contributions:
            </Typography>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={4} xs={6}>
                <CeroInput
                  id="amount"
                  name="amount"
                  label="Total amount of Political Contributions"
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.amount && formik.errors.amount}
                />
              </Grid>
              <Grid item container direction="column" md={8} xs={6}>
                <CeroInput
                  id="beneficiaryContribution"
                  name="beneficiaryContribution"
                  label="Beneficiary of the contribution"
                  fullWidth
                  value={formik.values.beneficiaryContribution}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.beneficiaryContribution && formik.errors.beneficiaryContribution}
                />
              </Grid>
            </Grid>
            <CeroInput
              required
              id="contributionDescription"
              name="contributionDescription"
              label="Description of Contributions"
              fullWidth
              multiline
              rows="2"
              value={formik.values.contributionDescription}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.contributionDescription && formik.errors.contributionDescription}
              classes={{ container: classes.textAreaContainer }}
            />
            <CeroInput
              required
              id="disclosureExplanation"
              name="disclosureExplanation"
              label="Disclosure Explanation"
              fullWidth
              multiline
              rows="2"
              value={formik.values.disclosureExplanation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.disclosureExplanation && formik.errors.disclosureExplanation}
              classes={{ container: classes.textAreaContainer }}
            />
            </> : ""}
          </Box>
          <FileUpload onFileUpload={handleFileUpload} onFileRemove={handleFileRemove} />
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            disabled={!formik.dirty || !formik.isValid || addEmissionData.status === STATUS.RUNNING}
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={onAddPoliticalContribution}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={formik.resetForm}
          />
        </Box>
      </Container>
    </>
  );
};

export default AddPoliticalContributionForm;
