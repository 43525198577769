import PropTypes from "prop-types";
import { useEffect, useState, useRef } from "react";
import { Drawer, Collapse, Icon } from "@mui/material";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CeroEdLogo from "../../assets/images/Logo";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useStyles from "./styles";
import { userRoles } from "../../constants";
import Cookies from "js-cookie";
import { getCookie, setCookie } from "../../services/cookie";
import React from "react";

const SideMenu = ({ options }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const listRef = useRef(null);
  const [moveDrawerToBack, setMoveDrawerToBack] = useState(false);
  const showSwitchUserModal = useSelector(
    (state) => state.users.showSwitchUserModal
  );
  const isTradeAdmin = getCookie("role_trade") === "trade_admin";
  const isTradePlatform = window.location.pathname.includes("trade");
  const scrollPos = getCookie("scrollPosition")|| 0;

  const userInfo = useSelector((state) => state.auth.userInfo);

  const sideMenu = Cookies.get("sideMenu") ? Cookies.get("sideMenu") : "{}";
  const [openSubMenu, setOpenSubMenu] = useState(JSON.parse(sideMenu));
  const [isMateriality, setIsMateriality] = useState(false);
  const [isSupplier, setIsSupplier] = useState(false);
  const [isSustainability, setIsSustainability] = useState(false);
  const [activeOption,setActiveOption] = useState("");

  const handleSubMenuClick = (key) => {
    const tmp = { ...openSubMenu };
    tmp[key] = !tmp[key];  
    setOpenSubMenu(tmp);
    Cookies.set("sideMenu", JSON.stringify(tmp));
  };
  

  const handleNavigation = (path) => {
    const scrollPosition = listRef.current ? listRef.current.scrollTop : 0;
    setCookie("scrollPosition", scrollPosition);
    navigate(path);
  };

  const isActive = (menuItem) => {
    return (
      location.pathname.includes(menuItem.path) ||
      (menuItem.subItems &&
        menuItem.subItems.some(
          (subItem) => location.pathname.includes(subItem.path) || isActive(subItem)
        ))
    );
  };

  useEffect(() => {
    if (listRef.current && scrollPos && scrollPos !== null) {
      setTimeout(() => {
        listRef.current.scrollTop = Number(scrollPos) || 0;
      }, 0);
    }
  }, [location.pathname, scrollPos]);

  useEffect(() => {
    if (showSwitchUserModal) {
      setMoveDrawerToBack(true);
    } else {
      setMoveDrawerToBack(false);
    }
  }, [showSwitchUserModal]);

  useEffect(()=>{
    setIsMateriality(false)
    setIsSupplier(false)
    setIsSustainability(false)
  },[])

  return (
    <Box
      className={[
        classes.drawerConainer,
        moveDrawerToBack ? classes.moveToBack : null,
      ]}
    >
      <Drawer
        className={classes.drawer}
        variant="permanent"
        anchor="left"
        classes={{ paper: classes.drawerCanvas }}
      >
        <Box className={classes.title}>
          <Box className={classes.titleText}>
            <CeroEdLogo />
          </Box>
        </Box>
        <List
          ref={listRef}
          sx={{
            fontWeight: "600 !important",
            marginTop: 8,
            marginLeft: 2,
            paddingLeft: 8,
            overflowY: "auto",
            height: "calc(100vh - 64px)",
          }}
        >
          {options.map((menuItem, index) => {
            return (
            <div key={menuItem.text}>
              <ListItem
                button
                onClick={() => {
                  handleSubMenuClick(index);
                  handleNavigation(menuItem.path);
                }}
                className={[
                  isActive(menuItem)
                    ? isTradeAdmin && isTradePlatform
                      ? classes.activeOptionTrade
                      : classes.activeOption
                    : "",
                  openSubMenu[index] &&
                  menuItem.subItems &&
                  menuItem.subItems.length > 0
                    ? classes.expandedOption
                    : "",
                ].join(" ")}
              >
                <ListItemIcon
                  className={
                    isActive(menuItem)
                      ? isTradeAdmin && isTradePlatform
                        ? classes.activeOptionTrade
                        : classes.activeOption
                      : classes.icon
                  }
                >
                  {menuItem.icon}
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={menuItem.text}
                  className={
                    isActive(menuItem)
                      ? isTradeAdmin && isTradePlatform
                        ? classes.activeOptionTrade
                        : classes.activeOption
                      : classes.menuText
                  }
                />
                {menuItem.subItems && menuItem.subItems.length > 0 && (
                  <ListItemIcon>
                    <Icon
                      style={{
                        color: openSubMenu[index] ? "#8C2148" : "#5A404E",
                        marginLeft: "auto",
                      }}
                    >
                      {openSubMenu[index] ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </Icon>
                  </ListItemIcon>
                )}
              </ListItem>
              {menuItem.subItems && menuItem.subItems.length > 0 && 
               (
                <Collapse in={openSubMenu[index]} timeout={0} unmountOnExit>
                  <List
                    // className={classes.branchList}
                    sx={{
                      marginLeft: 3,
                      padding: 0,
                      position: "relative",
                    }}
                  >
                    
                        <div
                        style={{
                          position: "absolute",
                          top: 0,
                          bottom: "22px",
                          left: "16px",
                          width: "1px",
                          backgroundColor: "rgb(140, 33, 72)",
                          height: menuItem.text === "Assessments" ? openSubMenu[index] ? openSubMenu[`${index}-${menuItem.subItems.length-1}`] ? openSubMenu[`${index}-${menuItem.subItems.length-2}`] ? openSubMenu[`${index}-${menuItem.subItems.length-3}`] ? "505px" : "315px" : openSubMenu[`${index}-${menuItem.subItems.length-3}`] ? "315px" : `${menuItem.subItems?.length * 48 -22  || 0}px` : 'auto' : '0px' : `${menuItem.subItems?.length * 48 - 22 || 0}px`,
                        }}
                      ></div>
                      
                    <>{
                      
                    }</>
                    {menuItem.subItems.map((subItem, subIndex) => (
                      <React.Fragment key={subItem.text}>
                        <ListItem
                          button
                          className={`${classes.branchListItem} ${
                            classes.branchList
                          } ${
                            location.pathname.includes(subItem.path)
                              ? classes.activeOption
                              : ""
                          }`}
                          

                          onClick={(e) => {
                            handleNavigation(subItem.path);
                            e.stopPropagation();
                            setActiveOption(subItem.text);
                            if (subItem.text === "Materiality") {
                              setIsMateriality(!isMateriality);
                            }
                            if (subItem.text === "Supplier(Vendor)") {
                              setIsSupplier(!isSupplier);
                            }
                            if (subItem.text === "Sustainability Risk") {
                              setIsSustainability(!isSustainability);
                            }
                            handleSubMenuClick(`${index}-${subIndex}`);
                          }}
                          
                        >
                          <ListItemIcon
                            className={
                              location.pathname.includes(subItem.path)
                                ? classes.activeOption
                                : classes.icon
                            }
                          ></ListItemIcon>
                          <ListItemText
                            disableTypography
                            primary={subItem.text}
                            className={
                              location.pathname.includes(subItem.path)
                                ? classes.activeOption
                                : classes.menuText
                            }
                          />
                          {subItem.subItems && subItem.subItems.length > 0 && (
                            <ListItemIcon>
                              <Icon
                                style={{
                                  color: openSubMenu[`${index}-${subIndex}`]
                                    ? "#8C2148"
                                    : "#5A404E",
                                  marginLeft: "auto",
                                }}
                              >
                                {openSubMenu[`${index}-${subIndex}`] ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </Icon>
                            </ListItemIcon>
                          )}
                        </ListItem>

                        {subItem.subItems && subItem.subItems.length > 0 && (
                          <Collapse
                            in={openSubMenu[`${index}-${subIndex}`]}
                            timeout={0}
                            unmountOnExit
                          >
                            <List
                              className={classes.branchList}
                              sx={{
                                marginLeft: 3,
                                padding: 0,
                              }}
                            >
                              {subItem.subItems.map(
                                (nestedItem, nestedIndex) => (
                                  <ListItem
                                    key={nestedItem.text}
                                    button
                                    className={`${classes.branchListItem} ${
                                      location.pathname.includes(
                                        nestedItem.path
                                      )
                                        ? classes.activeOption
                                        : ""
                                    }`}
                                    onClick={() =>{
                                      handleNavigation(nestedItem.path)
                                      if (nestedItem.parent === "materiality") {
                                        setIsMateriality(true);
                                      }
                                      if (nestedItem.parent === "supplier") {
                                        setIsSupplier(true);
                                      }
                                      if (nestedItem.parent === "sustainability") {
                                        setIsSustainability(true);
                                      }
                                    }
                                     
                                    }
                                  >
                                    <ListItemIcon
                                      className={
                                        location.pathname.includes(
                                          nestedItem.path
                                        )
                                          ? classes.activeOption
                                          : classes.icon
                                      }
                                    ></ListItemIcon>
                                    <ListItemText
                                      disableTypography
                                      primary={nestedItem.text}
                                      className={
                                        location.pathname.includes(
                                          nestedItem.path
                                        )
                                          ? classes.activeOption
                                          : classes.menuText
                                      }
                                    />
                                  </ListItem>
                                )
                              )}
                            </List>
                          </Collapse>
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
            )
})}
        </List>
      </Drawer>
    </Box>
  );
};

SideMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
};

SideMenu.defaultProps = {
  options: [],
};


export default React.memo(SideMenu);
