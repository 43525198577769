import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/DashboardLayout";
import { Box, Container, Link, Typography } from "@mui/material";
import useStyles from "./styles";
import CeroTable from "../../../components/CeroTable";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSurveyInsightsList, getSurveyList } from "../../../redux/actions";
import dayjs from "dayjs";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import { sampleYear } from "../../../constants";

const MaterialityInsights = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [year, setYear] = useState(0);
  const isHistorypage = window.location.pathname.includes("/history");
  const surveyList = useSelector(
    (state) => state.materiality.getSurveyInsightsList.data
  );

  const progressTrackColumns = [
    {
      columnKey: "name",
      columnId: "name",
      columnHeader: "Survey Name",
      width: "40vw",
    },
    {
      columnKey: "created_ts",
      columnId: "created_ts",
      columnHeader: "Date",
      width: "20vw",
    },
    {
      columnKey: "respondents",
      columnId: "respondents",
      columnHeader: "Respondents",
      width: "20vw",
      alignContent: "center",
      align: "center",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "Action",
      width: "20vw",
    },
    {
      columnKey: "materiality_score",
      columnId: "materiality_score",
      columnHeader: "materiality Score",
      width: "20vw",
      alignContent: "center",
      align: "center",
    },
    {
      columnKey: "materiality_level",
      columnId: "materiality_level",
      columnHeader: "materiality Level",
      width: "20vw",
    },
    {
      columnKey: "insights",
      columnId: "insights",
      columnHeader: "Insights",
      width: "20vw",
    },
  ];

  useEffect(() => {
    dispatch(getSurveyInsightsList(year));
  }, [dispatch, year]);

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const getStatus = (status) => {
    if (status === "pending") {
      return <Typography className={classes.pending}>Pending</Typography>;
    } else if (status === "completed") {
      return <Typography className={classes.completed}>Completed</Typography>;
    }
  };

  const onSelectData = (id) => {
    if (isHistorypage) {
      navigate(`/history/${id}`);
    } else {
      navigate(`/track-progress/${id}`);
    }
  };
  const onSelectInsightsData = (id) => {
    navigate(`/insights-details/${id}`);
  };

  const getData = () => {
    if (!Array.isArray(surveyList)) {
      return [];
    }

    return surveyList?.map((item) => ({
      ...item,
      created_ts: item.created_ts
        ? dayjs(item.created_ts).format("DD/MM/YYYY")
        : "NA",
      status: getStatus(item.status),
      materiality_score: `${item.materiality_score}%`,
      materiality_level: (
        <Typography className={classes[item.materiality_level.split(" ")[0]]}>
          {item.materiality_level}
        </Typography>
      ),
      action: (
        <Link
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onSelectData(item.id);
          }}
          style={{ cursor: "pointer" }}
        >
          View Details
        </Link>
      ),
      insights: (
        <Link
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onSelectInsightsData(item.id);
          }}
          style={{ cursor: "pointer" }}
        >
          View insights
        </Link>
      ),
    }));
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Box className={classes.yearFilter}>
          <CeroAutoComplete
            id="year"
            label="Assessment Year"
            onChange={(e, item) => setYear(item ? item.id : null)}
            options={yearList}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Box>
        <CeroTable
          columns={progressTrackColumns}
          data={getData}
          hasMore={false}
          loading={false}
          useExtraStyles={true}
          classes={{ tableContainer: classes.tableContainer }}
        />
      </Container>
    </DashboardLayout>
  );
};

export default MaterialityInsights;
