import DashboardIcons from "../../assets/icons/DashboardIcon1.png";
import TOPICSICON from "../../assets/icons/TopicsIcon .png";
import esgIcon from "../../assets/icons/esgIcon.png";
import GoalIcon from "../../assets/icons/GoalsIcon.png";
import ReportIcon from "../../assets/icons/ReportsIcon.png";
import FactoryIcon from "../../assets/icons/FacilitiesIcon.png";
import ApprovalstatusIcon from "../../assets/icons/ApprovalIcon.png";
import AuditIcon from "../../assets/icons/AuditIcon .png";
import TwoTickets from "../../assets/icons/TicketIcon.png";
import AssesmentIcon from "../../assets/icons/AssessmentIcon.png";
import UsersIcon from "../../assets/icons/UsersIcon.png";
import CompaniesIcon from "../../assets/icons/CompaniesIcon.png";
import stakeHolders from "../../assets/stakeholders.png";
import Glossary from "../../assets/icons/GlossaryIcon.png";
import CarbonIcon from "../../assets/icons/carbonIcon.png";
import ApprovalFlowIcon from "../../assets/icons/ApprovalFlowIcon.png";
import offsetIcon from "../../assets/icons/offsetIcon.png";
import Blockchain from "../../assets/icons/Blockchain_icon.jpeg";
import tradeDashboard from "../../assets/icons/tradeDashboard.png";
import supplierScreeningIcon from "../../assets/icons/supplierScreeningIcon.png";
import Assessment from "../../assets/icons/Assessment.png";

export const rolesEnum = {
  ADMIN: "admin",
  AUDITOR: "auditor",
  SUSTAINABILITY_MANAGER: "sustainability_manager",
  APPROVER: "approver",
  FACILITY_MANAGER: "facility_manager",
  BUSINESS_USER: "business_user",
  SUPER_ADMIN: "super_admin",
  STAKEHOLDER: "read_only_user",
  PROXY_SUSTAINABILITY_MANAGER: "proxy_sustainability_manager",
  TRADE_ADMIN: "trade_admin",
};

const isTradePlatform = window.location.pathname.includes("trade/dashboard-internal");

export const sideMenuItems = [
  {
    text: "Dashboard",
    icon: <img src={DashboardIcons} alt="Logo" width="25" height="25" />,
    path: isTradePlatform ? "/trade/dashboard-internal" :"/dashboard",
    roles: [
      rolesEnum.ADMIN,
      rolesEnum.APPROVER,
      rolesEnum.AUDITOR,
      rolesEnum.FACILITY_MANAGER,
      rolesEnum.BUSINESS_USER,
      rolesEnum.SUSTAINABILITY_MANAGER,
      rolesEnum.SUPER_ADMIN,
      rolesEnum.STAKEHOLDER,
      rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
    ],
  },
  {
    text: "ESG",
    icon: <img src={esgIcon} alt="Logo" width="25" />,
    roles: [
      rolesEnum.APPROVER,
      rolesEnum.FACILITY_MANAGER,
      rolesEnum.SUSTAINABILITY_MANAGER,
      rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
    ],
    subItems: [
      {
        text: "Topics",
        // icon: <img src={TOPICSICON} alt="Logo" width="25" />,
        path: "/emissions",
        roles: [
          rolesEnum.APPROVER,
          rolesEnum.FACILITY_MANAGER,
          rolesEnum.SUSTAINABILITY_MANAGER,
          rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
        ],
      },
      {
        text: "ESG Approvals",
        // icon: <img src={ApprovalstatusIcon} alt="Logo" width="25" height="25" />,
        roles: [
          rolesEnum.SUSTAINABILITY_MANAGER,
          rolesEnum.APPROVER,
          rolesEnum.FACILITY_MANAGER,
          rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
        ],
        subItems: [
          {
            text: "Approval Status",
            path: "/approval-status",
            roles: [
              rolesEnum.SUSTAINABILITY_MANAGER,
              rolesEnum.APPROVER,
              rolesEnum.FACILITY_MANAGER,
              rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
            ],
          },
          {
            text: "Pending Approvals",
            path: "/pending-approvals",
            roles: [
              rolesEnum.SUSTAINABILITY_MANAGER,
              rolesEnum.APPROVER,
              rolesEnum.FACILITY_MANAGER,
              rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
            ],
          },
        ],
      },
    ],
  },
  {
    text: "ESG",
    icon: <img src={esgIcon} alt="Logo" width="25" />,
    roles: [rolesEnum.BUSINESS_USER],
    subItems: [
      {
        text: "Topics",
        // icon: <img src={TOPICSICON} alt="Logo" width="25" />,
        path: "/emissions",
        roles: [rolesEnum.BUSINESS_USER],
      },
      {
        text: "ESG Approvals",
        icon: (
          <img src={ApprovalstatusIcon} alt="Logo" width="25" height="25" />
        ),
        roles: [rolesEnum.BUSINESS_USER],
        subItems: [
          {
            text: "Approval Status",
            path: "/approval-status",
            roles: [rolesEnum.BUSINESS_USER],
          },
          {
            text: "Request Approvals",
            path: "/request-approvals",
            roles: [rolesEnum.BUSINESS_USER],
          },
        ],
      },
    ],
  },

  {
    text: "Simulations",
    icon: <img src={TOPICSICON} alt="Logo" width="20" height="20" />,
    path: "/simulations",
    roles: [],
  },
  {
    text: "Goals",
    icon: <img src={GoalIcon} alt="Logo" width="25" height="25" />,
    roles: [
      rolesEnum.SUSTAINABILITY_MANAGER,
      rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
      rolesEnum.FACILITY_MANAGER,
    ],
    subItems: [
      {
        text: "Vision & Mission",
        path: "/vision-mission",
        roles: [
          rolesEnum.SUSTAINABILITY_MANAGER,
          rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
        ],
      },
      {
        text: "Initiatives",
        path: "/initiatives",
        roles: [
          rolesEnum.SUSTAINABILITY_MANAGER,
          rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
          rolesEnum.FACILITY_MANAGER,
        ],
      },
      {
        text: "Initiative Tracking",
        path: "/initiative-tracking",
        roles: [
          rolesEnum.SUSTAINABILITY_MANAGER,
          rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
          rolesEnum.FACILITY_MANAGER,
        ],
      },
      {
        text: "Target vs Actuals",
        path: "/target-vs-actuals",
        roles: [
          rolesEnum.SUSTAINABILITY_MANAGER,
          rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
        ],
      },
    ],
  },

  {
    text: "Carbon Offset",
    icon: <img src={offsetIcon} alt="Logo" width="25" height="25" />,
    // path: "/carbon-offset",
    roles: [
      // rolesEnum.BUSINESS_USER,
      rolesEnum.SUSTAINABILITY_MANAGER,
      rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
    ],
    subItems: [
      {
        text: "Offset Programs",
        // icon: <img src={offsetIcon} alt="Logo" width="25" height="25" />,
        path: "/carbon-offset",
        roles: [
          // rolesEnum.BUSINESS_USER,
          rolesEnum.SUSTAINABILITY_MANAGER,
          rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
        ],
      },
      {
        text: "Offset Approvals",
        // icon: <img src={ApprovalstatusIcon} alt="Logo" width="25" height="25" />,
        roles: [rolesEnum.SUSTAINABILITY_MANAGER],
        subItems: [
          {
            text: "Approval Status",
            path: "/offset-approval-management",
            roles: [rolesEnum.SUSTAINABILITY_MANAGER],
          },
          {
            text: "Pending Approvals",
            path: "/offset-approval-pending",
            roles: [rolesEnum.SUSTAINABILITY_MANAGER],
          },
          // {
          //   text: "Request Approvals",
          //   path: "/request-approvals",
          //   roles: [rolesEnum.BUSINESS_USER],
          // },
        ],
      },
    ],
  },
  {
    text: "Carbon Offset",
    icon: <img src={offsetIcon} alt="Logo" width="25" height="25" />,
    // path: "/carbon-offset",
    roles: [
      rolesEnum.BUSINESS_USER,
      // rolesEnum.SUSTAINABILITY_MANAGER,
      // rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
    ],
    subItems: [
      {
        text: "Offset Programs",
        // icon: <img src={offsetIcon} alt="Logo" width="25" height="25" />,
        path: "/carbon-offset",
        roles: [
          rolesEnum.BUSINESS_USER,
          // rolesEnum.SUSTAINABILITY_MANAGER,
          // rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
        ],
      },
      {
        text: "Offset Approvals",
        // icon: <img src={ApprovalstatusIcon} alt="Logo" width="25" height="25" />,
        roles: [rolesEnum.BUSINESS_USER],
        subItems: [
          {
            text: "Approval Status",
            path: "/offset-approval-management",
            roles: [rolesEnum.BUSINESS_USER],
          },
          // {
          //   text: "Pending Approvals",
          //   path: "/offset-approval-pending",
          //   roles: [rolesEnum.BUSINESS_USER],
          // },
          {
            text: "Request Approvals",
            path: "/offset-approval-pending",
            roles: [rolesEnum.BUSINESS_USER],
          },
        ],
      },
    ],
  },
  {
    text: "Carbon Credit Pool",
    icon: <img src={CarbonIcon} alt="Logo" width="25" height="25" />,
    path: "/carbon-credit-pool",
    roles: [
      rolesEnum.SUSTAINABILITY_MANAGER,
      rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
    ],
  },
  {
    text: "Assessments",
    icon: <img src={Assessment} alt="Logo" width="25" height="25" />,
    roles: [
      rolesEnum.SUSTAINABILITY_MANAGER,
      rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
      rolesEnum.FACILITY_MANAGER,
    ],
    subItems: [
      {
        text: "Materiality",
        // icon: <img src={stakeHolders} alt="Logo" width="25" height="25" />,
        roles: [
          rolesEnum.SUSTAINABILITY_MANAGER,
          rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
        ],
        subItems: [
          {
            text: "Survey",
            path: "/survey",
            roles: [
              rolesEnum.SUSTAINABILITY_MANAGER,
              rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
            ],
            parent: "materiality",
          },
          {
            text: "Track Progress",
            path: "/track-progress",
            roles: [
              rolesEnum.SUSTAINABILITY_MANAGER,
              rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
            ],
            parent: "materiality",
          },
          {
            text: "History",
            path: "/history",
            roles: [
              rolesEnum.SUSTAINABILITY_MANAGER,
              rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
            ],
            parent: "materiality",
          },
          {
            text: "Insights",
            path: "/materiality-insights",
            roles: [
              rolesEnum.SUSTAINABILITY_MANAGER,
              rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
            ],
            parent: "materiality",
          },
        ],
      },
      {
        text: "Supplier(Vendor)",
        // icon: <img src={supplierScreeningIcon} alt="Logo" width="25" height="25" />,
        roles: [
          rolesEnum.SUSTAINABILITY_MANAGER,
          rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
        ],
        subItems: [
          {
            text: "Survey",
            path: "/supplier-screening/survey",
            roles: [
              rolesEnum.SUSTAINABILITY_MANAGER,
              rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
            ],
            parent: "supplier",
          },
          {
            text: "Track Progress",
            path: "/supplier-screening/track-progress",
            roles: [
              rolesEnum.SUSTAINABILITY_MANAGER,
              rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
            ],
            parent: "supplier",
          },
          {
            text: "History",
            path: "/supplier-screening/history",
            roles: [
              rolesEnum.SUSTAINABILITY_MANAGER,
              rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
            ],
            parent: "supplier",
          },
          {
            text: "Survey Results",
            path: "/survey-results",
            roles: [
              rolesEnum.SUSTAINABILITY_MANAGER,
              rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
            ],
            parent: "supplier",
          },
        ],
      },
      {
        text: "Sustainability Risk",
        icon: (
          <img src={supplierScreeningIcon} alt="Logo" width="25" height="25" />
        ),
        roles: [
          rolesEnum.SUSTAINABILITY_MANAGER,
          rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
        ],
        subItems: [
          {
            text: "Survey",
            path: "/sustainability-risk/survey",
            roles: [
              rolesEnum.SUSTAINABILITY_MANAGER,
              rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
            ],
            parent: "sustainability",
          },
          {
            text: "Track Progress",
            path: "/sustainability-risk/track-progress",
            roles: [
              rolesEnum.SUSTAINABILITY_MANAGER,
              rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
            ],
            parent: "sustainability",
          },
          {
            text: "History",
            path: "/sustainability-risk/history",
            roles: [
              rolesEnum.SUSTAINABILITY_MANAGER,
              rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
            ],
            parent: "sustainability",
          },
          {
            text: "Survey Results",
            path: "/sustainability-risk-survey-results",
            roles: [
              rolesEnum.SUSTAINABILITY_MANAGER,
              rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
            ],
            parent: "sustainability",
          },
        ],
      },
    ],
  },

  {
    text: "Report",
    icon: <img src={ReportIcon} alt="Logo" width="25" height="25" />,
    path: "/reports",
    roles: [
      rolesEnum.SUSTAINABILITY_MANAGER,
      rolesEnum.APPROVER,
      rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
      rolesEnum.BUSINESS_USER,
      rolesEnum.FACILITY_MANAGER,
    ],
  },
  {
    text: "Benchmarking",
    icon: <img src={TOPICSICON} alt="Logo" />,
    path: "/benchmarking",
    roles: [],
  },
  {
    text: "Facilities",
    icon: <img src={FactoryIcon} alt="Logo" width="25" height="25" />,
    path: "/facilities",
    roles: [rolesEnum.ADMIN],
  },
  {
    text: "User Management",
    icon: <img src={TOPICSICON} alt="Logo" width="25" height="25" />,
    path: "/users",
    roles: [rolesEnum.ADMIN],
  },
  {
    text: "Approval Flow",
    icon: <img src={ApprovalFlowIcon} alt="Logo" width="25" height="25" />,
    path: "/approval-flow",
    roles: [rolesEnum.ADMIN],
  },
  {
    text: "Companies",
    icon: <img src={CompaniesIcon} alt="Logo" width="25" height="25" />,
    path: "/companies",
    roles: [rolesEnum.AUDITOR, rolesEnum.SUPER_ADMIN],
  },
  {
    text: "Audit",
    icon: <img src={AuditIcon} alt="Logo" width="25" height="25" />,
    roles: [
      rolesEnum.SUSTAINABILITY_MANAGER,
      rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
    ],
    subItems: [
      {
        text: "Audit Management",
        path: "/audit-type-management",
        roles: [
          rolesEnum.SUSTAINABILITY_MANAGER,
          rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
        ],
      },
      {
        text: "Audit Status",
        path: "/audit-status",
        roles: [
          rolesEnum.SUSTAINABILITY_MANAGER,
          rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
        ],
      },
    ],
  },
  {
    text: "Tickets",
    icon: <img src={TwoTickets} alt="Logo" width="25" height="25" />,
    // path: "/tickets",
    roles: [
      rolesEnum.ADMIN,
      rolesEnum.SUSTAINABILITY_MANAGER,
      rolesEnum.AUDITOR,
      rolesEnum.APPROVER,
      rolesEnum.FACILITY_MANAGER,
      rolesEnum.BUSINESS_USER,
      rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
    ],
    subItems: [
      {
        text: "Internal Tickets",
        path: "/tickets",
        roles: [
          rolesEnum.ADMIN,
          rolesEnum.SUSTAINABILITY_MANAGER,
          rolesEnum.AUDITOR,
          rolesEnum.APPROVER,
          rolesEnum.FACILITY_MANAGER,
          rolesEnum.BUSINESS_USER,
          rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
        ],
      },
      {
        text: "Support Tickets",
        path: "/support-tickets",
        roles: [
          rolesEnum.ADMIN,
          rolesEnum.SUSTAINABILITY_MANAGER,
          rolesEnum.AUDITOR,
          rolesEnum.APPROVER,
          rolesEnum.FACILITY_MANAGER,
          rolesEnum.BUSINESS_USER,
          rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
        ],
      },
    ],
  },
  {
    text: "Tickets",
    icon: <img src={TwoTickets} alt="Logo" width="25" height="25" />,
    path: "/super-admin-tickets",
    roles: [rolesEnum.SUPER_ADMIN],
  },
  {
    text: "Assessment Cycles",
    icon: <img src={AssesmentIcon} alt="Logo" width="25" height="25" />,
    path: "/assesment-cycles",
    roles: [
      rolesEnum.ADMIN,
      rolesEnum.SUSTAINABILITY_MANAGER,
      rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
    ],
  },
  {
    text: "Users",
    icon: <img src={UsersIcon} alt="Logo" width="25" height="25" />,
    path: "/superadmin-users/statistics",
    roles: [rolesEnum.SUPER_ADMIN],
  },
  {
    text: "Read Only users",
    icon: <img src={stakeHolders} alt="Logo" width="25" height="25" />,
    path: "/read-only-users",
    roles: [
      rolesEnum.SUSTAINABILITY_MANAGER,
      rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
    ],
  },
  {
    text: "Reports",
    icon: <img src={ReportIcon} alt="Logo" width="25" height="25" />,
    path: "/reports-stakeholder",
    roles: [rolesEnum.STAKEHOLDER],
  },
  {
    text: "Notification",
    icon: <img src={TwoTickets} alt="Logo" width="25" />,
    path: "/admin/notifications",
    roles: [rolesEnum.ADMIN],
  },
  {
    text: "Glossary",
    icon: <img src={Glossary} alt="Logo" width="25" height="25" />,
    path: "/glossary",
    roles: [
      rolesEnum.ADMIN,
      rolesEnum.SUSTAINABILITY_MANAGER,
      rolesEnum.AUDITOR,
      rolesEnum.APPROVER,
      rolesEnum.FACILITY_MANAGER,
      rolesEnum.BUSINESS_USER,
      rolesEnum.PROXY_SUSTAINABILITY_MANAGER,
    ],
  },
  {
    text: "Dashboard",
    icon: <img src={tradeDashboard} alt="Logo" width="25" height="25" />,
    path: "/trade/dashboard",
    roles: [rolesEnum.TRADE_ADMIN],
  },
  {
    text: "Notifications",
    icon: <img src={TwoTickets} alt="Logo" width="25" />,
    path: "/notifications",
    roles: [rolesEnum.SUPER_ADMIN],
  },
  {
    text: "Blockchain",
    icon: <img src={Blockchain} alt="Logo" width="25" height="25" />,
    path: "/blockchain",
    roles: [rolesEnum.SUPER_ADMIN],
  },
];
