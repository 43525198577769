import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { notificationValidation } from "./schema";
import { STATUS } from "../../../redux/constants";
import {
  getCountryList,
  getUserCompanyDetails,
  addAdminAnnouncement,
  resetAddAdminAnnouncement,
} from "../../../redux/actions";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import CeroSelect from "../../../components/CeroSelect";
import CeroDateTime from "../../../components/CeroDateTime";
import DashboardLayout from "../../../layouts/DashboardLayout";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import CeroButton from "../../../components/CeroButton";
import CeroSearchWithMultiselect from "../../../components/CeroSearchWithMultiselect";

const AddAdminNotification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);

  const countryListData = useSelector(
    (state) => state.listings.countryList.data
  );
  const companyDetails = useSelector(
    (state) => state.account.companyDetails.data
  );

  const addAnnouncementStatus = useSelector(
    (state) => state.company.addAdminAnnouncement
  );
  const companyList = companyDetails
    ? [
        {
          value: companyDetails.name,
          key: companyDetails.id,
          title: companyDetails.name,
        },
      ]
    : [];

  const countryList = countryListData?.map((item) => ({
    key: item.code,
    value: item.name,
    title: item.name,
  }));

  useEffect(() => {
    dispatch(getCountryList());
    dispatch(getUserCompanyDetails("business"));
  }, [dispatch]);

  const notificationForm = useFormik({
    initialValues: {
      notificationType: "",
      exhibitType: "",
      message: "",
      startTime: null,
      endTime: null,
      messageStatus: "",
      company: [],
      country: [],
    },
    validationSchema: notificationValidation,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (addAnnouncementStatus?.status === STATUS.SUCCESS) {
      enqueueSnackbar("Announcement added successfully", {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(resetAddAdminAnnouncement());
      navigate("/admin/notifications");
    } else if (addAnnouncementStatus?.status === STATUS.ERROR) {
      enqueueSnackbar(addAnnouncementStatus?.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      dispatch(resetAddAdminAnnouncement());
    }
  }, [addAnnouncementStatus, navigate, enqueueSnackbar, dispatch]);

  const onSubmitData = () => {
    const requestData = {
      message: notificationForm.values.message,
      country: selectedCountry.map((item) => item.key),
      company_id: notificationForm.values.company,
      start_ts: notificationForm.values.startTime,
      end_ts: notificationForm.values.endTime,
      status: notificationForm.values.messageStatus,
      type_of_notification: notificationForm.values.notificationType,
      type_of_exhibit: notificationForm.values.exhibitType,
    };
    dispatch(addAdminAnnouncement(requestData));
  };

  const notificationTypes = [
    { key: "news", value: "News" },
    { key: "outage", value: "Outage" },
    { key: "release_update", value: "Release Update" },
    { key: "emergency", value: "Emergency" },
  ];

  const exhibitTypes = [
    { key: "prompt", value: "Prompt" },
    { key: "scroll", value: "Scroll" },
    { key: "status_bar", value: "Status Bar" },
  ];

  const messageStatuses = [
    { key: "enable", value: "Enable" },
    { key: "disable", value: "Disable" },
  ];

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };

  // const filteredExhibitTypes =
  //   notificationForm.values.notificationType === "news"
  //     ? exhibitTypes.filter((type) => type.key === "scroll")
  //     : exhibitTypes;

  const filteredExhibitTypes = (() => {
    const { notificationType } = notificationForm.values;

    switch (notificationType) {
      case "news":
        return exhibitTypes.filter((type) => type.key === "scroll");
      case "release_update":
        return exhibitTypes.filter((type) => type.key === "scroll");
      case "outage":
      case "emergency":
        return exhibitTypes.filter((type) => type.key === "prompt");
      default:
        return exhibitTypes;
    }
  })();

  const handleClear = () => {
    setSelectedCountry([]);
    setSelectedCompany([]);
    notificationForm.resetForm();
  };

  return (
    <DashboardLayout>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Notification
          </Typography>
          <Box>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="notificationType"
                  name="notificationType"
                  label="Notification Type"
                  fullWidth
                  options={notificationTypes}
                  selectedValue={notificationForm.values.notificationType}
                  onChange={notificationForm.handleChange}
                  onBlur={notificationForm.handleBlur}
                  error={
                    notificationForm.touched.notificationType &&
                    notificationForm.errors.notificationType
                  }
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="exhibitType"
                  name="exhibitType"
                  label="Exhibit Type"
                  fullWidth
                  options={filteredExhibitTypes}
                  selectedValue={notificationForm.values.exhibitType}
                  onChange={notificationForm.handleChange}
                  onBlur={notificationForm.handleBlur}
                  error={
                    notificationForm.touched.exhibitType &&
                    notificationForm.errors.exhibitType
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item container direction="column" md={12} xs={12}>
                <CeroInput
                  required
                  id="message"
                  name="message"
                  label="Message"
                  fullWidth
                  multiline
                  rows={4}
                  value={notificationForm.values.message}
                  onChange={notificationForm.handleChange}
                  onBlur={notificationForm.handleBlur}
                  error={
                    notificationForm.touched.message &&
                    notificationForm.errors.message
                  }
                  classes={{ container: classes.textAreaContainer }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSearchWithMultiselect
                  id="country"
                  label="Country"
                  formControlClass={classes.multiSelect}
                  options={countryList}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  classes={{
                    label: classes.label,
                    textField: classes.textField,
                    container: classes.adjustedContainer,
                  }}
                />
                <CeroDateTime
                  useDayjs={true}
                  id="startTime"
                  name="startTime"
                  label="Start Date & Time (UTC)"
                  fullWidth
                  value={notificationForm.values.startTime || null}
                  onChange={(newValue) =>
                    notificationForm.setFieldValue("startTime", newValue)
                  }
                  error={
                    notificationForm.errors.startTime &&
                    notificationForm.touched.startTime
                  }
                  helperText={
                    notificationForm.touched.startTime &&
                    notificationForm.errors.startTime
                  }
                />
                <CeroSelect
                  required
                  id="messageStatus"
                  name="messageStatus"
                  label="Message Status"
                  fullWidth
                  options={messageStatuses}
                  selectedValue={notificationForm.values.messageStatus}
                  onChange={notificationForm.handleChange}
                  onBlur={notificationForm.handleBlur}
                  error={
                    notificationForm.touched.messageStatus &&
                    notificationForm.errors.messageStatus
                  }
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="company"
                  label="Company"
                  fullWidth
                  options={companyList}
                  selectedValue={notificationForm.values.company}
                  onChange={(e) => {
                    notificationForm.setFieldValue("company", [e.target.value]);
                  }}
                  classes={{
                    label: classes.label,
                    textField: classes.textField,
                    container: classes.adjustedContainer,
                  }}
                />
                <CeroDateTime
                  useDayjs={true}
                  id="endTime"
                  name="endTime"
                  label="End Date & Time (UTC)"
                  fullWidth
                  value={notificationForm.values.endTime || null}
                  onChange={(newValue) =>
                    notificationForm.setFieldValue("endTime", newValue)
                  }
                  error={
                    notificationForm.errors.endTime &&
                    notificationForm.touched.endTime
                  }
                  helperText={
                    notificationForm.touched.endTime &&
                    notificationForm.errors.endTime
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add"
            disabled={!notificationForm.dirty || !notificationForm.isValid}
            className={classes.buttonPrimary}
            onClick={onSubmitData}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={handleClear}
          />
        </Box>
      </Container>
    </DashboardLayout>
  );
};

export default AddAdminNotification;
