import { Padding } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2, 2, 8, 2),
    width: "100%",
    borderRadius: 8,
    marginBottom: theme.spacing(3),
  },
  sectionTitle: {
    fontSize: 30,
    fontWeight: 450,
    color: theme.palette.Primary.background,
    padding: theme.spacing(4, 0),
  },

  subItemTitle: {
    fontSize: 20,
    fontWeight: 450,
    padding: theme.spacing(4, 0),
  },

  subItemValue: {
    padding: theme.spacing(2, 4, 3, 0),
    fontSize: 20,
    // whiteSpace: "pre-line",
    margin: 0,
  },
  consentText: {
    padding: theme.spacing(2, 0, 4, 0),
    margin: 0,
    fontWeight: 500,
    fontSize: 20,
  },
  letterContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(4),
  },
  available: {
    color: "#0088C7",
  },
  checkbox: {
    color: "#8C2148 !important",
    borderWidth: 0.1,
  },
  dialog: {
    width: "40% !important",
    textAlign: "center",
    height: "auto",
    "& .MuiPaper-root.MuiDialog-paper": {
      maxWidth: "none",
      backgroundColor: "white",
    },
  },
  popUp: {
    margin: "auto",
  },
  selectCheckbox: {
    padding: 0,
    color: "#8C2148",
    borderRadius: "20%",
    margin: theme.spacing(2, 2, 2, 0),
    "& .MuiFormControlLabel-root.MuiFormControlLabel-label.Mui-disabled": {
      color: "black",
    },
  },
  label: {
    "& .MuiFormControlLabel-label.Mui-disabled": {
      color: "black !important",
    },
  },
  textAreaContainer: {
    margin: theme.spacing(8, 0),
    height: "auto",
    "& .css-1ps9hi4-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
      WebkitTextFillColor: "black",
    },
  },
  popUpRedirect: {
    margin: "auto",
    minHeight: 200,
    maxWidth: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainerReview: {
    display: "flex",
    justifyContent: "center",
    gap: 5,
    paddingTop: 20,
    paddingBottom: 15,
    width: "50%",
    margin: "auto",
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    height: 35,
    borderColor: theme.palette.Primary.background,
    marginRight: 15,
    fontWeight: 400,
    color: theme.palette.background.white,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      borderColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  headerCell: {
    border: "0.5px solid #5A404E",
  },
  tableBodyCell: {
    border: "0.5px solid #5A404E",
    fontWeight: 550,
  },
  tableTitle: {
    textAlign: "center",
  },
  tableContainer: {
    marginTop: theme.spacing(5),
    border: "1px solid black",
    borderRadius: 8,
    paddingBottom: "0px !important",
    minHeight: "none",
    maxHeight: "none",
    "& .MuiTable-root": {
      paddingBottom: "0px !important",
    },
  },
  gridItem: {
    margin: theme.spacing(5, 0),
  },
  inputGrid: {
    margin: theme.spacing(5, 0),
    paddingBottom: theme.spacing(8),
  },
  subScoreDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "10px",
  },
  scoreDiv: {
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "10px",
  },
  scoreHeadDiv: {
    textAlign: "left",
    width: "235px",
  },
  sliderQuestion: {
    margin: theme.spacing(2, 0),
    width: "70%",
    "& .MuiTypography-root": {
      paddingLeft: "10px",
    },
  },
  sliderDiv: {
    width: "20%",
  },
  default: {
    width: "100%",
  },
  sliderQuestionMain: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  slider: {
    color: "#E0E0E0", // Change the slider color
    "& .MuiSlider-thumb": {
      backgroundColor: "#8C2148", // Thumb color
    },
    "& .MuiSlider-track": {
      backgroundColor: "#E0E0E0", // Track color
    },
    "& .MuiSlider-rail": {
      backgroundColor: "#E0E0E0", // Optional: Change the rail color if needed
    },
  },
}));

export default useStyles;
