import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { STATUS } from "../../../redux/constants";
import { schemeValidation } from './schema';
import { addDescriminationIncidentRecord, getAssessmentPeriod, resetAddCombustionStatus } from '../../../redux/actions';
import CeroButton from '../../../components/CeroButton';
import CeroSelect from '../../../components/CeroSelect';
import CeroInput from '../../../components/CeroInput';
import useStyles from "./styles";
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CeroDate from '../../../components/CeroDate';
import CeroAutoComplete from '../../../components/CeroAutoComplete';
import { sampleYear } from '../../../constants';
import CeroChipSelect from '../../../components/CeroChipSelect';
import FileUpload from '../FileUpload';

const AddDescriminationIncidentForm = (props) => {
    const { onCancel } = props
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedFile, setSelectedFile] = useState(null);

    const facilitiesData = useSelector(state => state.listings.listFacilities.data);
    const addEmissionData = useSelector(state => state.emission.addDescriminationIncident)
    const currentYear = new Date().getFullYear().toString()

    const formik = useFormik({
        initialValues: {
            facility: '',
            year: currentYear,
            assessmentPeriod: '',
            date: '',
            incidentStatus: '',
            discriminationType: [],
            details: '',
            correctiveAction: '',    
        },
        validationSchema: schemeValidation,
        onSubmit: () => { },
    });
    useEffect(() => {
        const { facility, year } = formik.values;
        if (facility && year) {
          dispatch(getAssessmentPeriod(year, facility));
        }
      }, [formik.values.facility, formik.values.year, dispatch]);

    const assessmentData = useSelector(
        (state) => state.assessment.assessmentPeriod.data
      );
    
      const yearList = sampleYear().map((item) => ({
        id: item.key,
        label: item.value,
      }));
    
      const assessmentOptions = Array.isArray(assessmentData)
        ? assessmentData.map((item) => ({
            key: item.period,
            value: item.period,
          }))
        : [];

    const [selectedDiscrimination, setSelectedDiscrimination] = useState([]);

    const incidentTypes = [
      { key: "In Review", value: "In Review" },
      { key: "No further action required", value: "No further action required" },
      { key: "Remediation Completed", value: "Remediation Completed" },
      { key: "Remediation in progress", value: "Remediation in progress" }
      ];

    const discriminationTypes = {
        "Colour": "Colour",
        "Gender": "Gender",
        "Natural extraction": "Natural extraction",
        "Political opinion": "Political opinion",
        "Race": "Race",
        "Religion": "Religion",
        "Others": "Others",
      };
    
      const discriminationHandleChange = (event) => {
        const value = event.target.value;
        setSelectedDiscrimination(value);
        formik.setFieldValue("types_of_discrimination", value);
        if (value[value.length - 1] === "all") {
          setSelectedDiscrimination(selectedDiscrimination.length === Object.keys(discriminationTypes).length ? [] : Object.keys(discriminationTypes));
          return;
        }
      };

    const facilitiesList = facilitiesData.map(item => ({ key: item.id, value: item.name }));

    useEffect(() => {
        if (addEmissionData.status === STATUS.SUCCESS) {
            enqueueSnackbar('Discrimination incident record added successfully', { variant: 'success' });
            dispatch(resetAddCombustionStatus())
            onCancel();
        } else if (addEmissionData.status === STATUS.ERROR) {
            enqueueSnackbar(addEmissionData.message.message || "We couldn't process your request. Please try again later.", { variant: 'error' });
            dispatch(resetAddCombustionStatus())
        }
    }, [addEmissionData, dispatch, enqueueSnackbar, onCancel])

    const onAddDescriminationIncidentDetails = () => {
        const requestData = {
            facility_id: formik.values.facility,
            year: formik.values.year,
            assessment_period: formik.values.assessmentPeriod,
            date: dayjs(formik.values.date).format("DD/MM/YYYY"),
            status_of_incident: formik.values.incidentStatus,
            types_of_discrimination: selectedDiscrimination,
            details: formik.values.details,
            corrective_action: formik.values.correctiveAction,
            file:selectedFile,
            save: true,
        }
        sessionStorage.setItem('year', requestData.year)
        dispatch(addDescriminationIncidentRecord(requestData))
    };

    const handleFileUpload = (file) => {
      setSelectedFile(file);
    };
    const handleFileRemove = () => {
      setSelectedFile(null);
    };
    

    return (
        <>
    <div className={classes.backContainer} onClick={()=>{navigate(-1)}}>
      <ArrowBackIosIcon />
      Back
    </div>
        <Container className={classes.container}>
            <Box className={classes.innerContainer}>
                <Typography className={classes.title} variant="h6" component="div" >Add Discrimination Incident Record</Typography>
                <Box>
                <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
                <CeroDate
                  required
                  label="Date"
                  value={formik.values.date}
                  assessmentPeriod={formik.values.assessmentPeriod}
                  year={formik.values.year}
                  onChange={(date) => formik.setFieldValue("date", date)}
                  error={formik.touched.date && formik.errors.date}
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.touched.year && formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
                <CeroSelect
                  required
                  id="incidentStatus"
                  name="incidentStatus"
                  label="Status of Incident"
                  fullWidth
                  options={incidentTypes}
                  selectedValue={formik.values.incidentStatus}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.incidentStatus &&
                    formik.errors.incidentStatus
                  }
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
                <CeroChipSelect
                  required
                  id="discriminationType"
                  name="discriminationType"
                  label={"Type of Discrimination"}
                  formControlClass={classes.MultiSelectformControl}
                  optionsde={discriminationTypes}
                  selected={selectedDiscrimination}
                  handleChange={discriminationHandleChange}
                  classes={{justCheckSelected: classes.justCheckSelected}}
                />
              </Grid>
            </Grid>
            <CeroInput
              required
              id="details"
              name="details"
              label="Details about the incident"
              value={formik.values.details}
              fullWidth
              multiline
              rows="2"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.details && formik.errors.details}
              classes={{ container: classes.textAreaContainer }}
            />
            <CeroInput
              required
              id="correctiveAction"
              name="correctiveAction"
              label="Corrective Action"
              value={formik.values.correctiveAction}
              fullWidth
              multiline
              rows="2"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.correctiveAction && formik.errors.correctiveAction}
              classes={{ container: classes.textAreaContainer }}
            />
                </Box>
                <FileUpload onFileUpload={handleFileUpload} onFileRemove={handleFileRemove} />
            </Box>
            <Box className={classes.buttonContainer}>
                <CeroButton
                    buttonText="Add Data"
                    className={clsx(classes.button, classes.buttonPrimary)}
                    disabled={!formik.isValid || !formik.dirty || addEmissionData.status === STATUS.RUNNING}
                    onClick={onAddDescriminationIncidentDetails} />
                    <CeroButton
                    buttonText="Clear"
                    variant="outlined"
                    className={classes.buttonSecondary}
                    onClick={formik.resetForm} />
            </Box>
        </Container >
        </>
    )
}

export default AddDescriminationIncidentForm;
