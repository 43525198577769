import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import {
  getAssessmentPeriod,
  resetAddCombustionStatus,
  getEmissionInputFormat,
  addPerformanceAndCareerDevelopmentPrograms,
} from "../../../redux/actions";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { addPerformance } from "./schema";
import CeroDate from "../../../components/CeroDate";
import FileUpload from "../FileUpload";

const AddPerformanceAndCareerDevelopmentPrograms = (props) => {
  const { onCancel } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFile, setSelectedFile] = useState(null);

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const addDataStatus = useSelector(
    (state) => state.emission.addPerformanceAndCareerDevelopmentPrograms
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const currentYear = new Date().getFullYear().toString()

  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      assessmentPeriod: "",
      programType: "",
      date: "",
      trainingMethods: "",
      programScope: "",
      transitionAssistance: "",
      employeesParticipated: "",
    },
    validationSchema: addPerformance,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (addDataStatus.status === STATUS.SUCCESS) {
      enqueueSnackbar("Performance and career development programs added successfully", {variant: "success",});
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addDataStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        addDataStatus.message.message || "We couldn't process your request. Please try again later.", {variant: "error",}
      );
    }
  }, [addDataStatus, dispatch, enqueueSnackbar, onCancel]);

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility));
    }
  }, [formik.values.facility, formik.values.year, dispatch]);

  useEffect(() => {
    const { facility, year, assessmentPeriod } = formik.values;
    if (facility && year && assessmentPeriod) {
      dispatch(getEmissionInputFormat("performance_and_career_development_programs", assessmentPeriod, year, facility));
    }
  }, [formik.values.facility, formik.values.year, formik.values.assessmentPeriod, dispatch]);

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const onAddData = () => {
    const requestData = {
      facility_id: formik.values.facility,
      year: formik.values.year,
      assessment_period : formik.values.assessmentPeriod,
      program_type: formik.values.programType,
      program_scope: formik.values.programScope,
      transition_assistance: formik.values.transitionAssistance,
      employees_participated: formik.values.employeesParticipated,
      date :formik.values.date.toISOString().split('T')[0],
      training_method :formik.values.trainingMethods,
      file:selectedFile,
      save: true,
    };
    sessionStorage.setItem('year', requestData.year)
    dispatch(addPerformanceAndCareerDevelopmentPrograms(requestData));
  };

  const ProgramsTypes = [
    { key: "educational support", value: "Educational Support" },
    { key: "external training courses", value: "External Training courses" },
    { key: "internal training courses", value: "Internal Training courses" },
    { key: "sabbatical periods", value: "Sabbatical periods" },
    { key: "others", value: "Others" },
  ];

  const TransitionAssistance = [
    { key: "job placement services", value: "Job placement services" },
    { key: "pre retirement planning", value: "Pre Retirement planning" },
    { key: "retraining", value: "Retraining" },
    { key: "severance pay", value: "Severance pay" },
    { key: "others", value: "Others" },
  ];

  const TrainingMethods = [
    { key: "on the job training", value: "On the job Training" },
    { key: "online courses", value: "Online courses" },
    { key: "workshops", value: "Workshops" },
  ];

  const handleFileUpload = (file) => {
    setSelectedFile(file);
  };
  const handleFileRemove = () => {
    setSelectedFile(null);
  };
  

  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
          Performance and Career Development Programs
          </Typography>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" lg={6} md={6} xs={4}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}

                />
              </Grid>
              <Grid item container direction={"column"} lg={6} md={6} xs={4}>
                <CeroAutoComplete
                  id="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
              </Grid>
              <Grid item container direction="column" lg={6} md={6} xs={4}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
              
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column"  lg={6} md={6} xs={4}>
                <CeroSelect
                    required
                    id="programType"
                    name="programType"
                    label="Type of programs"
                    options={ProgramsTypes}
                    selectedValue={formik.values.programType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
              </Grid>
              <Grid item container direction="column"  lg={6} md={6} xs={4}>
              <CeroDate
                name="date"
                value={formik.values.date}
                label="Training Date"
                assessmentPeriod={formik.values.assessmentPeriod}
                year={formik.values.year}
                onChange={(date) => formik.setFieldValue("date", date)}
                error={formik.touched.date && formik.errors.date}
              />
              </Grid>
              <Grid item container direction="column"  lg={6} md={6} xs={4}>
                <CeroSelect
                    required
                    id="trainingMethods"
                    name="trainingMethods"
                    label="Training Methods"
                    options={TrainingMethods}
                    selectedValue={formik.values.trainingMethods}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column"  lg={12} md={12} xs={12}>
                <CeroInput 
                required
                name="programScope" 
                label="Scope of the program" 
                id="programScope"
                value={formik.values.programScope}
                fullWidth
                multiline
                rows="2"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.programScope && formik.errors.programScope}
                classes={{ container: classes.textAreaContainer }}/>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column"  lg={6} md={6} xs={12}>
                <CeroSelect
                      required
                      id="transitionAssistance"
                      name="transitionAssistance"
                      label="Transition Assistance"
                      options={TransitionAssistance}
                      selectedValue={formik.values.transitionAssistance}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
              </Grid>
              
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column"  lg={6} md={6} xs={12}>
                <CeroInput 
                name="employeesParticipated" 
                label="Total number of employees participated" 
                id="employeesParticipated"
                value={formik.values.employeesParticipated}
                fullWidth
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.employeesParticipated && formik.errors.employeesParticipated}
                classes={{ container: classes.textAreaContainer }}
                />
              </Grid>
            </Grid>
          </Box>
          <FileUpload onFileUpload={handleFileUpload} onFileRemove={handleFileRemove} />
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            className={classes.buttonPrimary}
            onClick={() => onAddData()}
            disabled={!formik.isValid || !formik.dirty}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={formik.resetForm}
          />
        </Box>
      </Container>
    </>
  );
};

export default AddPerformanceAndCareerDevelopmentPrograms;
