import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CeroInput from "../../../../../components/CeroInput";
import DashboardLayout from "../../../../../layouts/DashboardLayout";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EastIcon from '@mui/icons-material/East';

import {
  getSupplierSurveyTrackQuestions,
  getSupplierSurveyTrackAnswers,
} from "../../../../../redux/actions/supplierScreening";
import useStyles from "./styles";
import PdfFileIconWithDownload from "../../../../../assets/images/Download";
import CeroButton from "../../../../../components/CeroButton";
import { userState } from "../../../../../redux/reducers/auth";

const SupplierViewSurvey = () => {
  const { answerId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [showIndustrySpecific , setShowIndustrySpecific] = useState(false)

  const surveyQuestions = useSelector(
    (state) => state.supplierScreening.getSupplierSurveyTrackQuestions.data
  );
  const surveyAnswers = useSelector(
    (state) => state.supplierScreening.getSupplierSurveyTrackAnswers.data.answer
  );

  useEffect(() => {
    dispatch(getSupplierSurveyTrackAnswers(answerId));
    dispatch(getSupplierSurveyTrackQuestions(answerId));
  }, [dispatch, answerId]);

  const getAnswerByQuestionId = (questionId) => {
    const answers = surveyAnswers?.answer || [];
    const answerObj = answers.find((item) => item.question_id === questionId && item.answer);
    
    return answerObj ? answerObj :  {};
  };

  const renderQuestion = (question) => {
    const answer = getAnswerByQuestionId(question.id);
    

    switch (question.question_type) {
      case "choose_from_option":
        return (
          <Box display="flex" flexDirection="column" paddingLeft={6}>
         <span style={{fontWeight:"400",fontSize:"16px"}}>{question.question_id && question.question}</span>
            {(question.options || []).map((option, idx) => (
              <FormControlLabel
                key={idx}
                control={
                  <Checkbox
                    className={classes.selectCheck}
                    sx={{
                      color: "#5A404E !important",
                      "&.Mui-checked": { color: "#8C2148 !important" },
                    }}
                    checked={answer.includes(option)}
                    disabled
                  />
                }
                label={option}
                className={classes.label}
                sx={{ paddingLeft: 6 }}
              />
            ))}
          </Box>
        );

      case "multiselect_check_box":
        return (
          <Box display="flex" flexDirection="column" paddingLeft={6}>
            {(question.options || []).map((option, idx) => (
              <FormControlLabel
                key={idx}
                control={
                  <Checkbox
                    className={classes.selectCheck}
                    sx={{
                      color: "#5A404E !important",
                      "&.Mui-checked": { color: "#8C2148 !important" },
                    }}
                    checked={answer.split(", ").includes(option)}
                    disabled
                  />
                }
                label={option}
                className={classes.label}
                sx={{ marginLeft: 3 }}
              />
            ))}
          </Box>
        );

      case "text":
        return (
          <Box display="flex" flexDirection="column" paddingLeft={6}>
            <Box display="flex" alignItems="center" paddingBottom={1}>
              <Typography
                variant="body1"
                sx={{ minWidth: 250, paddingLeft: 3, marginRight: 1 }}
              >
                {question.question}:
              </Typography>
              <CeroInput
                rows={1}
                placeholder={`Enter ${question.question}`}
                value={answer}
                disabled
                sx={{ width: "60%" }}
              />
            </Box>
          </Box>
        );

      case "upload":
        return (
          <Box display="flex" flexDirection="column" paddingLeft={2}>
            <Typography
              fontSize={16}
              fontWeight={400}
              variant="h3"
              paddingBottom={2}
              paddingTop={1.5}
            >
              {question.question}
            </Typography>
            {answer ? (
              <a href={answer} target="_blank" rel="noopener noreferrer" style={{marginTop:"12px",paddingLeft:"6px"}}>
               <PdfFileIconWithDownload/>
              </a>
            ) : (
              <Typography variant="body2" color="textSecondary">
                No file uploaded.
              </Typography>
            )}
          </Box>
        );
        case "object":
        return (
          <Box display="flex" flexDirection="column" paddingLeft={6}>
            <Box display="flex" alignItems="flex-start" paddingTop={3} paddingBottom={1}>
              <Typography
                variant="body1"
                sx={{ minWidth: 250, paddingLeft: 3, marginRight: 1 }}
              >
                {question.question}:
              </Typography>
              <div>
              {Object.entries(answer).map(([key, value]) =>
              <Typography
                variant="body2"
                sx={{ minWidth: 250, paddingLeft: 3, marginRight: 1 }}
              >
                {`${key} : ${value}`}
              </Typography>)
             }
             </div>
            </Box>
          </Box>
        );

      default:
        return null;
    }
  };

  const renderQuestionUpdated = (question) => {
    const answerObj = getAnswerByQuestionId(question.id);
    const answer = answerObj?.answer

    switch (question.question_type) {
      case "choose_from_option":
        return (
          <Box display="flex" flexDirection="row" alignItems="center" paddingLeft={6}>
         <span style={{fontWeight:"500",fontSize:"16px"}}>{`answer: `}</span>
         <span style={{fontWeight:"200",fontSize:"16px"}}>{typeof answer === 'string' && answer?.includes('please specify') ? answer?.replace(/\s*Other \(please specify\)\s*/i, ' '): answer}</span>
         {answer?.includes('please specify') && answerObj?.answer_value && <span style={{fontWeight:"200",fontSize:"16px"}}>{answerObj?.answer_value}</span>}
          </Box>
        );

      case "multiselect_check_box":
        return (
          <Box display="flex" flexDirection="row" alignItems="center" paddingLeft={6}>
         <span style={{fontWeight:"500",fontSize:"16px"}}>{`answer: `}</span>
         <span style={{fontWeight:"200",fontSize:"16px"}}>{typeof answer === 'string' && answer?.includes('please specify') ? answer?.replace(/\s*Other \(please specify\)\s*/i, ' '): answer}</span>
         {answer?.includes('please specify') && answerObj?.answer_value && <span style={{fontWeight:"200",fontSize:"16px"}}>{answerObj?.answer_value}</span>}
          </Box>
        );

      case "text":
        return (
          <Box display="flex" flexDirection="row" alignItems="center" paddingLeft={6}>
            <Box display="flex" alignItems="center" paddingBottom={1}>
            <span style={{fontWeight:"500",fontSize:"16px"}}>{`answer: `}</span>
            <span style={{fontWeight:"200",fontSize:"16px"}}>{answer}</span>
            </Box>
          </Box>
        );

      case "upload":
        return (
          <Box display="flex" flexDirection="row" alignItems="center" paddingLeft={2}>
            {answer?.length ? answer.map(item=> (
              <a href={item} target="_blank" rel="noopener noreferrer" style={{marginTop:"12px",paddingLeft:"6px"}}>
               <PdfFileIconWithDownload/>
              </a>
            )) : ""}
          </Box>
        );
        case "object":
        return (
          <Box display="flex" flexDirection="row" alignItems="center" paddingLeft={6}>
            <Box display="flex" alignItems="flex-start" paddingTop={3} paddingBottom={1}>
              <Typography
                variant="body1"
                sx={{ minWidth: 250, paddingLeft: 3, marginRight: 1 }}
              >
                {"answer"}:
              </Typography>
              <div>
              {Object.entries(answer).map(([key, value]) =>
              <Typography
                variant="body2"
                sx={{ minWidth: 250, paddingLeft: 3, marginRight: 1 }}
              >
                {`${key} : ${value}`}
              </Typography>)
             }
             </div>
            </Box>
          </Box>
        );

      default:
        return null;
    }
  };


  const groupQuestions = (questionsList) => {
    const grouped = [];
    const questionMap = new Map();

    questionsList?.forEach((question) => {
      const { id, question_id } = question;

      if (question_id && questionMap.has(question_id)) {
        questionMap.get(question_id).push(question);
      } else {
        questionMap.set(id, [question]);
      }
    });

    questionMap.forEach((group) => {
      grouped.push(group);
    });

    return grouped;
  };

  const groupedQuestions = groupQuestions(
    surveyQuestions?.supplier_generic_Q_list || []
  );
  
  const groupedQuestionsIndustry = groupQuestions(
    surveyQuestions?.supplier_industry_Q_list || []
  );

  function organizeBySectionAndSubSection(data) {
    const result = {};
  
    data.forEach(item => {
      const { section, sub_section } = item[0];
      const sub_section_refactor = sub_section === null ? 'no_section' : sub_section 
      // Ensure the section exists in the result object
      if (!result[section]) {
        result[section] = {};
      }
  
      // Ensure the sub_section exists in the section
      if (!result[section][sub_section_refactor]) {
        result[section][sub_section_refactor] = [];
      }
  
      // Add the item to the appropriate sub_section
      item.forEach(element => {
        result[section][sub_section_refactor].push(element);
        
      });
    });
  
    return result;
  }

  function numberToRoman(num) {
    // Define the mapping of Roman numerals
    const romanMap = [
      { value: 1000, numeral: "M" },
      { value: 900, numeral: "CM" },
      { value: 500, numeral: "D" },
      { value: 400, numeral: "CD" },
      { value: 100, numeral: "C" },
      { value: 90, numeral: "XC" },
      { value: 50, numeral: "L" },
      { value: 40, numeral: "XL" },
      { value: 10, numeral: "X" },
      { value: 9, numeral: "IX" },
      { value: 5, numeral: "V" },
      { value: 4, numeral: "IV" },
      { value: 1, numeral: "I" }
    ];
  
    let result = "";
  
    // Iterate through the mapping
    for (const { value, numeral } of romanMap) {
      while (num >= value) {
        result += numeral; // Add the Roman numeral to the result
        num -= value;      // Subtract the value from the number
      }
    }
  
    return result;
  }
  let qtsnNumber = 1
  let industryQtnNumbr = 0
  const sectionedGenricQuestion = organizeBySectionAndSubSection(groupedQuestions) 
  const sectionedIndustryQuestion = organizeBySectionAndSubSection(groupedQuestionsIndustry) 
  
  const companyProfile = sectionedGenricQuestion['Company Profile']?.['Supplier Information']?.reduce((acc, item) => {
    acc[item.question] = item.id;
    return acc;
  }, {});

  delete sectionedGenricQuestion['Company Profile']
  const renderCompanyProfile = () => {
      return (
            <Grid key={1} className={classes.gridItem}>
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  variant="h3"
                  paddingLeft={4}
                  paddingBottom={2}
                  style={{ textDecoration: 'underline' }}
                  // underLine={true}
                >
                  {numberToRoman(1)}. COMPANY PROFILE
                  </Typography>
                    <div>
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      variant="h4"
                      paddingLeft={4}
                      paddingBottom={2}
                    >
                      1. Supplier information
                    </Typography>
                    {companyProfile &&

                      <div className={classes.supInfoMain}>
                        <div className={classes.supInfoSingle}>
                          <div className={classes.supInfoitem}>
                          <span style={{fontWeight:"500",fontSize:"16px"}}>{`a. Company Name: `}</span>
                          <span style={{fontWeight:"300",fontSize:"16px"}}>{getAnswerByQuestionId(companyProfile['Company Name'])?.answer ?? ""}</span>
                          </div>
                          <div className={classes.supInfoitem}>
                          <span style={{fontWeight:"500",fontSize:"16px"}}>{`b. Company Phone Number: `}</span>
                          <span style={{fontWeight:"300",fontSize:"16px"}}>{getAnswerByQuestionId(companyProfile['Company Phone Number'])?.answer ?? ""}</span>
                          </div>
                        </div>
                        <div className={classes.supInfoSingle}>
                          <div className={classes.supInfoitem} style={{display:"flex"}}>
                          <span style={{fontWeight:"500",fontSize:"16px"}}>{`c. Company Address : `}</span>
                          <div style={{display:"flex", flexDirection:"column", paddingLeft:'5px'}}>
                          {Object.entries(getAnswerByQuestionId(companyProfile['Company Address'])?.answer ?? "").map(([key, value]) =>
                                                      <span style={{fontWeight:"500",fontSize:"16px"}}>{value}</span>

                          )}
                          </div>
                          </div>
                          <div>
                          
                          </div>
                        </div>
                        <div className={classes.supInfoSingle}>
                          <div className={classes.supInfoitem}>
                          <span style={{fontWeight:"500",fontSize:"16px"}}>{`d. Contact: `}</span>
                          {Object.entries(getAnswerByQuestionId(companyProfile['Contact'])?.answer ?? "").map(([key, value]) =>
                                                      <span style={{fontWeight:"500",fontSize:"16px"}}>{value}</span>

                          )}
                          </div>
                          <div>
                          
                          </div>
                        </div>
                        <div className={classes.supInfoSingle}>
                          <div className={classes.supInfoitem}>
                          <span style={{fontWeight:"500",fontSize:"16px"}}>{`e. Designation: `}</span>
                          <span style={{fontWeight:"300",fontSize:"16px"}}>{getAnswerByQuestionId(companyProfile['Designation'])?.answer ?? ""}</span>
                          </div>
                          <div className={classes.supInfoitem}>
                          <span style={{fontWeight:"500",fontSize:"16px"}}>{`f. Email ID: `}</span>
                          <span style={{fontWeight:"300",fontSize:"16px"}}>{getAnswerByQuestionId(companyProfile['Email ID'])?.answer ?? ""}</span>
                          </div>
                        </div>
                        <div className={classes.supInfoSingle}>
                          <div className={classes.supInfoitem}>
                          <span style={{fontWeight:"500",fontSize:"16px"}}>{`g. Contact Number: `}</span>
                          <span style={{fontWeight:"200",fontSize:"16px"}}>{getAnswerByQuestionId(companyProfile['Contact Number'])?.answer ?? ""}</span>
                          </div>
                          <div>
                          
                          </div>
                        </div>
                      </div>}
                    </div>
                    </Grid>)
  }
  return (
    <DashboardLayout>
      <Container className={classes.mainContainer}>
        <div
          className={classes.backContainer}
          onClick={() => showIndustrySpecific ? setShowIndustrySpecific(false) : navigate(-1)}
          style={{ cursor: "pointer", display: "flex", justifyContent: "flex-start" }}
        >
          <ArrowBackIosIcon />
          <Typography variant="body1">Back</Typography>
        </div>

        <Paper className={classes.container}>
          {!showIndustrySpecific && <>
          <div className={classes.paperTop}>
            <Typography variant="h6" className={classes.title}>
              Supplier Assessment General Questionnaire
            </Typography>
            <Typography variant="body2" className={classes.completed}>
              <span style={{ color: "green" }}>Score</span>:{" "}
              {surveyAnswers?.total_score}
            </Typography>
          </div>

          <div className={classes.questionList}>
            {renderCompanyProfile()}
            {Object.keys(sectionedGenricQuestion).map((group, index) => (
                <Grid key={index} className={classes.gridItem}>
                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    variant="h3"
                    paddingLeft={4}
                    paddingBottom={2}
                    style={{ textDecoration: 'underline' }}
                    // underLine={true}
                  >
                    {numberToRoman(index + 2)}. {group}
                    </Typography>
                    {Object.keys(sectionedGenricQuestion[group]).map((subGroup, subIndex) => (
                      <div>
                      {subGroup!=='no_section' && <Typography
                        fontSize={16}
                        fontWeight={500}
                        variant="h4"
                        paddingLeft={4}
                        paddingBottom={2}
                        paddingTop={2}
                      >
                        {String.fromCharCode(96 + subIndex + 1)}. {subGroup}
                      </Typography>}
                        {sectionedGenricQuestion[group][subGroup].map((question, qu_index) => {
                          if (question.question_type !== 'upload')  qtsnNumber +=1 
                          return (
                          <>
                              <div className={classes.questScore}>
                                <Typography
                                  fontSize={17}
                                  fontWeight={500}
                                  // variant="h4"
                                  paddingLeft={4}
                                  paddingBottom={2}
                                  className={classes.questionQr}
                                >
                                  {question.question_type !== 'upload' && `${qtsnNumber}. ${question.question}`}
                                </Typography>
                                {question.question_type !== 'upload' &&
                                <Typography variant="body2" color={"green"} className={classes.score}>
                                  <span style={{ color: "green" }}>Score</span>:{" "}
                                  {getAnswerByQuestionId(question.id)?.score ?? 0}
                                </Typography>}
                                </div>
                          <Box
                            key={question.id}
                            paddingLeft={4}
                          >
                            {renderQuestionUpdated(question)}
                          </Box>
                          </>
                        )})}
                          </div>
                        ))}
                </Grid>
              ))}

            {/* {sectionedGenricQuestion.map((group, index) => (
              <Grid key={index} className={classes.gridItem}>
                <Typography
                  fontSize={18}
                  fontWeight={500}
                  variant="h3"
                  paddingLeft={4}
                  paddingBottom={2}
                >
                  {index + 1}. {group[0].question}
                </Typography>
                {group.map((question) => (
                  <Box
                    key={question.question_id || question.id}
                    paddingLeft={4}
                  >
                    {renderQuestion(question)}
                  </Box>
                ))}
              </Grid>
            ))} */}
          </div>
          <div className={classes.footer}>
            <CeroButton
              buttonText="Industry Specific Questionaire"
              variant="outlined"
              className={classes.buttonPrimary}
              endIcon={<EastIcon />}
              onClick={() => setShowIndustrySpecific(true)}
            />
          </div></>}
          {showIndustrySpecific && <>
          <div className={classes.paperTop}>
            <Typography variant="h6" className={classes.title}>
              Supplier Assessment Industry Questionnaire
            </Typography>
          </div>
          <div className={classes.paperTop}>
            <Typography variant="h6" className={classes.title}>
              {surveyQuestions?.industry_type_name}
            </Typography>
          </div>

          <div className={classes.questionList}>
          {Object.keys(sectionedIndustryQuestion).map((group, index) => (
                <Grid key={index} className={classes.gridItem}>
                  <Typography
                    fontSize={18}
                    fontWeight={500}
                    variant="h3"
                    paddingLeft={4}
                    paddingBottom={2}
                    style={{ textDecoration: 'underline' }}
                    // underLine={true}
                  >
                    {numberToRoman(index + 1)}. {group === 'null' ? 'Custom added' : group}
                    </Typography>
                    {Object.keys(sectionedIndustryQuestion[group]).map((subGroup, subIndex) => (
                      <div>
                      {subGroup!=='no_section' && <Typography
                        fontSize={18}
                        fontWeight={500}
                        variant="h4"
                        paddingLeft={4}
                        paddingBottom={2}
                      >
                        {String.fromCharCode(96 + subIndex + 1)}. {subGroup}
                      </Typography>}
                        {sectionedIndustryQuestion[group][subGroup].map((question, qu_index) => {
                        if (question.question_type !== 'upload') industryQtnNumbr+=1
                        return(
                          <>
                              <div className={classes.questScore}>
                                <Typography
                                  fontSize={17}
                                  fontWeight={500}
                                  // variant="h3"
                                  paddingLeft={4}
                                  paddingBottom={2}
                                  paddingTop={2}
                                  className={classes.questionQr}
                                >
                                  {question.question_type !== 'upload' && `${industryQtnNumbr}. ${question.question}`}
                                </Typography>
                                {question.question_type !== 'upload' &&
                                <Typography variant="body2" color={"green"} className={classes.score}>
                                  <span style={{ color: "green" }}>Score</span>:{" "}
                                  {getAnswerByQuestionId(question.id)?.score ?? 0}
                                </Typography>}
                                </div>
                          <Box
                            key={question.id}
                            paddingLeft={4}
                          >
                            {renderQuestionUpdated(question)}
                          </Box>
                          </>)
                        })}
                          </div>
                        ))}
                </Grid>
              ))}
          </div></>
          }
        </Paper>
      </Container>
    </DashboardLayout>
  );
};

export default SupplierViewSurvey;
