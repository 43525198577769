import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import useStyles from "./styles";

const FileUpload = ({ onFileUpload, onFileRemove, boxClass }) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreviewUrl, setFilePreviewUrl] = useState("");

  const handleFileChange = ({ target }) => {
    const file = target.files[0];
    if (file) {
      setSelectedFile(file);
      if (file.type.startsWith("image/") || file.type === "application/pdf") {
        setFilePreviewUrl(URL.createObjectURL(file));
      } else {
        setFilePreviewUrl("");
      }
      onFileUpload(file);
    }
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
    setFilePreviewUrl("");
    onFileRemove();
  };

  return (
    <Box className={boxClass ? boxClass : classes.mainDiv} marginTop={4}>
      <Button
        variant="contained"
        className={classes.uploadButton}
        component="label"
        startIcon={<AttachmentOutlinedIcon />}
      >
        Attach Documents
        <input type="file" hidden onChange={handleFileChange} />
      </Button>
      {selectedFile && (
        <Button
          variant="outlined"
          color="error"
          style={{ marginLeft: "10px" }}
          onClick={handleFileRemove}
        >
          <DeleteIcon /> Remove file
        </Button>
      )}

      {selectedFile && (
        <Box
          mt={2}
          p={2}
          border="1px solid #ddd"
          borderRadius="8px"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
          bgcolor="#f9f9f9"
          maxWidth="50%"
        >
          <Typography variant="h6">File Preview:</Typography>
          {filePreviewUrl && selectedFile.type.startsWith("image/") && (
            <div
              style={{ maxWidth: "100%", height: "200px", overflowY: "scroll" }}
            >
              <img
                src={filePreviewUrl}
                alt="File preview"
                style={{ maxWidth: "100%" }}
              />
            </div>
          )}
          {filePreviewUrl && selectedFile.type === "application/pdf" && (
            <embed
              src={filePreviewUrl}
              type="application/pdf"
              width="100%"
              height="200px"
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default FileUpload;
