import { createActions } from 'redux-actions';
import { ActionTypes } from '../constants/actions';

export const {
    getSustainabilityRiskQuestions,
    createSustainabilityRiskQuestions,
    getSustainabilityRiskGenericQuestions,
    sendSustainabilityRiskQuestions,
    resetSustainabilityRiskScreeningStatus,
    getSustainabilityRiskSurveyQuestions,
    sustainabilityRiskSurveyResponse,
    getSustainabilityRiskSurveys,
    getSustainabilityRiskRespondentList,
    sendSustainabilityRiskRespondentReminder,
    getSustainabilityRiskSurveyTrackQuestions,
    getSustainabilityRiskSurveyTrackAnswers,
    resetSendSustainabilityRiskRespondentReminderStatus,
    getSustainabilityRiskSurveyResults,
} = createActions({
    [ActionTypes.GET_SUSTAINABILITY_RISK_QUESTIONS]: (industryId) => ({industryId}),
    [ActionTypes.CREATE_SUSTAINABILITY_RISK_QUESTIONS]: (industryId, requestData) => ({industryId, requestData}),
    [ActionTypes.GET_SUSTAINABILITY_RISK_GENERIC_QUESTIONS]: () => ({}),
    [ActionTypes.SEND_SUSTAINABILITY_RISK_QUESTIONS]: (senderList) => ({senderList}),
    [ActionTypes.RESET_SUSTAINABILITY_RISK_SCREENING_STATUS]: () => {},
    [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_QUESTIONS]: (answerId) => ({answerId}),
    [ActionTypes.SUSTAINABILITY_RISK_SURVEY_RESPONSE]: (answerId, requestData) => ({answerId, requestData}),
    [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEYS]: (year) => ({year}),
    [ActionTypes.GET_SUSTAINABILITY_RISK_RESPONDENT_LIST]: (id) => ({id}),
    [ActionTypes.SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER]: (id, requestData) => ({id, requestData}),
    [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_TRACK_QUESTIONS]: (id) => ({id}),
    [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_TRACK_ANSWERS]: (id) => ({id}),
    [ActionTypes.RESET_SEND_SUSTAINABILITY_RISK_RESPONDENT_REMINDER_STATUS]: () => {},
    [ActionTypes.GET_SUSTAINABILITY_RISK_SURVEY_RESULTS]: (year) => ({year}),
})
