import * as yup from "yup";

export const notificationValidation = yup.object({
  exhibitType: yup.string().required("Exhibit type is required"),
  notificationType: yup.string().required("Notification type is required"),
  message: yup
    .string()
    .required("Message is required")
    .when("notificationType", (notificationType, schema) => {
      const limits = {
        news: 150,
        outage: 80,
        release_update: 200,
        emergency: 100,
      };
      const limit = limits[notificationType];
      return limit
        ? schema.max(limit, `Message cannot exceed ${limit} characters`)
        : schema;
    }),
  startTime: yup.number().typeError("Invalid date format"),
  endTime: yup
    .number()
    .min(yup.ref("startTime"), "End time must be later than start time")
    .typeError("Invalid date format"),
  messageStatus: yup.string().required("Message status is required"),
  company: yup.array(),
  country: yup.array(),
});
