import { Box, Checkbox, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "../../layouts/DashboardLayout";
import CeroDropdown from "../../components/CeroDropdown";
import useStyles from "./styles";
import { designationWorkFlow, sampleYear } from "../../constants";
import ArrowIcon from "../../assets/icons/ArrowIcon.png";
import ArrowIconDisabled from "../../assets/icons/ArrowDisabled.png";
import {
  getApprovalFlow,
  listAssessmentCycle,
  resetApprovalUpdate,
  resetGetApproval,
  updateApprovalFlow,
} from "../../redux/actions";
import { STATUS } from "../../redux/constants";
import { useSnackbar } from "notistack";
import CeroButton from "../../components/CeroButton";
import CeroPopUp from "../../components/CeroPopUp";
import { useNavigate } from "react-router-dom";

const ApprovalWorkFlow = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [country, setCountry] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [selected, setSelected] = useState(null);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [open, setOpen] = useState(false);

  const countryData = useSelector(
    (state) => state.listings.getOrganizationCountry.data.country_data
  );
  const getApproval = useSelector(
    (state) => state.company.approvalFlow.data?.approval_flow
  );
  const getApprovalStatus = useSelector(
    (state) => state.company.approvalFlow.status
  );
  const updateApprovalStatus = useSelector(
    (state) => state.company.approvalFlowUpdate.status
  );

  const assessmentList = useSelector(
    (state) => state.assessment.assessmentList.data
  );

  let countryOptions = [];

  useEffect(() => {
    if (country && year) {
      dispatch(getApprovalFlow(country, year));
    }

    return () => {
      setSelected(null);
      setIsLocked(false);
      dispatch(resetGetApproval());
    };
  }, [country, year, dispatch]);

  const transformedYearList = [
    { key: null, value: "Select Option" },
    ...(assessmentList
      ?.filter((item) => item.country === country)
      .map((item) => ({
        key: item.assessment_year,
        value: `${item.assessment_year}`,
      })) || []),
  ];

  useEffect(() => {
    dispatch(listAssessmentCycle());
  }, []);

  useEffect(() => {
    if (getApprovalStatus === STATUS.SUCCESS) {
      const defaultFlow = designationWorkFlow.find(
        (flow) => flow.name === "Flow 1"
      );
      const initialFlow = getApproval || defaultFlow.value;

      setSelected(initialFlow);
      setSelectedFlow(getApproval ? getApproval.name : defaultFlow.name);

      if (getApproval) setIsLocked(true);
      else setIsLocked(false);
    }
  }, [getApprovalStatus, getApproval]);

  useEffect(() => {
    if (updateApprovalStatus === STATUS.SUCCESS) {
      enqueueSnackbar("User Flow Selected Successfully!", {
        variant: "success",
      });
      setIsLocked(true);
    } else if (updateApprovalStatus === STATUS.ERROR) {
      enqueueSnackbar("Error saving approval flow. Please try again.", {
        variant: "error",
      });
    }
    dispatch(resetApprovalUpdate());
  }, [updateApprovalStatus, dispatch, enqueueSnackbar]);

  if (countryData) {
    countryOptions = Object.keys(countryData).map((key) => ({
      key: countryData[key],
      value: key,
    }));
  }
  countryOptions.unshift({ key: null, value: "Select Option" });
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };
  const handleYearChange = (event) => {
    setYear(event.target.value);
  };
  const checkBoxChange = (item, name) => (event) => {
    if (!isLocked) {
      if (event.target.checked) {
        setSelected(item);
        setSelectedFlow(name);
        //   dispatch(updateApprovalFlow(country, year, item))
        setShowSaveButton(true);
      } else {
        setSelected(null);
        setSelectedFlow(null);
        setShowSaveButton(false);
      }
    }
  };

  const handleSave = () => {
    if (year && country) {
      dispatch(updateApprovalFlow(country, year, selected));
    }
    setOpen(false);
    setShowSaveButton(false);
  };

  const getApproveConfirm = (flow) => (
    <Box>
      <Typography variant="body1" style={{ fontWeight: 500 }}>
        Are you sure you want to save "{flow}" as your approval flow?
      </Typography>
      <div className={classes.buttonContainerReview}>
        <CeroButton
          className={classes.buttonPrimary}
          variant="contained"
          onClick={handleSave}
          buttonText="Confirm"
        />
        <CeroButton
          className={classes.buttonSecondary}
          variant="outlined"
          onClick={() => setOpen(false)}
          buttonText="Cancel"
        />
      </div>
    </Box>
  );

  const handleSetupButton = () => {
    navigate("functionality-view");
  };

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Grid container spacing={1.5} className={classes.filterHeader}>
          <Grid container item xs={8.5} spacing={1.5}>
            <Grid item xs={4} className={classes.filterContainer}>
              <CeroDropdown
                id="country"
                label="Country"
                fullWidth
                options={countryOptions}
                onChange={handleCountryChange}
                selectedValue={country}
              />
            </Grid>
            <Grid item xs={4} className={classes.filterContainer}>
              <CeroDropdown
                id="year"
                label="Assessment Year"
                fullWidth
                options={transformedYearList}
                onChange={handleYearChange}
                selectedValue={year}
              />
            </Grid>
          </Grid>

          <Grid item xs={3.5} container alignItems="center">
            {showSaveButton && (
              <CeroButton
                buttonText="Save"
                className={`${classes.buttonPrimaryTop} ${classes.savePosition}`}
                onClick={() => setOpen(true)}
              />
            )}
            <CeroButton
              buttonText={"Functionality View"}
              className={`${classes.buttonPrimaryTop} ${classes.functionalityPosition}`}
              onClick={handleSetupButton}
            />
          </Grid>
        </Grid>

        <Grid container className={classes.content} spacing={2}>
          {designationWorkFlow.map((item) => {
            const isSelected =
              selected &&
              item.value.every((value, index) => value === selected[index]);
            const isFlowLocked = isLocked && !isSelected;

            return (
              <Grid
                item
                xs={12}
                className={
                  isSelected
                    ? classes.flowMainContainerSelected
                    : isFlowLocked
                    ? classes.flowMainContainerDisabled
                    : classes.flowMainContainer
                }
              >
                <div className={classes.textAction}>
                  <Typography
                    className={
                      isFlowLocked ? classes.flowTextDisabled : classes.flowText
                    }
                  >
                    {item.name}:
                  </Typography>
                  <Checkbox
                    checked={isSelected}
                    disabled={isFlowLocked}
                    style={{
                      color: isFlowLocked
                        ? "rgba(162, 159, 159, 1)"
                        : "#8C2148",
                    }}
                    onChange={checkBoxChange(item.value, item.name)}
                  />
                </div>
                <div className={classes.designationOuterBox}>
                  <Box
                    className={classes.designationBox}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "250px",
                      height: "80px",
                      border: "3px solid #8C2148",
                      padding: "15px 25px",
                      borderRadius: "10px",
                      backgroundColor: "rgba(233, 226, 229, 1)",
                      color: isFlowLocked
                        ? "rgba(162, 159, 159, 1)"
                        : "#8C2148",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "18px",
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      {item.designationOrder[0]}
                    </Typography>
                  </Box>
                  {item.designationOrder.slice(1).map((designation, idx) => (
                    <>
                      <img
                        src={isFlowLocked ? ArrowIconDisabled : ArrowIcon}
                        alt="Arrow"
                        width="30"
                        height="25"
                        style={{ margin: "0 5px" }}
                      />
                      <Box
                        className={classes.designationBox}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "250px",
                          height: "80px",
                          border: "3px solid #8C2148",
                          padding: "15px 25px",
                          borderRadius: "10px",
                          backgroundColor: "rgba(233, 226, 229, 1)",
                          color: isFlowLocked
                            ? "rgba(162, 159, 159, 1)"
                            : "#8C2148",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "18px",
                            fontWeight: "700",
                            textAlign: "center",
                          }}
                        >
                          {designation}
                        </Typography>
                      </Box>
                    </>
                  ))}
                </div>
              </Grid>
            );
          })}
        </Grid>

        <CeroPopUp
          primaryPopUp={{
            open: open,
            onClose: () => setOpen(false),
            content: getApproveConfirm(selectedFlow),
            header: { title: "Confirmation" },
          }}
          classes={{ dialog: classes.dialog }}
        />
      </Container>
    </DashboardLayout>
  );
};

export default ApprovalWorkFlow;
