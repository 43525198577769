import auth from "./auth";
import account from "./account";
import emission from "./emission";
import listings from "./listings";
import facility from "./facility";
import dashboard from "./dashboard";
import users from "./users";
import company from "./company";
import approval from "./approval";
import audit from "./audit";
import tickets from "./tickets";
import reports from "./reports";
import emissionComment from "./emissionComment";
import assessment from "./assessment";
import superAdmin from "./superAdmin";
import goalSettings from './goalSettings';
import trade from "./trade";
import materiality from "./materiality";
import carbonOffset from "./carbonOffset";
import supplierScreening from "./supplierScreening";
import productionData from "./productionData";
import sustainabilityRiskAssessment from "./sustainabilityRiskAssessment"

const rootReducer = {
  ...auth,
  ...account,
  ...emission,
  ...listings,
  ...facility,
  ...dashboard,
  ...users,
  ...company,
  ...approval,
  ...audit,
  ...tickets,
  ...reports,
  ...emissionComment,
  ...assessment,
  ...superAdmin,
  ...goalSettings,
  ...trade,
  ...materiality,
  ...carbonOffset,
  ...supplierScreening,
  ...productionData,
  ...sustainabilityRiskAssessment
};

export default rootReducer;
