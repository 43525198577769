import React, { useState } from "react";

const ResilienceTable = ({ resilienceTable, setResilienceTable }) => {

  const handleInputChange = (categoryIndex, riskIndex, field, value) => {
    const updatedData = [...resilienceTable];
    updatedData[categoryIndex].risks[riskIndex][field] = value;
    setResilienceTable(updatedData);
  };

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          borderRadius: "6px",
          overflow: "hidden",
          border: "1px solid #ddd",
        }}
      >
        <table border="2" style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr style={{ backgroundColor: "#d0c5ca", textAlign: "left" }}>
              <th style={{ padding: "8px" }}>Scenario</th>
              <th style={{ padding: "8px" }}>Description of Scenario</th>
              <th style={{ padding: "8px" }}>
              Key Risks Identified
              </th>
              <th style={{ padding: "8px" }}>
              Key Opportunities Identified
              </th>
              <th style={{ padding: "8px" }}>
              Resilience Strategies 
              </th>
              <th style={{ padding: "8px" }}>Gaps or Challenges Identified</th>
            </tr>
          </thead>
          <tbody>
            {resilienceTable?.map((category, categoryIndex) => (
              <React.Fragment key={categoryIndex}>
                {category.risks.map((risk, riskIndex) => (
                  <tr key={`${categoryIndex}-${riskIndex}`}>
                    {/* Only render the category cell for the first row of each category */}
                    <td style={{ padding: "8px" }}>{risk.risk}</td>
                    <td style={{ padding: "8px" }}>{risk.impacts}</td>
                    <td style={{ padding: "8px" }}>
                      <textarea
                        value={risk.keyRisk}
                        onChange={(e) =>
                          handleInputChange(
                            categoryIndex,
                            riskIndex,
                            "keyRisk",
                            e.target.value
                          )
                        }
                        style={{ width: "100%",height: "80px", borderRadius: "6px",border: "1px solid grey",resize: "none" }}
                      />
                    </td>
                    <td style={{ padding: "8px" }}>
                      <textarea
                        value={risk.keyOpportunity}
                        onChange={(e) =>
                          handleInputChange(
                            categoryIndex,
                            riskIndex,
                            "keyOpportunity",
                            e.target.value
                          )
                        }
                        style={{ width: "100%",height: "80px", borderRadius: "6px",border: "1px solid grey",resize: "none" }}
                      />
                    </td>
                    <td style={{ padding: "8px" }}>
                      <textarea
                        value={risk.resilience}
                        onChange={(e) =>
                          handleInputChange(
                            categoryIndex,
                            riskIndex,
                            "resilience",
                            e.target.value
                          )
                        }
                        style={{ width: "100%",height: "80px", borderRadius: "6px",border: "1px solid grey",resize: "none" }}
                      />
                    </td>
                    <td style={{ padding: "8px" }}>
                      <textarea
                        value={risk.gapsChallenges}
                        onChange={(e) =>
                          handleInputChange(
                            categoryIndex,
                            riskIndex,
                            "gapsChallenges",
                            e.target.value
                          )
                        }
                        style={{ width: "100%",height: "80px", borderRadius: "6px",border: "1px solid grey",resize: "none" }}
                      />
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ResilienceTable;
