import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    drawerContainer: {
        maxWidth: '30vw',
        width: '30vw',
    },
    drawerContentArea: {
        padding: theme.spacing(4),
        overflow: 'auto'
    },
    selectContainer: {
        marginBottom: theme.spacing(5),
    },
    formField: {
        margin: theme.spacing(3, 0),
    },
    mainContainer:{
        
    },
    popUpClass:{
        "& .MuiDialog-container > .MuiPaper-root":{
            backgroundColor:"white !important"
        },
        // "& .MuiDialog-container > .MuiPaper-root > .MuiTypography-root":{
        //     borderBottom:"1px solid black"
        // },
        "& .MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root > .MuiGrid-container > .MuiGrid-item > .MuiBox-root":{
            height:"40px",
            marginBottom:"4px !important",

        },
        "& .MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root > .MuiGrid-container > .MuiGrid-item > .MuiBox-root > .MuiFormControl-root > .MuiInputBase-root":{
            height:"40px",
            // marginBottom:"4px !important",

        },
        "& .MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root > .MuiGrid-container > .MuiGrid-item > .MuiBox-root > .MuiFormControl-root > .MuiInputLabel-outlined":{
            // height:"40px",
            marginTop:"-6px !important",

        },
        "& .MuiDialog-container > .MuiPaper-root > .MuiDialogContent-root > .MuiGrid-container > .MuiGrid-item > .MuiBox-root > .MuiFormControl-root > .MuiFormLabel-filled":{
            marginTop:"0px !important",
        }
    },
    primaryBtn: {
        height: 36,
        borderRadius: 5,
        borderWidth: 1,
        padding: theme.spacing(1.5, 4),
        marginRight: theme.spacing(2),
        width: 80,
        borderColor: theme.palette.Primary.main,
        backgroundColor: theme.palette.Primary.background,
        textTransform: "capitalize",
        '&:hover': {
          backgroundColor: theme.palette.Primary.hoverButton,
        },
        '&:disabled': {
          color: theme.palette.Actions.disabled,
          borderColor: theme.palette.action.disabledBackground,
          backgroundColor: theme.palette.Actions.disabledBackground,
        },
      },
      primaryBtnText: {
        color: theme.palette.Primary.contrastText,
        fontWeight: 600,
        fontSize: 14,
      },
      secondaryBtn: {
        height: 36,
        padding: theme.spacing(1.5, 4),
        borderWidth: 1,
        borderRadius: 5,
        borderStyle: 'solid',
        borderColor: theme.palette.Primary.background,
        textTransform: "capitalize",
        "&:hover": {
          borderColor: theme.palette.Primary.background,
          backgroundColor:theme.palette.Secondary.hoverButton,
        },
        '&:disabled': {
          color: theme.palette.Actions.disabled,
          borderColor: theme.palette.Actions.disabledBackground,
        },
      },
      secondaryBtnText: {
        color: theme.palette.Primary.main,
        fontWeight: 600,
        fontSize: 14,
      },
      infoContainer: {
        marginTop: theme.spacing(3),
        width: "400px !important",
        height: "15px"
        // height: "calc(15px + 100%)",
      },
      countryContainer: {
        marginTop: theme.spacing(3),
        width: "400px !important",
        // height: "15px"
        height: "calc(15px + 100%)",
      },
      title: {
          width: '50%'
      },
      tableContainer: {
        maxHeight: "calc(100vh - 140px)",
        border: '1px solid #36262f'
      },
      enabled: {
        fontWeight: 600,
        color: "#48742c",
      },
      disabled: {
        fontWeight: 600,
        color: "#d3291d",
      },

}));

export default useStyles;