import * as yup from "yup";

export const addWaterDischargeCombustionValidation = yup.object({
  facility: yup.string("Facility is required").required("Facility is required"),
  month: yup.string("Month is required").required("Month is required"),
  year: yup.string("Year is required").required("Year is required"),
  destination: yup
    .string("Destination is required")
    .required("Destination is required"),
  stressType: yup
    .string("Stress Type is required")
    .required("Stress Type is required"),
  destinationType: yup
    .string("Destination Type is required")
    .required("Destination Type is required"),
  treatmentRequired: yup.boolean().required("Treatment Required is required"),
  treatmentLevel: yup
    .string("Treatment Level is required")
    .when("treatmentRequired", {
      is: true, // Validate when treatmentRequired is `true` (boolean)
      then: yup
        .string("Treatment Level is required")
        .required("Treatment Level is required"),
      otherwise: yup.string().nullable(), // Allow null/undefined if not required
    }),
  fuelUnit: yup.string("Unit is required").required("Unit is required"),
  amountOfFuel: yup
    .number()
    .typeError("Amount of water must be a valid number")
    .required("Amount of water is required")
    .strict(true),
});
