import React, { useState } from "react";

const RiskTable = ({ riskTable, setRiskTable }) => {
  const handleInputChange = (categoryIndex, riskIndex, field, value) => {
    const updatedData = [...riskTable];
    updatedData[categoryIndex].risks[riskIndex][field] = value;
    setRiskTable(updatedData);
  };

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          borderRadius: "6px",
          overflow: "hidden",
          border: "1px solid #ddd",
        }}
      >
        <table border="2" style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr style={{ backgroundColor: "#d0c5ca", textAlign: "left" }}>
              <th style={{ padding: "8px" }}>Category</th>
              <th style={{ padding: "8px" }}>Risk</th>
              <th style={{ padding: "8px" }}>
                Potential Impacts (Pre-defined)
              </th>
              <th style={{ padding: "8px" }}>
                Time period (Short/Medium/Long)
              </th>
              <th style={{ padding: "8px" }}>
                Applicable to Your Organization? (Yes/No)
              </th>
              <th style={{ padding: "8px" }}>Comments/Additional Details</th>
            </tr>
          </thead>
          <tbody>
            {riskTable?.map((category, categoryIndex) => (
              <React.Fragment key={categoryIndex}>
                {category.risks.map((risk, riskIndex) => (
                  <tr key={`${categoryIndex}-${riskIndex}`}>
                    {riskIndex === 0 && (
                      <td
                        rowSpan={category.risks.length}
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                          padding: "8px",
                        }}
                      >
                        {category.category}
                      </td>
                    )}
                    <td style={{ padding: "8px" }}>{risk.risk}</td>
                    <td style={{ padding: "8px" }}>{risk.impacts}</td>
                    <td style={{ padding: "28px" }}>
                      <input
                        type="text"
                        value={risk.timePeriod}
                        onChange={(e) =>
                          handleInputChange(
                            categoryIndex,
                            riskIndex,
                            "timePeriod",
                            e.target.value
                          )
                        }
                        style={{
                          width: "100%",
                          height: "34px",
                          borderRadius: "6px",
                          border: "1px solid grey",
                        }}
                      />
                    </td>
                    <td style={{ padding: "38px" }}>
                      <input
                        type="text"
                        value={risk.applicable}
                        onChange={(e) =>
                          handleInputChange(
                            categoryIndex,
                            riskIndex,
                            "applicable",
                            e.target.value
                          )
                        }
                        style={{
                          width: "100%",
                          height: "34px",
                          borderRadius: "6px",
                          border: "1px solid grey",
                        }}
                      />
                    </td>
                    <td style={{ padding: "8px" }}>
                      {/* <input
                        type="text"
                        value={risk.comments}
                        onChange={(e) =>
                          handleInputChange(
                            categoryIndex,
                            riskIndex,
                            "comments",
                            e.target.value
                          )
                        }
                        style={{ width: "100%",height: "84px", borderRadius: "6px",border: "1px solid grey" }}
                      /> */}
                      <textarea
                        value={risk.comments}
                        onChange={(e) =>
                          handleInputChange(
                            categoryIndex,
                            riskIndex,
                            "comments",
                            e.target.value
                          )
                        }
                        style={{
                          width: "100%",
                          height: "80px",
                          borderRadius: "6px",
                          border: "1px solid grey",
                          padding: "8px",
                          boxSizing: "border-box",
                          resize: "none",
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RiskTable;
