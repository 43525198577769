import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import {
  getAssessmentPeriod,
  resetAddCombustionStatus,
  getEmissionInputFormat,
  addSubsidiesFinancialAssistance,
} from "../../../redux/actions";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { addPerformance } from "./schema";
import CeroQuestionWithCheckbox from "../../../components/CeroQuestionWithCheckbox";
import CeroChipSelect from "../../../components/CeroChipSelect";
import FileUpload from "../FileUpload";

const AddSubsidiesAndFinancialAssistance = (props) => {
  const { onCancel } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [showOtherDetails, setShowOtherDetails] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );

  const addDataStatus = useSelector(
    (state) => state.emission.addFinancialAssistance
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));
  const currentYear = new Date().getFullYear().toString()

  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      assessmentPeriod: "",
      programType: "",
      financialAssistanceRecieved: "",
      financialAssistanceType: [],
      isGovernmentPresent: "",
      details: "",
      facilities:facilitiesData.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.id]: 0,
        };
      }, {}),
      otherDetails:""
    },
    validationSchema: addPerformance,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (addDataStatus.status === STATUS.SUCCESS) {
      enqueueSnackbar("Subsidies and Financial Assistance added successfully", {variant: "success",});
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addDataStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        addDataStatus.message.message || "We couldn't process your request. Please try again later.", {variant: "error",}
      );
    }
  }, [addDataStatus, dispatch, enqueueSnackbar, onCancel]);

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility, 'subsidies_financial_assistance'));
    }
  }, [formik.values.facility, formik.values.year, dispatch]);

  useEffect(() => {
    const { facility, year, assessmentPeriod } = formik.values;
    if (facility && year && assessmentPeriod) {
      dispatch(getEmissionInputFormat("performance_and_career_development_programs", assessmentPeriod, year, facility));
    }
  }, [formik.values.facility, formik.values.year, formik.values.assessmentPeriod, dispatch]);

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const onAddData = () => {
    const requestData = {
      facility_id: formik.values.facility,
      year: formik.values.year,
      assessment_period : formik.values.assessmentPeriod,
      type_financial_assistant: formik.values.financialAssistanceType,
      financial_assistant_received: formik.values.financialAssistanceRecieved,
      any_government_present_in_shareholder: formik.values.isGovernmentPresent,
      financial_assistant_by_facilities:convert_facility_data(),
      details: formik.values.details,
      type_financial_assistant_details: formik.values.otherDetails,
      file:selectedFile,
      save: true,
    };
    sessionStorage.setItem('year', requestData.year)
    dispatch(addSubsidiesFinancialAssistance(requestData));
  };

  const FinancialAssistanceType = {
    "Awards" : "awards",
    "Export Credit Agencies" : "export credit agencies",
    "Financial Incentives" : "financial incentives",
    "Investment Grands" : "investment grands",
    "Royalty Holidays" : "royalty holidays",
    "Subsidies" : "subsidies",
    "Tax relief and Tax credits": "tax relief and tax credits",
    "Others" : "others" 
  }

  const facilityAmountChange = (fac, event) => {
    const value = event.target.value;
    const sanitizedValue = value === "" ? "" : parseFloat(value);
    formik.setFieldValue("facilities", {
      ...formik.values.facilities,
      [fac]: sanitizedValue,
    });
  };
  const dropHandleChange = (e) => {
    const value = e.target.value;
    
    if (value[value.length - 1] === "all") {
      const updateValues = formik.values.financialAssistanceType.length === Object.keys(FinancialAssistanceType).length ? [] : Object.keys(FinancialAssistanceType) 
      formik.setFieldValue("financialAssistanceType", updateValues);
      setShowOtherDetails(updateValues.includes("Others"));
      return;
    }
    else{
      formik.setFieldValue("financialAssistanceType",value)
      setShowOtherDetails(value.includes("Others"));
    }
    

  }

  const handleFileUpload = (file) => {
    setSelectedFile(file);
  };
  const handleFileRemove = () => {
    setSelectedFile(null);
  };
  
  
  const convert_facility_data = () => {
    const return_data = []
    Object.keys(formik.values.facilities).map(item=>{
      return_data.push({
        "facility_name": facilitiesData.filter(data=>data.id==item)[0].name,
        "facility_id": item,
        "amount": formik.values.facilities[item]
      })
    })
    return return_data
  }
  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
          Add Subsidies & Financial Assistance
          </Typography>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" lg={6} md={6} xs={4}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  mu
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
              </Grid>
              <Grid item container direction={"column"} lg={6} md={6} xs={4}>
                <CeroAutoComplete
                  id="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
              </Grid>
              <Grid item container direction="column" lg={6} md={6} xs={4}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
              
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column"  lg={8} md={8} xs={8}>
                <CeroInput 
                required
                name="financialAssistanceRecieved" 
                label="Total Financial Assistance Recieved" 
                id="financialAssistanceRecieved"
                value={formik.values.financialAssistanceRecieved}
                fullWidth
                multiline
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.financialAssistanceRecieved && formik.errors.financialAssistanceRecieved}
                classes={{ container: classes.textAreaContainer }}/>
              </Grid>
              <Grid item container direction="column"  lg={4} md={4} xs={4}>
              <CeroChipSelect
              id="financialAssistance"
              name="financialAssistance"
              label={"Type of Financial Assistance"}
              formControlClass={classes.MultiSelectformControl}
              optionsde={FinancialAssistanceType}
              selected={formik.values.financialAssistanceType}
              handleChange={(e)=>dropHandleChange(e)}
              />
              </Grid>
            </Grid>
            {showOtherDetails && (
                      <Grid
                      container
                      direction="row"
                      wrap="nowrap"
                      justifyContent="space-between"
                      spacing={8}
                      className={classes.financialEntries}
                    >
                        <Grid item container direction="column"   lg={12} md={12} xs={12}>
                          <CeroInput
                            id="otherDetails"
                            name="otherDetails"
                            label="Financial assistance details"
                            fullWidth
                            value={formik.values.otherDetails}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            />
                          </Grid>
                        </Grid>
              )}
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
              className={classes.financialEntries}
            >
              <Grid item container direction="column"   lg={12} md={12} xs={12}>
              <Typography fontWeight="bold">Financial Assistance by Facility</Typography>
              </Grid>
              <Grid item container direction="column"  lg={12} md={12} xs={12}>
              <Typography>Enter the total monetary value of financial assistance recieved from each facility</Typography>
              </Grid>
              {facilitiesData.map(item=>(<Grid item container direction="column"  className={classes.facilityEntries} lg={12} md={12} xs={12}>
                <Typography fontWeight="bold" className={classes.facilityNames}>{item.name}</Typography>
                <CeroInput
                  required
                  name={item.id} 
                  label="Amount" 
                  id={item.id}
                  value={formik.values.facilities[item.id]?formik.values.facilities[item.id]: 0}
                  fullWidth
                  multiline
                  type={"number"}
                  onChange={(e)=>facilityAmountChange(item.id,e)}
                  onBlur={formik.handleBlur}
                  error={formik.values.facilities[item.id] && formik.touched.facilities && formik.touched.facilities[item.id] && formik.errors.facilities[item.id]}
                  classes={{ container: classes.textAreaContainer }}/>
              </Grid>))}
            </Grid>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column"  lg={12} md={12} xs={12}>
              <CeroQuestionWithCheckbox classes={{'questionBox':classes.questionBox}}  question="Indicating whether any government is present in the shareholding structure of the organization?"
              formik={formik}
              fieldName="isGovernmentPresent"
              onChange={()=>console.log("")}/>
                
              </Grid>
            </Grid>
            {formik.values.isGovernmentPresent === "Yes" &&(<Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column"  lg={12} md={12} xs={12}>
                <CeroInput 
                name="details" 
                label="Details" 
                id="details"
                value={formik.values.details}
                fullWidth
                row={2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.details && formik.errors.details}
                classes={{ container: classes.textAreaContainer }}
                />
              </Grid>
            </Grid>)}
            
          </Box>
          <FileUpload onFileUpload={handleFileUpload} onFileRemove={handleFileRemove} />
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            className={classes.buttonPrimary}
            onClick={() => onAddData()}
            disabled={!formik.isValid || !formik.dirty}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={formik.resetForm}
          />
        </Box>
      </Container>
    </>
  );
};

export default AddSubsidiesAndFinancialAssistance;
