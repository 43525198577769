import React from "react";
import { Scatter } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend, PointElement, LinearScale, CategoryScale } from "chart.js";

ChartJS.register(LinearScale, PointElement, Legend, Tooltip);

// Plugin to add background colors to chart areas
const gradientBackgroundPlugin = {
  id: "customBackground",
  beforeDraw: (chart) => {
    const { ctx, scales } = chart;
    const xScale = scales.x;
    const yScale = scales.y;
    

    // Define solid colors for each box
    const boxColors = [
      ["#f7f7f7", "#e3e3e3", "#cfcfcf", "#bfbfbf",], // Row 1
      ["#e3e3e3", "#e3e3e3", "#cfcfcf", "#bfbfbf",], // Row 2
      ["#cfcfcf", "#cfcfcf", "#cfcfcf", "#bfbfbf",],
      ["#bfbfbf", "#bfbfbf", "#bfbfbf", "#bfbfbf",],
      ["#f7f7f7", "#e3e3e3", "#cfcfcf", "#bfbfbf",],
    ];

    const boxWidth = (xScale.max - xScale.min) / 4; // Dividing x-axis into 4 sections
    const boxHeight = (yScale.max - yScale.min) / 4; // Dividing y-axis into 4 sections
    
    boxColors.forEach((rowColors, rowIndex) => {
      rowColors.forEach((color, columnIndex) => {
        ctx.fillStyle = color;
    
        const xStart = xScale.getPixelForValue(xScale.min + boxWidth * columnIndex); // Left x-coordinate
        const xEnd = xScale.getPixelForValue(xScale.min + boxWidth * (columnIndex + 1)); // Right x-coordinate
    
        const yStart = yScale.getPixelForValue(yScale.min + boxHeight * rowIndex); // Bottom y-coordinate
        const yEnd = yScale.getPixelForValue(yScale.min + boxHeight * (rowIndex + 1)); // Top y-coordinate
        if (yEnd>0)
        ctx.fillRect(
          xStart,                     // Starting x-coordinate
          yEnd,                       // Starting y-coordinate (top of the box)
          xEnd - xStart,              // Box width
          yStart - yEnd               // Box height (difference between bottom and top y-coordinates)
        );
      });
    });
    
  },
};


export  const ScatterPlot = ({dataMap , xAttr, yAttr,  xAxisLabel, yAxisLabel}) => {
  const randomColor = ['rgba(94, 128, 63, 1)', "rgba(58, 142, 235, 1)", "rgba(248, 99, 5, 1)", "rgba(42, 101, 101, 1)", "rgba(252, 103, 109, 1)"]
  const dataSet = dataMap && dataMap[xAttr] ? Object.keys(dataMap[xAttr])?.map((item, index)=> { return {
    label:item, 
    data:[{x:dataMap[xAttr][item], y:dataMap[yAttr][item]}],
    backgroundColor:randomColor[index],
    pointRadius: 10
  }}) : []
  const data = {
  datasets: dataSet
  };


  const options = {
    responsive: true,
    maintainAspectRatio: false, // Ensures square dimensions
    aspectRatio: 2, // Sets a 1:1 aspect ratio
    plugins: {
      legend: {
        position: "right",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          boxWidth: 10,
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const { x, y } = context.raw;
            return `${xAttr}: ${x}, ${yAttr}: ${y}`;
          },
        },
      },
    },
    scales: {
      x: {
        type: "linear",
        min: 1,
        max: 5,
        ticks: {
          autoSkip: false,
          callback: (value) => {
            const labels = {
              1: "",
              1.5: "Negligible",
              2.5: "Moderate",
              3.5: "Significant",
              4.5: "Major",
            };
            return labels[value] || value; // Show the label if it exists, otherwise show the value
          },
        },
        title: {
          display: true,
          text: xAxisLabel,
          color: "Black",
        },
        grid: {
          color: (context) => {
            // Only show grid lines for whole numbers
            return context.tick.value % 1 === 0 ? "#D3D3D3" : "transparent";
          },
          borderColor: "#8B0000",
        },
      },
      y: {
        type: "linear",
        min: 1,
        max: 5,
        ticks: {
          autoSkip: false,
          callback: (value) => {
            const labels = {
              1: "",
              1.5: "Negligible",
              2.5: "Moderate",
              3.5: "Significant",
              4.5: "Major",
            };
            return labels[value] || value; // Show the label if it exists, otherwise show the value
          },
        },
        title: {
          display: true,
          text: yAxisLabel,
          color: "Black",
        },
        grid: {
          color: (context) => {
            // Only show grid lines for whole numbers
            return context.tick.value % 1 === 0 ? "#D3D3D3" : "transparent";
          },
          borderColor: "#8B0000",
        },
      },
    },
  };

  return (
    <div
      style={{
        width: "800px", // Ensure container is square
        height: "500px", // Match width for a square chart
        margin: "0 auto",
      }}
    >
      <Scatter
        data={data}
        options={options}
        plugins={[gradientBackgroundPlugin]}
      />
    </div>
  );
};



// export default ScatterPlot1;

