import { all, put, call, takeLatest, delay } from 'redux-saga/effects'

import { deleteCookie, getCookie, setCookie } from '../../services/cookie'
import { request } from '../../services/client'
import { ActionTypes } from '../constants/actions';
import { APIEndpoints } from '../constants';

/**
 * Login
 */
export function* login(action) {
  try {
    const { userType, email, password } = action.payload
    let loginFormData = new URLSearchParams();
    loginFormData.append('username', email)
    loginFormData.append('password', password)
    let API = (userType === 'auditor' && APIEndpoints.AUDITOR_LOGIN) || (userType === 'business' && APIEndpoints.LOGIN) || (userType === 'super_admin' && APIEndpoints.SUPERADMIN_LOGIN) || (userType === 'stakeholder' && APIEndpoints.STAKEHOLDER_LOGIN)
    const response = yield call(request, API, {
      method: 'POST',
      isFormData: true,
      disableAuthorization: true,
      payload: loginFormData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
    yield setCookie('auth_token_admin', response.access_token)
    yield setCookie('access_token_expiry', response.access_token_expiry)
    yield setCookie('user_details', JSON.stringify(response))
    yield setCookie('role', [response.role[0]])
    yield setCookie('availableRoles', response.role)
    yield setCookie('availableModules', response.modules)
    localStorage.setItem('password', password)
    yield put({
      type: ActionTypes.USER_LOGIN_SUCCESS,
      payload: response,
      role: [response.role[0]],
      modules:response.modules
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.USER_LOGIN_FAILURE,
      payload: err.message
    })
  }
}

export function* getForgotPasswordOtp(action) {
  try {
    const { email } = action.payload
    let API =  APIEndpoints.FORGOT_PASSWORD
    const response = yield call(request, API, {
      method: 'POST',
      payload: { email }
    })
    yield put({
      type: ActionTypes.GET_FORGOT_PASSWORD_OTP_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_FORGOT_PASSWORD_OTP_FAILURE,
      payload: err
    })
  }
}

export function* resetPassword(action) {
  try {
    const {  email, otp, password } = action.payload
    let API = APIEndpoints.RESET_PASSWORD
    const response = yield call(request, API, {
      method: 'POST',
      payload: { email, otp, new_password: password }
    })
    yield put({
      type: ActionTypes.RESET_PASSWORD_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.RESET_PASSWORD_FAILURE,
      payload: err
    })
  }
}

export function* changePassword(action) {
  try {
    const { oldPassword, password } = action.payload
    const role = getCookie('role');
    const apiEndpoint = (role === 'read_only_user' ? APIEndpoints.STAKEHOLDER_CHANGE_PSWD :
    (role === 'super_admin' ? APIEndpoints.SUPERADMIN_CHANGE_PASSWORD :
    APIEndpoints.CHANGE_PASSWORD()))
    const response = yield call(request, apiEndpoint, {
      method: 'POST',
      payload: { current_password: oldPassword, new_password: password }
    })
    yield put({
      type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CHANGE_PASSWORD_FAILURE,
      payload: err
    })
  }
}

export function* userSignUp(action) {
  try {
    const { signupDetails } = action.payload
    const response = yield call(request, APIEndpoints.USER_SIGN_UP, {
      method: 'POST',
      payload: { ...signupDetails }
    })
    // yield setCookie('auth_token_admin', response.access_token)
    // yield setCookie('access_token_expiry', response.access_token_expiry)
    // yield setCookie('user_details', JSON.stringify(response))
    // yield setCookie('role', response.role)
    // localStorage.setItem('password', signupDetails.password)
    localStorage.clear();
    yield put({
      type: ActionTypes.USER_SIGN_UP_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.USER_SIGN_UP_FAILURE,
      payload: err.message
    })
  }
}

export function* auditorSignUp(action) {
  try {
    const { signupDetails } = action.payload
    const response = yield call(request, APIEndpoints.AUDITOR_SIGN_UP, {
      method: 'POST',
      payload: { ...signupDetails }
    })
    localStorage.clear();
    yield put({
      type: ActionTypes.AUDITOR_SIGN_UP_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.AUDITOR_SIGN_UP_FAILURE,
      payload: err.message
    })
  }
}

export function* refreshToken() {
  try {
    const accessTokenExpiry = parseInt(getCookie('access_token_expiry'))
    const userDetails = JSON.parse(getCookie('user_details'))
    const password = localStorage.getItem('password')
    const userType = getCookie('role').split(',')
    // const userTypeString = getCookie('role'); // Read as a string
    // const userType = userTypeString ? [userTypeString] : []; 
    const now = new Date().getTime()
    if (!userType) {
      yield deleteCookie('auth_token_admin')
      yield deleteCookie('access_token_expiry')
      yield deleteCookie('user_details')
      yield deleteCookie('role')
      yield deleteCookie('availableRoles')
      yield deleteCookie('type')
      yield put({
        type: ActionTypes.USER_LOGOUT_SUCCESS
      })
      yield put({
        type: ActionTypes.REFRESH_TOKEN_FAILURE
      })
    }
    if (accessTokenExpiry > now) {
    
      yield put({
        type: ActionTypes.USER_LOGIN_SUCCESS,
        payload: userDetails,
        role: userType
      })
      yield put({
        type: ActionTypes.REFRESH_TOKEN_SUCCESS
      })
    } else if (accessTokenExpiry < now && password) {
      let loginFormData = new URLSearchParams();
      loginFormData.append('username', userDetails.email)
      loginFormData.append('password', password)
      const response = yield call(request, APIEndpoints.LOGIN, {
        method: 'POST',
        isFormData: true,
        disableAuthorization: true,
        payload: loginFormData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      })
      yield setCookie('auth_token_admin', response.access_token)
      yield setCookie('access_token_expiry', response.access_token_expiry)
      yield setCookie('user_details', JSON.stringify(response))
      yield setCookie('role', userType)
    yield setCookie('availableModules', response.modules)

      yield put({
        type: ActionTypes.USER_LOGIN_SUCCESS,
        payload: response,
        role: userType,
        modules:response.modules
      })
      yield put({
        type: ActionTypes.REFRESH_TOKEN_SUCCESS
      })
    } else {
      yield deleteCookie('auth_token_admin')
      yield deleteCookie('access_token_expiry')
      yield deleteCookie('user_details')
      yield deleteCookie('role')
      yield deleteCookie('availableRoles')
      yield put({
        type: ActionTypes.REFRESH_TOKEN_FAILURE
      })
    }
  } catch (err) {
    /* istanbul ignore next */
    yield deleteCookie('auth_token_admin')
    yield deleteCookie('access_token_expiry')
    yield deleteCookie('user_details')
    yield deleteCookie('role')
    yield deleteCookie('availableRoles')
    yield put({
      type: ActionTypes.USER_LOGOUT_SUCCESS
    })
    yield put({
      type: ActionTypes.REFRESH_TOKEN_FAILURE
    })
  }
}

export function* userInviteLogin(action) {
  try {
    const { userId, name, password, code, userType } = action.payload
    const apiEndpoint = (userType === 'stakeholder') ? APIEndpoints.STAKEHOLDER_INVITE_LOGIN : APIEndpoints.USER_INVITE_LOGIN
    const response = yield call(request, apiEndpoint, {
      method: 'POST',
      disableAuthorization: true,
      payload: { user_id: userId, code, name, password },
    })
    yield setCookie('auth_token_admin', response.access_token)
    yield setCookie('access_token_expiry', response.access_token_expiry)
    yield setCookie('user_details', JSON.stringify(response))
    yield setCookie('role', [response.active_role ? response.active_role : response.role[0]])
    yield setCookie('availableRoles', response.role)
    yield setCookie('availableModules', response.modules)
    localStorage.setItem('password', password)
    
    yield put({
      type: ActionTypes.USER_LOGIN_SUCCESS,
      payload: response,
      modules:response.modules,
      role: [response.active_role ? response.active_role : response.role[0]]
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.USER_LOGIN_FAILURE,
      payload: err.message
    })
  }
}

export function* logout(action) {
  const { email } = action.payload
  const isTradePlatform = window.location.pathname.includes("trade");

  try {
    const accessToken = getCookie('auth_token_admin')
    if (accessToken) {
      yield call(request, APIEndpoints.LOGOUT(email), {
        method: 'POST',
        payload: {}
      })
    }
    if(isTradePlatform){
      yield deleteCookie('auth_token_trade')
    yield deleteCookie('access_token_expiry_trade')
    yield deleteCookie('user_details_trade')
    yield deleteCookie('role_trade')
    yield deleteCookie('availableRoles_trade')
    yield deleteCookie('type_trade')
    yield deleteCookie('availableModules_trade')
    yield deleteCookie('type_trade')
    }else{
      yield deleteCookie('auth_token_admin')
      yield deleteCookie('access_token_expiry')
      yield deleteCookie('user_details')
      yield deleteCookie('role')
      yield deleteCookie('availableRoles')
      yield deleteCookie('type')
    }
    yield put({
      type: ActionTypes.USER_LOGOUT_SUCCESS
    })
  } catch (err) {
    /* istanbul ignore next */
    if(isTradePlatform){
      yield deleteCookie('auth_token_trade')
    yield deleteCookie('access_token_expiry_trade')
    yield deleteCookie('user_details_trade')
    yield deleteCookie('role_trade')
    yield deleteCookie('availableRoles_trade')
    yield deleteCookie('type_trade')
    yield deleteCookie('availableModules_trade')
    yield deleteCookie('type_trade')
    }else{
      yield deleteCookie('auth_token_admin')
      yield deleteCookie('access_token_expiry')
      yield deleteCookie('user_details')
      yield deleteCookie('role')
      yield deleteCookie('availableRoles')
      yield deleteCookie('type')
      yield deleteCookie('availableModules')
    }
    yield put({
      type: ActionTypes.USER_LOGOUT_FAILURE,
      payload: err
    })
  }
}

export function* getRefreshToken() {
  try {
    const userType = getCookie('role')
    const token = getCookie('auth_token_admin')
    let API = (userType === 'auditor' && APIEndpoints.GET_REFRESH_TOKEN_AUDITOR) || (userType === 'stakeholder' && APIEndpoints.GET_REFRESH_TOKEN_STAKEHOLDER) || (userType === 'super_admin' && APIEndpoints.GET_REFRESH_TOKEN_ADMIN) ||
    APIEndpoints.GET_REFRESH_TOKEN_BUSINESS
      const response = yield call(request, API, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      yield setCookie('auth_token_admin', response.refresh_token)
      yield setCookie('access_token_expiry', response.expiry_ts)
      yield put({
        type: ActionTypes.GET_REFRESH_TOKEN_SUCCESS
      })
  } catch (err) {
    /* istanbul ignore next */
    yield deleteCookie('auth_token_admin')
    yield deleteCookie('access_token_expiry')
    yield deleteCookie('user_details')
    yield deleteCookie('role')
    yield deleteCookie('availableRoles')
    yield put({
      type: ActionTypes.USER_LOGOUT_SUCCESS
    })
    yield put({
      type: ActionTypes.GET_REFRESH_TOKEN_FAILURE
    })
  }
}

export function* setActiveRole(action) {
  const {role} = action.payload
  try {
    const response = yield call(request, APIEndpoints.SET_ACTIVE_ROLE(role), {
      method: 'POST',
      payload: {}
    })
    yield setCookie('availableModules', response.modules)
    yield put({
      type: ActionTypes.SET_ACTIVE_ROLE_SUCCESS,
      payload: response,
      modules:response.modules
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.SET_ACTIVE_ROLE_FAILURE,
      payload: err.message
    })
  }
}

export function* generalLogin(action) {
  try {
    const { email, password } = action.payload
    let loginFormData = new URLSearchParams();
    loginFormData.append('username', email)
    loginFormData.append('password', password)
    let API = APIEndpoints.GENERAL_USER_LOGIN
    const response = yield call(request, API, {
      method: 'POST',
      isFormData: true,
      disableAuthorization: true,
      payload: loginFormData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    })
    yield setCookie('read_only_mode', response.status)
    yield setCookie('auth_token_admin', response.access_token)
    yield setCookie('access_token_expiry', response.access_token_expiry)
    yield setCookie('user_details', JSON.stringify(response))
    yield setCookie('role', [response.active_role ? response.active_role : response.role[0]])
    yield setCookie('availableRoles', response.role)
    yield setCookie('availableModules', response.modules)
    yield deleteCookie('session_expired')
    localStorage.setItem('password', password)
    yield put({
      type: ActionTypes.USER_LOGIN_SUCCESS,
      payload: response,
      role: [response.active_role ? response.active_role : response.role[0]],
      modules:response.modules
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.USER_LOGIN_FAILURE,
      payload: err.message
    })
  }
}
export function* validateTwoFAOtp(action) {
  try {
    const { otp,userId } = action.payload.otp;
    let API = APIEndpoints.SUPER_ADMIN_TWOFA_VALIDATION(userId);
    const response = yield call(request, API, {
      method: "POST",
      disableAuthorization: true,
      payload: {otp},
      headers: {
        "Content-Type": "application/json",
      },
    });
    yield setCookie("auth_token_admin", response.access_token);
    yield setCookie("access_token_expiry", response.access_token_expiry);
    yield setCookie("user_details", JSON.stringify(response));
    yield setCookie("availableRoles", response.role);
    yield put({
      type: ActionTypes.SUPER_ADMIN_TWOFA_VALIDATION_SUCCESS,
      payload: response,
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.SUPER_ADMIN_TWOFA_VALIDATION_FAILURE,
      payload: err.message,
    });
  }
}

export function* resendOtp(action) {
  try {
    const { userId } = action.payload;
    let API = APIEndpoints.RESEND_OTP(userId);
    const response = yield call(request, API, {
      method: "GET"
    });
    yield put({
      type: ActionTypes.RESEND_OTP_SUCCESS,
      payload: response
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.RESEND_OTP_FAILURE,
      payload: err.message,
    });
  }
}
export function* clearOtp(action) {
  try {
    yield delay(5 * 60 * 1000);
      // const { userId } = action.payload;
      // let API = APIEndpoints.CLEAR_OTP(userId);
      // const response = yield call(request, API, {
      //     method: 'PUT',
      //     payload: userId
      // })
      yield put({
          type: ActionTypes.CLEAR_OTP,
          // payload: response.emission,
          // save: requestData.save
      })
  } catch (err) {
      /* istanbul ignore next */
      yield put({
          type: ActionTypes.CLEAR_OTP,
          // payload: err
      })
  }
}

export function* proxySustainabilityManagerLogin(action) {
  try {
    const { companyId } = action.payload;
    let API = APIEndpoints.PROXY_SUSTAINABILITY_MANAGER_LOGIN(companyId);
    const response = yield call(request, API, {
      method: "POST",
      payload:{},
    });


    yield setCookie("auth_token_admin", response.access_token);
    yield setCookie("access_token_expiry", response.access_token_expiry);
    yield setCookie('role', [response.role[0]])
    yield setCookie("user_details", JSON.stringify(response));
    yield setCookie("availableRoles", response.role);
    yield setCookie('availableModules', response.modules)
    yield put({
      type: ActionTypes.PROXY_SUSTAINABILITY_MANAGER_LOGIN_SUCCESS,
      payload: response,
      role: [response.role[0]],
      modules:response.modules
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.PROXY_SUSTAINABILITY_MANAGER_LOGIN_FAILURE,
      payload: err.message,
    });
  }
}

export function* switchBackToSuperAdmin(action) {
  try {
    const { userId } = action.payload;
    let API = APIEndpoints.SWITCH_BACK_TO_SUPER_ADMIN(userId);
    const response = yield call(request, API, {
      method: "POST",
      payload:{}
    });
    yield setCookie("auth_token_admin", response.access_token);
    yield setCookie("access_token_expiry", response.access_token_expiry);
    yield setCookie('role', [response.role[0]])
    yield setCookie("user_details", JSON.stringify(response));
    yield setCookie("availableRoles", response.role);
    yield setCookie('availableModules', response.modules)
    yield put({
      type: ActionTypes.SWITCH_BACK_TO_SUPER_ADMIN_SUCCESS,
      payload: response,
      role: [response.role[0]],
      modules:response.modules
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.SWITCH_BACK_TO_SUPER_ADMIN_FAILURE,
      payload: err.message,
    });
  }
}

export function* generate_admin_otp(action) {
  const {email} = action.payload
  try {
    const response = yield call(request, APIEndpoints.ADMIN_TWOFA_OTP_GENERATE(email), {
      method: 'GET',
      payload: {}
    })
    yield put({
      type: ActionTypes.ADMIN_TWOFA_OTP_GENERATE_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADMIN_TWOFA_OTP_GENERATE_FAILURE,
      payload: err.message
    })
  }
}

export function* submit_admin_otp(action) {
  const {email, otp} = action.payload
  try {
    const response = yield call(request, APIEndpoints.ADMIN_TWOFA_OTP_SUBMIT(), {
      method: 'POST',
      payload: {email:email, otp:otp}
    })
    yield put({
      type: ActionTypes.ADMIN_TWOFA_OTP_SUBMIT_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADMIN_TWOFA_OTP_SUBMIT_FAILURE,
      payload: err.message
    })
  }
}

export function* submit_admin_password(action) {
  const {email, password} = action.payload
  try {
    const response = yield call(request, APIEndpoints.ADMIN_PASSWORD_SUBMIT(), {
      method: 'POST',
      payload: {email:email, password:password}
    })
    yield setCookie('read_only_mode', response.status)
    yield setCookie('auth_token_admin', response.access_token)
    yield setCookie('access_token_expiry', response.access_token_expiry)
    yield setCookie('user_details', JSON.stringify(response))
    yield setCookie('role', [response.role[0]])
    yield setCookie('availableRoles', response.role)
    yield setCookie('availableModules', response.modules)
    localStorage.setItem('password', password)
    yield put({
      type: ActionTypes.ADMIN_PASSWORD_SUBMIT_SUCCESS,
      payload: response,
      role: [response.role[0]],
      modules:response.modules
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADMIN_PASSWORD_SUBMIT_FAILURE,
      payload: err.message
    })
  }
}

export function* tradeLoginArroUser(action) {
  try {
    const { displayCarbonCredit } = action.payload
    const response = yield call(request, APIEndpoints.TRADE_LOGIN_ARRO_USER, {
      method: "POST",
      payload:{consent_to_display_carbon_credit_status: displayCarbonCredit},
    });
    yield setCookie("auth_token_trade", response.access_token);
    yield setCookie("access_token_expiry_trade", response.access_token_expiry);
    yield setCookie('role_trade', [response.role[0]])
    yield setCookie("user_details_trade", JSON.stringify(response));
    yield setCookie('availableRoles_trade', [response.role[0]])
    yield setCookie('availableModules_trade', response.modules)
    yield setCookie("type_trade", response.type);
    yield put({
      type: ActionTypes.TRADE_LOGIN_ARRO_USER_SUCCESS,
      payload: response,
      role_trade: [response.role[0]],
      modules_trade:response.modules
    });
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.TRADE_LOGIN_ARRO_USER_FAILURE,
      payload: err.message,
    });
  }
}


export default function* root() {
  yield all([
    takeLatest(ActionTypes.USER_LOGIN, login),
    takeLatest(ActionTypes.GET_FORGOT_PASSWORD_OTP, getForgotPasswordOtp),
    takeLatest(ActionTypes.RESET_PASSWORD, resetPassword),
    takeLatest(ActionTypes.USER_SIGN_UP, userSignUp),
    takeLatest(ActionTypes.REFRESH_TOKEN, refreshToken),
    takeLatest(ActionTypes.USER_LOGOUT, logout),
    takeLatest(ActionTypes.CHANGE_PASSWORD, changePassword),
    takeLatest(ActionTypes.USER_INVITE_LOGIN, userInviteLogin),
    takeLatest(ActionTypes.AUDITOR_SIGN_UP, auditorSignUp),
    takeLatest(ActionTypes.GET_REFRESH_TOKEN, getRefreshToken),
    takeLatest(ActionTypes.SET_ACTIVE_ROLE, setActiveRole),
    takeLatest(ActionTypes.GENERAL_USER_LOGIN, generalLogin),
    takeLatest(ActionTypes.SUPER_ADMIN_TWOFA_VALIDATION, validateTwoFAOtp),
    takeLatest(ActionTypes.RESEND_OTP, resendOtp),
    takeLatest(ActionTypes.CLEAR_OTP, clearOtp),
    takeLatest(ActionTypes.PROXY_SUSTAINABILITY_MANAGER_LOGIN, proxySustainabilityManagerLogin),
    takeLatest(ActionTypes.SWITCH_BACK_TO_SUPER_ADMIN, switchBackToSuperAdmin),
    takeLatest(ActionTypes.ADMIN_TWOFA_OTP_GENERATE, generate_admin_otp),
    takeLatest(ActionTypes.ADMIN_TWOFA_OTP_SUBMIT, submit_admin_otp),
    takeLatest(ActionTypes.ADMIN_PASSWORD_SUBMIT, submit_admin_password),
    takeLatest(ActionTypes.TRADE_LOGIN_ARRO_USER, tradeLoginArroUser),
  ])
}