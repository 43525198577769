import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2,2,8, 5),
    width: "100%",
    borderRadius: 8,
    marginBottom: theme.spacing(3),
  },
  subItemTitle: {
    fontSize: 20,
    fontWeight: 450,
    padding: theme.spacing(4, 0),
  },
  subItemValue: {
    padding: theme.spacing(2, 4, 3, 0),
    fontSize: 20,
    margin: 0,
  },
  consentText: {
    padding: theme.spacing(2, 0, 4, 0),
    margin: 0,
    fontWeight: 500,
    fontSize: 20,
  },
  letterContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(4),
  },
  available: {
    color: "#0088C7",
  },
  checkbox: {
    color: "#8C2148 !important",
    borderWidth: 0.1,
  },
  dialog: {
    width: "40% !important",
    textAlign: "center",
    height: "auto",
    "& .MuiPaper-root.MuiDialog-paper": {
      maxWidth: "none",
      backgroundColor: "white",
    },
  },
  popUp: {
    margin: "auto",
  },
  popUpRedirect: {
    margin: "auto",
    minHeight: 200,
    maxWidth: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainerReview: {
    display: "flex",
    justifyContent: "center",
    gap: 5,
    paddingTop: 20,
    paddingBottom: 15,
    width: "50%",
    margin: "auto",
  },
  buttonSubmitContainer:{
    margin: "auto",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(8, 0),
  },
  buttonSecondary: {
    color: theme.palette.Primary.background,
    borderColor: theme.palette.Primary.background,
    textTransform: "capitalize",
    "&:hover": {
      borderColor: theme.palette.Primary.background,
      backgroundColor: theme.palette.Primary.contrastText,
    },
  },
  buttonPrimary: {
    background: theme.palette.Primary.background,
    textTransform: "capitalize",
    height: 35,
    borderColor: theme.palette.Primary.background,
    marginRight: 15,
    fontWeight: 400,
    color: theme.palette.background.white,
    "&:hover": {
      backgroundColor: theme.palette.Primary.hoverButton,
      borderColor: theme.palette.Primary.hoverButton,
      color: theme.palette.Primary.light,
    },
  },
  headerCell: {
    border: "0.5px solid #5A404E",
  },
  tableBodyCell: {
    border: "0.5px solid #5A404E",
    fontWeight: 550,
  },
  tableTitle: {
    textAlign: "center",
  },
  tableContainer: {
    marginTop: theme.spacing(5),
    border: "1px solid black",
    borderRadius: 8,
    paddingBottom: "0px !important",
    minHeight: "none",
    maxHeight: "none",
    "& .MuiTable-root":{
      paddingBottom: "0px !important",
    }
  },
  gridItem: {
    margin: theme.spacing(5, 0),
  },
  inputGrid:{
    margin: theme.spacing(5, 0),
    paddingBottom: theme.spacing(8),
  },
  sectionTitle:{
    fontSize: 18,
    paddingLeft: theme.spacing(5),
    marginTop: theme.spacing(5),
    textDecoration: "underline"
  },
  mainQuestion:{
    fontSize: 16,
    margin: theme.spacing(3,0,0,0),
  },
  subQuestion:{
    margin: theme.spacing(3,0,0,0),
    fontSize: 14,
    fontWeight: 500,
  },
  textAreaContainer: {
    margin: theme.spacing(8, 0),
    height: "auto",
    "& .css-1ps9hi4-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":{
      WebkitTextFillColor: "black",
    }
  },
  inputContainer:{
    // width: "50%",
    // marginBottom: theme.spacing(8),
    width: "100%",
    marginBottom: theme.spacing(8),
    height: '15px',
    margin: '12px 5px',
    "& .MuiInputBase-root":{
      padding: "0px"
    },
    // "& .MuiFormControl-root > label" :{
    //   marginBottom : '5px'
    // },
    "& .MuiFormControl-root > .MuiOutlinedInput-root > textarea":{
      position: 'relative',
      top: '10px',
      left: '5px'
    }
  },
  selectCheckbox: {
    padding: 0,
    color: "#8C2148",
    borderRadius: "20%",
    margin: theme.spacing( 2 , 5 , 2, 0),
    "& .MuiFormControlLabel-root.MuiFormControlLabel-label.Mui-disabled":{
      color: "black"
    }
  },
  label:{
    "& .MuiFormControlLabel-label.Mui-disabled":{
      color: "black !important",
      fontSize: 14,
    }
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiCircularProgress-root": {
      color: theme.palette.Primary.background,
    },
  },
  upload: {
    margin: theme.spacing(5,0),
  },
  supInfo:{
    display: 'flex',
    // justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  supInfoHalfContent:{
    width:'50%'
  },
  supInfoFullContent:{
    width:'100%'
  },
  addressInner:{
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '10px 25px',
    "& .MuiAutocomplete-root":{
      marginLeft: '5px'
    },
    "& .MuiAutocomplete-root > .MuiFormControl-root":{
      width: '101%'
    },
    "& .MuiInputBase-root":{
      maxHeight : '53px'
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root > .MuiOutlinedInput-notchedOutline" : {
      borderColor: "rgba(0, 0, 0, 0.23)"
    },
    "& .MuiBox-root:hover fieldset": {
        borderColor: theme.palette.Primary.main,
      },
      "&.Mui-focused fieldset": {
        borderWidth: 12,
        borderColor: theme.palette.Primary.main,
      },
      // "& .MuiBox-root":{
      //   margin: '10px 0px 0px 0px',
      // }
  },
  layout : {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  textDiv:{
    display: 'grid',
    gridTemplateColumns: '1fr',
    "& .MuiBox-root > .MuiBox-root > .MuiFormControl-root > .MuiInputBase-root":{
      height : '46px'
    }
  },
  phoneInput:{
    margin : "12px 5px",
    "& .MuiFormControl-root > .MuiInputBase-root > input":{
      padding:"11px 10px"
    }
  },
  inputContainerFreeMargin:{
    width: "100%",
    marginBottom: theme.spacing(8),
    height: '15px',
    margin: '0px 5px',
    "& .MuiInputBase-root":{
      padding: "0px"
    },
    "& .MuiFormControl-root > .MuiOutlinedInput-root > textarea":{
      position: 'relative',
      top: '10px',
      left: '5px'
    }
    // "& .MuiInputBase-root > label" :{
    //   marginBottom : '5px'
    // }
  },
}));

export default useStyles;
