import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(5),
    height: "100%",
    borderRadius: 8,
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    paddingBottom: theme.spacing(5),
    alignItems: "center",
    cursor: "pointer",
  },
  content:{
    margin: "0 auto", 
    
  },
  commonChart:{
    padding: '50px 10px 10px 10px',
    width: "85%", 
    background:"white",
    marginBottom:'10px'
  },
  headings:{
    fontWeight:500,
    marginBottom:'25px'
  },
  commonSuggestion:{
    padding: '50px 10px 10px 10px',
    width: "85%", 
    background:"white",
    marginBottom:'10px'
  },
  mainContentText:{
    fontWeight:500,
    marginBottom:'10px',
    marginTop:'10px'
  },
  tooltip:{
    cursor: "pointer",
      "& .MuiTooltip-tooltip":{
          backgroundColor:` ${theme.palette.Primary.light} !important`,
          border: "1px solid rgb(10, 9, 10)",
          color: "black"
      }
  },
  subtopicTitle:{
    "& .MuiSvgIcon-root":{
        width : '0.5em',
        // height : '2em'
      },
      padding:'10px',
      display:"flex",
      justifyItems:"center"
  },
  infoIcon:{
    fontSize: "larger",
    color: "black",
    cursor: "pointer",
},
suggesionContentText:{
    paddingLeft:'5px'
},
overAllSuggesion:{
    display:"flex",
    alignItems:"center"
},
suggesionContentTextTopic:{
  paddingLeft:'10px'
}
}));

export default useStyles;
