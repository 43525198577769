import {
  IconButton,
  Typography,
  Link as MuiLink,
  CardContent,
  Card,
  Grid,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import useStyles from "./styles";
import CeroPopUp from "../../../components/CeroPopUp";
import BulkValidationTable from "./BulkValidationTable";
import CeroButton from "../../../components/CeroButton";
import ProceedIcon from "../../../assets/icons/ProceedIcon.png";
import WarningIcon from "../../../assets/icons/WarningIcon.png";

const BulkValidationNotification = ({
  open,
  onClose,
  status,
  response,
  warningRecord,
}) => {
  const classes = useStyles();
  const [icon, setIcon] = useState(null);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [showButton, setShowButton] = useState(false);
  const [linkOnClick, setLinkOnClick] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    if (status === "passed") {
      setIcon(<img src={ProceedIcon} alt="Logo" width="40" height="40" />);
      setTitle("Data validation complete");
      setSubtitle("Please proceed to upload the file.");
      setShowButton(true);
      setLinkOnClick(setIsPopupOpen(false));
    } else {
      setIcon(<img src={WarningIcon} alt="Logo" width="40" height="40" />);
      setTitle(!warningRecord ? "Invalid Data" : "Duplicate Data");
      setSubtitle(
        !warningRecord
          ? "This file contains Invalid Data. Please update and upload again."
          : "This file contains duplicate records with your existing data. Please make sure they are relevant. "
      );
      setShowButton(false);
      setIsPopupOpen(false);
    }
  }, [status, onClose]);

  if (response === null) {
    return null;
  }

  const invalidData = response.total_row - response.total_validated || "0";
  const tableData = response.emission_add_data_frame || "";
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const getvalidationSummary = () => {
    return (
      <>
        <CloseIcon onClick={handleClosePopup} className={classes.closeIcon} />
        <div className={classes.underline}></div>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card className={classes.card}>
              <CardContent>
                <Typography variant="h6" className={classes.boxTitle}>
                  Total Records
                </Typography>
                <div className={classes.numberBox}>
                  <Typography variant="h5" className={classes.boxValue}>
                    {response.total_row || "0"}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card className={classes.card}>
              <CardContent style={{ color: "green" }}>
                <Typography variant="h6" className={classes.boxTitle}>
                  Valid Records
                </Typography>
                <div className={classes.numberBox}>
                  <Typography variant="h5" className={classes.boxValue}>
                    {response.total_validated || "0"}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card className={classes.card}>
              <CardContent style={{ color: "red" }}>
                <Typography variant="h6" className={classes.boxTitle}>
                  Invalid Records
                </Typography>
                <div className={classes.numberBox}>
                  <Typography variant="h5" className={classes.boxValue}>
                    {invalidData}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <div className={classes.tableTitle}>
          {!warningRecord
            ? "Update these records and upload again"
            : "Continue upload if these records are valid else remove them"}
        </div>
        <BulkValidationTable data={tableData} />
        <CeroButton
          buttonText="Ok"
          variant="outlined"
          className={classes.buttonPrimary}
          onClick={handleClosePopup}
        />
      </>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} className={classes.root}>
      <DialogTitle className={classes.DialogTitle}>
        <Grid container alignItems="center">
          <Grid item className={classes.icon}>
            {icon}
          </Grid>
          <Grid item xs>
            <Typography variant="h6">{title}</Typography>
            <Typography className={classes.DialogSubtitle}>
              {subtitle}
            </Typography>
            {status === "failed" && (
              <MuiLink
                component="button"
                onClick={handleOpenPopup}
                className={classes.Link}
              >
                Click here to know more
              </MuiLink>
            )}
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {showButton ? (
          <CeroButton
            buttonText="Ok"
            variant="outlined"
            className={classes.buttonPrimary}
            onClick={onClose}
          />
        ) : (
          ""
        )}
      </DialogContent>
      <CeroPopUp
        primaryPopUp={{
          open: isPopupOpen,
          onClose: () => setIsPopupOpen(false),
          content: getvalidationSummary(),
          header: { title: "Validation Summary" },
        }}
      />
    </Dialog>
  );
};

export default BulkValidationNotification;
