import { useEffect, useState, useCallback } from "react";
import {
  Container,
  Box,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router";
import CeroButton from "../../../../components/CeroButton";
import ListTicketDrawer from "../../../common/ListTicketsDrawer";
import useStyles from "./styles";
import CeroPopUp from "../../../../components/CeroPopUp";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { getAuditTrails } from "../../../../redux/actions/audit";
import AuditTrailTable from "./AuditTrailTable";
import TicketIcon from "../../../../assets/icons/TicketIcon.png"
import { APIEndpoints, STATUS } from "../../../../redux/constants";
import { generateAuditedReport } from "../../../../redux/actions";
import axios from "axios";
import { getCookie } from "../../../../services/cookie";

const Header = ({
  auditId,
  onApplyFilter,
  selectedYear,
  isApproveAuditVisible,
  onApproveAudit,
  onRaiseAuditTicket,
  isLoading,
  companyId,
  data,
  performAudit,
  isAudited,
  country,
  carbonOffsetList
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filterYear, setYear] = useState(selectedYear);
  const [showTickets, setShowTickets] = useState(false);
  const [comment, setComment] = useState("");
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState(false);
  const [isAuditTrailPopupOpen, setIsAuditTrailPopupOpen] = useState(false);

  const auditTrails = useSelector((state) => state.audit.auditTrails.data);
  const auditStatus = useSelector((state) => state.audit.auditTrails.status);

  const auditTrailStatus = auditStatus === STATUS.RUNNING;

  const authToken = getCookie("auth_token_admin");
  const onDownloadAuditedReport = async (event) => {
    event.preventDefault();
    
    const payload = {
      year:selectedYear,
      company:companyId,
      auditId:auditId
    }
    try {
      const response = await axios.post(
        APIEndpoints.DOWNLOAD_AUDITED_REPORT(payload),{},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const url = response?.data?.response.blob_url;
      const fileName = 'audited-report.pdf';
      const link = document.createElement("a");
      link.setAttribute('download', fileName);
      link.href = url;
      link.click();
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (companyId && auditId) {
      dispatch(getAuditTrails({ companyId, auditId }));
    }
    setYear(selectedYear);
  }, [dispatch, companyId, auditId]);

  useEffect(() => {
    setYear(selectedYear);
  }, [dispatch, selectedYear]);

  const onclickShowTickets = () => {
    setShowTickets(true);
  };

  const onCloseTickets = () => {
    setShowTickets(false);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSaveComment = () => {
    approveAudit(comment); 
    setComment("");
    handleCloseApprovePopup();
    setTimeout(() => {
    window.location.reload();
    }, 1000); 
    const payload = {
      company:companyId,
      year:selectedYear,
      data:carbonOffsetList.length > 0 ? [...data, ...carbonOffsetList] : data,
      auditId:auditId

    }
    dispatch(generateAuditedReport(payload))

  };

  const onClose = useCallback(() => {
    onClose();
  }, []);

  const handleCloseApprovePopup = () => {
    setIsApprovePopupOpen(false);
    setComment("");
  };

  const handleApproveButtonClick = () => {
    setIsApprovePopupOpen(true);
  };

  const handleAuditTrailButtonClick = () => {
    setIsAuditTrailPopupOpen(true);
  };

  const handleCloseAuditTrailPopup = () => {
    setIsAuditTrailPopupOpen(false);
  };

  const approveAudit = (comment) => {
    onApproveAudit(comment);
  };

  const getApproveAuditPopUp = () => {
    return (
      <>
        <TextField
          id="outlined-multiline-static"
          multiline
          rows={5}
          placeholder="Comment"
          style={{ width: "100%" }}
          value={comment}
          onChange={handleCommentChange}
          className={classes.textField}
        />
        <div className={classes.textLimit}>
          <div className={classes.noComments}>
            {comment.trim().length === 0 ? "No comments" : ""}
          </div>
          <div className={classes.maxCharacters}>
            {"Maximum 200 characters"}
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.buttonPrimary}
            variant="contained"
            onClick={handleSaveComment}
          >
            Save
          </Button>
          <Button
            className={classes.buttonSecondary}
            variant="outlined"
            onClick={handleCloseApprovePopup}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  };

  const getAuditTrailPopUp = () => {
    return (
      <>
        <CloseIcon onClick={handleCloseAuditTrailPopup} className={classes.closeIcon}/>
        <div className={classes.underline}></div>
        <AuditTrailTable auditTrailsData={auditTrails} company={companyId} status={auditId}/>
      </>
    );
  };

return (
    <Container className={classes.headerContainer}>
      {/* <Box className={classes.yearContainer}>
        <CeroDropdown
          classes={{ container: classes.dropdown }}
          id="year"
          label="Year"
          fullWidth
          options={sampleYear()}
          onChange={onChangeYear}
          selectedValue={parseInt(filterYear)}
        />
      </Box> */}
       {/* {isSustainabilityManager && ( */}
                    {/* <CeroButton
                      buttonText="View Certificate"
                      className={classes.buttonPrimary}
                      // onClick={openModal}
                      // disabled={readOnlyMode}
                    /> */}
                  {/* )} */}
      <CeroButton
        buttonText={"Qualitative data"}
        className={classes.buttonPrimary}
        variant="contained"
        onClick={() => navigate(`qualitative-data`)}
      />
      <Box className={classes.ticketContainer}>
        {isApproveAuditVisible ? (
          <>
          <CeroButton
            buttonText={isLoading ? "Loading..." : "Approve Audit"}
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={handleApproveButtonClick}
            disabled={!performAudit}
          />
            <CeroButton
              buttonText={"Raise a ticket"}
              className={classes.buttonPrimary}
              onClick={onRaiseAuditTicket}
              variant="contained"
            />
            <IconButton onClick={onclickShowTickets}>
            <img src={TicketIcon} alt="Logo" width="25" height="25"/>
            </IconButton>
          </>
        ) : (
          <>
            {isAudited && <CeroButton
              buttonText={"Download report"}
              className={classes.buttonPrimary}
              variant="contained"
              onClick={onDownloadAuditedReport}
            />}
          <CeroButton
            buttonText={auditTrailStatus ? "Loading..." : "Audit Trail"}
            className={classes.buttonPrimary}
            onClick={handleAuditTrailButtonClick}
            variant="contained"
            disabled={auditTrailStatus}
          />
          </>
        )}
      </Box>
      <CeroPopUp
        primaryPopUp={{
          open: isApprovePopupOpen,
          onClose: { handleCloseApprovePopup },
          content: getApproveAuditPopUp(),
          header: { title: "Approve Audit" },
        }}
        classes={{ dialog: classes.dialog,
        }}
      />
      <CeroPopUp
        primaryPopUp={{
          open: isAuditTrailPopupOpen,
          onClose: { handleAuditTrailButtonClick },
          content: getAuditTrailPopUp(),
          header: { title: "Audit Trail" },
        }}   
        classes={{ dialog: classes.dialog,
        }}
      />
      {showTickets && (
        <ListTicketDrawer
          isOpen={showTickets}
          scope="audit"
          scopeId={auditId}
          company={companyId}
          onClose={onCloseTickets}
        />
      )}
    </Container>
  );
};

export default Header;
function enqueueSnackbar(message, arg1) {
  throw new Error("Function not implemented.");
}

