import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Slider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CeroAutoComplete from "../../../../../components/CeroAutoComplete";
import CeroTable from "../../../../../components/CeroTable";
import { STATUS } from "../../../../../redux/constants";
import CeroInput from "../../../../../components/CeroInput";
import {
  getMaterialityIndustries,
  getSurvey,
  surveyQuestions,
  surveyResponse,
} from "../../../../../redux/actions";
import DashboardLayout from "../../../../../layouts/DashboardLayout";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const ViewSurvey = () => {
  const classes = useStyles();
  const { id, answerId } = useParams();

  const [selectedValues, setSelectedValues] = useState({});
  const [textAnswers, setTextAnswers] = useState({});
  const [tableData, setTableData] = useState({});
  const [topicAnswers, setTopicAnswers] = useState({});
  const [slideAnswers, setSlideAnswers] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const materialityQuestionsList = useSelector((state) => {
    const data = state.materiality.surveyQuestions.data;
    return Array.isArray(data) ? data : [];
  });

  function toRoman(num) {
    const romanMap = {
      1: "I",
      2: "II",
      3: "III",
      4: "IV",
      5: "V",
      6: "VI",
      7: "VII",
      8: "VIII",
      9: "IX",
      10: "X",
      11: "XI",
      12: "XII",
      13: "XIII",
      14: "XIV",
      15: "XV",
    };

    return romanMap[num] || "Invalid number";
  }

  const questionDict = {};

  materialityQuestionsList.forEach(
    ({
      section,
      sub_section,
      question,
      category,
      question_type,
      options,
      id,
    }) => {
      // Ensure the section exists
      if (!questionDict[section]) {
        questionDict[section] = {};
      }

      // Ensure the sub_section exists
      if (!questionDict[section][sub_section]) {
        questionDict[section][sub_section] = [];
      }

      // Add the question to the appropriate sub_section
      questionDict[section][sub_section].push({
        question,
        category,
        question_type,
        options,
        id,
      });
    }
  );

  var qtnIndex = 0;
  console.log("Niks", questionDict);

  const surveyData = useSelector(
    (state) => state.materiality.surveyResponse.data
  );
  const companyData = useSelector((state) => state.materiality.getSurvey.data);
  const surveyStatus = useSelector(
    (state) => state.materiality.surveyQuestions
  );
  const companyName = useSelector(
    (state) => state.materiality.getSurveyQuestions.data.company_name
  );
  const industryData =
    useSelector((state) => state.materiality.getMaterialityIndustries.data) ||
    [];
  const tableColums = [
    {
      columnKey: "topic",
      columnId: "topic",
      columnHeader: "Topic",
      width: "35vw",
    },
    {
      columnKey: "relevance",
      columnId: "relevance",
      columnHeader: `The relevance of the topic`,
      width: "35vw",
    },
    {
      columnKey: "impact",
      columnId: "impact",
      columnHeader: `Impact/Influence of this topic`,
      width: "35vw",
    },
  ];

  useEffect(() => {
    dispatch(getMaterialityIndustries());
    dispatch(getSurvey());
    if (answerId) {
      dispatch(surveyResponse(answerId));
    }
    if (id) {
      dispatch(surveyQuestions(id));
    }
  }, [dispatch, answerId, id]);

  const industryList = Array.isArray(industryData)
    ? industryData.map((item) => ({
        id: item.id,
        label: item.industry_type,
      }))
    : [];

  useEffect(() => {
    if (surveyData && surveyData.answer) {
      const answers = surveyData.answer;
      const selected = {};
      const text = {};
      const table = {};
      const topic = {};
      const sliderAns = {};

      answers.forEach((item) => {
        const { question_id, answer, question_type, answer_value } = item;
        setAnsweredQuestions((prev) => {
          return [...prev, question_id]; // Create a new array with the previous values and the new question_id
        });
        switch (question_type) {
          case "choose_from_option":
            selected[question_id] = answer;
            if (answer === "Other (please specify):") {
              text[`other-${question_id}`] = answer_value || "";
            }
            break;
          case "text":
            text[question_id] = answer;
            break;
          case "table":
            table[question_id] = answer;
            break;
          case "choose_from_topic":
            topic[question_id] = answer;
            if (answer.includes("Other (please specify):")) {
              text[`other-${question_id}`] = answer_value || "";
            }
            break;
          case "slider":
            sliderAns[question_id] = answer;
            break;
          default:
            break;
        }
      });

      setSelectedValues(selected);
      setTextAnswers(text);
      setTableData(table);
      setTopicAnswers(topic);
      console.log("hr");

      setSlideAnswers(sliderAns);
    }
  }, [surveyData]);
  const getTabledata = (question) =>
    question?.options?.map((item) => {
      const relevanceValue = tableData[question.id]?.[item]?.relevance || "";
      const impactValue = tableData[question.id]?.[item]?.impact || "";
      return {
        ...item,
        topic: item,
        relevance: <div className={classes.editBox}>{relevanceValue}</div>,
        impact: <div className={classes.editBox}>{impactValue}</div>,
      };
    });

  let surveyIndustry =
    Array.isArray(industryData) &&
    industryData.filter((item) => item.id === companyData?.industry_type);

  return (
    <DashboardLayout>
      <Container className={classes.mainContainer}>
        <div
          className={classes.backContainer}
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIosIcon />
          Back
        </div>
        {surveyStatus?.status === STATUS.SUCCESS ? (
          <>
            <Typography variant="h4" className={classes.companyName}>
              {companyData?.company_name}
            </Typography>
            <Grid item xs={3} mr={3} className={classes.yearFilter}>
              <CeroAutoComplete
                classes={{
                  container: classes.autoComplete,
                  root: classes.textField,
                }}
                id="industry"
                label="Type of Industry"
                fullWidth
                value={surveyIndustry[0]?.industry_type}
                options={industryList || []}
                disabled={surveyData?.industry_type !== "None"}
              />
            </Grid>
            <Paper className={classes.container}>
              <div className={classes.paperTop}>
                <Typography variant="h6" className={classes.title}>
                  MATERIALITY SURVEY
                </Typography>
                <Typography variant="body" className={classes.completed}>
                  <span style={{ color: "green" }}>Completed by</span>:{" "}
                  {surveyData?.name}
                </Typography>
              </div>
              <div className={classes.questionList}>
                <Typography
                  fontSize={20}
                  fontWeight={550}
                  variant="h3"
                  paddingTop="10px"
                >
                  {`SECTION 1 : BACKGROUND INFORMATION`}
                </Typography>
                {questionDict["Background Information"] &&
                  Object.entries(questionDict["Background Information"]).map(
                    ([key, questions]) =>
                      questions.map((question, index) => {
                        qtnIndex += 1;
                        return (
                          <Grid
                            key={question.id}
                            className={
                              question.question_type === "text" ||
                              question.question_type === "Text Field"
                                ? classes.inputGrid
                                : selectedValues[question.id] ===
                                    "Other (please specify):" ||
                                  topicAnswers[question.id]?.includes(
                                    "Other (please specify):"
                                  )
                                ? classes.otherinput
                                : question.question_type === "slider"
                                ? classes.sliderQuestionMain
                                : classes.gridItem
                            }
                          >
                            <div className={classes.sliderQuestion}>
                              <Typography
                                fontSize={18}
                                fontWeight={500}
                                variant="h3"
                                paddingLeft={4}
                                paddingBottom={2}
                              >
                                {qtnIndex}. {question.question}
                              </Typography>
                            </div>
                            <Box
                              display="flex"
                              paddingLeft={6}
                              flexWrap="wrap"
                              className={
                                question.question_type === "slider"
                                  ? classes.sliderDiv
                                  : classes.default
                              }
                            >
                              {(question.question_type ===
                                "choose_from_option" ||
                                question.question_type ===
                                  "Choose from Options") &&
                                question.options.map((option, idx) => (
                                  <>
                                    <FormControlLabel
                                      key={idx}
                                      control={
                                        <Checkbox
                                          className={classes.selectCheck}
                                          checked={
                                            selectedValues[question.id] ===
                                            option
                                          }
                                          value={option}
                                          sx={{
                                            color: "#5A404E !important",
                                            "&.Mui-checked": {
                                              color: "#8C2148 !important",
                                            },
                                          }}
                                          disabled
                                        />
                                      }
                                      label={option}
                                      className={classes.label}
                                    />
                                    {selectedValues[question.id] ===
                                      "Other (please specify):" &&
                                      option === "Other (please specify):" && (
                                        <Box
                                          sx={{
                                            width: "100%",
                                            flexBasis: "100%",
                                            display: "block",
                                            mt: 2,
                                          }}
                                        >
                                          <CeroInput
                                            classes={{
                                              input: classes.textAreaContainer,
                                            }}
                                            rows={3}
                                            placeholder={"Please specify"}
                                            id={`other-${question.id}`}
                                            name={`other-${question.id}`}
                                            label="Please specify"
                                            multiline
                                            value={
                                              textAnswers[
                                                `other-${question.id}`
                                              ] || ""
                                            }
                                            disabled
                                          />
                                        </Box>
                                      )}
                                  </>
                                ))}
                              {question.question_type === "choose_from_topic" &&
                                question.options.map((option, idx) => (
                                  <>
                                    <FormControlLabel
                                      key={idx}
                                      control={
                                        <Checkbox
                                          className={classes.selectCheckbox}
                                          checked={
                                            topicAnswers[question.id]?.includes(
                                              option
                                            ) || false
                                          }
                                          value={option}
                                          sx={{
                                            color: "#5A404E !important",
                                            "&.Mui-checked": {
                                              color: "#8C2148 !important",
                                            },
                                          }}
                                        />
                                      }
                                      label={option}
                                    />
                                    {topicAnswers[question.id]?.includes(
                                      "Other (please specify):"
                                    ) &&
                                      option === "Other (please specify):" && (
                                        <Box
                                          sx={{
                                            width: "100%",
                                            flexBasis: "100%",
                                            display: "block",
                                            mt: 2,
                                          }}
                                        >
                                          <CeroInput
                                            classes={{
                                              input: classes.textAreaContainer,
                                            }}
                                            rows={3}
                                            placeholder={
                                              "Please type your Answer"
                                            }
                                            id={`other-${question.id}`}
                                            name={`other-${question.id}`}
                                            label="Please specify"
                                            multiline
                                            value={
                                              textAnswers[
                                                `other-${question.id}`
                                              ] || ""
                                            }
                                            disabled
                                          />
                                        </Box>
                                      )}
                                  </>
                                ))}
                              {(question.question_type === "text" ||
                                question.question_type === "Text Field") && (
                                <Box minHeight={80} sx={{ width: "80vw" }}>
                                  <CeroInput
                                    classes={{
                                      input: classes.textAreaContainer,
                                    }}
                                    rows={3}
                                    placeholder={"Please type your Answer"}
                                    id={`answer-${question.id}`}
                                    name={`answer-${question.id}`}
                                    label="Answer"
                                    multiline
                                    value={textAnswers[question.id] || ""}
                                    disabled
                                  />
                                </Box>
                              )}
                              {question.question_type === "table" && (
                                <Grid>
                                  <Typography
                                    fontSize={18}
                                    fontWeight={500}
                                    variant="h3"
                                    paddingLeft={4}
                                    paddingY={3}
                                  >
                                    Please rate the importance of the following
                                    topics for you in two main areas:
                                  </Typography>
                                  <Typography
                                    paddingLeft={6}
                                    paddingBottom={2}
                                    mt={3}
                                  >
                                    a) The relevance of the topic for you as a{" "}
                                    {companyData.company_name} stakeholder
                                  </Typography>
                                  <Typography
                                    paddingLeft={6}
                                    paddingBottom={2}
                                    mb={3}
                                  >
                                    b) The influence of{" "}
                                    {companyData.company_name} on this topic
                                  </Typography>
                                  <Typography paddingLeft={4} paddingY={3}>
                                    This will allow us to identify material
                                    topics relevant to our activities and
                                    disclose information about our activities
                                    within{"\n"} these topics.Please use a scale
                                    from 0 to 10
                                  </Typography>
                                  <Typography paddingLeft={4} paddingBottom={2}>
                                    (0 - the topic is not important at all; 10 -
                                    the topic is extremely important).
                                  </Typography>
                                  <CeroTable
                                    columns={tableColums}
                                    data={getTabledata(question)}
                                    hasMore={false}
                                    loading={false}
                                    classes={{
                                      headerCell: classes.headerCell,
                                      tableBodyCell: classes.tableBodyCell,
                                      tableHead: classes.tableTitle,
                                      tableContainer: classes.tableContainer,
                                    }}
                                  />
                                </Grid>
                              )}
                              {question.question_type === "slider" && (
                                <Slider
                                  size="medium"
                                  defaultValue={slideAnswers?.[question.id]}
                                  aria-label="Small"
                                  valueLabelDisplay="auto"
                                  shiftStep={1}
                                  step={1}
                                  min={1}
                                  max={5}
                                  className={classes.slider}
                                />
                              )}
                            </Box>
                          </Grid>
                        );
                      })
                  )}

                {Object.keys(questionDict).map(
                  (section, sectionIndex) =>
                    section !== "Background Information" &&
                    section != "null" && (
                      <>
                        <div className={classes.scoreDiv}>
                          <Typography
                            fontSize={20}
                            fontWeight={550}
                            variant="h3"
                            paddingTop="10px"
                          >
                            {`Section ${sectionIndex} : ${section}`.toLocaleUpperCase()}
                          </Typography>
                          <div
                            className={classes.scoreHeadDiv}
                            style={{
                              display: sectionIndex == 1 ? "block" : "None",
                            }}
                          >
                            <Typography
                              fontSize={20}
                              fontWeight={550}
                              variant="h3"
                              paddingTop="10px"
                            >
                              {`Score`}
                            </Typography>
                          </div>
                        </div>
                        {Object.keys(questionDict[section]).map(
                          (subSection, subIndex) => (
                            <>
                              <div className={classes.subScoreDiv}>
                                <Typography
                                  fontSize={19}
                                  fontWeight={450}
                                  variant="h4"
                                  paddingTop="10px"
                                >
                                  {`${toRoman(subIndex + 1)}. ${subSection}`}
                                </Typography>
                                <div
                                  className={classes.scoreHeadDiv}
                                  style={{
                                    display:
                                      sectionIndex == 1 && subIndex == 0
                                        ? "block"
                                        : "None",
                                  }}
                                >
                                  <div className={classes.scoreDiv}>
                                    {["Low", "Medium", "high"].map((item) => (
                                      <Typography
                                        fontSize={18}
                                        fontWeight={450}
                                        variant="h4"
                                        paddingTop="10px"
                                      >
                                        {item}
                                      </Typography>
                                    ))}
                                  </div>
                                  <div className={classes.scoreDiv}>
                                    {[1, 2, 3, 4, 5].map((item) => (
                                      <Typography
                                        fontSize={18}
                                        fontWeight={450}
                                        variant="h4"
                                        paddingTop="10px"
                                      >
                                        {item}
                                      </Typography>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              {questionDict[section][subSection].map(
                                (question, index) => {
                                  qtnIndex += 1;
                                  return (
                                    <Grid
                                      key={question.id}
                                      className={
                                        question.question_type === "text" ||
                                        question.question_type === "Text Field"
                                          ? classes.inputGrid
                                          : selectedValues[question.id] ===
                                              "Other (please specify):" ||
                                            topicAnswers[question.id]?.includes(
                                              "Other (please specify):"
                                            )
                                          ? classes.otherinput
                                          : question.question_type === "slider"
                                          ? classes.sliderQuestionMain
                                          : classes.gridItem
                                      }
                                    >
                                      <div className={classes.sliderQuestion}>
                                        <Typography
                                          fontSize={18}
                                          fontWeight={500}
                                          variant="h3"
                                          paddingLeft={4}
                                          paddingBottom={2}
                                        >
                                          {qtnIndex}. {question.question}
                                        </Typography>
                                      </div>
                                      <Box
                                        display="flex"
                                        paddingLeft={6}
                                        flexWrap="wrap"
                                        className={
                                          question.question_type === "slider"
                                            ? classes.sliderDiv
                                            : classes.default
                                        }
                                      >
                                        {(question.question_type ===
                                          "choose_from_option" ||
                                          question.question_type ===
                                            "Choose from Options") &&
                                          question.options.map(
                                            (option, idx) => (
                                              <>
                                                <FormControlLabel
                                                  key={idx}
                                                  control={
                                                    <Checkbox
                                                      className={
                                                        classes.selectCheck
                                                      }
                                                      checked={
                                                        selectedValues[
                                                          question.id
                                                        ] === option
                                                      }
                                                      value={option}
                                                      sx={{
                                                        color:
                                                          "#5A404E !important",
                                                        "&.Mui-checked": {
                                                          color:
                                                            "#8C2148 !important",
                                                        },
                                                      }}
                                                      disabled
                                                    />
                                                  }
                                                  label={option}
                                                  className={classes.label}
                                                />
                                                {selectedValues[question.id] ===
                                                  "Other (please specify):" &&
                                                  option ===
                                                    "Other (please specify):" && (
                                                    <Box
                                                      sx={{
                                                        width: "100%",
                                                        flexBasis: "100%",
                                                        display: "block",
                                                        mt: 2,
                                                      }}
                                                    >
                                                      <CeroInput
                                                        classes={{
                                                          input:
                                                            classes.textAreaContainer,
                                                        }}
                                                        rows={3}
                                                        placeholder={
                                                          "Please specify"
                                                        }
                                                        id={`other-${question.id}`}
                                                        name={`other-${question.id}`}
                                                        label="Please specify"
                                                        multiline
                                                        value={
                                                          textAnswers[
                                                            `other-${question.id}`
                                                          ] || ""
                                                        }
                                                        disabled
                                                      />
                                                    </Box>
                                                  )}
                                              </>
                                            )
                                          )}
                                        {question.question_type ===
                                          "choose_from_topic" &&
                                          question.options.map(
                                            (option, idx) => (
                                              <>
                                                <FormControlLabel
                                                  key={idx}
                                                  control={
                                                    <Checkbox
                                                      className={
                                                        classes.selectCheckbox
                                                      }
                                                      checked={
                                                        topicAnswers[
                                                          question.id
                                                        ]?.includes(option) ||
                                                        false
                                                      }
                                                      value={option}
                                                      sx={{
                                                        color:
                                                          "#5A404E !important",
                                                        "&.Mui-checked": {
                                                          color:
                                                            "#8C2148 !important",
                                                        },
                                                      }}
                                                    />
                                                  }
                                                  label={option}
                                                />
                                                {topicAnswers[
                                                  question.id
                                                ]?.includes(
                                                  "Other (please specify):"
                                                ) &&
                                                  option ===
                                                    "Other (please specify):" && (
                                                    <Box
                                                      sx={{
                                                        width: "100%",
                                                        flexBasis: "100%",
                                                        display: "block",
                                                        mt: 2,
                                                      }}
                                                    >
                                                      <CeroInput
                                                        classes={{
                                                          input:
                                                            classes.textAreaContainer,
                                                        }}
                                                        rows={3}
                                                        placeholder={
                                                          "Please type your Answer"
                                                        }
                                                        id={`other-${question.id}`}
                                                        name={`other-${question.id}`}
                                                        label="Please specify"
                                                        multiline
                                                        value={
                                                          textAnswers[
                                                            `other-${question.id}`
                                                          ] || ""
                                                        }
                                                        disabled
                                                      />
                                                    </Box>
                                                  )}
                                              </>
                                            )
                                          )}
                                        {(question.question_type === "text" ||
                                          question.question_type ===
                                            "Text Field") && (
                                          <Box
                                            minHeight={80}
                                            sx={{ width: "80vw" }}
                                          >
                                            <CeroInput
                                              classes={{
                                                input:
                                                  classes.textAreaContainer,
                                              }}
                                              rows={3}
                                              placeholder={
                                                "Please type your Answer"
                                              }
                                              id={`answer-${question.id}`}
                                              name={`answer-${question.id}`}
                                              label="Answer"
                                              multiline
                                              value={
                                                textAnswers[question.id] || ""
                                              }
                                              disabled
                                            />
                                          </Box>
                                        )}
                                        {question.question_type === "table" && (
                                          <Grid>
                                            <Typography
                                              fontSize={18}
                                              fontWeight={500}
                                              variant="h3"
                                              paddingLeft={4}
                                              paddingY={3}
                                            >
                                              Please rate the importance of the
                                              following topics for you in two
                                              main areas:
                                            </Typography>
                                            <Typography
                                              paddingLeft={6}
                                              paddingBottom={2}
                                              mt={3}
                                            >
                                              a) The relevance of the topic for
                                              you as a{" "}
                                              {companyData.company_name}{" "}
                                              stakeholder
                                            </Typography>
                                            <Typography
                                              paddingLeft={6}
                                              paddingBottom={2}
                                              mb={3}
                                            >
                                              b) The influence of{" "}
                                              {companyData.company_name} on this
                                              topic
                                            </Typography>
                                            <Typography
                                              paddingLeft={4}
                                              paddingY={3}
                                            >
                                              This will allow us to identify
                                              material topics relevant to our
                                              activities and disclose
                                              information about our activities
                                              within{"\n"} these topics.Please
                                              use a scale from 0 to 10
                                            </Typography>
                                            <Typography
                                              paddingLeft={4}
                                              paddingBottom={2}
                                            >
                                              (0 - the topic is not important at
                                              all; 10 - the topic is extremely
                                              important).
                                            </Typography>
                                            <CeroTable
                                              columns={tableColums}
                                              data={getTabledata(question)}
                                              hasMore={false}
                                              loading={false}
                                              classes={{
                                                headerCell: classes.headerCell,
                                                tableBodyCell:
                                                  classes.tableBodyCell,
                                                tableHead: classes.tableTitle,
                                                tableContainer:
                                                  classes.tableContainer,
                                              }}
                                            />
                                          </Grid>
                                        )}
                                        {question.question_type ===
                                          "slider" && (
                                          <Slider
                                            size="medium"
                                            defaultValue={
                                              slideAnswers?.[question.id]
                                            }
                                            aria-label="Small"
                                            valueLabelDisplay="auto"
                                            shiftStep={1}
                                            step={1}
                                            min={1}
                                            max={5}
                                            className={classes.slider}
                                          />
                                        )}
                                      </Box>
                                    </Grid>
                                  );
                                }
                              )}
                            </>
                          )
                        )}
                      </>
                    )
                )}
              </div>
            </Paper>
          </>
        ) : surveyStatus?.status === STATUS.RUNNING ? (
          <div className={classes.loader}>
            <CircularProgress className={classes.icon} />
          </div>
        ) : (
          <Typography variant="h6" className={classes.tableTitle}>
            {surveyStatus?.message}
          </Typography>
        )}
      </Container>
    </DashboardLayout>
  );
};

export default ViewSurvey;
