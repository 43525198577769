import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants/actions';

export const {
    listAssessmentCycle,
    getAssessmentDetails,
    createAssessmentCycle,
    updateAssessmentCycle,
    resetAssessmentCycle,
    getAssessmentPeriod,
    saveStandardsQuestionnaire,
    getStandardsQuestionnaireHistory,
    viewStandardsQuestionnaireAnswer,
    submitStandardsQuestionnaire,
    getStandardsQuestionnaireAnswers,
    approveStandardsQuestionnaire,
    resetSaveStandardsQuestionnaire,
    resetSubmitStandardsQuestionnaire,
    resetApproveStandardsQuestionnaire,
    resetStandardsQuestionnaireAnswers,
    listAssessmentCycleCountry,
} = createActions({
    [ActionTypes.LIST_ASSESSMENT_CYCLE]: () => {},
    [ActionTypes.GET_ASSESSMENT_DETAILS]: (payload) => (payload),
    [ActionTypes.CREATE_ASSESSMENT_CYCLE]: (payload) => (payload),
    [ActionTypes.UPDATE_ASSESSMENT_CYCLE]: (payload) => (payload),
    [ActionTypes.GET_ASSESSMENT_PERIOD]: (year, facility, emissionType, emissionId) => ({year, facility, emissionType, emissionId}),
    [ActionTypes.RESET_ASSESSMENT_CYCLE]: () => {},
    [ActionTypes.SAVE_STANDARDS_QUESTIONNAIRE]: (requestData) => (requestData),
    [ActionTypes.GET_STANDARDS_QUESTIONNAIRE_HISTORY]: () => ({}),
    [ActionTypes.VIEW_STANDARDS_QUESTIONNAIRE_ANSWER]: (payload) => ({payload}),
    [ActionTypes.SUBMIT_STANDARDS_QUESTIONNAIRE]: (requestData) => (requestData),
    [ActionTypes.GET_STANDARDS_QUESTIONNAIRE_ANSWERS]: (payloadData) => (payloadData),
    [ActionTypes.APPROVE_STANDARDS_QUESTIONNAIRE]: (questionnaireId) => (questionnaireId),
    [ActionTypes.RESET_SAVE_STANDARDS_QUESTIONNAIRE]: () => {},
    [ActionTypes.RESET_SUBMIT_STANDARDS_QUESTIONNAIRE]: () => {},
    [ActionTypes.RESET_APPROVE_STANDARDS_QUESTIONNAIRE]: () => {},
    [ActionTypes.RESET_STANDARDS_QUESTIONNAIRE_ANSWERS]: () => {},
    [ActionTypes.LIST_ASSESSMENT_CYCLE_COUNTRY]: (country) => ({country}),
})
