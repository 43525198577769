import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import { APIEndpoints, STATUS } from "../../../redux/constants";
import { schemeValidation } from "./schema";
import {
  addEmployeeHealthDetails,
  getAssessmentPeriod,
  resetAddCombustionStatus,
} from "../../../redux/actions";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import { sampleYear } from "../../../constants";
import CeroDate from "../../../components/CeroDate";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import axios from "axios";
import { getCookie } from "../../../services/cookie";
import FileUpload from "../FileUpload";

const AddEmployeesHealthForm = (props) => {
  const { onCancel } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [getImageFile, setImageFile] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);

  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const addEmissionData = useSelector(
    (state) => state.emission.addEmployeeHealth
  );

  const currentYear = new Date().getFullYear().toString();

  const formik = useFormik({
    initialValues: {
      facility: "",
      date: "",
      assessmentPeriod: "",
      year: currentYear,
      typeOfIncident: "",
      severtiyOfIncident: "",
      impactOnWork: "",
      medicalTreatment: "",
      noOfAffected: "",
      correctiveAction: "",
      details: "",
    },
    validationSchema: schemeValidation,
    onSubmit: () => {},
  });

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));

  useEffect(() => {
    if (addEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar(
        "Employees Health and Safety incident details added successfully",
        { variant: "success" }
      );
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        addEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [addEmissionData, dispatch, enqueueSnackbar, onCancel]);

  const onAddEmployeeHealthDetails = () => {
    const requestData = {
      facility_id: formik.values.facility,
      date: dayjs(formik.values.date).format("DD/MM/YYYY"),
      assessment_period: formik.values.assessmentPeriod,
      year: formik.values.year,
      type_of_incident: formik.values.typeOfIncident,
      severity_of_incident: formik.values.severtiyOfIncident,
      impact_on_work: formik.values.impactOnWork,
      medical_treatment_provided: formik.values.medicalTreatment,
      number_of_employees_affected: formik.values.noOfAffected,
      details: formik.values.details,
      corrective_action: formik.values.correctiveAction,
      attachment: getImageFile,
      file: selectedFile,
      save: true,
    };
    sessionStorage.setItem("year", requestData.year);
    dispatch(addEmployeeHealthDetails(requestData));
  };

  useEffect(() => {
    const { facility, year } = formik.values;
    if (facility && year) {
      dispatch(getAssessmentPeriod(year, facility));
    }
  }, [formik.values, dispatch]);

  const assessmentData = useSelector(
    (state) => state.assessment.assessmentPeriod.data
  );

  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));

  const assessmentOptions = Array.isArray(assessmentData)
    ? assessmentData.map((item) => ({
        key: item.period,
        value: item.period,
      }))
    : [];

  const impactTypes = [
    { key: "Lost Time", value: "Lost Time" },
    { key: "Restricted Duty", value: "Restricted Duty" },
    { key: "No Impact", value: "No Impact" },
  ];

  const treatementOptions = [
    { key: "Yes", value: "Yes" },
    { key: "No", value: "No" },
  ];

  const incidentTypes = [
    { key: "Injury", value: "Injury" },
    { key: "Illness", value: "Illness" },
    { key: "Near Miss", value: "Near miss" },
  ];

  const incidentSeverity = [
    { key: "Minor", value: "Minor" },
    { key: "Moderate", value: "Moderate" },
    { key: "Severe", value: "Severe" },
  ];

  const fileInputRef = React.useRef(null);
  const authToken = getCookie("auth_token_admin");
  const onFileSelected = async (event) => {
    const selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      const response = await axios.post(
        APIEndpoints.UPLOAD_ATTACHMENT,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const attachmentUrl = response.data.document_data;
      setImageFile(attachmentUrl);
      enqueueSnackbar(
        response.data.message || "Attachment added successfully",
        { variant: "success" }
      );
    } catch (error) {
      enqueueSnackbar("Failed to add attachment. Please try again.", {
        variant: "error",
      });
    }
  };

  const onFileUpload = async () => {
    fileInputRef.current.click();
  };
  const onFileUploadAndSelected = () => {
    onFileUpload();
  };

  const handleFileUpload = (file) => {
    setSelectedFile(file);
  };
  const handleFileRemove = () => {
    setSelectedFile(null);
  };

  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Employees Health and Safety Incident Record
          </Typography>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
                <CeroDate
                  label="Date"
                  value={formik.values.date}
                  onChange={(date) => formik.setFieldValue("date", date)}
                  assessmentPeriod={formik.values.assessmentPeriod}
                  year={formik.values.year}
                  error={formik.touched.date && formik.errors.date}
                />
                <CeroSelect
                  required
                  id="impactOnWork"
                  name="impactOnWork"
                  label="Impact on Work"
                  options={impactTypes}
                  selectedValue={formik.values.impactOnWork}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
                <CeroSelect
                  required
                  id="typeOfIncident"
                  name="typeOfIncident"
                  label="Type of Incident"
                  options={incidentTypes}
                  selectedValue={formik.values.typeOfIncident}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <CeroSelect
                  required
                  id="medicalTreatment"
                  name="medicalTreatment"
                  label="Medical Treatment provided"
                  options={treatementOptions}
                  selectedValue={formik.values.medicalTreatment}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
                <CeroSelect
                  required
                  id="severtiyOfIncident"
                  name="severtiyOfIncident"
                  label="Severity of Incident"
                  options={incidentSeverity}
                  selectedValue={formik.values.severtiyOfIncident}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <CeroInput
                  required
                  id="noOfAffected"
                  name="noOfAffected"
                  label="Number of Affected Employees"
                  fullWidth
                  value={formik.values.noOfAffected}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
            </Grid>
            <CeroInput
              required
              id="details"
              name="details"
              label="Details about the incident"
              value={formik.values.details}
              fullWidth
              multiline
              rows="2"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.details && formik.errors.details}
              classes={{ container: classes.textAreaContainer }}
            />
            <CeroInput
              required
              id="correctiveAction"
              name="correctiveAction"
              label="Corrective Action"
              value={formik.values.correctiveAction}
              fullWidth
              multiline
              rows="2"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.correctiveAction &&
                formik.errors.correctiveAction
              }
            />
            <FileUpload
              boxClass={classes.fileDiv}
              onFileUpload={handleFileUpload}
              onFileRemove={handleFileRemove}
            />
            {/* <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={onFileSelected}
            /> */}
          </Box>
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            disabled={
              !formik.dirty ||
              !formik.isValid ||
              addEmissionData.status === STATUS.RUNNING
            }
            className={clsx(classes.button, classes.buttonPrimary)}
            onClick={onAddEmployeeHealthDetails}
          />
          <CeroButton
            buttonText="Clear"
            variant="outlined"
            className={classes.buttonSecondary}
            onClick={formik.resetForm}
          />
        </Box>
      </Container>
    </>
  );
};

export default AddEmployeesHealthForm;
