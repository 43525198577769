import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    height: "auto",
    border: "none",
    "@media (min-width: 1200px)": {
      maxWidth: "none",
    },
  },
  container: {
    padding: theme.spacing(2),
    width: "100%",
    height: "auto",
    border: "none",
    marginTop: theme.spacing(8),
  },
  tableContainer: {
    marginTop: theme.spacing(5),
    border: "1px solid black",
    borderRadius: 8,
    paddingBottom: "0px !important",
    minHeight: "none",
    maxHeight: "none",
    "& .MuiTable-root": {
      paddingBottom: "0px !important",
    },
  },
  title: {
    textAlign: "left",
    fontWeight: 600,
    padding: theme.spacing(4, 0),
    textTransform: "uppercase",
  },
  sectionTitle: {
    fontSize: 30,
    fontWeight: 450,
    color: theme.palette.Primary.background,
    padding: theme.spacing(4, 0),
  },
  selectCheckbox: {
    padding: 0,
    color: "#8C2148",
    borderRadius: "20%",
    margin: theme.spacing(2, 2, 2, 5),
    "& .MuiFormControlLabel-root.MuiFormControlLabel-label.Mui-disabled": {
      color: "#5A404E",
    },
  },
  selectCheck: {
    padding: 0,
    color: "#8C2148",
    borderRadius: "20%",
    margin: theme.spacing(2, 2, 2, 6),
    "& .css-jvw3lj.Mui-disabled": {
      color: "#5A404E",
    },
  },
  label: {
    "& .MuiFormControlLabel-label.Mui-disabled": {
      color: "black !important",
    },
  },
  textAreaContainer: {
    marginBottom: theme.spacing(6),
    width: "100%",
    height: "auto",
    "& .css-1ps9hi4-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
      WebkitTextFillColor: "black !important",
    },
    " & .css-10m59zd.Mui-disabled": {
      WebkitTextFillColor: "black !important",
    },
  },
  gridItem: {
    margin: theme.spacing(5, 0, 5, 0),
    "& .css-i4bv87-MuiSvgIcon-root": {
      margin: "-4px",
    },
  },
  otherinput: {
    margin: theme.spacing(5, 0, 12, 0),
  },
  questionList: {
    padding: theme.spacing(0, 5),
  },
  paperTop: {
    padding: theme.spacing(0, 5),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  completed: {
    fontWeight: 500,
    textTransform: "capitalize",
  },
  textField: {
    width: "30%",
    background: "white",
    margin: theme.spacing(3, 0),
    "& .css-14mxzot-MuiInputBase-input-MuiOutlinedInput-input": {
      textAlign: "center",
    },
    "&:hover": {
      backgroundColor: "#EFEFEF",
    },
  },
  input: {
    "& label.Mui-focused": {
      color: theme.palette.Primary.main,
    },
    "& .MuiInput-underline:after": {
      border: "none",
    },
    "& .MuiInput-root:hover::before": {
      border: "none",
    },
    "& .MuiFilledInput-root:hover::before": {
      border: "none",
    },
    "& .MuiFilledInput-underline:after": {
      border: "none",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .css-czrb7a-MuiFormLabel-root-MuiInputLabel-root,.css-kyi7ki-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
      {
        color: theme.palette.Primary.main,
      },
  },
  editBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerCell: {
    border: "0.5px solid #5A404E",
  },
  tableBodyCell: {
    border: "0.5px solid #5A404E",
    fontWeight: 550,
  },
  tableTitle: {
    textAlign: "center",
  },
  popupTitle: {
    backgroundColor: theme.palette.Primary.hoverButton,
    color: "white",
    padding: theme.spacing(5),
    marginBottom: theme.spacing(8),
  },
  icon: {
    color: theme.palette.Primary.hoverButton,
    fontSize: "xxx-large",
    marginBottom: theme.spacing(8),
  },
  message: {
    marginBottom: theme.spacing(8),
  },
  loader: {
    width: "100%",
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  backContainer: {
    color: theme.palette.Primary.background,
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  filterContainer: {
    margin: theme.spacing(3, 0, 8, 0),
    display: "flex",
    alignItems: "center",
  },
  yearFilter: {
    "& .MuiBox-root": {
      height: "42px !important",
      margin: theme.spacing(3, 0, 0, 0),
      padding: "0px !important",
    },
    "& .MuiBox-root > .MuiAutocomplete-root ": {
      background: "white",
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root":
      {
        height: 46,
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputBase-root > input[type=text]":
      {
        paddingBlock: 0,
        paddingInline: 0,
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .Mui-focused":
      {
        color: theme.palette.Primary.main,
        marginTop: "0 !important",
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiFormLabel-filled":
      {
        color: theme.palette.Primary.main,
        marginTop: "0 !important",
      },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputLabel-outlined":
      {
        marginTop: -3,
      },
    "& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
      WebkitTextFillColor: "#024FE4",
      fontWeight: 500,
    },
  },
  autoComplete: {
    width: "100%",
    marginRight: theme.spacing(4),
  },
  companyName: {
    fontWeight: 500,
    textTransform: "capitalize",
    marginBottom: theme.spacing(2),
  },
  inputGrid: {
    margin: theme.spacing(5, 0),
    paddingBottom: theme.spacing(8),
  },
  subScoreDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  scoreDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  scoreHeadDiv: {
    textAlign: "left",
    width: "235px",
  },
  sliderQuestion: {
    margin: theme.spacing(2, 0),
    width: "70%",
    "& .MuiTypography-root": {
      paddingLeft: "0px",
    },
  },
  sliderDiv: {
    width: "20%",
  },
  default: {
    width: "100%",
  },
  sliderQuestionMain: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  slider: {
    color: "#E0E0E0", // Change the slider color
    "& .MuiSlider-thumb": {
      backgroundColor: "#8C2148", // Thumb color
    },
    "& .MuiSlider-track": {
      backgroundColor: "#E0E0E0", // Track color
    },
    "& .MuiSlider-rail": {
      backgroundColor: "#E0E0E0", // Optional: Change the rail color if needed
    },
  },
}));

export default useStyles;
