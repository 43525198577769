import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Container,
  Typography,
  Switch,
  Stack,
  Grid,
  Paper,
  Tabs,
  Tab,
  Checkbox,
} from "@mui/material";
import DashboardLayout from "../../layouts/DashboardLayout";
import CeroTable from "../../components/CeroTable";
import { APIEndpoints, STATUS } from "../../redux/constants";
import useStyles from "./styles";
import ApprovalstatusIcon from "../../assets/icons/ApprovalIcon.png";
import AuditIcon from "../../assets/icons/AuditIcon .png";
import GovernanceIcon from "../../assets/icons/GovernanceIcon.png";
import { assignAuditor, getAuditTypes, resetAssignAuditor, updateAuditTypes } from "../../redux/actions";
import CeroAutoComplete from "../../components/CeroAutoComplete";
import { sampleYear } from "../../constants";
import { getCookie } from "../../services/cookie";
import CeroPopUp from "../../components/CeroPopUp";
import CloseIcon from "@mui/icons-material/Close";
import CeroButton from "../../components/CeroButton";
import CeroInput from "../../components/CeroInput";
import WarningIcon from "../../assets/icons/WarningIcon.png";
import { useSnackbar } from "notistack";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "@mui/styles/styled";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";


const AuditTypeManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);
  const [auditSettingPopup, setAuditSettingPopup] = useState(false);
  const [warningPopup, setWarningPopup] = useState(false);
  const [itemId, setItemId] = useState("");
  const [name,setName] = useState("");
  const [emailValidMessage,setEmailValidMessage] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const authToken = getCookie("auth_token_admin");
  const navigate = useNavigate();
  const availableModules = Cookies.get("availableModules") ? Cookies.get("availableModules") : "{}";
  
  const auditTypeColumns = [
    {
      columnKey: "country",
      columnId: "country",
      columnHeader: "Country",
    },
    {
      columnKey: "assessment_year",
      columnId: "assessment_year",
      columnHeader: "Year",
    },
    {
      columnKey: "period",
      columnId: "period",
      columnHeader: "Audit Period",
    },
    {
      columnKey: "action",
      columnId: "action",
      columnHeader: "Audit Type",
    },
    {
      columnKey: "assigned_to_name",
      columnId: "assigned_to_name",
      columnHeader: "Auditor",
    },
    {
      columnKey: "topics",
      columnId: "topics",
      columnHeader: "Audit Items",
    },
  ];
  // const auditHistoryColumns = [
  //   {
  //     columnKey: "country",
  //     columnId: "country",
  //     columnHeader: "Country",
  //   },
  //   {
  //     columnKey: "assessment_year",
  //     columnId: "assessment_year",
  //     columnHeader: "Year",
  //   },
  //   {
  //     columnKey: "period",
  //     columnId: "period",
  //     columnHeader: "Audit Period",
  //   },
  //   {
  //     columnKey: "auditType",
  //     columnId: "auditType",
  //     columnHeader: "Audit Type",
  //   },
  //   {
  //     columnKey: "assigned_to_name",
  //     columnId: "assigned_to_name",
  //     columnHeader: "Auditor",
  //   },
  //   {
  //     columnKey: "topics",
  //     columnId: "topics",
  //     columnHeader: "Audit Items",
  //   },
  //   {
  //     columnKey: "dateAdded",
  //     columnId: "dateAdded",
  //     columnHeader: "Date Added",
  //   },
  // ];
  const [selectedTopic, setSelectedTopic] = useState(0);
  const [selectedYear, setselectedYear] = useState("Year");
  const readOnlyMode = getCookie("read_only_mode") === "disable_edit";
  const [loading,setLoading] = useState(false);

  // const handleTabChange = (event, newValue) => {
  //   setSelectedTopic(newValue);
  //   setauditData([]);
  //   setLoading(true);
  // };

  const handleHistory = () => {
    navigate('history');
  }

  useEffect(() => {
    setLoading(true);
    if (selectedTopic === 0) {
      dispatch(getAuditTypes(0, "in progress,added"));
    } else if (selectedTopic === 1) {
      dispatch(getAuditTypes(selectedYear==="Year" || selectedYear===null?0:selectedYear, ""));
    }
  }, [selectedTopic, selectedYear]);

  const auditTypeData = useSelector(
    (state) => state.listings.getAuditTypes.data
  );

  const updateStatus = useSelector(
    (state) => state.approval.updateAuditTypes.status
  );

  const auditorAssignStatus = useSelector(
    (state) => state.audit.assignAuditor
  );

  const [auditData, setauditData] = useState([]);
  useEffect(() => {
    setauditData(auditTypeData);
    setLoading(false);
  }, [auditTypeData]);

  useEffect(() => {
    if (updateStatus === STATUS.SUCCESS) dispatch(getAuditTypes(0, "added"));
  }, [updateStatus]);

  // const yearList = sampleYear().map((item) => ({
  //   id: item.key,
  //   label: item.value,
  // }));

  // const iconMapping = {
  //   History: <img src={ApprovalstatusIcon} alt="Environmental" />,
  //   "Audit Management": <img src={AuditIcon} alt="Audit" />,
  //   Governance: <img src={GovernanceIcon} alt="Governance" />,
  // };

  const handleClosePopup = () => {
    setAuditSettingPopup(false);
  };
  const initialSelectedTopics = {
    environmental: false,
    social: false,
    governance: false,
    carbon_offset: false
  };
  const [email, setEmail] = useState('');
  const [selectedTopics, setSelectedTopics] = useState(initialSelectedTopics);

  const handleCheckboxChange = (topic) => {
    setSelectedTopics({
      ...selectedTopics,
      [topic]: !selectedTopics[topic],
    });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Required'),
    }),
    onSubmit: ()=>{},
  });

  const handleConfirm = () => {
    const email = formik.values.email
    const requestData = {
      email,
      save: true,
      topics: Object.keys(selectedTopics).filter((topic) => selectedTopics[topic]),
      audit_id: itemId,
    };
    dispatch(assignAuditor(requestData))
    handleCancel()
  }

  useEffect(() => {
    if (auditorAssignStatus.status === STATUS.SUCCESS) {
      dispatch(getAuditTypes(0, "in progress,added"));
      enqueueSnackbar("Auditor & Audit Type chosen successfully !", { variant: "success" });
      dispatch(resetAssignAuditor())
    } else if (auditorAssignStatus.status === STATUS.ERROR) {
      enqueueSnackbar(auditorAssignStatus.message.message, { variant: "error" });
      dispatch(resetAssignAuditor())
      dispatch(getAuditTypes(0, "in progress,added"));
    }
  }, [dispatch,auditorAssignStatus]);

  const handleCancel = () => {
    handleClosePopup()
    setEmail("")
    setItemId("")
    setSelectedTopics(initialSelectedTopics)
  }

  const handleCheckEmail = async(email)=>{
    setEmailValidMessage("")
    try {
      const response = await axios.post(
        APIEndpoints.EMAIL_VALIDATE(),
        {
          email: email,
          save: false,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
    setName(response.data.name)
    } catch (error) {
      setEmailValidMessage(error.response.data.detail)
      setName("")
      // enqueueSnackbar(error.response.data.detail, { variant: 'error' });
    }
  }

  const getExternalAuditorSettings = () => {
    return (
      <Box className= {classes.popUp}>
        <CloseIcon onClick={handleClosePopup} className={classes.closeIcon} />
        <Typography variant="h6" style={{ textAlign: "center" }}>
        Choose Auditor & Audit items
        </Typography>
        <Grid container spacing={2} display="flex" flexDirection="column">
            <Box container spacing={2} p={4} display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                <Typography className={classes.popupLabel} p={1}>
                  Auditor Email ID:
                </Typography>
                <CeroInput
                  required
                  id="email"
                  label="Email"
                  size="small"
                  autoFocus
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email||emailValidMessage}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                    if (!formik.errors.email) {
                      handleCheckEmail(formik.values.email);
                    }
                  }}
                  classes={{ container: classes.textAreaContainer }}
                />
              </Box>
              {name && <Box display="flex" alignItems="center" ml={4}>
                <Typography mr={2}>
                 Name:
                </Typography>
                <Typography fontWeight={450}>
                 {name}
                </Typography>
              </Box>}
            </Box>
            <Box pl={4} display="flex" alignItems="center">
                <Typography className={classes.popupLabel}>
                  Choose Audit Items:
                </Typography>
              <Box display="flex">
                {availableModules.includes("ESG") &&
                <>
                <Grid item className={classes.checkboxContainer}>
                  <Checkbox
                    className={classes.checkbox}
                    // checked={true}
                    // disabled={true}
                    className={classes.checkbox}
                    checked={selectedTopics.environmental}
                  onChange={() => handleCheckboxChange('environmental')}
                  />
                  <Typography className={classes.topic}>
                    Environmental
                  </Typography>
                </Grid>
                <Grid item className={classes.checkboxContainer}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={selectedTopics.social}
                  onChange={() => handleCheckboxChange('social')}
                  />
                  <Typography className={classes.topic}>Social</Typography>
                </Grid>
                <Grid item className={classes.checkboxContainer}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={selectedTopics.governance}
                  onChange={() => handleCheckboxChange('governance')}
                  />
                  <Typography className={classes.topic}>Governance</Typography>
                </Grid>
                </>
                  }
                  {availableModules.includes("Carbon Offset") &&
                <>
                <Grid item className={classes.checkboxContainer}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={selectedTopics.carbon_offset}
                  onChange={() => handleCheckboxChange('carbon_offset')}
                  />
                  <Typography className={classes.topic}>Carbon Offset</Typography>
                </Grid>
                </>
                  }
              </Box>
            </Box>
        </Grid>
        <div className={classes.buttonContainer}>
          <CeroButton
            className={classes.buttonPrimary}
            variant="contained"
            onClick={handleConfirm}
            buttonText="Confirm"
          />
          <CeroButton
            className={classes.cancelButton}
            variant="outlined"
            onClick={handleCancel}
            buttonText="Cancel"
          />
        </div>
      </Box>
    );
  };

  const handleCancelWarning = () => {
    setWarningPopup(false)
  }

  
  const getWarningPopup = () => {
    return(
      <>
      <CloseIcon onClick={handleCancelWarning} className={classes.closeIcon} />
      <Grid container spacing={2} alignItems="center" className={classes.deleteBox}>
          <Grid item xs={2} className={classes.icon}>
            <img src={WarningIcon} alt="Logo" width="40" height="40" />
          </Grid>
          <Grid item xs ={10}>
            <Typography variant="h6" style={{ textAlign: "center" }}>
          Audit Type Change Restricted!
        </Typography>
          </Grid>
        </Grid>
        <Typography style={{ textAlign: "center" }}>Once Selected, the audit type cannot be changed from External to Internal.</Typography>
        <CeroButton
            className={classes.warningButton}
            variant="contained"
            onClick={handleCancelWarning}
            buttonText="Ok"
          />
      </>
    )
  }

  const handleSwitchChange = (e, selfAudited) => {
    const newCheckedState = e.target.checked;
    if (newCheckedState === false) {
      setAuditSettingPopup(true);
    } else {
      setWarningPopup(true);
    }
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 35,
    height: 20,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 17,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
        color: "white",
      },
    },
    '& .MuiSwitch-switchBase': {
      paddingTop: 2,
      paddingLeft: 3,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: 'green',
          borderRadius: 16,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 17,
      height: 16,
      borderRadius: 16,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16,
      opacity: 1,
      backgroundColor: 'red',
      boxSizing: 'border-box',
    },
  }));
  
  const getAuditData = () =>
    auditData?.map((item) => ({
      ...item,
      auditType: item.is_self_audited ? "Internal" : "External",
      action: (
        // <Stack direction="row" spacing={1} alignItems="center">
        //   <Typography
        //     style={{ fontWeight: !item.is_self_audited ? "bold" : "normal" }}
        //     className={classes.statusText}
        //   >
        //     External
        //   </Typography>
        //   <Switch
        //     checked={item.is_self_audited}
        //     className={
        //        item.is_self_audited
        //         ? classes.switchColor
        //         : classes.switchColorInactive
        //     }
        //     inputProps={{ "aria-label": "Toggle Status" }}
        //     // onChange={(e) => handleToggleStatus(item._id, e.target.checked)}
        //     // onChange={(e) => setAuditSettingPopup(true)}
        //     onChange={(e) => {handleSwitchChange(e,item.is_self_audited);setItemId(item._id)}}
        //     disabled={readOnlyMode}
        //   />
        //   <Typography
        //     style={{ fontWeight: item.is_self_audited ? "bold" : "normal" }}
        //     className={classes.statusText}
        //   >
        //     Internal
        //   </Typography>
        // </Stack>
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
             <Typography
            style={{ fontWeight: !item.is_self_audited ? "bold" : "normal" }}
            className={classes.statusText}
          >
            External
          </Typography>              
          <AntSwitch 
          checked={item.is_self_audited}
            className={
               item.is_self_audited
                ? classes.switchColor
                : classes.switchColorInactive
            }
            inputProps={{ "aria-label": "Toggle Status" }}
            // onChange={(e) => handleToggleStatus(item._id, e.target.checked)}
            // onChange={(e) => setAuditSettingPopup(true)}
            onChange={(e) => {handleSwitchChange(e,item.is_self_audited);setItemId(item._id)}}
            disabled={readOnlyMode} />
              <Typography
            style={{ fontWeight: item.is_self_audited ? "bold" : "normal" }}
            className={classes.statusText}
          >
            Internal
          </Typography>            
          </Stack>
      
      ),
      topics: (
        <Grid>
          {availableModules.includes("ESG") &&
                <>
                <Grid item className={classes.checkboxContainer}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={item.topics ? item.topics.includes("environmental") : false}
                  />
                  <Typography className={classes.topic}>
                    Environmental
                  </Typography>
                </Grid>
                <Grid item className={classes.checkboxContainer}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={item.topics ? item.topics.includes("social") : false}
                  />
                  <Typography className={classes.topic}>Social</Typography>
                </Grid>
                <Grid item className={classes.checkboxContainer}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={item.topics ? item.topics.includes("governance") : false}
                  />
                  <Typography className={classes.topic}>Governance</Typography>
                </Grid>
                </>
                }
                {availableModules.includes("Carbon Offset") &&
                <>
                <Grid item className={classes.checkboxContainer}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={item.topics ? item.topics.includes("carbon_offset") : false}
                  />
                  <Typography className={classes.topic}>Carbon Offset</Typography>
                </Grid>
                </>
                }
              </Grid>
      )
    }));


    // const getAuditHistory = () =>
    // auditData?.map((item) => ({
    //   ...item,
    //   auditType: item.is_self_audited ? "Internal" : "External",
    //   action: (
    //     <Stack direction="row" spacing={1} alignItems="center">
    //       <Typography
    //         style={{ fontWeight: !item.is_self_audited ? "bold" : "normal" }}
    //         className={classes.statusText}
    //       >
    //         External
    //       </Typography>
    //       <Switch
    //         checked={item.is_self_audited}
    //         className={
    //           item.is_self_audited
    //             ? classes.switchColor
    //             : classes.switchColorInactive
    //         }
    //         inputProps={{ "aria-label": "Toggle Status" }}
    //         // onChange={(e) => handleToggleStatus(item._id, e.target.checked)}
    //         onChange={(e) => setAuditSettingPopup(true)}
    //         disabled={readOnlyMode}
    //       />
    //       <Typography
    //         style={{ fontWeight: item.is_self_audited ? "bold" : "normal" }}
    //         className={classes.statusText}
    //       >
    //         Internal
    //       </Typography>
    //     </Stack>
    //   ),
    //   topics: (
    //   <Typography className={classes.topic}>
    //     {item.topics ? item.topics.map((topic, index) => (
    //     <div key={index}>{topic}</div>
    //       )) : ""}
    //   </Typography>
    //   ),
    //   dateAdded: dayjs(item.date_added).format('DD/MM/YYYY')
    // }));

  return (
    <DashboardLayout>
      <Container className={classes.container}>
        <Paper
          className={classes.containerBox}
          style={{
            backgroundColor: (() => {
              switch (selectedTopic) {
                case 0:
                  return "#F8F7F9";
                case 1:
                  return "#F8F7F9";
                case 2:
                  return "#F8F7F9";
                default:
                  return "#E0E0E0";
              }
            })(),
          }}
        >
          {/* <div className={classes.tabsContainer}>
            <Tabs
              value={selectedTopic}
              className={classes.tabs}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              {["Audit Management", "History"]?.map((topic, index) => (
                <Tab
                  className={classes.tabOption}
                  key={index}
                  style={{
                    backgroundColor:
                      selectedTopic === index ? "#F8F7F9" : "#E0E0E0",
                    color: selectedTopic === index ? "white" : "inherit",
                    flexGrow: 1,
                    border:
                      selectedTopic === index ? "3px solid #5A404E" : "none",
                    borderBottom:
                      selectedTopic === index ? "none" : "3px solid #5A404E",
                  }}
                  label={
                    <div
                      className={classes.tabLabel}
                      onClick={() => console.log("")}
                    >
                      {iconMapping[topic]}
                      <span className={classes.tabTitle}>{topic}</span>
                    </div>
                  }
                />
              ))}
            </Tabs>
            <div className={classes.tabBorder}></div>
          </div>
          {selectedTopic === 0 ? (
            <div className={classes.contentSection}>
              <CeroTable
                columns={auditTypeColumns}
                data={getAuditData()}
                hasMore={false}
                loading={loading}
                classes={{ tableContainer: classes.tableContainer }}
              />
            </div>
          ) : (
            <div className={classes.contentSection}>
              <div className={classes.yearSelection}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Year"
                  value={selectedYear}
                  onChange={(e, value) => setselectedYear(value?value.id:null)}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </div>
              <CeroTable
                columns={auditHistoryColumns}
                data={getAuditHistory()}
                hasMore={false}
                loading={loading}
                classes={{ tableContainer: classes.tableContainer }}
                onSelectRow={() => console.log("")}
              />
            </div>
          )} */}
          <CeroPopUp
            primaryPopUp={{
              open: auditSettingPopup,
              onClose: { handleClosePopup },
              content: getExternalAuditorSettings(),
              header: { title: "" },
            }}
            classes={{ dialog: classes.dialog }}
          />
          <CeroPopUp
            primaryPopUp={{
              open: warningPopup,
              onClose: { handleCancelWarning },
              content: getWarningPopup(),
              header: { title: "" },
            }}
            classes={{ dialog: classes.dialog }}
          />
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <CeroButton
            className={`${classes.buttonPrimary} ${classes.buttonAlignment}`}
            variant="contained"
            onClick={handleHistory}
            buttonText="History"
            />
          <div>
              <CeroTable
                columns={auditTypeColumns}
                data={getAuditData()}
                hasMore={false}
                loading={loading}
                classes={{ tableContainer: classes.tableContainer }}
              />
            </div>
          </div>
        </Paper>
      </Container>
    </DashboardLayout>
  );
};

export default AuditTypeManagement;
