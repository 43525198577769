import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Grid, Typography, Box } from "@mui/material";
import { useSnackbar } from 'notistack';
import { STATUS } from "../../../redux/constants";
import { schemeValidation } from './schema';
import { addLocalCommunities, getAssessmentPeriod, resetAddCombustionStatus } from '../../../redux/actions';
import CeroButton from '../../../components/CeroButton';
import CeroSelect from '../../../components/CeroSelect';
import CeroInput from '../../../components/CeroInput';
import useStyles from "./styles";
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CeroAutoComplete from '../../../components/CeroAutoComplete';
import { sampleYear } from '../../../constants';
import FileUpload from '../FileUpload';

const AddLocalCommunitiesForm = (props) => {
    const { onCancel } = props
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [selectedFile, setSelectedFile] = useState(null);

    const facilitiesData = useSelector(state => state.listings.listFacilities.data);
    const addEmissionData = useSelector(state => state.emission.addLocalCommunities)
    const currentYear = new Date().getFullYear().toString()

    const formik = useFormik({
        initialValues: {
            facility: '',
            assessmentPeriod: '',
            year: currentYear,
            amount: '',
            localCommunity: '',
            projectUndertaken: '',
            objective: '',
            activitiesExecuted: '',
            resultAchieved: '',
        },
        validationSchema: schemeValidation,
        onSubmit: () => { },
    });
    
      const assessmentData = useSelector(
        (state) => state.assessment.assessmentPeriod.data
      );
    
      const assessmentOptions = Array.isArray(assessmentData)
        ? assessmentData.map((item) => ({
            key: item.period,
            value: item.period,
          }))
        : [];

        const facilitiesList = facilitiesData.map((item) => ({
            key: item.id,
            value: item.name,
          }));
    
      const yearList = sampleYear().map((item) => ({
        id: item.key,
        label: item.value,
      }));
    
      useEffect(() => {
        const { facility, year } = formik.values;
        if (facility && year) {
          dispatch(getAssessmentPeriod(year, facility, 'local_communities'));
        }
      }, [formik.values.facility, formik.values.year, dispatch]);
      
    useEffect(() => {
        if (addEmissionData.status === STATUS.SUCCESS) {
            enqueueSnackbar('Local Communities added successfully', { variant: 'success' });
            dispatch(resetAddCombustionStatus())
            onCancel();
        } else if (addEmissionData.status === STATUS.ERROR) {
            enqueueSnackbar(addEmissionData.message.message || "We couldn't process your request. Please try again later.", { variant: 'error' });
            dispatch(resetAddCombustionStatus())
        }
    }, [addEmissionData, dispatch, enqueueSnackbar, onCancel])

    const onAddLocalCommunitiesDetails = () => {
        const requestData = {
            facility_id: formik.values.facility,
            assessment_period: formik.values.assessmentPeriod,
            year: formik.values.year,
            amount: formik.values.amount,
            local_community: formik.values.localCommunity,
            project_undertaken: formik.values.projectUndertaken,
            objective: formik.values.objective,
            activities_executed: formik.values.activitiesExecuted,
            result_achieved: formik.values.resultAchieved,
            file:selectedFile,
            save: true,
        }
        sessionStorage.setItem('year', requestData.year)
        dispatch(addLocalCommunities(requestData))
    };

    const handleFileUpload = (file) => {
      setSelectedFile(file);
    };
    const handleFileRemove = () => {
      setSelectedFile(null);
    };
    

    return (
        <>
    <div className={classes.backContainer} onClick={()=>{navigate(-1)}}>
      <ArrowBackIosIcon />
      Back
    </div>
        <Container className={classes.container}>
            <Box className={classes.innerContainer}>
                <Typography className={classes.title} variant="h6" component="div" >Add Local Communities</Typography>
                <Box>
                <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={8}
            >
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
                <CeroInput
                  required
                  id="localCommunity"
                  name="localCommunity"
                  label="Name of local community"
                  fullWidth
                  value={formik.values.localCommunity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.localCommunity && formik.errors.localCommunity}
                />
              </Grid>
              <Grid item container direction={"column"} md={6} xs={12}>
                <CeroAutoComplete
                  required
                  id="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
                <CeroInput
                  required
                  id="amount"
                  name="amount"
                  label="Total Contributions"
                  fullWidth
                  value={formik.values.amount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.amount && formik.errors.amount}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={12}>
                <CeroSelect
                  required
                  id="assessmentPeriod"
                  name="assessmentPeriod"
                  label="Assessment Period"
                  fullWidth
                  options={assessmentOptions}
                  selectedValue={formik.values.assessmentPeriod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.assessmentPeriod &&
                    formik.errors.assessmentPeriod
                  }
                />
              </Grid>
            </Grid>
            <CeroInput
              required
              id="projectUndertaken"
              name="projectUndertaken"
              label="Describe the projects undertaken."
              fullWidth
              multiline
              rows="2"
              value={formik.values.projectUndertaken}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.projectUndertaken && formik.errors.projectUndertaken}
              classes={{ container: classes.textAreaContainer }}
            />
            <CeroInput
              required
              id="objective"
              name="objective"
              label="Describe the main objective of the projects."
              fullWidth
              multiline
              rows="2"
              value={formik.values.objective}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.objective && formik.errors.objective}
              classes={{ container: classes.textAreaContainer }}
            />
            <CeroInput
              required
              id="activitiesExecuted"
              name="activitiesExecuted"
              label="Describe activities that were executed as part of the engagement projects."
              fullWidth
              multiline
              rows="2"
              value={formik.values.activitiesExecuted}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.activitiesExecuted && formik.errors.activitiesExecuted}
              classes={{ container: classes.textAreaContainer }}
            />
            <CeroInput
              required
              id="resultAchieved"
              name="resultAchieved"
              label="Summarize the results achieved as a result of the engagement projects."
              fullWidth
              multiline
              rows="2"
              value={formik.values.resultAchieved}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.resultAchieved && formik.errors.resultAchieved}
              classes={{ container: classes.textAreaContainer }}
            />
                </Box>
            <FileUpload onFileUpload={handleFileUpload} onFileRemove={handleFileRemove} />
            </Box>
            <Box className={classes.buttonContainer}>
                <CeroButton
                    buttonText="Add Data"
                    disabled={!formik.dirty || !formik.isValid || addEmissionData.status === STATUS.RUNNING}
                    className={clsx(classes.button, classes.buttonPrimary)}
                    onClick={onAddLocalCommunitiesDetails} />
                    <CeroButton
                    buttonText="Clear"
                    variant="outlined"
                    className={classes.buttonSecondary}
                    onClick={formik.resetForm} />
            </Box>
        </Container >
        </>
    )
}

export default AddLocalCommunitiesForm;
