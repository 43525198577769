import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    maxWidth: "30vw",
    width: "30vw",
  },
  drawerContentArea: {
    padding: theme.spacing(4),
    overflow: "auto",
  },
  selectContainer: {
    margin: theme.spacing(5, 0),
  },
  multiselect: {
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.Primary.main}`,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.Primary.main,
    },
    "& .css-czrb7a-MuiFormLabel-root-MuiInputLabel-root,.css-kyi7ki-MuiFormLabel-root-MuiInputLabel-root.Mui-focused":
      {
        color: theme.palette.Primary.main,
      },
    "& .css-j0qy1l-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: theme.palette.Primary.main,
      },
  },
  selectCheckbox: {
    padding: theme.spacing(3),
  },
  checkbox: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    maxWidth: "27vw",
    border: "1px solid #5A404E",
    borderRadius: 4,
    maxHeight: "55vh",
    overflow:"scroll",
    '&::-webkit-scrollbar': {
        width: '0.4em',
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        outline: '1px solid slategrey',
        borderRadius:8
      }
  },
  scrollBar: {
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
  MultiSelectformControl: {
    marginTop: 12,
    width: '100%',
    height: 60,
    "& .Mui-focused": {
      backgroundColor: '#fff'
    },
    "& .MuiFormLabel-filled":{
      backgroundColor: '#fff'
    },
    "& .MuiInputLabel-root": {
      margin:0,
      left:theme.spacing(0),
      top:theme.spacing(0)
    }
  },
  mainContainer:{
    "& .MuiBox-root":{
      marginBottom:"0px !important"
    },
    "& .MuiBox-root > .MuiAutocomplete-root > .MuiFormControl-root > .Mui-focused ":{
      color:theme.palette.Primary.main
    },
  },
  yearFilter:{
    "& .Mui-focused": {
      backgroundColor: '#fff',
      color:theme.palette.Primary.main
    },
    "& .MuiFormLabel-filled":{
      backgroundColor: '#fff',
      color:theme.palette.Primary.main
    },
    "& .MuiOutlinedInput-root": {
      width: '110%'
    }
  },
  'input-padding':{
    paddingTop:'10px !important'
  },
  'no-padding':{
    paddingTop:'0px'
  },
  gridContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  gridItem : {
  width: '48%',
  }
}));

export default useStyles;
