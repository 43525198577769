import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { STATUS } from "../../../redux/constants";
import CeroInput from "../../../components/CeroInput";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate, useParams } from "react-router-dom";
import { getSupplierSurveyQuestions } from "../../../redux/actions/supplierScreening";
import CeroButton from "../../../components/CeroButton";
import { supplierSurveyResponse } from "../../../redux/actions/supplierScreening";
import { useSnackbar } from "notistack";
import { resetSupplierScreeningStatus } from "../../../redux/actions/supplierScreening";
import axios from "axios";
import { styled } from "@mui/material/styles";
import { APIEndpoints } from "../../../redux/constants";
import CeroPhoneInput from "../../../components/CeroPhoneInput";
import CeroAutoComplete from "../../../components/CeroAutoComplete";
import { getCountryList, getStatesPublic, getSustainabilityRiskSurveyQuestions, resetSustainabilityRiskScreeningStatus, sustainabilityRiskSurveyResponse } from "../../../redux/actions";

const SustainabilityRiskAssessmentResponse = () => {
  const classes = useStyles();
  const [selectedValues, setSelectedValues] = useState({});
  const dispatch = useDispatch();
  const { answerId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [textAnswers, setTextAnswers] = useState({});
  const [state, setState] = useState('');
  const [country, setcountry] = useState('');
  const [companyNumber, setCompanyNumber] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  
  const [supplierInfoAnswers, setSupplierInfoAnswers] = useState({});
  const [requiredAnswers, setRequiredAnswers] = useState({});

  const [errors, setErrors] = React.useState({});
  const [touched, setTouched] = React.useState({});
  const requiredFields = [
    'Company Name',
    'Company Number',
    'First Name',
    'Last Name',
    'Designation',
    'Email ID',
    'Contact Number',
  ];

  const validateInput = (value, fieldName) => {
    let error = '';
    if (requiredFields.includes(fieldName)) {
      if (!value.trim()) {
      error = `${fieldName} is required.`;
    } else if (['Company Number', 'Contact Number'].includes(fieldName) && value.length<10){
      error = `${fieldName} is not entered properly`;
    } else if (fieldName.toLowerCase().includes('email') && !/\S+@\S+\.\S+/.test(value)) {
      error = 'Invalid email format.'
    }
     else {
      setRequiredAnswers({ ...requiredAnswers, [fieldName]: value })
    }
  }
  setErrors((prev) => ({ ...prev, [fieldName]: error }));
};

const isFormValid = () => {
  // Check if all required fields are filled and no errors exist
  return requiredFields.every((field) => {
    const value = requiredAnswers[field];
    const error = errors[field];
    return value && !error;
  });
};

const handleBlur = (fieldName, value) => {
  setTouched((prev) => ({ ...prev, [fieldName]: true }));
  // Trigger validation on blur if the field is required
  validateInput(value, fieldName);
};

  const supplierQuestionsList = useSelector((state) => {
    const data = state.sustainabilityRiskAssessment.getSustainabilityRiskAssessementSurveyQuestions.data;
    return Array.isArray(data) ? data : [];
  });
  const questionListStatus = useSelector(
    (state) => state.sustainabilityRiskAssessment.getSustainabilityRiskAssessementSurveyQuestions.status
  );
  const formStatus = useSelector(
    (state) => state.sustainabilityRiskAssessment.sustainabilityRiskAssessementSurveyResponse
  );

  useEffect(()=>{
    dispatch(getStatesPublic(country.id))
  }, [country])

  const handleCheckboxChange = (event, questionId) => {
    const value = event.target.value;
    setSelectedValues((prevValues) => {
      const questionValues = prevValues[questionId] || [];
      if (questionValues.includes(value)) {
        return {
          ...prevValues,
          [questionId]: questionValues.filter((item) => item !== value),
        };
      } else {
        return {
          ...prevValues,
          [questionId]: [...questionValues, value],
        };
      }
    });
  };

  const handleFileUpload = async (file, questionId) => {
    const formData = new FormData();
    const files = []
    for (let i=0; i<file.length; i++)
      formData.append("file", file[i]);
    try {
      const response = await axios.post(
        APIEndpoints.SURVEY_FILE_UPLOAD(answerId, questionId),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const blobUrl = response.data?.blob_url;
      enqueueSnackbar("File uploaded successfully.", {
        variant: "success",
      });
      if (blobUrl) {
        setTextAnswers((prevAnswers) => ({
          ...prevAnswers,
          [`file-${questionId}`]: blobUrl,
        }));
      }
    } catch (error) {
      enqueueSnackbar("File upload failed. Please try again.", {
        variant: "error",
      });
    }
  };

  const onSubmit = () => {
    const answerList = supplierQuestionsList.map((question) => {
      let answer = "";
      let answer_value = "";
      if (
        question.question_type === "text" ||
        question.question_type === "Text Field"
      ) {
        answer = textAnswers[question.id] || "";
      } else if (
        question.question_type === "choose_from_option" ||
        question.question_type === "Choose from Options"
      ) {
        answer = selectedValues[question.id] || "";

        if (answer === "Other (please specify)") {
          answer_value = textAnswers[`other-${question.id}`] || "";
        }
      } else if (question.question_type === "multiselect_check_box") {
        const selectedOptions = selectedValues[question.id] || [];
        answer = selectedOptions.join(", ");
        if (selectedOptions.includes("Other (please specify)")) {
          answer_value = textAnswers[`other-${question.id}`] || "";
        }
      } else if (question.question_type === "upload") {
        answer = textAnswers[`file-${question.id}`] || "";
      }
      return {
        question_id: question.id,
        answer,
        question_type: question.question_type,
        ...(answer_value && { answer_value }),
      };
    });
    Object.entries(supplierInfoAnswers).forEach(([key, value]) => {
      answerList.push({
        question_id: key,
        answer: value,
        question_type: typeof value === "object" ? "object" : "text",
        answer_value:""
      });
    });
    const payload = {
      answer_list: answerList,
      company_name:companyName
    };
    console.log("nik", payload, answerId);
    
    
    dispatch(sustainabilityRiskSurveyResponse(answerId, payload));
  };

  useEffect(() => {
    if (formStatus.status === STATUS.SUCCESS) {
      navigate("/supplier-assessment/survey-response");
    } else if (formStatus.status === STATUS.ERROR) {
      enqueueSnackbar(
        formStatus.message ||
          "We couldn't process your request. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      dispatch(resetSustainabilityRiskScreeningStatus());
    }
  }, [formStatus, dispatch, enqueueSnackbar]);

  const handleTextInputChange = (questionId, value) => {
    setTextAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: value,
    }));
  };

  const handleSingleSelect = (questionId, selectedOption) => {
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [questionId]: selectedOption,
    }));

    if (selectedOption === "Other") {
      setTextAnswers((prevAnswers) => ({
        ...prevAnswers,
        [`other-${questionId}`]: "",
      }));
    }
  };

  useEffect(() => {
    if (answerId) {
      dispatch(getSustainabilityRiskSurveyQuestions(answerId));
      dispatch(getCountryList());
    }
  }, [dispatch, answerId]);

  const groupedQuestions = supplierQuestionsList?.reduce((acc, question) => {
    const section = question.section || "Uncategorized";
    if (!acc[section]) {
      acc[section] = [];
    }
    acc[section].push(question);
    return acc;
  }, {});

  function reorderLists(data) {
    Object.keys(data).forEach((key) => {
      const list = data[key];
      const reorderedList = [];
      const itemMap = new Map();
  
      // Create a map of items based on their `_id`
      list.forEach((item) => {
        itemMap.set(item.id, item);
      });
  
      // Iterate through the list and reorder dynamically
      list.forEach((item) => {
        if (!reorderedList.includes(itemMap.get(item.id))) {
          reorderedList.push(item); // Add the main item first
        }
        // Find and add all items with matching `question_id`
        const relatedItems = list.filter((related) => related.question_id === item.id);
        relatedItems.forEach((relatedItem) => {
          if (!reorderedList.includes(relatedItem)) {
            reorderedList.push(relatedItem);
          }
        });
      });
  
      // Add any remaining items that weren't matched
      const remainingItems = list.filter((item) => !reorderedList.includes(item));
      reorderedList.push(...remainingItems);
  
      // Update the original list with the reordered one
      data[key] = reorderedList;
    });
  
    return data;
  }
  

  const VisuallyHiddenInput = styled("input")({
    position: "absolute",
    width: "1px",
    height: "1px",
    padding: 0,
    margin: "-1px",
    overflow: "hidden",
    clip: "rect(0,0,0,0)",
    border: 0,
  });

  let questionNumber = 1;

  const countryListData = useSelector(
    (state) => state.listings.countryList.data
  );
  const countryList = countryListData.map((item) => ({
    id: item.code,
    label: item.name,
  }));
  const stateList = useSelector((state) => state.listings.getStates.data);
  const stateNames = Object.values(stateList).map(
    (stateObj) => Object.values(stateObj)[0]
  );

  // const renderSupplierInfo = (infoQuestions) =>{

  //   delete groupedQuestions['Company Profile']
  //   var qtIndex = 1;
  //   const nameCont = infoQuestions?.filter(item=>['company name', 'company phone number' ].includes(item.question.toLowerCase()))
  //   const addrCntct = infoQuestions?.filter(item=>['company address', 'contact' ].includes(item.question.toLowerCase()))
  //   const others = infoQuestions?.filter(item=>['email id', 'designation', 'contact number' ].includes(item.question.toLowerCase()))
    
  //   return(
  //     <>
  //   <Typography
  //                       fontSize={18}
  //                       fontWeight={500}
  //                       variant="body1"
  //                       className={classes.mainQuestion}
  //                     >
  //                       {`1. Supplier information:`}
  //                     </Typography>
  //                     <Box className={classes.supInfo}>
  //                     {nameCont?.map((it,index)=>
  //                       <Grid key={it.id} paddingLeft={6} className={classes.supInfoHalfContent}>
  //                       <Typography className={classes.subQuestion}>
  //                         {`${String.fromCharCode(96 + qtIndex+index)}. ${it.question}`}
  //                       </Typography>
  //                       <Box>
  //                         {it.question.toLowerCase().includes('phone')?
  //                         <CeroPhoneInput
  //                         required
  //                         fullWidth
  //                         label="Company Number"
  //                         name="company phone"
  //                         value={companyNumber}
  //                         onChange={(value) => {
  //                           setCompanyNumber(value)
  //                           setSupplierInfoAnswers({...supplierInfoAnswers, [it.id]:value})
  //                         }}
  //                         error={Boolean(errors['Company Number'])}
  //                         helperText={errors['Company Number']}
  //                         onBlur={(event) => handleBlur('Company Number', event.target.value)}
  //                         // onBlur={userDetailsForm.handleBlur}
  //                         // error={userDetailsForm.touched.phone && userDetailsForm.errors.phone}
  //                         classes={{ container: classes.phoneInput }}
  //                       />: 
  //                         <CeroInput
  //                           classes={{container : classes.inputContainer}}
  //                           rows={2}
  //                           placeholder={"Please type your Answer"}
  //                           id={`answer-${it.id}-${it.question}`}
  //                           name={`answer-${it.id}-${it.question}`}
  //                           label={`Enter ${it.question}`}
  //                           multiline
  //                           onChange={(event)=>{
  //                             setSupplierInfoAnswers({...supplierInfoAnswers, [it.id]:event.target.value});
  //                             it.question.toLowerCase().includes('company name') && setCompanyName(event.target.value)
  //                           }}
  //                           error={Boolean(errors[it.question])}
  //                           helperText={errors[it.question]}
  //                           onBlur={(event) => handleBlur(it.question, event.target.value)}
  //                             />
  //                         }
                          
  //                       </Box>
  //                       </Grid>
  //                     )}
  //                     {addrCntct?.map((it,index)=>
  //                       <Grid key={it.id} paddingLeft={6} className={classes.supInfoFullContent}>
  //                       <Typography className={classes.subQuestion}>
  //                         {`${String.fromCharCode(96 + (nameCont?.length ? nameCont?.length:0) + qtIndex+index)}. ${it.question}`}
  //                       </Typography>
  //                       <Box className={classes.addressInner}>
  //                         {Object.entries(it.sub_fields).map(([key,value])=>
  //                         key.includes('Country')? 
  //                         <CeroAutoComplete
  //                           required
  //                           id="country"
  //                           name="country"
  //                           label="Country"
  //                           fullWidth
  //                           options={countryList}
  //                           value={country}
  //                           onChange={(e, value) => {
  //                             setcountry(value)
  //                             setSupplierInfoAnswers({...supplierInfoAnswers, [it.id]:{...supplierInfoAnswers[it.id],[key]:value.id}})}}
  //                           isOptionEqualToValue={(option, value) => option.label === value}
  //                           disabled={false}
  //                           placeholder={key}
  //                         />:key.includes('State')?
  //                         <CeroAutoComplete
  //                           required
  //                           id="state"
  //                           name="state"
  //                           label="State"
  //                           fullWidth
  //                           options={stateNames.map((state) => ({
  //                             label: state,
  //                             value: state,
  //                           }))}
  //                           value={state}
  //                           onChange={(e, value) => {
  //                             setState(value)
  //                             setSupplierInfoAnswers({...supplierInfoAnswers, [it.id]:{...supplierInfoAnswers[it.id],[key]:value.value}})}}
  //                           isOptionEqualToValue={(option, value) => option.label === value}
  //                           disabled={false}
  //                         />:
  //                         <CeroInput
  //                           classes={{container : key.toLowerCase().includes('postal') ?classes.inputContainerFreeMargin :  classes.inputContainer}}
  //                           rows={2}
  //                           placeholder={key}
  //                           id={`answer-${it.id}-${it.question}`}
  //                           name={`answer-${it.id}-${it.question}`}
  //                           label={`Enter ${key}`}
  //                           multiline
  //                           onChange={(event)=>{
  //                             setSupplierInfoAnswers({...supplierInfoAnswers, [it.id]:{...supplierInfoAnswers[it.id],[key]:event.target.value}})
  //                           }}
  //                           error={Boolean(errors[key])}
  //                           helperText={errors[key]}
  //                           onBlur={(event) => handleBlur(key, event.target.value)}
  //                         />)}
  //                       </Box>
  //                       </Grid>
  //                     )}
  //                     {others?.map((it,index)=>
  //                       <Grid key={it.id} paddingLeft={6} className={classes.supInfoHalfContent}>
  //                       <Typography className={classes.subQuestion}>
  //                         {`${String.fromCharCode(96 + (nameCont?.length ? nameCont?.length:0) + (addrCntct?.length ? nameCont?.length:0) + qtIndex+index)}. ${it.question}`}
  //                       </Typography>
  //                       <Box>
  //                       {it.question.toLowerCase().includes('number')?
  //                         <CeroPhoneInput
  //                         required
  //                         fullWidth
  //                         label="Contact Number"
  //                         name="phone"
  //                         value={contactNumber}
  //                         onChange={(value) => {
  //                           setContactNumber(value)
  //                           setSupplierInfoAnswers({...supplierInfoAnswers, [it.id]:value})
  //                           // validateInput(it.id, value, "Contact Number")
  //                         }
  //                       }
  //                         // onBlur={userDetailsForm.handleBlur}
  //                         // error={userDetailsForm.touched.phone && userDetailsForm.errors.phone}
  //                         classes={{ container: classes.phoneInput }}
  //                         error={Boolean(errors["Contact Number"])}
  //                         helperText={errors["Contact Number"]}
  //                         onBlur={(event) => handleBlur("Contact Number", event.target.value)}

  //                       />: 
  //                         <CeroInput
  //                           classes={{container : classes.inputContainer}}
  //                           rows={2}
  //                           placeholder={"Please type your Answers"}
  //                           id={`answer-${it.id}-${it.question}`}
  //                           name={`answer-${it.id}-${it.question}`}
  //                           label={`Enter ${it.question}`}
  //                           multiline
  //                           onChange={(event)=>{
  //                             setSupplierInfoAnswers({...supplierInfoAnswers, [it.id]:event.target.value})
  //                           }}
  //                           error={Boolean(errors[it.question])}
  //                           helperText={errors[it.question]}
  //                           onBlur={(event) => handleBlur(it.question, event.target.value)}
  //                         />}
  //                       </Box>
  //                       </Grid>
  //                     )}
  //                     </Box>
  //                     </>

  // )}

  
  return (
    <Paper className={classes.container}>
      <Box mb={4} display="flex" justifyContent="space-between" paddingY={2}>
        <Typography fontSize={20} fontWeight={550} variant="h3">
        SUSTAINABILITY RISK ASSESSMENT QUESTIONNAIRE
        </Typography>
      </Box>
      {questionListStatus === STATUS.SUCCESS ? (
        <>
        {/* {renderSupplierInfo(groupedQuestions['Company Profile'])} */}
          {Object.entries(reorderLists(groupedQuestions))?.map(
            ([sectionTitle, questions]) => (
              <Box key={sectionTitle} mb={4}>
                {questions?.map((question) => {
                  if (question.question_id === null) {
                    const currentQuestionNumber = questionNumber;
                    questionNumber += 1;
                    return (
                      <Grid
                        key={question.id}
                        className={
                          question.question_type === "text"
                            ? classes.inputGrid
                            : classes.gridItem
                        }
                      >
                        <Typography
                          fontSize={18}
                          fontWeight={500}
                          variant="body1"
                          className={classes.mainQuestion}
                        >
                          {`${currentQuestionNumber}. ${question.question}`}
                        </Typography>
                        <Box
                          display="flex"
                          flexDirection="column"
                          paddingLeft={6}
                          className={classes.layout}
                        >
                          {question.question_type === "multiselect_check_box" &&
                            question.options.map((option, idx) => {
                              return (<>
                                <FormControlLabel
                                  key={idx}
                                  control={
                                    <Checkbox
                                      className={classes.selectCheckbox}
                                      checked={
                                        selectedValues[question.id]?.includes(
                                          option
                                        ) || false
                                      }
                                      onChange={(e) =>
                                        handleCheckboxChange(e, question.id)
                                      }
                                      value={option}
                                      sx={{
                                        color: "#5A404E !important",
                                        "&.Mui-checked": {
                                          color: "#8C2148 !important",
                                        },
                                      }}
                                    />
                                  }
                                  label={option}
                                  className={classes.label}
                                />
                                {selectedValues[question.id]?.includes(
                                  "Other (please specify)"
                                ) &&
                                  option === "Other (please specify)" && (
                                    <CeroInput
                                      classes={{
                                        container: classes.inputContainer,
                                      }}
                                      variant="outlined"
                                      placeholder="Please type your Answer"
                                      multiline
                                      rows={3}
                                      value={
                                        textAnswers[`other-${question.id}`] ||
                                        ""
                                      }
                                      onChange={(e) =>
                                        handleTextInputChange(
                                          `other-${question.id}`,
                                          e.target.value
                                        )
                                      }
                                      className={classes.input}
                                    />
                                  )}
                              </>)}
                            )}

                          {(question.question_type === "choose_from_option" ||
                            question.question_type === "Choose from Options") &&
                            question.options.map((option, idx) => {
                              return (
                                <>
                                  <FormControlLabel
                                    key={idx}
                                    control={
                                      <Checkbox
                                        className={classes.selectCheckbox}
                                        checked={
                                          selectedValues[question.id] === option
                                        }
                                        onChange={() =>
                                          handleSingleSelect(
                                            question.id,
                                            option
                                          )
                                        }
                                        value={option}
                                        sx={{
                                          color: "#5A404E !important",
                                          borderWidth: "none",
                                          "&.Mui-checked": {
                                            color: "#8C2148 !important",
                                          },
                                        }}
                                      />
                                    }
                                    label={option}
                                  />
                                  {selectedValues[question.id] ===
                                    "Other (please specify)" &&
                                    option === "Other (please specify)" && (
                                      <TextField
                                        variant="outlined"
                                        placeholder="Please type your Answer"
                                        multiline
                                        rows={3}
                                        value={
                                          textAnswers[`other-${question.id}`] ||
                                          ""
                                        }
                                        onChange={(e) =>
                                          handleTextInputChange(
                                            `other-${question.id}`,
                                            e.target.value
                                          )
                                        }
                                        className={classes.input}
                                      />
                                    )}
                                </>
                              );
                            })}
                          {question.question_type === "upload" && (
                            <Box className={classes.upload}>
                              <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                className={classes.buttonPrimary}
                                startIcon={<CloudUploadIcon />}
                              >
                                Upload files
                                <VisuallyHiddenInput
                                  type="file"
                                  onChange={(e) => {
                                    // const file = []
                                    // for (let i=0;i<e.target.files.length; i++)
                                    // {
                                    //   file.push(e.target.files[i])
                                    // }
                                    if (e.target.files.length) {
                                      handleFileUpload(e.target.files, question.id);
                                    }
                                  }}
                                  multiple
                                />
                              </Button>
                            </Box>
                          )}
                          {question.question_type === "text" && (
                            <Box>
                              <CeroInput
                                classes={{ container: classes.inputContainer }}
                                rows={3}
                                placeholder={"Please type your Answer"}
                                id={`answer-${question.id}`}
                                name={`answer-${question.id}`}
                                label="Please type your Answer"
                                value={textAnswers[question.id] || ""}
                                onChange={(e) =>
                                  handleTextInputChange(
                                    question.id,
                                    e.target.value
                                  )
                                }
                                multiline
                              />
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    );
                  }
                  const subQuestionLetter = String.fromCharCode(
                    96 +
                      supplierQuestionsList
                        .filter((q) => q.question_id === question.question_id)
                        .indexOf(question) +
                      1
                  );
                  return (
                    <Grid key={question.id} paddingLeft={6}>
                      <Typography className={classes.subQuestion}>
                        {`${subQuestionLetter}. ${question.question}`}
                      </Typography>
                      {(question.question_type === "choose_from_option" ||
                            question.question_type === "Choose from Options") &&
                            question.options.map((option, idx) => {
                              return (
                                <>
                                  <FormControlLabel
                                    key={idx}
                                    control={
                                      <Checkbox
                                        className={classes.selectCheckbox}
                                        checked={
                                          selectedValues[question.id] === option
                                        }
                                        onChange={() =>
                                          handleSingleSelect(
                                            question.id,
                                            option
                                          )
                                        }
                                        value={option}
                                        sx={{
                                          color: "#5A404E !important",
                                          borderWidth: "none",
                                          "&.Mui-checked": {
                                            color: "#8C2148 !important",
                                          },
                                        }}
                                      />
                                    }
                                    label={option}
                                  />
                                  {selectedValues[question.id] ===
                                    "Other (please specify)" &&
                                    option === "Other (please specify)" && (
                                      <TextField
                                        variant="outlined"
                                        placeholder="Please type your Answer"
                                        multiline
                                        rows={3}
                                        value={
                                          textAnswers[`other-${question.id}`] ||
                                          ""
                                        }
                                        onChange={(e) =>
                                          handleTextInputChange(
                                            `other-${question.id}`,
                                            e.target.value
                                          )
                                        }
                                        className={classes.input}
                                      />
                                    )}
                                </>
                              );
                            })}
                            {question.question_type === "multiselect_check_box" &&
                            question.options.map((option, idx) => {
                              return (<>
                                <FormControlLabel
                                  key={idx}
                                  control={
                                    <Checkbox
                                      className={classes.selectCheckbox}
                                      checked={
                                        selectedValues[question.id]?.includes(
                                          option
                                        ) || false
                                      }
                                      onChange={(e) =>
                                        handleCheckboxChange(e, question.id)
                                      }
                                      value={option}
                                      sx={{
                                        color: "#5A404E !important",
                                        "&.Mui-checked": {
                                          color: "#8C2148 !important",
                                        },
                                      }}
                                    />
                                  }
                                  label={option}
                                  className={classes.label}
                                />
                                {selectedValues[question.id]?.includes(
                                  "Other (please specify)"
                                ) &&
                                  option === "Other (please specify)" && (
                                    <CeroInput
                                      classes={{
                                        container: classes.inputContainer,
                                      }}
                                      variant="outlined"
                                      placeholder="Please type your Answer"
                                      multiline
                                      rows={3}
                                      value={
                                        textAnswers[`other-${question.id}`] ||
                                        ""
                                      }
                                      onChange={(e) =>
                                        handleTextInputChange(
                                          `other-${question.id}`,
                                          e.target.value
                                        )
                                      }
                                      className={classes.input}
                                    />
                                  )}
                              </>)}
                            )}
                      {question.question_type === "text" && (
                        <Box>
                          <CeroInput
                            classes={{ container: classes.inputContainer }}
                            rows={2}
                            placeholder={"Please type your Answer"}
                            id={`answer-${question.id}`}
                            name={`answer-${question.id}`}
                            label="Please type your Answer"
                            value={textAnswers[question.id] || ""}
                            onChange={(e) =>
                              handleTextInputChange(question.id, e.target.value)
                            }
                            multiline
                          />
                        </Box>
                      )}
                      {question.question_type === "upload" && (
                        <Box className={classes.upload}>
                          <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            className={classes.buttonPrimary}
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload files
                            <VisuallyHiddenInput
                              type="file"
                              onChange={(e) => {
                                
                                if (e.target.files.length) {
                                  handleFileUpload(e.target.files, question.id);
                                }
                              }}
                              multiple
                            />
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  );
                })}
              </Box>
            )
          )}
          <div className={classes.buttonSubmitContainer}>
            <CeroButton
              buttonText="Submit Survey"
              className={classes.buttonPrimary}
              onClick={onSubmit}
              // disabled={!isFormValid()} // Disable if invalid
            />
          </div>
        </>
      ) : (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
    </Paper>
  );
};

export default SustainabilityRiskAssessmentResponse;
