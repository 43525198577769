import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Container, Grid, Typography, Box, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";

import { months, sampleYear } from "../../../constants";
import { STATUS } from "../../../redux/constants";
import { addWaterDischargeCombustionValidation } from "./schema";
import {
  addWaterDischargeCombustion,
  getEmissionInputFormat,
  resetAddCombustionStatus,
} from "../../../redux/actions";

import CeroAutoComplete from "../../../components/CeroAutoComplete";
import CeroButton from "../../../components/CeroButton";
import CeroSelect from "../../../components/CeroSelect";
import CeroInput from "../../../components/CeroInput";
import CeroInfoPair from "../../../components/CeroInfoPair";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FileUpload from "../FileUpload";

const AddWaterDischargeForm = (props) => {
  const { onCancel } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const isCalculateDone = useSelector(
    (state) => state.emission.addWaterDischarge.isCalculateDone
  );
  const facilitiesData = useSelector(
    (state) => state.listings.listFacilities.data
  );
  const addEmissionData = useSelector(
    (state) => state.emission.addWaterDischarge
  );
  const emissionInputs = useSelector(
    (state) => state.emission.emissionInputs.data
  );

  const facilitiesList = facilitiesData.map((item) => ({
    key: item.id,
    value: item.name,
  }));
  const fuelUnits =
    emissionInputs &&
    (emissionInputs.units || []).map((item) => ({
      key: item.name,
      value: item.name,
    }));
  const waterDestinationList =
    emissionInputs &&
    (emissionInputs.water_destinations || []).map((item) => ({
      key: item.id,
      value: item.name,
    }));
  const stressTypeList =
    emissionInputs &&
    (emissionInputs.water_destination_stress_types || []).map((item) => ({
      key: item.id,
      value: item.name,
    }));
  const destinationTypeList =
    emissionInputs &&
    (emissionInputs.water_destination_types || []).map((item) => ({
      key: item.id,
      value: item.name,
    }));
  const yearList = sampleYear().map((item) => ({
    id: item.key,
    label: item.value,
  }));
  const currentYear = new Date().getFullYear().toString();
  const [selectedFile, setSelectedFile] = useState(null);
  const treatmentLevelList = ["Primary", "Secondary", "Tertiary"].map(
    (item, index) => ({ key: index + 1, value: item })
  );
  const formik = useFormik({
    initialValues: {
      facility: "",
      year: currentYear,
      month: "",
      destination: "",
      stressType: "",
      destinationType: "",
      treatmentRequired: "",
      treatmentLevel: "",
      amountOfFuel: null,
      fuelUnit: "",
    },
    validationSchema: addWaterDischargeCombustionValidation,
    onSubmit: () => {},
  });

  useEffect(() => {
    dispatch(getEmissionInputFormat("water_discharge"));
    return () => {
      dispatch(resetAddCombustionStatus());
    };
  }, [dispatch]);

  useEffect(() => {
    if (addEmissionData.status === STATUS.SUCCESS) {
      enqueueSnackbar("Water discharge added successfully", {
        variant: "success",
      });
      dispatch(resetAddCombustionStatus());
      onCancel();
    } else if (addEmissionData.status === STATUS.ERROR) {
      enqueueSnackbar(
        addEmissionData.message.message ||
          "We couldn't process your request. Please try again later.",
        { variant: "error" }
      );
      dispatch(resetAddCombustionStatus());
    }
  }, [addEmissionData, dispatch, enqueueSnackbar, onCancel]);

  useEffect(() => {
    if (!formik.values.treatmentRequired) {
      formik.setFieldValue("treatmentLevel", ""); // Reset treatmentLevel to ""
    }
  }, [formik.values.treatmentRequired]);

  const handleFileUpload = (file) => {
    setSelectedFile(file);
    // dispatch(uploadEmissionAttachement("emissionId", file));
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };

  const onCalculate = () => {
    const requestData = {
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      destination_id: formik.values.destination,
      stress_type_id: formik.values.stressType,
      destination_type_id: formik.values.destinationType,
      treatment_required: formik.values.treatmentRequired,
      treatment_level: formik.values.treatmentLevel,
      amount: parseFloat(formik.values.amountOfFuel),
      unit: formik.values.fuelUnit,
      save: false,
    };
    dispatch(addWaterDischargeCombustion(requestData));
  };

  const onAddWaterDischargeData = () => {
    const requestData = {
      facility_id: formik.values.facility,
      year: formik.values.year,
      month: formik.values.month,
      destination_id: formik.values.destination,
      stress_type_id: formik.values.stressType,
      destination_type_id: formik.values.destinationType,
      treatment_required: formik.values.treatmentRequired,
      treatment_level: formik.values.treatmentLevel,
      amount: parseFloat(formik.values.amountOfFuel),
      unit: formik.values.fuelUnit,
      save: true,
      file: selectedFile,
    };
    sessionStorage.setItem("year", requestData.year);
    dispatch(addWaterDischargeCombustion(requestData));
  };

  const info = () => {
    return (
      <div>
        <Typography variant="body2">
          Stress type: refers to whether a water source is experiencing scarcity
          or not.
          <br />
          Stressed source: There is not enough water available to meet demand or
          needs.
          <br />
          Not stressed source: There is enough water available to meet demand
          and needs without issue.
          <br />
        </Typography>
      </div>
    );
  };

  return (
    <>
      <div
        className={classes.backContainer}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackIosIcon />
        Back
      </div>
      <Container className={classes.container}>
        <Box className={classes.innerContainer}>
          <Typography className={classes.title} variant="h6" component="div">
            Add Water Discharge
          </Typography>
          <Box className={classes.topContainer}>
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="space-between"
              spacing={4}
            >
              <Grid item container direction="column" xs={4}>
                <CeroSelect
                  required
                  id="facility"
                  name="facility"
                  label="Facility"
                  fullWidth
                  options={facilitiesList}
                  selectedValue={formik.values.facility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.facility && formik.errors.facility}
                />
                <CeroSelect
                  required
                  id="month"
                  name="month"
                  label="Month"
                  fullWidth
                  options={months}
                  selectedValue={formik.values.month}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.month && formik.errors.month}
                />
                <CeroSelect
                  required
                  id="destination"
                  name="destination"
                  label="Destination"
                  fullWidth
                  options={waterDestinationList}
                  selectedValue={formik.values.destination}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.destination && formik.errors.destination
                  }
                />
                <CeroSelect
                  required
                  id="treatmentRequired"
                  name="treatmentRequired"
                  label="Treatment Required"
                  fullWidth
                  options={[
                    { key: true, value: "Yes" },
                    { key: false, value: "No" },
                  ]}
                  selectedValue={formik.values.treatmentRequired}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.treatmentRequired &&
                    formik.errors.treatmentRequired
                  }
                />
                <CeroInput
                  required
                  id="amountOfFuel"
                  name="amountOfFuel"
                  label="Amount of Water"
                  value={formik.values.amountOfFuel}
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.amountOfFuel && formik.errors.amountOfFuel
                  }
                  type="number"
                />
              </Grid>
              <Grid
                item
                container
                direction="column"
                xs={1}
                style={{ position: "relative" }}
              >
                <span className={classes.treatementRequired}>
                  <Tooltip
                    title="If the discharged  water needs purification to meet environmental standards before released into environment."
                    classes={{ popper: classes.tooltip }}
                    arrow
                    placement="right-start"
                  >
                    <InfoOutlinedIcon className={classes.infoIcon} />
                  </Tooltip>
                </span>
              </Grid>
              <Grid item container direction={"column"} xs={4}>
                <CeroSelect
                  required
                  id="stressType"
                  name="stressType"
                  label="Stress type"
                  fullWidth
                  options={stressTypeList}
                  selectedValue={formik.values.stressType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.stressType && formik.errors.stressType}
                />
                <CeroAutoComplete
                  id="year"
                  label="Assessment Year"
                  onChange={(e, value) =>
                    formik.setFieldValue("year", value.id)
                  }
                  onBlur={formik.handleBlur}
                  error={formik.touched.year && formik.errors.year}
                  options={yearList}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  value={formik.values.year}
                />
                <CeroSelect
                  required
                  id="destinationType"
                  name="destinationType"
                  label="Destination Type"
                  fullWidth
                  options={destinationTypeList}
                  selectedValue={formik.values.destinationType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.destinationType &&
                    formik.errors.destinationType
                  }
                  classes={{
                    input: classes.input,
                    container: classes.selectContainer,
                    paperRoot: classes.paperRoot,
                  }}
                />
                <CeroSelect
                  required
                  id="treatmentLevel"
                  name="treatmentLevel"
                  label="Treatment Level"
                  fullWidth
                  options={treatmentLevelList}
                  selectedValue={formik.values.treatmentLevel}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.treatmentLevel &&
                    formik.errors.treatmentLevel
                  }
                  classes={{
                    input: classes.input,
                    container: classes.selectContainer,
                    paperRoot: classes.paperRoot,
                  }}
                  disabled={!formik.values.treatmentRequired}
                />

                <CeroSelect
                  required
                  id="fuelUnit"
                  name="fuelUnit"
                  label="Unit"
                  fullWidth
                  options={fuelUnits}
                  selectedValue={formik.values.fuelUnit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.fuelUnit && formik.errors.fuelUnit}
                />
              </Grid>
              <Grid item container direction="column" md={6} xs={1}>
                <span className={classes.info}>
                  <Tooltip
                    title={info()}
                    classes={{ popper: classes.tooltip }}
                    arrow
                    placement="right-start"
                  >
                    <InfoOutlinedIcon className={classes.infoIcon} />
                  </Tooltip>
                </span>
              </Grid>
            </Grid>
            <CeroButton
              buttonText="Calculate"
              className={classes.buttonPrimary}
              onClick={onCalculate}
              disabled={!formik.dirty || !formik.isValid}
            />
            <CeroButton
              buttonText="Clear"
              variant="outlined"
              className={classes.buttonSecondary}
              onClick={formik.resetForm}
            />
            {isCalculateDone && (
              <Box className={classes.bottomContainer}>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.previewTitle}
                >
                  Emission Preview
                </Typography>
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-between"
                  spacing={8}
                >
                  <Grid item container direction="column" xs={12} md={6}>
                    <CeroInfoPair
                      title="Usage"
                      value={`${addEmissionData.data.usage} ${addEmissionData.data.usage_unit}`}
                    />
                    <CeroInfoPair
                      title={
                        <>
                          CO<sub>2</sub>e
                        </>
                      }
                      value={`${addEmissionData.data.co2e} ${addEmissionData.data.emission_unit}`}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          <FileUpload
            onFileUpload={handleFileUpload}
            onFileRemove={handleFileRemove}
          />
        </Box>
        <Box className={classes.buttonContainer}>
          <CeroButton
            buttonText="Add Data"
            disabled={
              !isCalculateDone || addEmissionData.status === STATUS.RUNNING
            }
            className={classes.buttonPrimary}
            onClick={() => onAddWaterDischargeData(formik.values)}
          />
        </Box>
      </Container>
    </>
  );
};

export default AddWaterDischargeForm;
