import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  drawerConainer: {
    width: "256px",
    height: "100%",
    "& .MuiList-root": {
      paddingLeft: theme.spacing(0),
    },
  },
  logo: {
    width: "8%",
    display: "flex",
    justifyContent: "center",
    marginLeft: theme.spacing(22),
  },
  drawer: {
    width: "256px",
    height: "100%",
  },
  drawerCanvas: {
    width: "256px",
    height: "100%",
  },
  icon: {
    minWidth: 35,
    color: `${theme.palette.Primary.main}!important`,
  },
  activeOption: {
    minWidth: 35,
    color: `${theme.palette.Primary.background}!important`,
    fontWeight: "bold",
    whiteSpace: "nowrap", 
    // paddingRight: theme.spacing(1),
    // backgroundColor: "#B2B2B2"
  },
  activeOptionTrade: {
    minWidth: 35,
    color: `${theme.palette.trade.header}!important`,
    fontWeight: "bold",
    // backgroundColor: "#B2B2B2"
  },
  expandedOption: {
    backgroundColor: "#EFEFEF",
  },
  menuText: {
    minWidth: 35,
    color: `${theme.palette.Primary.main}!important`,
    fontWeight: 500,
    whiteSpace: "nowrap",
    paddingRight: theme.spacing(1)
  },
  title: {
    width: "100%",
    height: "60px",
    padding: theme.spacing(6, 8),
    color: theme.palette.text.accent,
    marginBottom: 15,
    marginTop: 10,
  },
  titleText: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
  },
  userContainer: {
    padding: theme.spacing(4, 4),
    display: "flex",
    alignItems: "center",
  },
  avatarContainer: {
    height: "46px",
    width: "46px",
    marginRight: theme.spacing(5),
  },
  avatar: {
    height: "100%",
    width: "100%",
    borderRadius: "100%",
  },
  userInfoContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  secodaryOptions: {
    borderTop: `1px solid ${theme.palette.border.gray}`,
    marginTop: theme.spacing(2.5),
    paddingTop: theme.spacing(2.5),
  },
  logoText: {
    display: "flex",
    justifyContent: "center",
    marginTop: 6,
    fontWeight: 800,
    fontSize: 40,
    color: theme.palette.Primary.dark,
    fontFamily: "Arial, sans-serif",
  },
  sidebarBranch: {
    position: "relative",
    paddingLeft: "20px",
  },
  sidebarBranchLine: {
    content: "''",
    position: "absolute",
    top: "8px",
    left: 0,
    width: "1px",
    height: "100%",
    backgroundColor: "#ccc",
    zIndex: -1,
  },
  moveToBack: {
    zIndex: "0 !important",
  },
  branchList: {
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 22,
      left: theme.spacing(4),
      width: "1px",
      backgroundColor: "#8C2148",
    },
  },
  // branchList: {
  //   position: "relative",
  //   "&:not(:last-child):before": { // Apply to all except the last child
  //     content: '""',
  //     position: "absolute",
  //     top: 0,
  //     bottom: 22, // Height adjustment for the vertical line
  //     left: theme.spacing(4),
  //     width: "1px",
  //     backgroundColor: "#8C2148",
  //   },
  // },
  branchListItem: {
    paddingLeft: theme.spacing(0),
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      top: 25,
      left: -1,
      width: theme.spacing(2.8),
      height: "1px",
      backgroundColor: "#8C2148",
      transform: "translateX(160%)",
    },
  },
}));

export default useStyles;
