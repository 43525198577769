import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useFormik, FieldArray, FormikProvider } from "formik";
import useStyles from "./styles";
import AddIcon from '@mui/icons-material/Add';
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { STATUS } from "../../../../../redux/constants";
import CeroInput from "../../../../../components/CeroInput";
import CeroButton from "../../../../../components/CeroButton";
import { createSupplierQuestions } from "../../../../../redux/actions/supplierScreening";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { getSupplierQuestions } from "../../../../../redux/actions/supplierScreening";
import { createSustainabilityRiskQuestions, getSustainabilityRiskQuestions } from "../../../../../redux/actions";

const AddQuestions = ({ setOpenQuestionsForm, createQuestionStatus, industry }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const addNewQuestionStatus = useSelector(
    (state) => state.sustainabilityRiskAssessment.createSustainabilityRiskAssessementQuestions.status
  );

  const optionList = [
    { id: 1, label: "Text Field" , value: "text"},
    { id: 2, label: "Choose from Option", value:"choose_from_option" },
    { id: 3, label: "File Upload", value: "upload" },
  ];

  const initialValues = {
    questions: [
      {
        question: "",
        question_type: "",
        options: [""],
        file: null,
      },
    ],
  };

  const validationSchema = Yup.object({
    questions: Yup.array().of(
      Yup.object({
        question: Yup.string().required("Question is required"),
        question_type: Yup.string().required("Type is required"),
        options: Yup.array().of(Yup.string().when("question_type", {
          is: "choose_from_option",
          then: Yup.string().required("Option is required")
        })),
      })
    )
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const payload = {
        questions: values.questions

      };    
      dispatch(createSustainabilityRiskQuestions(industry, payload));
    },
  });

  const handleSubmit = () => {
    formik.handleSubmit();
    setOpenQuestionsForm(false)
  };

  useEffect(() => {
    if (createQuestionStatus === STATUS.SUCCESS) {
      dispatch(getSustainabilityRiskQuestions(industry))
      formik.resetForm();
    }
  }, [createQuestionStatus]);

  useEffect(() => {
    if (addNewQuestionStatus === STATUS.SUCCESS) {
      dispatch(getSustainabilityRiskQuestions(industry))
    }
  }, [addNewQuestionStatus]);

  const addMoreOptionsDisabled = (qIndex) => {
    return !formik.values.questions[qIndex].question || !formik.values.questions[qIndex].question_type;
  };

  const addMoreQuestionnaireDisabled = () => {
    return formik.values.questions.some(question => !question.question || !question.question_type);
  };

  const isFormComplete = () => {
    return formik.values.questions.every((question) => {
      if (!question.question || !question.question_type) {
        return false;
      }
      if (question.question_type === "choose_from_option") {
        return question.options.every(option => option.trim() !== "");
      }
      return true;
    });
  };

  const handleCancel = () => {
    setOpenQuestionsForm(false)
    formik.resetForm(); 
  };

  return (
    <FormikProvider value={formik}>
      <Box className={classes.popUp}>
        <Typography variant="title" style={{ fontWeight: 600}}>
          Add New Questions
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <FieldArray name="questions">
            {({ push, remove }) => (
              <>
                {formik.values.questions.map((question, qIndex) => (
                  <Grid key={qIndex} display={"flex"} flexDirection="column" gap={2} justifyContent="flex-start">
                    <Box display={"flex"} flexDirection="column" gap={2} justifyContent="flex-start">
                      <Typography display={"flex"} fontSize={16} fontWeight={550} variant="body1" style={{ marginTop: 20 }}>
                        Enter Question:
                      </Typography>
                      <CeroInput
                        placeholder={"Please type your Question here"}
                        required
                        id={`questions.${qIndex}.question`}
                        name={`questions.${qIndex}.question`}
                        label="Please type your Question here"
                        value={formik.values.questions[qIndex].question}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.questions?.[qIndex]?.question && Boolean(formik.errors.questions?.[qIndex]?.question)}
                        helperText={formik.touched.questions?.[qIndex]?.question && formik.errors.questions?.[qIndex]?.question}
                        classes={{ container: classes.error }}
                      />
                    </Box>
                    <Grid>
                      <Typography display={"flex"} fontSize={16} fontWeight={550} variant="body1" paddingBottom={2}>
                        Answer Type:
                      </Typography>
                      <Box display="flex" flexDirection="column" paddingLeft={2}>
                        {optionList.map((topic) => (
                          <FormControlLabel
                            key={topic.id}
                            control={
                              <Checkbox
                                checked={formik.values.questions[qIndex].question_type === topic.value}
                                onChange={() => formik.setFieldValue(`questions.${qIndex}.question_type`, topic.value)}
                                value={topic.value}
                                style={{
                                  color: "#8C2148",
                                }}
                              />
                            }
                            label={topic.label}
                          />
                        ))}
                      </Box>
                      {/* {formik.values.questions[qIndex].question_type === "upload" && (
                        <Box className={classes.upload}>
                          <Button
                            component="label"
                            role={undefined}
                            variant="contained"
                            className={classes.buttonPrimary}
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload files
                            <input
                              type="file"
                              hidden
                              onChange={(event) => {
                                formik.setFieldValue(`questions.${qIndex}.file`, event.currentTarget.files[0]);
                              }}
                            />
                          </Button>
                          {formik.errors.questions?.[qIndex]?.file && (
                            <Typography color="error">
                              {formik.errors.questions[qIndex].file}
                            </Typography>
                          )}
                        </Box>
                      )} */}
                      {formik.values.questions[qIndex].question_type === "choose_from_option" && (
                        <FieldArray name={`questions.${qIndex}.options`}>
                          {({ push: pushOption, remove: removeOption }) => (
                            <Grid>
                              <Typography display={"flex"} fontSize={16} fontWeight={450} variant="h3" paddingY={2}>
                                Enter Options:
                              </Typography>
                              {formik.values.questions[qIndex].options.map((option, oIndex) => (
                                <Box key={oIndex} display="flex" paddingLeft={2} gap={2} alignItems={"center"}>
                                  <Typography>Option {oIndex + 1}:</Typography>
                                  <CeroInput
                                    placeholder={`Please enter option ${oIndex + 1} here`}
                                    required
                                    id={`questions.${qIndex}.options.${oIndex}`}
                                    name={`questions.${qIndex}.options.${oIndex}`}
                                    label={`Enter Option ${oIndex + 1}`}
                                    value={option}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.questions?.[qIndex]?.options?.[oIndex] && Boolean(formik.errors.questions?.[qIndex]?.options?.[oIndex])}
                                    helperText={formik.touched.questions?.[qIndex]?.options?.[oIndex] && formik.errors.questions?.[qIndex]?.options?.[oIndex]}
                                    classes={{ container: classes.textAreaContainerValue }}
                                  />
                                </Box>
                              ))}
                              <Box
                                display="flex"
                                paddingY={2}
                                paddingLeft={2}
                                gap={1}
                                onClick={() => !addMoreOptionsDisabled(qIndex) && pushOption("")}
                                style={{
                                  cursor: addMoreOptionsDisabled(qIndex) ? "not-allowed" : "pointer",
                                  color: addMoreOptionsDisabled(qIndex) ? "grey" : "blue",
                                  fontWeight: "bold",
                                }}
                              >
                                <AddIcon />
                                <Typography>Add More Options</Typography>
                              </Box>
                            </Grid>
                          )}
                        </FieldArray>
                      )}
                    </Grid>
                    {formik.values.questions.length > 1 && <Box display="flex" paddingLeft={2} gap={1} paddingY={2} onClick={() => remove(qIndex)}>
                      <AddIcon />
                      <Typography>Remove Question</Typography>
                    </Box>}
                  </Grid>
                ))}
                <Box
                  display="flex"
                  paddingY={2}
                  paddingLeft={2}
                  gap={1}
                  onClick={() => !addMoreQuestionnaireDisabled() && push({ question: "", question_type: "", options: [""], file: null })}
                  style={{
                    cursor: addMoreQuestionnaireDisabled() ? "not-allowed" : "pointer",
                    color: addMoreQuestionnaireDisabled() ? "grey" : "blue",
                    fontWeight: "bold",
                  }}
                >
                  <AddIcon />
                  <Typography>Add More Questions</Typography>
                </Box>
              </>
            )}
          </FieldArray>
          <div className={classes.buttonContainerReview}>
            <CeroButton
              className={classes.buttonPrimary}
              variant="contained"
              type="submit"
              buttonText="Save"
              onClick={handleSubmit}
              disabled={!isFormComplete()} 
            />
            <CeroButton
              className={classes.buttonSecondary}
              variant="outlined"
              onClick={handleCancel} 
              buttonText="Cancel"
            />
          </div>
        </form>
      </Box>
    </FormikProvider>
  );
};

export default AddQuestions;
